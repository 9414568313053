<template>
  <div class="content-text">
    <h1>Согласие на обработку персональных данных</h1>

    <p>
      Пользователь, отправляя информацию при помощи формы обратной связи на
      интернет-сайте всепоплану (далее — Сайт), обязуется принять настоящее
      Согласие на обработку персональных данных (далее — Согласие). Принятием
      (акцептом) оферты Согласия является установка флажка «Подтверждаю согласие
      на обработку персональных данных», либо факт отправки формы обратной
      связи. Пользователь дает свое согласие Компания (далее - Оператор),
      которому принадлежит права использования Сайта и которое расположенного по
      адресу: Адрес, на обработку своих персональных данных со следующими
      условиями:
    </p>
    <ol>
      <li>
        Данное Согласие дается на обработку персональных данных, как без
        использования средств автоматизации, так и с их использованием.
      </li>
      <li>
        Согласие дается на обработку следующих моих персональных данных:
        <ul>
          <li>
            персональные данные, не являющиеся специальными или биометрическими;
          </li>
          <li>фамилия, имя, отчество;</li>
          <li>номера контактных телефонов;</li>
          <li>адрес электронной почты.</li>
        </ul>
      </li>
      <li>
        Цель обработки персональных данных: обеспечение обратной связи Оператора
        с заинтересованными в ней организациями и их представителями.
      </li>
      <li>
        Основанием для обработки персональных данных являются: ст. 24
        Конституции Российской Федерации; ст. 6 Федерального закона № 152-ФЗ «О
        персональных данных»; Свидетельство о государственной
        регистрации|УставОператора; иные федеральные законы и
        нормативно-правовые акты.
      </li>
      <li>
        В ходе обработки с персональными данными могут быть совершены следующие
        действия: сбор, запись, систематизация, накопление, хранение, уточнение
        (обновление, изменение), извлечение, использование, передача
        (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение.
      </li>
      <li>
        Передача персональных данных третьим лицам осуществляется на основании
        законодательства Российской Федерации, договора с участием субъекта
        персональных данных или с согласия субъекта персональных данных.
      </li>
      <li>
        Персональные данные обрабатываются до ликвидации Оператора. Также
        обработка персональных данных может быть прекращена по запросу субъекта
        персональных данных.
      </li>
      <li>
        Согласие может быть отозвано субъектом персональных данных или его
        представителем путем направления письменного заявления в адрес Оператора
        или его представителя.
      </li>
      <li>
        В случае отзыва субъектом персональных данных или его представителем
        согласия на обработку персональных данных Оператор вправе продолжить
        обработку персональных данных без согласия субъекта персональных данных
        при наличии оснований, указанных в пунктах 2–11 части 1 статьи 6
        Федерального закона № 152-ФЗ «О персональных данных» от 27.07.2006 г.
      </li>
      <li>
        Настоящее согласие действует все время до момента прекращения обработки
        персональных данных, указанных в п. 8 и п. 9 данного Согласия.
      </li>
    </ol>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "PersonalData",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/env";
@mixin font_t($size, $lineHeight) {
  font-size: #{$size}px !important;
  line-height: #{$lineHeight}px !important;
  @include _1170 {
    font-size: calc_fluid($size - ($size / 4), $size, 320, 1920) !important;
    line-height: calc_fluid(
      $lineHeight - ($lineHeight / 4),
      $lineHeight,
      320,
      1920
    ) !important;
  }
}
.content-text {
  flex: 1;
  padding: 20px 40px;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: -16px;
  & h1,
  h2,
  h3,
  h4,
  caption {
    font-weight: bold;
    color: $base;
    width: 100%;
  }
  & h2,
  h3,
  h4 {
    margin-top: 24px;
  }
  & p {
    color: $base;
    font-size: calc_fluid(14, 16, 320, 1920);
    line-height: calc_fluid(21, 24, 320, 1920);
    margin-bottom: 16px;
    width: 100%;
  }
  & span {
    color: $base;
    font-size: calc_fluid(14, 16, 320, 1920);
    line-height: calc_fluid(21, 24, 320, 1920);
  }
  & img {
    width: 100% !important;
    object-fit: contain;
  }
  & h1 {
    margin-bottom: 28px;
    @include font_t(38, 43);
  }
  & h2 {
    @include font_t(31, 40);
    margin-bottom: 24px;
  }
  & h3 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }
  & h4 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }
  & ol,
  ul {
    padding-left: 20px;
    margin-bottom: 16px;
    width: 100%;
    & > li {
      width: 100%;
      margin-top: 12px;
      color: $base;
      font-size: calc_fluid(14, 16, 320, 1920);
      line-height: calc_fluid(19, 22, 320, 1920);
      &:first-child {
        margin-top: 0;
      }
    }
  }
  & ol {
    & > li {
      list-style-type: decimal;
    }
  }
  & ul {
    & > li {
      position: relative;
      padding-left: 9px;
    }
  }
  & a {
    color: #eb5757 !important;
    text-decoration: underline;
    &:hover {
      color: #eb5757 !important;
      text-decoration: none;
    }
    &:visited {
      color: #7a33d4 !important;
      text-decoration: underline;
    }
  }
}
</style>
