<script>
import BaseIcon from "../../components/icons/BaseIcon";
import SuccessIcon from "../../components/icons/BaseSuccess";
import ArrowIcon from "../../components/icons/BaseArrow";
import ErrorIcon from "../../components/icons/ProjectPublicationEditorError";
import ProjectMenu from "../../components/ProjectMenu";
import socialModule from "../../components/ProjectSettingsAccointAddSocial";
import targetAccountModule from "../../components/ProjectSettingsAccointAddTarget";
import finishModule from "../../components/ProjectSettingsAccointAddFinish";
import BaseLayout from "@/layouts/BaseLayout"
import {mapState} from "vuex";

export default {
  data() {
    return {
      steps: [
        {
          id: 0, title: "Социальные сети", description: "Выберите социальную сеть для подключения",
          success: false, error: false, component: socialModule
        },
        {
          id: 1, title: "Аккаунт", description: "Выберите аккаунт для подключения.",
          success: false, error: false, component: targetAccountModule
        },
        {
          id: 2, title: "Подключение", description: "Соединение установлено",
          success: false, error: false, component: finishModule
        },
      ],
      currentStep: 0,
      social: "",
      tempToken: "",
      account: {},
    }
  },
  components: {
    BaseIcon,
    SuccessIcon,
    ErrorIcon,
    ProjectMenu,
    ArrowIcon,
    socialModule,
    targetAccountModule,
    BaseLayout,
  },
  methods: {
    onSocialSelected(social, tempToken) {
      this.social = social;
      this.tempToken = tempToken;
    },
    onAccountSelect(account) {
      this.account = account;
    },
    onSuccess() {
      this.steps[this.currentStep].success = true;
      this.currentStep += 1;
      console.log('step', this.currentStep);
    },
    onError() {
      this.steps[this.currentStep].error = true;
    },
    onFinish() {
      this.steps[this.currentStep].success = true;
    },
    onMoreClick() {
      this.steps = [
        {
          id: 0, title: "Социальные сети", description: "Выберите социальную сеть для подключения",
          success: false, error: false, component: socialModule
        },
        {
          id: 1, title: "Аккаунт", description: "Выберите аккаунт для подключения.",
          success: false, error: false, component: targetAccountModule
        },
        {
          id: 2, title: "Подключение", description: "Соединение установлено",
          success: false, error: false, component: finishModule
        },
      ]
      this.currentStep = 0
      this.social = ""
      this.tempToken = ""
      this.account = {}
    }
  },
  computed: {
    currentComponent() {
      console.log(this.steps[this.currentStep])
      return this.steps[this.currentStep].component
    },
    ...mapState(['currentProject', 'projectTime', 'project'])
  }
}
//:project="project" :project-id="currentProject" :time="projectTime"
</script>

<template>
  <BaseLayout>
    <div class="sp-flex">
      <ProjectMenu :project="project" :project-id="currentProject" :time="projectTime"></ProjectMenu>
      <div class="sp-account-add">
        <div class="sp-account-add__title">Подключить новый аккаунт</div>
        <div class="sp-account-add__steps">
          <div class="sp-account-add-step"
               :class="{'sp-account-add-step--current': steps[0].id === currentStep}"
          >
            <base-icon v-show="steps[0].success"
                       width="20"
                       height="20"
                       icon-name="Успешно"
            >
              <success-icon/>
            </base-icon>
            {{ steps[0].title }}
          </div>
          <base-icon width="13.09" height="13.09" icon-name="">
            <arrow-icon/>
          </base-icon>
          <div class="sp-account-add-step"
               :class="{'sp-account-add-step--current': steps[1].id === currentStep}"
          >
            <base-icon v-show="steps[1].success"
                       width="20"
                       height="20"
                       icon-name="Успешно"
            >
              <success-icon/>
            </base-icon>
            <base-icon v-show="steps[1].error"
                       width="20"
                       height="20"
                       icon-name="Ошибка"
                       view-box="0 0 30 30"
            >
              <error-icon/>
            </base-icon>
            {{ steps[1].title }}
          </div>
          <base-icon width="13.09" height="13.09" icon-name="">
            <arrow-icon/>
          </base-icon>
          <div class="sp-account-add-step"
               :class="{'sp-account-add-step--current': steps[2].id === currentStep}"
          >
            <base-icon v-show="steps[2].success"
                       width="20"
                       height="20"
                       icon-name="Успешно"
            >
              <success-icon/>
            </base-icon>
            <base-icon v-show="steps[2].error"
                       width="20"
                       height="20"
                       icon-name="Ошибка"
                       view-box="0 0 30 30"
            >
              <error-icon/>
            </base-icon>
            {{ steps[2].title }}
          </div>
        </div>
        <p class="sp-account-add__description">{{ steps[currentStep].description }}</p>
        <div class="sp-account-add__component">
          <component :is="currentComponent"
                     @socialSelect="onSocialSelected"
                     @accountSelect="onAccountSelect"
                     @success="onSuccess"
                     @error="onError"
                     @finish="onFinish"
                     @more="onMoreClick"
                     :social="social"
                     :tempToken="tempToken"
                     :account="account"
          >

          </component>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<style lang="scss">
@import "/src/assets/scss/env";

.sp-account-add {
  padding: 0 60px 0 30px;
  flex: 1;
  margin: 40px 0;

  &__title {
    @include font(24, 30, bold);
  }

  &__description {
    margin-top: 20px;
    @include font(16, 24);
  }

  &__component {
    margin-top: 20px;
    width: 100%;
    border-top: 1px solid $border;
  }

  &__steps {
    margin-top: 30px;
    display: flex;
    user-select: none;
    align-items: center;

    & > svg {
      & path {
        fill: $text;
      }
    }
  }

  &-step {
    display: flex;
    align-items: center;
    margin: 0 15px;
    padding: 9px 15px;
    border-radius: 8px;
    @include font(14, 16, null, $projectMenuTime);

    & > svg {
      margin-right: 6px;
    }

    &:first-child {
      margin: 0 15px 0 0;
    }

    &--current {
      color: $text;
      background: $focusedBtn;
    }
  }
}
</style>