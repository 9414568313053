<script>
import { validateMedia } from "../logic/project/publications/mediaValidator";
import BaseIcon from "./icons/BaseIcon";
import ImagePreview from "./ImagePreview";
import CloseIcon from "./icons/BaseClose";

export default {
  name: "ProjectPublicationsCreatePhotosUploader",
  data() {
    return {
      currentDropdown: undefined,
      imageDropdownCloseBlocked: true,
      renameModal: {
        target: undefined,
        show: false,
      },
      modelValueCopy: [],
    };
  },
  components: {
    BaseIcon,
    CloseIcon,
    ImagePreview,
  },
  props: {
    currentProject: Number,
    modelValue: Object,
    type: Number,
    groups: Array,
    groupsInfo: Array,
  },
  emits: ["photosUpdate"],
  methods: {
    getObjectUrl(photo) {
      return URL.createObjectURL(photo);
    },
    getGroupsSocialList() {
      return this.groupsInfo
        .filter((v) => this.groups.indexOf(v.id) !== -1)
        .flatMap((v) => v.social);
    },
    async checkErrors() {
      return validateMedia(
        this.getGroupsSocialList(),
        this.type,
        this.modelValue
      );
    },
    preventVideoPlay(e) {
      e.target.pause();
    },
    // file actions dropdown
    openDropdown(file) {
      if (this.currentDropdown === file) this.currentDropdown = undefined;
      else {
        this.currentDropdown = file;
        this.imageDropdownCloseBlocked = true;
      }
    },
    // remove file action
    removeFile(file) {
      let files = this.modelValueCopy,
        index = files.findIndex((value) => value.url === file.url);
      files = [
        ...files.slice(0, index),
        ...files.slice(index + 1, files.length),
      ];
      this.$emit("update:modelValue", files);
    },

    //rename file action methods
    closeRenameModal() {
      this.renameModal.show = false;
    },
    bindRenameModal(file) {
      this.renameModal.target = file;
      this.renameModal.show = true;
    },
    submitRenameModal() {
      this.renameModal.target.name = this.$refs.renameInput.value;
      this.renameModal.target = undefined;
      this.renameModal.show = false;
    },
  },
  created() {
    document.addEventListener("click", (ev) => {
      const target = ev.target || ev.currentTarget;
      if (target.closest(".files-list-item-settings-menu")) return;
      if (this.imageDropdownCloseBlocked) {
        this.imageDropdownCloseBlocked = false;
      } else this.currentDropdown = undefined;
    });
  },
  watch: {
    modelValue: {
      handler: function (v) {
        this.modelValueCopy = v
      },
      immediate: true,
    },
    groups() {
      this.checkErrors();
    },
    type() {
      this.checkErrors();
    },
  },
};
</script>

<template>
  <div class="files-list files-list-pub_editor" v-show="modelValue && modelValue.length">
    <div class="files-list-item sp-btn files-list-item--editable" v-for="file in modelValue" :key="file">
      <div class="files-list-item__remove sp-btn"
           @click.prevent="removeFile(file, 'uploaded')"
      >
        <base-icon height="16" width="16" icon-name="Удалить">
          <close-icon/>
        </base-icon>
      </div>

      <div class="files-list-item-container" v-if="file.type === 1">
        <image-preview :url="file.url">
          <template v-slot:default="{ onClick }">
            <img class="files-list-item__image" :src="file.url" :alt="file.name" />
            <div class="files-list-item__fg" @click="onClick"></div>
          </template>
        </image-preview>
      </div>
      <video
        class="files-list-item__video"
        v-else
        muted="muted"
        :src="file.url"
        :alt="file.name"
        @play="$event.target.pause()"
      ></video>
    </div>
  </div>
  <div>{{ errors }}</div>
  <Modal v-model="renameModal.show" :close="closeRenameModal">
    <div class="rename-modal">
      <p class="rename-modal__title">Изменить название файла</p>
      <div class="rename-modal-inner">
        <input class="rename-modal__input" type="text" ref="renameInput" />
        <div class="rename-modal__btn" @click="submitRenameModal">
          Сохранить
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "../assets/scss/env";
.files-list {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 14px -6px -6px;
  &-pub_editor {
    padding: 0;
    margin-top: 11px;
  }
  &-item {
    object-fit: cover;
    margin: 6px;
    position: relative;
    width: 86px;
    height: 63px;
    $file: &;

    &.warn {
      border: 3px solid #ff9966;
    }
    &.error {
      border: 3px solid #cc3300;
    }
    &__remove {
      position: absolute;
      right: 0;
      top: 0;
      height: 26px;
      width: 26px;
      display: none;
      z-index: 1;
    }
    &__fg {
      transition: background .2s;
      position: absolute;
      left: 0;
      top: 0;
      background: transparent;
      height: 100%;
      width: 100%;
      border-radius: 8px;
    }
    &--editable {
      &:hover {
        #{$file} {
          &__fg {
            background: rgba(0, 0, 0, 0.5);
          }
          &__remove {
            display: flex;
          }
        }
      }

    }
    &-settings {
      position: absolute;
      right: 0;
      display: flex;
      &-container {
        position: relative;
        width: 0;
        height: 0;
      }
      &-menu {
        position: absolute;
        top: 25px;
        left: -25px;
        width: auto;
        height: auto;
        cursor: default;
        z-index: 1;
        padding: 5px 10px;
        background: #282e33;
        border: 1px solid $base;
        border-radius: 10px;
        &-item {
          display: flex;
          padding: 5px;
          cursor: pointer;
          font-size: 14px;
          color: $base;
          justify-content: space-between;
        }
      }
      &-warn {
        position: relative;
        $warn: &;

        &-hint {
          position: absolute;
          left: -130px;
          display: none;
          top: 100%;
          border: 1px solid #ff9966;
          background: $component;
          border-radius: 5px;
          &-item {
            padding: 5px;
            font-size: 12px;
          }
        }
        &:hover {
          #{$warn}-hint {
            display: block;
          }
        }
      }
      &-error {
        position: relative;
        $error: &;

        &-hint {
          position: absolute;
          left: -130px;
          display: none;
          top: 100%;
          border: 1px solid #cc3300;
          background: $component;
          border-radius: 5px;
          &-item {
            padding: 5px;
            font-size: 12px;
          }
        }
        &:hover {
          #{$error}-hint {
            display: block;
          }
        }
      }
      &__icon {
        z-index: 1;
      }
      &.active {
      }
    }
    &-container {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
    }
    &-info {
      padding: 0 5px;
      font-size: 10px;
      background: $component;
      border: 1px solid $base;
      color: $base;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    &__image,
    &__video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    &.selected {
      border: 1px solid limegreen;
    }
  }
}
.rename-modal {
  background: $component;
  border-radius: 10px;
  padding: 20px 25px;
  &__input,
  &__btn {
    width: 100%;
    border: 1px solid $base;
    border-radius: 10px;
    background: $component;
    height: 40px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    color: $base;
    justify-content: center;
  }
  &__title {
    text-align: center;
    color: $base;
  }
  &__btn {
    cursor: pointer;
  }
  &__input {
    padding: 0 20px;
  }
}
</style>
