<script>
export default {
  name: "BaseDraggableWindow.vue",
  data() {
    return {
      x: 40,
      y: 40,
      drag: false,
      resize: {
        topRight: false,
        topLeft: false,
        bottomLeft: false,
        bottomRight: false,
        top: false,
        right: false,
        bottom: false,
        left: false,
      },
      dragWindowX: 0,
      dragWindowY: 0,
    };
  },
  props: {
    width: {default: 700, type: Number},
    height: {default: 400, type: Number},
    top: {default: 40, type: Number},
    left: {default: 40, type: Number},
  },
  computed: {
    cssW() {
      return `${this.width}px`;
    },
    cssH() {
      return `${this.height}px`;
    },
    cssTop() {
      return `${this.y}px`;
    },
    cssLeft() {
      return `${this.x}px`;
    },
  },
  methods: {
    dragStart(evt) {
      console.log(evt)
      this.dragWindowX = evt.offsetX;
      this.dragWindowY = evt.offsetY;
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    },
    dragMove(evt) {
      if (this.drag) {
        console.log(evt.pageX, this.dragWindowX, evt.pageY, this.dragWindowY, evt);
        this.x = evt.pageX - this.dragWindowX;
        this.y = evt.pageY - this.dragWindowY;
      }
      ;
    },
  },
  watch: {
    top: {
      handle: function (v) {
        this.y = v;
      },
      immediate: true,
    },
    left: {
      handle: function (v) {
        this.x = v;
      },
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener("mouseup", this.dragEnd);
    document.addEventListener("mousemove", this.dragMove);
  },
  beforeUnmount() {
    document.removeEventListener("mouseup", this.dragEnd);
    document.removeEventListener("mousemove", this.dragMove);
  },
};
</script>
<template>
  <div class="app-window">
    <div class="app-window__inner">
      <div class="app-window__angle app-window__angle-top_left"></div>
      <div class="app-window__angle app-window__angle-top_right"></div>
      <div class="app-window__angle app-window__angle-bottom_left"></div>
      <div class="app-window__angle app-window__angle-bottom_right"></div>
      <div class="app-window__drag" @mousedown="dragStart">

      </div>
      <div class="app-window__content">
        <slot></slot>
      </div>
    </div>

  </div>
</template>


<style lang="scss" scoped>
.app-window {
  background: $componentBg;
  border: 1px solid black;
  position: fixed;
  top: v-bind('cssTop');
  left: v-bind('cssLeft');
  width: v-bind('cssW');
  height: v-bind('cssH');

  z-index: 5;


  &__drag {
    height: 20px;
    width: 100%;
    border-bottom: 1px dotted black;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__inner {
    height: 100%;
    flex: 1;
    position: relative;
  }

  &__angle {
    position: absolute;
    width: 26px;
    height: 20px;
    z-index: 2;

    &-top_left {
      top: 0;
      left: 0;
      cursor: nwse-resize;
    }

    &-top_right {
      top: 0;
      right: 0;
      cursor: nesw-resize;
    }

    &-bottom_right {
      right: 0;
      bottom: 0;
      cursor: nwse-resize;
    }

    &-bottom_left {
      left: 0;
      bottom: 0;
      cursor: nesw-resize;
    }
  }

}
</style>