<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            width: {
                type: Number,
                default: 24,
            },
            height: {
                type: Number,
                default: 24,
            },
            path: String,
            color: {
                type: String,
                default: "black",
            },
            name: {
                type: String,
                default: '',
            },
            padding: {
                type: Number,
                default: 0,
            }
        },
        computed: {
            paddingStyle() {
                return "" + this.padding + "px"
            }
        }
    }
</script>

<template>
    <div class="sp-icon">
        <svg :width="width"
             :height="height"
             :fill="color"
             :aria-labelledby="name"
             role="presentation"
        >
            <g>
                <path :d="path"/>
            </g>
        </svg>
    </div>
</template>

<style lang="scss" scoped>
    .sp-icon {
        padding: v-bind('paddingStyle');
    }
</style>