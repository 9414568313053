function gcd(a, b) {
  while (b) {
    a = b;
    b = a % b;
  }
  return a;
}

const ok_vkPostValidators = {
  image: [
    async (file) => {
      file.errors = [];
      file.warns = [];
      if (file.file.size > 4999999)
        file.errors.push("Размер изображения не должен превышать 5 мб.");
    },
  ],
  video: [
    async (file) => {
      file.errors = [];
      file.warns = [];
      if (file.file.size > 1999999999)
        file.errors.push("Размер видео не должен превышать 2 гб.");
    },
  ],
};

const vkStoryValidators = {
  image: [
    async (file) => {
      file.errors = [];
      file.warns = [];
      const src = URL.createObjectURL(file.file);
      let image = new Image();
      image.src = src;
      const wh = await new Promise((resolve) => {
        image.onload = () => {
          const wh = [image.width, image.height];
          URL.revokeObjectURL(src);
          resolve(wh);
        };
      });
      const w = wh[0],
        h = wh[1];
      if (w > h) {
        file.warns.push(
          "Изображение слишком широкое. Социальные сети обрежут его до соотношения 9:16"
        );
      } else {
        const h_ratio = h / 16,
          w_ratio = w / 9;
        if (w_ratio !== h_ratio) {
          const gcdWH = gcd(w, h),
            w_ratio_real = w / gcdWH,
            h_ratio_real = h / gcdWH;
          file.warns.push(
            `Соотношение сторон изображения ${w_ratio_real}:${h_ratio_real} вместо требуемого 9:16. Изображение может быть обрезано при загрузке в соц сети`
          );
        }
      }
      if (file.file.size > 4999999)
        file.errors.push("Изображение должно весить меньше 5 мб");
    },
  ],
  video: [
    async (file) => {
      file.errors = [];
      file.warns = [];
      let video = document.createElement("video");
      video.preload = "metadata";
      const meta = await new Promise((resolve) => {
        video.src = URL.createObjectURL(file.file);
        video.onloadedmetadata = function () {
          const videoMeta = {
            duration: video.duration,
            w: video.width,
            h: video.height,
          };
          window.URL.revokeObjectURL(video.src);
          resolve(videoMeta);
        };
      });
      if (meta.w > meta.h) {
        file.warns.push(
          "Видео слишком широкое. Социальные сети обрежут его до соотношения 9:16"
        );
      } else {
        const h_ratio = meta.h / 16,
          w_ratio = meta.w / 9;
        if (w_ratio !== h_ratio) {
          const gcdWH = gcd(meta.w, meta.h),
            w_ratio_real = meta.w / gcdWH,
            h_ratio_real = meta.h / gcdWH;
          file.warns.push(
            `Соотношение сторон видео ${w_ratio_real}:${h_ratio_real} вместо требуемого 9:16. Видео может быть обрезано при загрузке в соц сети`
          );
        }
      }
      if (meta.duration >= 15) {
        file.errors.push("Длительность видео не должна превышать 15 секунд");
      }
      if (file.file.size > 4999999) {
        file.errors.push("Размер видео не должен превышать 5 мб.");
      }
    },
  ],
};

const imPostValidators = {
  image: [
    async (file) => {
      file.errors = [];
      file.warns = [];
      const src = URL.createObjectURL(file.file);
      let image = new Image();
      image.src = src;
      const wh = await new Promise((resolve) => {
        image.onload = () => {
          const wh = [image.width, image.height];
          URL.revokeObjectURL(src);
          resolve(wh);
        };
      });
      const w = wh[0],
        h = wh[1];
      if (w < 320)
        file.errors.push(
          `Минимальная ширина изображения - 320 px. Текущая ширина: ${w}`
        );
      if (w > 1440)
        file.errors.push(
          `Максимальная ширина изображения - 1440 px. Текущая ширина: ${w}`
        );
      const h_ratio = h / 16,
        w_ratio = w / 9;
      if (w_ratio !== h_ratio) {
        const gcdWH = gcd(w, h),
          w_ratio_real = w / gcdWH,
          h_ratio_real = h / gcdWH,
          final = w_ratio_real / h_ratio_real;
        if (final < 0.8 || final > 1.91) {
          file.errors.push(
            `Соотношение сторон изображения ${w_ratio_real}:${h_ratio_real} (${final}). Оно должно быть в диапазоне от 4:5 (0.8) до 1.91:1 (1.91)`
          );
        }
      }

      if (file.file.size > 7999999)
        file.errors.push("Изображение должно весить меньше 8 мб");
    },
  ],
  video: [
    async (file) => {
      file.errors = [];
      file.warns = [];
      let video = document.createElement("video");
      video.preload = "metadata";
      const meta = await new Promise((resolve) => {
        video.src = URL.createObjectURL(file.file);
        video.onloadedmetadata = function () {
          const videoMeta = {
            duration: video.duration,
            w: video.width,
            h: video.height,
          };
          window.URL.revokeObjectURL(video.src);
          resolve(videoMeta);
        };
      });
      if (meta.w > 1920) {
        file.errors.push(
          `Максимальная ширина видео - 1920. Текущая ширина: ${meta.w}`
        );
      }
      const h_ratio = meta.h / 16,
        w_ratio = meta.w / 9;
      if (w_ratio !== h_ratio) {
        const gcdWH = gcd(meta.w, meta.h),
          w_ratio_real = meta.w / gcdWH,
          h_ratio_real = meta.h / gcdWH,
          final = w_ratio_real / h_ratio_real;
        if (final > 1.7777777777777777 || final < 0.8) {
          file.errors.push(
            `Соотношение сторон видео ${w_ratio_real}:${h_ratio_real} (${final}). Оно должно быть в диапазоне от 4/5 (0.8) до 16/9 (~1.77777)`
          );
        }
      }
      if (meta.duration > 60 || meta.duration < 3) {
        file.errors.push(
          "Длительность видео не должна быть в диапазоне от 3 до 60 секунд"
        );
      }
      if (file.file.size > 99999999) {
        file.errors.push("Размер видео не должен превышать 100 мб.");
      }
    },
  ],
};

// VALIDATORS[SOCIAL_ID][PUBLICATION_TYPE][MEDIA_TYPE]
const VALIDATORS = {
  1: {
    1: ok_vkPostValidators,
    2: vkStoryValidators,
  },
  2: {
    1: imPostValidators,
    2: imPostValidators,
  },
  3: {
    1: ok_vkPostValidators,
    2: ok_vkPostValidators,
  },
};

export async function validateMedia(socials, publicationType, files) {
  console.log("VALIDATE FILES", files);
  console.log("SOCIALS TO VALIDATE", socials);
  let currentValidators = [];
  for (const social of socials) {
    currentValidators.push(VALIDATORS[social][publicationType]);
  }
  for (const mediaType in files) {
    if (!files[mediaType].length) continue;
    for (const file of files[mediaType]) {
      for (let validatorsGroup of currentValidators) {
        for (let validator of validatorsGroup[mediaType]) {
          validator(file);
        }
      }
    }
  }
}
