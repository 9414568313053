<template>
    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M50 25C50 38.807 38.807 50 25 50C11.193 50 0 38.807 0 25C0 11.193 11.193 0 25 0C38.807 0 50 11.193 50 25" fill="url(#paint0_linear_43_331)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M34.117 30.2738C34.117 32.8079 32.0691 34.7585 29.6322 34.7585H20.3681C17.834 34.7585 15.8833 32.7106 15.8833 30.2738V21.0096C15.8833 18.4755 17.9312 16.5249 20.3681 16.5249H29.6322C32.1663 16.5249 34.117 18.5728 34.117 21.0096V30.2738ZM29.6325 14.1855H20.3683C16.5672 14.1855 13.5439 17.2073 13.5439 21.0099V30.2741C13.5439 34.0752 16.5672 37.0985 20.3683 37.0985H29.6325C33.4351 37.0985 36.4569 34.0752 36.4569 30.2741V21.0099C36.4569 17.2073 33.3364 14.1855 29.6325 14.1855ZM24.9521 29.2005C23.0015 29.2005 21.344 27.5416 21.344 25.5938C21.344 23.6432 23.0015 21.9843 24.9521 21.9843C26.9028 21.9843 28.5603 23.6432 28.5603 25.5938C28.5603 27.6403 27 29.2005 24.9521 29.2005ZM24.9517 19.7417C21.734 19.7417 19.0039 22.3745 19.0039 25.6895C19.0039 28.9072 21.6367 31.6387 24.9517 31.6387C28.1694 31.6387 30.8994 29.0044 30.8994 25.6895C30.8994 22.3745 28.2666 19.7417 24.9517 19.7417ZM32.3613 19.7433C32.3613 18.9349 31.7067 18.2803 30.8998 18.2803C30.0913 18.2803 29.4368 18.9349 29.4368 19.7433C29.4368 20.5503 30.0913 21.2063 30.8998 21.2063C31.7067 21.2063 32.3613 20.5503 32.3613 19.7433Z" fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_43_331" x1="25.1867" y1="74.4398" x2="74.8133" y2="24.8133" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFD12A"/>
                <stop offset="0.531888" stop-color="#E3009A"/>
                <stop offset="1" stop-color="#5628CA"/>
            </linearGradient>
        </defs>
    </g>
</template>