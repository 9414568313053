import { Model } from '@vuex-orm/core'

export default class Reciever extends Model {
    static entity = "reciever"
    static fields () {
        return {
            id: this.attr(null),
            project: this.attr(null),
            reciever: this.attr(null)
        }
    }

}