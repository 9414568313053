<script>
import BaseIcon from "./icons/BaseIcon";
import ErrorIcon from "./icons/BaseWarn"

export default {
  components: {
    BaseIcon,
    ErrorIcon
  },
  props: {
    notify: Object,
  }
}
</script>
<template>
  <base-icon class="sp-header-notify__icon sp-header-notify__icon_error"
             width="40" height="40"
             view-box="0 0 16 16"
  >
    <error-icon></error-icon>
  </base-icon>
  <div class="sp-header-notify__body">
    <p class="sp-header-notify__date">
      {{ notify.date }}
    </p>
    <p class="sp-header-notify__title">{{ notify.title }}</p>
    <p class="sp-header-notify__description" v-if="notify.account">
      При публикации в аккаунте {{ notify.account.title }} произошла ошибка.
      {{ notify.description }}
    </p>
  </div>

</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-header-notify {
  &__body {
    margin-top: 2px;
  }

  &__title {
    @include font(14, 14, null, $statusError)
  }

  &__date {
    width: 100%;
    @include font(12, 16, null, $inactiveText);
  }

  &__description {
    @include font(12, 16);
    margin-top: 5px;
  }

  &__icon {
    min-width: 40px;
    margin-right: 12px;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/env";

.sp-header-notify__icon_error {
  & path {
    fill: $statusError;
  }
}
</style>