<script>
import ProjectMenu from "../../components/ProjectMenu";
import SettingsMenu from "../../components/SettingsMenu";

import BaseIcon from "../../components/icons/BaseIcon";
import CloseIcon from "../../components/icons/BaseClose";
import EditIcon from "../../components/icons/BaseEdit";
import DragIcon from "../../components/icons/NavbarDrag";
import BaseInput from "../../components/BaseInput";
import BaseLayout from "@/layouts/BaseLayout"

import draggable from "vuedraggable";

import {mapState} from "vuex";
import Caption from "../../store/models/Caption";

export default {
  name: "SettingsCaptions",
  data() {
    return {
      sortableCaptions: [],
      drag: false,
      deleteModal: {
        id: -1,
        show: false
      },
      modal: {
        show: false,
        mode: 0,
        target: 0,
        form: {
          title: "",
          text: "",
        },
        openCreate() {
          this.mode = 0;
          this.show = true;
          this.form = {
            title: "",
            text: "",
          };
        },
        openChange(caption) {
          this.mode = 1;
          this.show = true;
          this.target = caption.id;
          this.form = {
            title: caption.title,
            text: caption.text,
          };
        },
      },
    };
  },
  props: {
    currentProject: Number,
  },
  methods: {
    async captionAdd() {
      let formData = new FormData(this.$refs.captionForm);
      await this.$API
          .post("projects/captions/add", formData)
          .then((response) => Caption.insert({data: response.data}));
      this.modal.show = false;
    },
    async captionChange() {
      let formData = new FormData(this.$refs.captionForm);
      formData.append("id", this.modal.target);
      await this.$API
          .post("projects/captions/change", formData)
          .then((response) => Caption.insert({data: response.data}));
      this.modal.show = false;
    },
    captionsPositionChange() {
      let order = {};
      for (let [index, caption] of this.sortableCaptions.entries()) {
        order[parseInt(caption.id)] = index;
      }
      let formData = new FormData();
      formData.append("order", JSON.stringify(order));
      formData.append("project", this.currentProject);
      this.$API.post("projects/captions/change/position", formData);
    },
    deleteModalOpen(account) {
      this.deleteModal.id = account.id
      this.deleteModal.show = true
    },
    async deleteModalConfirm() {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("id", this.deleteModal.id);
      await this.$API.post("projects/captions/remove", formData)
          .then(() => {
            Caption.delete(this.deleteModal.id);
            this.deleteModalClose()
          });
    },
    deleteModalClose() {
      this.deleteModal.id = -1
      this.deleteModal.show = false
    },
  },
  computed: {
    captions() {
      return this.project && this.project.captions || []
    },
    ...mapState(['project', 'projectTime'])
  },
  components: {
    ProjectMenu,
    SettingsMenu,
    draggable,
    BaseIcon,
    DragIcon,
    EditIcon,
    CloseIcon,
    BaseInput,
    BaseLayout,
  },
  watch: {
    captions: {
      handler: function (v) {
        if (v) this.sortableCaptions = v.sort(v => v.position);
      },
      immediate: true,
    }
  },
};
</script>

<template>
  <BaseLayout>
    <div class="sp-flex">
      <ProjectMenu :project="project" :project-id="currentProject" :time="projectTime"></ProjectMenu>
      <div class="sp-settings-page">
        <h2 class="sp-settings-page__title">Подписи</h2>
        <p class="sp-settings-page__description">
          Подписи — это шаблоны текста, которые можно вставить в каждый пост проекта. Что удобно, например,
          для продающих постов: можно добавить в подпись всю контактную информацию и призыв к действию.
          Также можно придумать свой вариант использования подписей.
        </p>
        <div class="sp-settings-page__create sp-btn sp-btn--main_outlined" @click="modal.openCreate">Создать</div>
        <draggable class="sp-settings-page__list"
                   v-model="sortableCaptions"
                   item-key="id"
                   @sort="captionsPositionChange"
        >
          <template #item="{ element }">
            <div class="sp-settings-page-item sp-sortable">
              <div class="sp-sortable__content">
                <base-icon class="sp-sortable__drag"
                           height="30"
                           width="30"
                           icon-name="Сортировка"
                >
                  <drag-icon/>
                </base-icon>
                {{ element.title }}
              </div>
              <div class="sp-sortable__actions">
                <div class="sp-settings-page-item__action sp-settings-page-item__edit sp-btn"
                     @click="modal.openChange(element)"
                >
                  <base-icon height="20"
                             width="20"
                             icon-name="Редактировать"
                  >
                    <edit-icon/>
                  </base-icon>
                </div>
                <div
                    class="sp-settings-page-item__action sp-btn"
                    @click="deleteModalOpen(element)"
                >
                  <base-icon height="20"
                             width="20"
                             icon-name="Удалить"
                  >
                    <close-icon/>
                  </base-icon>
                </div>
              </div>
            </div>
          </template>
        </draggable>

      </div>
      <SettingsMenu :currentProject="currentProject"></SettingsMenu>
    </div>
  </BaseLayout>
  <Modal v-model="modal.show"
         :close="() => (modal.show = false)"
  >
    <form class="sp-modal sp-modal-edit" ref="captionForm">
      <input type="hidden" name="project" :value="currentProject">
      <p class="sp-modal__title sp-modal-edit__title">Подпись</p>
      <base-input name="title" label="Название"
                  :input-class-list="['sp-sp-modal-edit__input']"
                  v-model="modal.form.title"
      >
      </base-input>
      <base-input name="text" label="Текст"
                  :input-class-list="['sp-sp-modal-edit__input']"
                  v-model="modal.form.text"
      >
      </base-input>
      <button class="sp-btn sp-btn--main sp-modal-edit__create"
              @click.prevent="modal.mode ? captionChange() : captionAdd()"
      >
        {{ modal.mode ? "Изменить" : "Добавить" }}
      </button>
    </form>
  </Modal>
  <Modal v-model="deleteModal.show" :close="deleteModalClose">
    <div class="sp-modal sp-modal-delete">
      <p class="sp-modal__title">Хотите удалить подпись?</p>
      <div class="sp-modal-delete__bottom">
        <button class="sp-modal-delete__back sp-btn sp-btn--optional"
                @click.prevent="deleteModalClose"
        >
          Нет, вернуться
        </button>
        <button class="sp-modal-delete__delete sp-btn sp-btn--main"
                @click.prevent="deleteModalConfirm"
        >
          Да, удалить безвозвратно
        </button>
      </div>
    </div>
  </Modal>
</template>
