<script>
import {createNamespacedHelpers} from "vuex";

const {mapActions} = createNamespacedHelpers('authStore')

export default {
  name: "ImConfirm",
  methods: {
    ConfirmAuth() {
      const code = this.$route.query.code;
      const state = this.$route.query.state;
      if (!code) this.$router.replace("/");
      console.log(code);
      if (state === "project") {
        this.$API
          .post("projects/accounts/auth/im", "code=" + code + "&state=" + state)
          .then((response) => {
            localStorage.tempAuthIm = response.data.authToken;
            window.close();
          });
      } else {
        this.$API.post("im.userAuth", "code=" + code).then((response) => {
          this.auth(response.data)
          localStorage.imAuth = true;
          window.close();
        });
      }
    },
    ...mapActions([
      'auth',
    ])
  },
  beforeCreate() {
    this.$store.commit('PRELOADER', true);
  },
  created() {
    this.ConfirmAuth();
  },
};
</script>

<template>
  <div class="app-login">
    <div class="sp-preload-page">
      <div class="sp-preloader"></div>
    </div>
  </div>
</template>
