<script setup>
// es-nocheck
import {NSpace, NH1, NConfigProvider, NButton, NCard, darkTheme} from 'naive-ui';
import AdminLayout from "@/layouts/AdminLayout.vue";
import {ref, watchEffect} from "vue";
import {API} from "../plugins/axios"
// import {limitString} from "@/logic/tools";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();
function back() {
  router.push({name:'AdminDebugProject', params: {
    project: route.params.project
  }})
}
const publication = ref({});
watchEffect(() => {
  const fd = new FormData();
  fd.append('publication', route.params.publication);
  API.post('/debug/publication/', fd).then(response => {
    publication.value = response.data;
    console.log(publication)
  })
})
// onMounted(() => {
//   const fd = new FormData();
//   fd.append('publication', route.params.publication);
//   API.post('/debug/publication/', fd).then(response => {
//     publication.value = response.data;
//     console.log(publication)
//   })
// })
</script>


<template>
<n-config-provider :theme="darkTheme">
    <admin-layout>
      <n-space vertical>
        <n-button @click="back">
          Назад
        </n-button>
        <n-h1>Панель отладки</n-h1>
        <div class="sp-debug-publication__items" >
          <n-card
             size="small"
             title="Лог"
             content-style="white-space: break-spaces;"
          >
            {{publication.log}}
          </n-card>
          <n-card
             size="small"
             title="Ошибки:"
             content-style="white-space: break-spaces;"
          >
            <div class="sp-adm-publication-err"
                 v-for="error in publication.errors"
                 :key="error.social"
            >
              <h3>Соц-сеть: {{error.social}}</h3>
              <h3>Группа: {{error.group.title}}</h3>
              <p>{{error.error}}</p>
            </div>
          </n-card>
           <n-card
             size="small"
             title="Публикация json"
             content-style="white-space: break-spaces;"
          >
            {{publication}}
          </n-card>
        </div>

      </n-space>
    </admin-layout>
</n-config-provider>
</template>

<style lang="scss" scoped>
  .sp-adm-publication-err {
    padding: 12px 0 20px;
  }
  .sp-debug-publication__items {
    width: 100%;
    & > div {
      margin: 10px 10px;
    }

  }
  .sp-debug-publication-info {
    width: calc(100% / 3 - 20px);
  }
</style>