<script>
    import {mapState} from 'vuex';
    import BaseAccount from "./BaseAccount";
    import ListChanges from "./ProjectPublicationHistoryListChanges";

    export default {
        computed: {
            ...mapState(['project']),
            stages() {
                return this.project.steps
            },
            beforeAccounts() {
                return this.changes.before.accounts
            },
            afterAccounts() {
                return this.changes.after.accounts
            }
        },
        props: {
            changes: Object,
        },
        components: {
            BaseAccount,
            ListChanges,
        }
    }
</script>

<template>
    <list-changes title="Изменения в аккаунтах">
        <template #before>
            <div class="sp-history-list-account"
                 v-for="account in beforeAccounts"
                 :key="account.id"
            >
                <BaseAccount :account="account"/>
                {{account.title}}
            </div>
        </template>
        <template #after>
            <div class="sp-history-list-account"
                 v-for="account in afterAccounts"
                 :key="account.id"
            >
                <BaseAccount :account="account"/>
                {{account.title}}
            </div>
        </template>
    </list-changes>
</template>

<style lang="scss" scoped>
    @import "src/assets/scss/env";
    .sp-history-list-account {
        display: flex;
        border: 1px solid $border;
        border-radius: 8px;
        min-width: 90%;
        margin: 5px 0;
        padding: 5px;
        align-items: center;
        @include font(16, 18);
        & > .sp-account {
            transform: scale(.8);
            min-width: 47px;
            margin-right: 12px;
        }

    }
</style>