<script>
// @ is an alias to /src
import BaseIcon from "../components/icons/BaseIcon";
// import SearchIcon from "../components/icons/BaseSearch";
import DragIcon from "../components/icons/NavbarDrag";
// import CloseIcon from "../components/icons/HomeProjectClose";
import draggable from "vuedraggable";
import {mapState} from "vuex";
import BaseLayout from "@/layouts/BaseLayout"

export default {
  name: "Home",
  data() {
    return {
      search: '',
      localProjects: [],
    }
  },
  components: {
    BaseIcon,
    // SearchIcon,
    DragIcon,
    // CloseIcon,
    BaseLayout,
    draggable,
  },
  methods: {
    projectsPositionChange(e) {
      let order = {};
      for (let [index, caption] of this.localProjects.entries()) {
        order[parseInt(caption.id)] = index;
      }
      let formData = new FormData();
      formData.append("order", JSON.stringify(order));
      formData.append("project", this.currentProject);
      this.$API.post("projects/position", formData);
      console.log(e, this.captions, order);
      this.$store.dispatch("projectsUpdate", this.localProjects);
    },
    async selectProject(project) {
      this.$store.dispatch("projectSelect", project.id);
      this.$router.push({
        name: "ProjectPublications",
        params: {currentProject: project.id},
      });
    },
  },
  computed: {
    ...mapState(['projects'])
  },
  created() {
    if (!this.$store.state.authCompleted) this.$router.push("Auth");
  },
  watch: {
    projects: {
      handler: function (v) {
        console.log(v)
        this.localProjects = v;
      },
      immediate: true,
    }
  }
};
</script>

<template>
  <BaseLayout>
    <div class="sp-home">
      <div class="sp-home-container">
        <div class="sp-home-top">
          <h1 class="sp-home__heading">Проекты</h1>
          <!--                <div class="sp-home-search sp-btn">-->
          <!--                    <input class="sp-home-search__input" type="text" placeholder="Поиск проекта"-->
          <!--                           v-model="search"-->
          <!--                    >-->
          <!--                    <div class="sp-home-search__btn sp-btn">-->
          <!--                        <base-icon width="20" height="20" icon-name="Поиск">-->
          <!--                            <search-icon/>-->
          <!--                        </base-icon>-->
          <!--                    </div>-->
          <!--                </div>-->
        </div>
        <draggable class="sp-home__projects" v-model="localProjects" item-key="id" @sort="projectsPositionChange">
          <template #item="{ element }">
            <div class="sp-home-project sp-btn"
                 @click="selectProject(element)"
            >
              <div class="sp-home-project__content">
                <base-icon class="sp-home-project__drag" height="30" width="30" icon-name="Сортировка">
                  <drag-icon/>
                </base-icon>
                <p class="sp-home-project__title">{{ element.title }}</p>
              </div>
              <!--                        <div class="sp-home-project__delete sp-btn">-->
              <!--                            <base-icon width="20" height="20" icon-name="Удалить">-->
              <!--                                <close-icon/>-->
              <!--                            </base-icon>-->
              <!--                        </div>-->
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </BaseLayout>
</template>

<style lang="scss">

.sp-home {
  margin-top: 57px;
  display: flex;
  flex: 1;
  justify-content: center;

  &-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &-container {
    width: 1270px;
    @include _1600 {
      padding: 0 30px;
      width: unset;
      flex: 1;
    }
  }

  &-search {
    width: 230px;
    height: 52px;
    border: 1px solid $border;
    background: $componentBg;
    overflow: hidden;
    position: relative;
    padding-right: 60px;

    &__input {
      appearance: none;
      border: none;
      height: 100%;
      padding-left: 20px;
      width: 100%;
      background: $componentBg;
      @include font(15, 20);

      &::placeholder {
        @include font(14, 20, normal, $fgText)
      }
    }

    &__btn {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      width: 60px;
    }
  }

  &-project {
    width: 100%;
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 19px 0 19px 6.13px;
    $self: &;
    transition: box-shadow .2s;

    &__content {
      display: flex;
      align-items: center;
    }

    &__title {
      @include font(16, 20, 700);
      transition: color .2s;
    }

    &__delete {
      width: 80px;
      height: 40px;
      display: none;
    }

    &__drag {
      margin-right: 16px;
    }

    &:hover {
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);

      #{$self} {
        &__title {
          color: $psText;
        }

        &__delete {
          display: flex;
        }
      }
    }

    position: relative;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      height: 1px;
      background: $border;
    }
  }

  &__projects {
    margin-top: 14px;
  }

  &__heading {
    @include font(30, 34, 700);
  }
}
</style>
