<script>
    export default {
        data() {
            return {
                collapsed: true,
            }
        }
    }
</script>
<template>
    <div class="sp-history-list-collapse"
         :class="{'sp-history-list-collapse_collapsed': collapsed}"
    >
        <div class="sp-history-list-collapse__btn sp-btn sp-btn--optional"
             @click="collapsed = !collapsed"
        >
            {{collapsed ? "Подробнее" : "Скрыть"}}
        </div>
        <div class="sp-history-list-collapse__content"
        >
            <slot></slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .sp-history-list {
        &-collapse {
            margin-top: 6px;
            $self: &;

            &__content {
                overflow: hidden;
                max-height: 9000px;
                transition: max-height .3s;
            }
            &__btn {
                height: 30px;
            }
            &_collapsed {
                #{$self} {
                    &__content {
                        max-height: 0;
                    }
                    &__btn {

                    }
                }
            }
        }
    }
</style>