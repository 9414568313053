<script>

import {applyOffset} from "./logic/tools";
import Project from "./store/models/Project";
import Publication from "./store/models/Publication";
import {mapState} from "vuex";
import Notification from "./store/models/Notification";
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('authStore')

export default {
  data() {
    return {
      projects: {},
      currentTime: new Date(Date.now()),
      currentTimeInterval: 0,
    };
  },
  methods: {
    applyOffset,
    getProjects() {
      this.$API.get("projects").then((response) => {
        this.projects = response.data;
        this.$store.dispatch("projectsUpdate", response.data);
        this.$API.get("projects?archive=1").then((response) => {
          this.projects = response.data;
          this.$store.dispatch("archiveProjectsUpdate", response.data);
        })
      })
    },
    async getProjectData() {
      let fd = new FormData()
      if (!this.currentProject) return
      fd.append('project', this.currentProject)
      await this.$API.post('/projects/info', fd).then(response => {
         Project.insert({data: response.data});
         Publication.insert({data: response.data.publications});
      })
    },
    updateNotifications() {
      let formData = new FormData()
      formData.append('project', this.currentProject)
      formData.append('notification', this.notifications[0].id)
      this.$API.post('projects/notifications/update', formData).then(response => {
        if (response.data.length) {
          Notification.insert({data: response.data})
        }
      })
    },
    ...mapActions([
        'auth',
    ])
  },
  computed: {
    project() {
      if (this.$store.state.cleanPage) return;
      console.log('project computed', this.$store.getters['entities/projects/query']().where('id', this.$store.state.currentProject).withAllRecursive().get()[0])
      return this.$store.getters['entities/projects/query']().where('id', this.$store.state.currentProject).withAllRecursive().get()[0]
    },
    projectTime() {
      console.log('project time computed', this.project && this.currentTime && this.applyOffset(this.currentTime, this.project.timezone))
      return this.project && this.currentTime && this.applyOffset(this.currentTime, this.project.timezone)
    },
    ...mapState(['currentProject']),
  },
  watch: {
    project: {
      handler(v) {
        this.$store.commit('PROJECT_CHANGE', v);
      }
    },
    projectTime: {
      handler(v) {
        this.$store.commit('PROJECT_TIME_CHANGE', v)
      }
    }
  },
  mounted() {
    if (this.$store.state.cleanPage) return;
    if (localStorage.token && localStorage.user) {
      try {
        this.auth({token: localStorage.token, user: JSON.parse(localStorage.user)});
      } catch (err) {
        localStorage.token = "";
        localStorage.user = "";
        this.$router.push({name: 'Auth'})
      }

      this.getProjects()
    }
    console.log('QUERY', this.$route.query);
    console.log("WINDOW", window)
    console.log(document.referrer);
    console.log(parent)
    const params = parent.window
    console.log(params)
    let lastMinute = new Date(Date.now()).getMinutes(),
        interval = setInterval(() => {
          let time = new Date(Date.now())
          if (lastMinute !== time.getMinutes()) {
            this.currentTimeInterval = setInterval(() => {
              this.currentTime = new Date(Date.now())
            }, 60000);
            clearInterval(interval)
          }
        }, 1000);
    setInterval(this.getProjectData, 600000)
  },
  beforeUnmount() {
    clearInterval(this.currentTimeInterval);
  }
};
</script>

<template>
  <router-view/>
  <div id="modals"></div>
</template>

<style lang="scss">
@import "src/assets/scss/env";
@import "src/assets/scss/pages/base";


.sp-preload-page {
  height: 100vh;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

//.sp-preloader {
//  width: 80px;
//  height: 80px;
//  border-radius: 50%;
//  background: linear-gradient(93.54deg, $publicationsEditBtn 0%, #B5E134 98.15%);
//  transition: background-color ;
//  background-size: 400% 400%;
//  animation: spin 1s infinite, gradient 3s infinite;
//}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes gradient {
    0%{
      background-position:0% 37%;

    }
    50%{
      background-position:100% 64%;

    }
    100%{
      background-position:0% 37%;

    }
}
.app {
  background: #1a1e22;
  height: 100%;
  min-height: 100vh;
  display: flex;

  &-page {
    flex: 1;
    padding: 20px 40px;

    &-list {
      margin-top: 20px;

      &-item {
        padding: 10px 15px;
        color: $base;
        border-radius: 10px;
        border: 1px solid $base;
        font-size: 12px;
        background: $component;
      }
    }

    &__title {
      color: $base;
      margin-top: 40px;
    }

    &__add {
      margin-top: 20px;
      border: 1px solid $base;
      padding: 5px 10px;
      width: fit-content;
      border-radius: 10px;
    }
  }

  &-modal {
    background: $component;
    border: 1px solid $base;
    width: 30em;
    border-radius: 10px;
    color: $base;

    &__title {
      color: $base;
      font-size: 18px;
      line-height: 24px;
    }

    &__description {
      color: $base;
      font-size: 16px;
      line-height: 20px;
    }

    &__content {
    }
  }

  &-form {
    &__field {
      width: 100%;
      margin: 10px 0 !important;
      height: 50px;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      border: 1px solid $base;
      background: $component;
    }

    &__input {
      position: absolute;
      left: 0;
      color: $base;
      top: 0;
      width: 100%;
      height: 100%;
      border: none;
      padding: 15px 20px 0 !important;
      background: none;
    }

    &__label {
      font-size: 12px;
      line-height: 16px;
      padding-left: 5px !important;
      margin-top: -5px !important;
      color: #868686;
    }
  }

  // &-btn {
  //   border-radius: 10px;
  //   border: 1px solid $base;
  //   background: $component;
  //   color: $base;
  //   width: fit-content;
  //   padding: 5px 10px !important;
  //   margin-top: 20px !important;
  //   cursor: pointer;
  //   &:hover {
  //     color: $baseHover;
  //     border: 1px solid $baseHover;
  //   }
  // }
}

.die {
  color: $base;
  cursor: pointer;

  &:hover {
    color: $baseHover;
  }

  &.selected {
    color: $baseHover;
  }
}

#modals {
  z-index: 10;
}
</style>
