<script>
import { projectSettingsRoutes } from "../router/index";
export default {
  name: "SettingsMenu",
  props: {
    currentProject: Number,
  },
  data() {
    return {
      routes: projectSettingsRoutes,
    };
  },
  methods: {
    replacePath(path) {
      return path.replace(":currentProject", this.currentProject);
    },
  },
};
</script>

<template>
  <div class="sp-settings-nav">
    <router-link
      class="sp-settings-nav-item sp-btn"
      v-for="item in routes"
      :key="item.path"
      :to="replacePath(item.path)"
      :class="{ selected: $route.path === replacePath(item.path) }"
    >
      {{ item.verbose_name }}
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";
.sp-settings-nav {
  padding: 40px 30px;
  background: $inactive;
  &-item {
    @include font(14,19,bold,$text);
    padding: 10px 20px;
    margin: 2px 0;
    display: block;
    &.router-link-active {
      background: $datepickerCurrent;
    }
    &:hover {
      color: $mainBtnHover;
    }
  }
}
</style>
