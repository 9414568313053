import { Model } from '@vuex-orm/core'

export default class Account extends Model{
    static entity = "accounts"
    static fields () {
        return {
            id: this.attr(null),
            project: this.attr(null),
            error: this.attr(null),
            social_group: this.attr(''),
            extended: this.attr(false),
            reconnect: this.attr(false),
            photo: this.attr(''),
            social: this.attr(''),
            title: this.attr(''),
            position: this.attr(''),
        }
    }

}