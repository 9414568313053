<script setup>
import BaseAvatar from "@/components/BaseAvatar";
import BaseRequestForm from "@/components/BaseRequestForm";
import BaseIcon from "@/components/icons/BaseIcon";
import CloseIcon from "@/components/icons/ProjectSettingsB24Remove";
import {computed, defineProps} from "vue";
import Client from "@/store/models/Client";
import Reciever from "@/store/models/Reciever";

const props = defineProps({
  clients: {type: Array},
  project: {type: Object},
  currentProject: {type: Number},
  mapping: {default: "client", type: String}
})
const b24Link = computed(() => {
  if (!(props.project && props.project.b_whook && props.project.b_whook)) return ""
  const index = props.project.b_whook.indexOf('rest/');
  if (index === -1) return "";
  return props.project.b_whook.slice(0, index);
})
const onClientB24Remove = function (response) {
  if (props.mapping === "client") {
    Client.delete(response.data.deleted)
  } else {
    Reciever.delete(response.data.deleted)
  }
}
const href = (client) => {
  console.log(props.mapping, props, client)
  return `${b24Link.value}company/personal/user/${client[props.mapping].b24_id}/`
}
</script>

<template>
  <div class="sp-settings-b24__clients">
    <div v-for="client in clients"
         :key="client.id"
         class="sp-settings-b24-client"
    >
      <a :href="href(client)" class="sp-settings-b24-client__link"
         target="_blank">
        <div class="sp-settings-b24-client__info">
          <BaseAvatar :photo="client[mapping].photo" :radius="25" :text="client[mapping].name"></BaseAvatar>
          <p class="sp-settings-b24-client__title">{{ client[mapping].name }}</p>
        </div>
      </a>
      <div class="sp-settings-b24-client__actions">
        <BaseRequestForm :response-callback="onClientB24Remove" :action="`projects/${mapping}/remove`">
          <button class="sp-settings-page-item__action sp-btn"
                  type="submit"
          >
            <input :value="client[mapping].b24_id" name="b24_id" type="hidden">
            <input :value="currentProject" name="project" type="hidden">
            <base-icon height="20"
                       icon-name="Удалить"
                       width="20"
            >
              <close-icon/>
            </base-icon>
          </button>
        </BaseRequestForm>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sp-settings-b24 {
  &__clients {
    margin-top: 20px;
  }

  &__search_list {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  &-search_result {
    width: 100%;
    display: flex;
    align-items: center;

    &__title {
      margin-left: 12px;
      @include font(16, 20, bold, $text);
    }
  }

  &-client {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
    border-top: 1px solid $border;

    &:last-child {
      border-bottom: 1px solid $border;
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__link {
      text-decoration: unset;
    }

    &__title {
      @include font(16, 20, bold, $text);
      margin-left: 12px;
    }

    &__actions {
      display: flex;
    }
  }
}
</style>