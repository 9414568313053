<script>
    import {mapState} from "vuex";
    import BaseIcon from "./icons/BaseIcon";
    import Logo from "./icons/BaseLogo"
    import Page from "./icons/NavbarPage"
    import Chat from "./icons/NavbarChat"
    import Question from "./icons/NavbarQuestion"

    export default {
        name: "ProjectsSidebar",
        components: {
            BaseIcon,
            Logo,
            Chat,
            Question,
            Page,
        },
        methods: {
            async selectProject(project) {
                this.$router.push({
                    name: "ProjectPublications",
                    params: {currentProject: project.id},
                });
                this.$store.dispatch("projectSelect", project.id);
            },
        },
        watch: {},
        computed: mapState(["projects", "currentProject"]),
    };
</script>


<template>
    <div class="sp-sidebar">
        <div class="sp-sidebar__top">
            <a class="sp-sidebar__logo" href="/">
                <base-icon icon-name="#всепоплану" width="215" height="20">
                    <logo fill="#1E1E1E"/>
                </base-icon>
            </a>
            <div class="sp-sidebar-projects">
                <button
                        class="sp-sidebar-add sp-btn"
                        @click="$router.push({ name: 'ProjectCreate' })"
                >
                    <div class="sp-flex sp-ai_c sp-h100">
                        <div class="sp-sidebar-add__left">
                            <span class="sp-sidebar-add__plus">+</span>
                        </div>
                        Новый проект
                    </div>
                </button>
                <div class="sp-sidebar-projects__list">
                    <div
                            class="sp-sidebar-project sp-btn"
                            v-for="project in projects"
                            :key="project.id"
                            :class="{ selected: currentProject === project.id }"
                            @click="selectProject(project)"
                    >
                        {{ project.title }}
                    </div>
                </div>

            </div>
        </div>
        <div class="sp-sidebar-footer">
            <div class="sp-btn sp-btn--optional-2 sp-sidebar__archive"
                 @click="$router.push({name: 'archive'})"
            >
                Архив
            </div>
            <div class="sp-sidebar-footer__top">
                <a class="sp-sidebar-link sp-btn" href="">
                    <base-icon width="24" height="24" icon-name="Справка">
                        <page/>
                    </base-icon>
                    Справка
                </a>
                <a class="sp-sidebar-link sp-btn" href="https://vk.me/plan_postov">
                    <base-icon width="24" height="24" icon-name="Чат с поддержкой">
                        <chat/>
                    </base-icon>
                    Чат с поддержкой
                </a>
                <a class="sp-sidebar-link sp-btn" href="">
                    <base-icon width="24" height="24" icon-name="Центр помощи">
                        <question/>
                    </base-icon>
                    Центр помощи
                </a>
            </div>
            <div class="sp-sidebar__copyright">
                <p>© 2021 Все права защищены</p>
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
    @import "@/assets/scss/env";

    .sp-sidebar {
        width: 268px;
        min-width: 268px;
        order: 0;
        background: $white;
        border-right: 1px solid $border;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        min-height: 100vh;

        &__copyright {
            display: flex;
            justify-content: center;
            padding: 23px 35px 28px;
            border-top: 1px solid $border;
            @include font(13, 18, normal, #696969)
        }

        &__archive {
            margin: 10px 22.5px;
            height: 50px;
            width: unset;
            flex: 1;
            @include font(15, 20, bold, $inactiveText)
        }

        &__top {

            display: flex;
            justify-content: center;
            flex-flow: row wrap;
        }

        &-footer {
            width: 100%;

            &__top {
                border-top: 1px solid $border;
                padding: 17.5px 22.5px;
            }
        }

        &__logo {
            margin-top: 28.77px;
            color: unset;
        }

        &__site {
            width: 100%;
            font-size: 20px;
            color: $base;
            padding: 20px;
            text-align: center;
            cursor: pointer;
        }

        &-projects {
            width: 100%;
            margin: 25.22px 0 20px;
            border-top: 1px solid $border;
            padding: 15px 16px;

            &__list {
            }
        }

        &-add {
            background: linear-gradient(93.54deg, #9ABE32 0%, #B5E134 98.15%);
            display: flex;
            align-self: center;
            text-align: center;
            height: 52px;
            justify-content: unset;
            @include font(15, 20, bold, $white);

            &__left {
                height: 100%;
                width: 61px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-right: 1px solid rgba(250, 250, 250, 0.35);
                margin-right: 25px;
            }

            &__plus {
                @include font(24, 29, normal, $white);
                letter-spacing: 0.04em;
            }

            &:focus {
                border: 1px solid $borderFocused;
            }
        }

        &-project {
            margin: 4px 0;
            justify-content: unset;

            &:first-child {
                margin-top: 17px;
            }

            padding: 16px 19px;
            @include font(16, 20, bold);

            &:hover {
                color: $psText;
            }

            &.selected {
                background: $selectedProject;
            }
        }

        &-link {
            justify-content: unset;
            padding: 12.5px;
            text-decoration: none;
            color: $text;
            @include font(14, 20, bold);

            & > svg {
                margin-right: 10px;
            }

            &:hover {
                color: $psText;
            }
        }
    }
</style>
