<script>
import { publicationTypes } from "../logic/enums";

export default {
  name: "ProjectPublicationsCreateModeSelector",
  data() {
    return {
      publicationTypes,
    };
  },
  props: {
    modelValue: Number,
  },
  methods: {
    onSelectType(type) {
      this.$emit("update:modelValue", type);
    },
  },
};
</script>

<template>
  <div class="sp-mode-selector">
    <div class="sp-mode-selector__container">
      <div
        class="sp-mode-selector-item sp-btn"
        :class="{ selected: modelValue === publicationTypes.Post }"
        @click="onSelectType(publicationTypes.Post)"
      >
        Пост
      </div>
      <div
        class="sp-mode-selector-item sp-btn"
        :class="{ selected: modelValue === publicationTypes.Story }"
        @click="onSelectType(publicationTypes.Story)"
      >
        История
      </div>
    </div>
    <slot name="templates"></slot>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/env";
.sp-mode-selector {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 30px;
  border-bottom: 1px solid $border;
  background: $inactive;
  border-radius: 8px 8px 0 0;
  &__container {
    display: flex;
    height: 100%;
  }
  &-item {
    border-radius: unset;
    padding: 20px 50px;
    border-right: 1px solid $border;
    @include font(20,25, bold, $inactiveText);
    width: unset;
    color: $inactiveText;
    &:first-child {
      border-radius: 8px 0 0 0;
    }
    &.selected {
      color: $text1;
      background: $white;
    }
  }
}
</style>
