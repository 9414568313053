import { Model } from '@vuex-orm/core'

export default class Step extends Model{
    static entity = "steps"
    static fields () {
        return {
            id: this.attr(null),
            title: this.attr(""),
            project: this.attr(null),
            next_action: this.attr(""),
            next_target: this.attr(""),
            position: this.attr(""),
            prev_action: this.attr(""),
            prev_target: this.attr(""),
            notify: this.attr(null),
        }
    }
}