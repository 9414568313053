<template>
    <g>
        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9089 9.87208C22.9666 9.86937 24.9446 10.6679 26.4237 12.0985C27.9028 13.5291 28.7669 15.4793 28.8328 17.536L28.8371 17.8003V22.1312L30.2958 25.4674C30.3836 25.6684 30.4199 25.8881 30.4015 26.1067C30.3831 26.3253 30.3106 26.5358 30.1905 26.7194C30.0704 26.9029 29.9065 27.0537 29.7136 27.1581C29.5207 27.2625 29.3048 27.3172 29.0855 27.3173L24.0801 27.3205C24.0812 28.146 23.7603 28.9395 23.1856 29.5322C22.6109 30.1249 21.8277 30.4701 21.0025 30.4945C20.1772 30.5189 19.375 30.2204 18.7664 29.6627C18.1577 29.1049 17.7905 28.3318 17.7429 27.5076L17.7376 27.3173H12.7428C12.5238 27.317 12.3083 27.2624 12.1156 27.1582C11.9229 27.0541 11.7591 26.9037 11.6389 26.7207C11.5187 26.5376 11.4459 26.3275 11.4269 26.1093C11.408 25.8911 11.4435 25.6716 11.5303 25.4705L12.9807 22.1322V17.8003C12.9807 13.4081 16.524 9.87208 20.9089 9.87208ZM22.4945 27.3183L19.3232 27.3205C19.3232 27.7278 19.4798 28.1195 19.7607 28.4144C20.0417 28.7093 20.4253 28.8848 20.8321 28.9045C21.239 28.9242 21.6378 28.7866 21.9459 28.5202C22.254 28.2539 22.4478 27.8791 22.4871 27.4737L22.4934 27.3183H22.4945ZM20.9089 11.4577C17.3993 11.4577 14.5663 14.2844 14.5663 17.8003V22.4621L13.1456 25.7327H28.6817L27.2514 22.4631V17.814L27.2472 17.5762C27.1923 15.9328 26.5001 14.3754 25.3171 13.2334C24.1341 12.0914 22.5531 11.4546 20.9089 11.4577Z" fill="#1E1E1E"/>
            <circle r="5" transform="matrix(1 0 0 -1 28.4065 11.1069)" fill="#FF0000" v-show="!checked"/>
        </svg>

    </g>
</template>

<script>
    export default {
        props: {
            checked: {
                type: Boolean,
                default: false,
            }
        }
    }
</script>