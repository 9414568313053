<script>
    import BaseIcon from "../components/icons/BaseIcon";
    import PlaceholderIcon from "../components/icons/ProjectPublicationsPlaceholder";
    import {mapState} from 'vuex';
    import BaseLayout from "@/layouts/BaseLayout"

    export default {
        name: "Archive",
        components: {
            BaseIcon,
            BaseLayout,
            PlaceholderIcon,
        },
        data() {
            return {}
        },
        methods: {
            getProjects() {
                this.$API.get("projects").then((response) => {
                    this.projects = response.data;
                    this.$store.dispatch("projectsUpdate", response.data);
                    this.$API.get("projects?archive=1").then((response) => {
                        this.projects = response.data;
                        this.$store.dispatch("archiveProjectsUpdate", response.data);
                    })
                })
            },
            async unarchive(project) {
                let formData = new FormData();
                formData.append('project', project.id)
                await this.$API.post("projects/unarchive", formData)
                    .then(() => {
                        this.getProjects()
                    });
            }
        },
        computed: {
            ...mapState(['archivedProjects']),
        }
    }
</script>

<template>
  <BaseLayout>
    <div class="sp-home">
        <div class="sp-home-container">
            <div class="sp-home-top">
                <h1 class="sp-home__heading">Архивированные проекты</h1>
<!--                <div class="sp-home-search sp-btn">-->
<!--                    <input class="sp-home-search__input" type="text" placeholder="Поиск проекта"-->
<!--                           v-model="search"-->
<!--                    >-->
<!--                    <div class="sp-home-search__btn sp-btn">-->
<!--                        <base-icon width="20" height="20" icon-name="Поиск">-->
<!--                            <search-icon/>-->
<!--                        </base-icon>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div class="sp-home__projects" v-if="archivedProjects && archivedProjects.length">
                <div class="sp-home-project sp-btn"
                     v-for="project in archivedProjects"
                     :key="project.id"
                >
                    <div class="sp-home-project__content">
                        <p class="sp-home-project__title">{{project.title}}</p>
                    </div>
                    <div class="sp-home__unarchive sp-btn--optional sp-btn"
                        @click="unarchive(project)"
                    >
                        Разархивировать
                    </div>
                </div>
            </div>
            <div class="sp-home-placeholder" v-else
            >
                <base-icon class="sp-home-placeholder__icon"
                           height="30px"
                           width="30px"
                           icon-name=""
                >
                    <placeholder-icon/>
                </base-icon>
                <div class="sp-home-placeholder__text">
                    Нет проектов в архиве. Архивировать проект можно на странице его настроек.
                </div>
            </div>
        </div>
    </div>
  </BaseLayout>
</template>

<style lang="scss">

    .sp-home {
        margin-top: 57px;
        display: flex;
        flex: 1;
        justify-content: center;
        &__unarchive {
            height: 40px;
            width: 200px;
        }
        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        &-placeholder {
            display: flex;
            padding: 25px 53px 25px 40px;
            border-radius: 8px;
            align-items: center;
            margin-top: 15px;
            border: 1px solid $borderDA;
            background: $white;

            &__icon {
                margin-right: 40px;
                min-width: 30px;
                min-height: 30px;
            }

            &__text {
                @include font(16, 24);
            }
        }
        &-container {
            width: 1270px;
            @include _1600 {
                padding: 0 30px;
                width: unset;
                flex: 1;
            }
        }

        &-search {
            width: 230px;
            height: 52px;
            border: 1px solid $border;
            background: $componentBg;
            overflow: hidden;
            position: relative;
            padding-right: 60px;

            &__input {
                appearance: none;
                border: none;
                height: 100%;
                padding-left: 20px;
                width: 100%;
                background: $componentBg;
                @include font(15, 20);

                &::placeholder {
                    @include font(14, 20, normal, $fgText)
                }
            }

            &__btn {
                position: absolute;
                height: 100%;
                right: 0;
                top: 0;
                width: 60px;
            }
        }

        &-project {
            width: 100%;
            height: 69px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 19px 0 19px 20px;
            $self: &;
            transition: box-shadow .2s;

            &__content {
                display: flex;
                align-items: center;
            }

            &__title {
                @include font(16, 20, 700);
                transition: color .2s;
            }

            &__delete {
                width: 80px;
                height: 40px;
                display: none;
            }

            &__drag {
                margin-right: 16px;
            }

            &:hover {
                box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.1);

                #{$self} {
                    &__title {
                        color: $psText;
                    }

                    &__delete {
                        display: flex;
                    }
                }
            }

            position: relative;

            &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 100%;
                width: 100%;
                height: 1px;
                background: $border;
            }
        }

        &__projects {
            margin-top: 14px;
            max-width: 800px;
        }

        &__heading {
            @include font(30, 34, 700);
        }
    }
</style>
