import BasePreloader from "@/components/BasePreloader";
import BaseRequestModal from "@/components/BaseRequestModal";
import BaseRequestForm from "@/components/BaseRequestModal";
import BaseIcon from "@/components/icons/BaseIcon";
import BaseInput from "@/components/BaseInputV2";
import BaseRequestFormBtn from "@/components/BaseRequestFormBtn";

export default {
  install: (app) => {
    app.component('BasePreloader', BasePreloader);
    app.component('BaseRequestModal', BaseRequestModal);
    app.component('BaseForm', BaseRequestForm);
    app.component('BaseFormBtn', BaseRequestFormBtn);
    app.component('BaseIcon', BaseIcon);
    app.component('BaseInput', BaseInput);
  }
};