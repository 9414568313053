<script>
import BaseIcon from "./icons/BaseIcon";
import ApprovedIcon from "./icons/BaseSuccess"

export default {
  components: {
    BaseIcon,
    ApprovedIcon
  },
  props: {
    notify: Object,
  }
}
</script>
<template>
  <base-icon class="sp-header-notify__icon"
             height="40"
             width="40"
             view-box="0 0 19 19"
  >
    <approved-icon></approved-icon>
  </base-icon>
  <div class="sp-header-notify__body">
    <p class="sp-header-notify__date">
      {{ notify.date }}
    </p>
    <p class="sp-header-notify__title">{{ notify.title }}</p>
    <p class="sp-header-notify__description">
      Пользователь: {{ notify.member && notify.member.user && notify.member.user.name }}
      ({{ notify.member && notify.member.user && notify.member.user.email }})
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-header-notify {
  &__body {
    margin-top: 2px;
  }

  &__title {
    @include font(14, 18, null)
  }

  &__date {
    width: 100%;
    @include font(12, 16, null, $inactiveText);
  }

  &__description {
    @include font(12, 16);
    margin-top: 5px;
  }

  &__icon {
    min-width: 40px;
    margin-right: 12px;
  }
}
</style>