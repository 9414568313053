import { Model } from '@vuex-orm/core'

export default class Caption extends Model{
    static entity = "captions"
    static fields () {
        return {
            id: this.attr(null),
            project: this.attr(null),
            title: this.attr(""),
            text: this.attr(""),
            position: this.attr(""),
        }
    }
}