<script>
import ProjectMenu from "../../components/ProjectMenu";
import BaseLayout from "@/layouts/BaseLayout"
import BaseIcon from "../../components/icons/BaseIcon";
import CloseIcon from "../../components/icons/BaseClose";
import MailIcon from "../../components/icons/BaseMail";
import BaseInput from "../../components/BaseInput";

import {mapState} from "vuex";
import Member from "../../store/models/Member";

export default {
  name: "ProjectTeam",
  data() {
    return {
      invites: [],
      deleteModal: {
        id: -1,
        show: false
      },
      deleteInviteModal: {
        id: -1,
        show: false,
      },
      modal: {
        show: false,
        form: {
          email: "",
        },
        openCreate() {
          this.show = true;
          this.form = {
            email: "",
          };
        },
      },
    };
  },
  components: {
    ProjectMenu,
    BaseInput,
    BaseIcon,
    CloseIcon,
    MailIcon,
    BaseLayout
  },
  props: {
    currentProject: Number,
  },
  methods: {
    updateInfo() {
      this.$API
          .get(`projects/team?project=${this.currentProject}`)
          .then((response) => {
            this.invites = response.data.invites;
          });
    },
    async createInvite() {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("email", this.modal.form.email);
      await this.$API.post("projects/team/create_team_invite", formData);
      this.updateInfo();
      this.modal.show = false;
    },
    deleteModalOpen(account) {
      this.deleteModal.id = account.id
      this.deleteModal.show = true
    },
    async deleteModalConfirm() {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("id", this.deleteModal.id);
      await this.$API.post("projects/team/remove", formData)
          .then(() => {
            Member.delete(this.deleteModal.id);
            this.deleteModalClose()
          });
    },
    deleteModalClose() {
      this.deleteModal.id = -1
      this.deleteModal.show = false
    },
    deleteInviteModalOpen(account) {
      this.deleteInviteModal.id = account.id
      this.deleteInviteModal.show = true
    },
    async deleteInviteModalConfirm() {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("id", this.deleteInviteModal.id);
      await this.$API.post("projects/team/remove_team_invite", formData)
          .then(() => {
            let index = this.invites.findIndex(v => v.id === this.deleteInviteModal.id)
            this.invites.splice(index, 1);
            this.deleteInviteModalClose()
          });
    },
    deleteInviteModalClose() {
      this.deleteInviteModal.id = -1
      this.deleteInviteModal.show = false
    },
  },
  computed: {
    members() {
      return this.project && this.project.members || []
    },
    ...mapState(['currentProject', 'project', 'projectTime']),
  },

  created() {
    this.updateInfo();
  },
};
</script>

<template>
  <BaseLayout>
    <div class="sp-flex">
      <ProjectMenu :project-id="currentProject" :project="project" :time="projectTime"></ProjectMenu>
      <div class="sp-settings-page">
        <h2 class="sp-settings-page__title">Команда</h2>
        <h2 class="sp-settings-page__description">
          Командная работа позволяет организовать совместную работу над созданием постов.
        </h2>

        <div class="sp-settings-page__create sp-btn sp-btn--main_outlined"
             @click="modal.openCreate"
        >
          Добавить
        </div>
        <div class="sp-team__list sp-settings-page__list">
          <div class="sp-team-item sp-settings-page-item sp-sortable"
               v-for="member in members"
               :key="member.id"
          >
            <div class="sp-team-item__content sp-sortable__content">
              {{ member.user.name }} <i class="sp-team-item__aka">|</i> {{ member.user.email }}
            </div>
            <div class="sp-sortable__actions">
              <div
                  class="sp-settings-page-item__action sp-btn"
                  @click="deleteModalOpen(member)"
              >
                <base-icon height="20"
                           width="20"
                           icon-name="Удалить"
                >
                  <close-icon/>
                </base-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="sp-team-invites">
          <h2 class="sp-team-invites__title">Приглашения</h2>
          <div class="sp-team-invites__list">
            <div class="sp-team-invites-invite"
                 v-for="invite in invites"
                 :key="invite.email"
            >
              <div class="sp-team-invites-invite__content">
                <base-icon height="24"
                           width="24"
                           icon-name="Приглашение отправлено"
                >
                  <mail-icon/>
                </base-icon>
                {{ invite.email }}
              </div>
              <div class="sp-team-invites-invite__remove sp-btn"
                   @click="deleteInviteModalOpen(invite)"
              >
                <base-icon height="16"
                           width="16"
                           icon-name="Удалить"
                >
                  <close-icon/>
                </base-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
  <Modal v-model="modal.show"
         :close="() => (modal.show = false)"
  >
    <form class="sp-modal sp-modal-edit" ref="memberForm">
      <input type="hidden" name="project" :value="currentProject">
      <p class="sp-modal__title sp-modal-edit__title">Доступ</p>
      <base-input name="title" label="Email"
                  :input-class-list="['sp-sp-modal-edit__input']"
                  v-model="modal.form.email"
      >
      </base-input>
      <button class="sp-btn sp-btn--main sp-modal-edit__create"
              @click.prevent="createInvite"
      >
        {{ "Отправить приглашение" }}
      </button>
    </form>
  </Modal>
  <Modal v-model="deleteModal.show" :close="deleteModalClose">
    <div class="sp-modal sp-modal-delete">
      <p class="sp-modal__title">Хотите удалить доступы?</p>
      <div class="sp-modal-delete__bottom">
        <button class="sp-modal-delete__back sp-btn sp-btn--optional"
                @click.prevent="deleteModalClose"
        >
          Нет, вернуться
        </button>
        <button class="sp-modal-delete__delete sp-btn sp-btn--main"
                @click.prevent="deleteModalConfirm"
        >
          Да, удалить безвозвратно
        </button>
      </div>
    </div>
  </Modal>
  <Modal v-model="deleteInviteModal.show" :close="deleteInviteModalClose">
    <div class="sp-modal sp-modal-delete">
      <p class="sp-modal__title">Хотите удалить приглашение?</p>
      <div class="sp-modal-delete__bottom">
        <button class="sp-modal-delete__back sp-btn sp-btn--optional"
                @click.prevent="deleteInviteModalClose"
        >
          Нет, вернуться
        </button>
        <button class="sp-modal-delete__delete sp-btn sp-btn--main"
                @click.prevent="deleteInviteModalConfirm()"
        >
          Да, удалить безвозвратно
        </button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss">
@import "src/assets/scss/env";

.sp-team {
  &-item {
    height: 52px;
    padding-left: 20px !important;

    &__content {
      font-weight: normal;
    }

    &__aka {
      @include font(16, 16, normal, $projectMenuTime);
      margin: 0 12px;
    }
  }

  &__list {
    max-width: 700px;
  }

  &-invites {
    margin-top: 40px;

    &__title {
      @include font(24, 30, bold, $inactiveText);
    }

    &__list {
      margin-top: 15px;
      max-width: 700px;
    }

    &-invite {
      border-radius: 8px;
      background: $projectMenuItemBg;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include font(16, 24, normal, $teamInviteText);
      margin: 5px 0;

      &__content {
        display: flex;

        & svg {
          margin-right: 20px;

          & path {
            fill: $fgText;
          }
        }
      }

      &__remove {
        width: unset;

        & svg {
          & path {
            fill: $fgText;
          }
        }

        &:hover {
          & svg {
            & path {
              fill: $text;
            }
          }
        }
      }

    }
  }
}
</style>
