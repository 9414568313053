import { Model } from '@vuex-orm/core'

export default class Member extends Model {
    static entity = "members"
    static fields () {
        return {
            id: this.attr(null),
            project: this.attr(null),
            user: this.attr(null)
        }
    }

}