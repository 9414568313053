<script>
import Datepicker from "../../components/Datepicker";
import timeSelect from "../../components/DatepickerTimeSelect";
import ProjectPublicationsFormv2 from "../../components/ProjectPublicationsFormv2";
import BaseIcon from "../../components/icons/BaseIcon";
import ArrowIcon from "../../components/icons/BaseArrow";
import BaseInput from "../../components/BaseInput";
import SuccessIcon from "../../components/icons/ProjectPublicationEditorSuccess";
import ErrorIcon from "../../components/icons/ProjectPublicationEditorError";
import InfoIcon from "../../components/icons/ProjectPublicationEditorInfo";
import BaseLayout from "@/layouts/BaseLayout"

import {publicationTypes} from "@/logic/enums";
import Publication from "../../store/models/Publication";

import {mapState} from "vuex"

export default {
  name: "PublicationsCreate",
  data() {
    return {
      publicationTypes,
      selectedDate: {
        date: new Date(Date.now()),
        hours: 0,
        minutes: 0,
      },
      callbackTime: {
        hours: 0,
        minutes: 0,
      },
      templates: [],

      data: {},
      stages: [],
      sendPreloader: {
        active: false,
        error: false,
        success: false,
        text: "Проверяем наличие файлов...",
        errorDescription: "",
        total: 999,
        value: 0,
        open(text, total) {
          this.text = text;
          this.total = total;
          this.value = 0;
          this.active = true;
          this.error = false;
        },
        step(text) {
          this.text = text;
          this.value++;
          this.error = false;
        },
        throw(text, description = "") {
          this.text = text;
          this.errorDescription = description;
          this.error = true;
        },
      },
      modals: {
        template: {
          title: '',
        }
      },
      show: {
        datepicker: false,
        templateMenu: false,
        templateModal: false,
      },
      formError: false,
      timepickerChanged: false,
    };
  },
  components: {
    Datepicker,
    timeSelect,
    ProjectPublicationsFormv2,
    BaseIcon,
    ArrowIcon,
    BaseInput,
    SuccessIcon,
    ErrorIcon,
    InfoIcon,
    BaseLayout,
  },
  props: {
    publication: Number,
    type: Number,
  },
  methods: {
    async fillForm() {
      if (!this.publicationToEdit) return
      let [day, month, year] = this.publicationToEdit.pub_date.split(".");
      let [hours, minutes] = this.publicationToEdit.pub_time.split(":");
      this.selectedDate.date = new Date(
          `${year}-${month}-${day}T${hours}:${minutes}`
      );
      this.selectedDate.hours = parseInt(hours);
      this.selectedDate.minutes = parseInt(minutes);
      this.timepickerChanged = true;
    },

    async addTemplate() {
      await this.createPublication(
          undefined,
          this.modals.template.title
      );
      this.show.templateModal = false;
    },

    closePreload() {
      this.sendPreloader.active = false;
      this.sendPreloader.success = false;
      this.sendPreloader.error = false;
      this.sendPreloader.text = 'Проверяем наличие файлов...';
      this.sendPreloader.errorDescription = "Возможно пропало соединение с сервером, или прводятся технические работы на нашем ресурсе. Попробуйте опубликовать чуть позже.";
    },

    //datepicker
    closeDatepicker() {
      this.show.datepicker = false;
    },
    ltNow(date) {
      if (!date) return false;
      date.setSeconds(0, 0,);
      let now = new Date(Date.now());
      now.setSeconds(0, 0);
      return date < now
    },
    onSelectDate(date) {
      this.timepickerChanged = true;
      date.setHours(this.selectedDate.hours);
      date.setMinutes(this.selectedDate.minutes);
      this.selectedDate.date = new Date(date.getTime());
      this.formError = this.ltNow(this.selectedDate.date)
    },
    onChangeHours(hours, initial = false) {
      if (!initial) this.timepickerChanged = true;
      this.selectedDate.hours = hours;
      this.selectedDate.date.setHours(hours);
      this.selectedDate.date = new Date(this.selectedDate.date.getTime());
      this.formError = this.ltNow(this.selectedDate.date)
    },
    onChangeMinutes(minutes, initial = false) {
      if (!initial) this.timepickerChanged = true;
      this.selectedDate.minutes = minutes;
      this.selectedDate.date.setMinutes(minutes);
      this.selectedDate.date = new Date(this.selectedDate.date.getTime())
      this.formError = this.ltNow(this.selectedDate.date)

    },
    clearMessage(message) {
      let nodes = new DOMParser().parseFromString(message, "text/html").body.childNodes,
          finalMessage = "";
      for (const node of nodes) {
        console.log('node', node)
        if (node.nodeType === 3) {
          finalMessage += node.textContent;
        } else {
          if (node.tagName === "IMG") {
            finalMessage += node.dataset.emoji
          }
        }
      }
      return finalMessage;
    },
    async createPublication(draft = false, template = "") {
      let formData = new FormData();
      for (const key in this.data) {
        if (this.data[key]) formData.append(key, this.data[key]);
      }
      if (this.publication) {
        formData.append("publication", this.publication);
      }
      if (draft) {
        formData.append("draft", "on");
      }
      if (template) {
        formData.append("template", template);
      }
      formData.append("project", this.currentProject);
      formData.append(
          "publication_date",
          this.selectedDate.date.toLocaleString()
      );
      try {
        this.sendPreloader.open("Отправляем данные на сервер...", 1);
        await this.$API.post(
            this.publication
                ? "projects/publications/edit"
                : "projects/publications/create",
            formData
        )
            .then((response) => {
              this.sendPreloader.step("Публикация успешно запланирована!");
              this.sendPreloader.success = true;

              Publication.insert({data: response.data});
            });
      } catch (e) {
        let message = (e.response && e.response.data && e.response.data.message) || "Произошла ошибка, попробуйте еще раз чуть позже",
            description = (e.response && e.response.data && e.response.data.description) || "Возможно пропало соединение с сервером, или прводятся технические работы на нашем ресурсе. Попробуйте опубликовать чуть позже.";
        this.sendPreloader.throw(
            message, description
        );
      }
    },

    leadingZero(num) {
      if (num < 10) {
        return `0${num}`;
      }
      return num;
    },
    onClearCurrentTime() {
      this.timepickerChanged = false;
      let now = new Date(Date.now());
      this.selectedDate.date = now
      this.selectedDate.hours = now.getHours()
      this.selectedDate.minutes = now.getMinutes()
      this.callbackTime.hours = now.getHours()
      this.callbackTime.minutes = now.getMinutes()
      this.formError = this.ltNow(this.selectedDate.date)
    },
    timepickerOpen(timeSelected) {
      if (!timeSelected) {
        this.onClearCurrentTime();
      }
      this.show.datepicker = !this.show.datepicker
    },
    closeOnOutsideClick(evt) {
      let target = evt.target || evt.currentTarget;
      if (!target.closest('.sp-pub_editor__time_select')) {
        this.show.datepicker = false
      }
    },
  },
  computed: {
    preloadProgressBar: function () {
      if (!this.sendPreloader.total) {
        return "0";
      } else {
        return `${
            this.sendPreloader.value / (this.sendPreloader.total / 100)
        }%`;
      }
    },
    publicationToEdit() {
      return this.$store.getters['entities/publications/query']()
          .where('id', this.publication).with('accounts').get()[0]
    },
    planning() {
      return this.selectedDate.date > new Date(Date.now());
    },
    publications() {
      return this.$store.getters['entities/publications/query']()
          .where('project', this.currentProject)
          .where('template', false).withAllRecursive().get()
    },
    datepickerPublications() {
      let publications = [];
      let addedDates = [];
      if (!this.publications) return
      for (const publication of this.publications) {
        if (!publication) continue;
        if (addedDates.indexOf(publication.pub_date) === -1) {
          publications[publication.pub_date] = {
            published: 0,
            count: 0,
            draft: 0,
            wait: 0,
            error: 0,
          };
          addedDates.push(publication.pub_date);
        }
        if (publication.published) publications[publication.pub_date].published++;
        else if (publication.draft) publications[publication.pub_date].draft++;
        else if (publication.error) publications[publication.pub_date].error++;
        else if (publication.approved) publications[publication.pub_date].wait++;
        else if (publication.stage) publications[publication.pub_date].count++;
      }

      return publications;
    },
    ...mapState(['project', 'currentProject', 'projectTime'])
  },
  watch: {
    publicationToEdit: {
      handler: function (v) {
        if (v) {
          this.fillForm()
        }
      },
      immediate: true,
    },
    projectTime: {
      handler: function (v) {
        if (v) {
          if (this.timepickerChanged && this.selectedDate.date < v) {
            this.formError = true
          } else if (!this.timepickerChanged) {
            this.selectedDate.date = v
            this.selectedDate.minutes = v.getMinutes()
            this.selectedDate.hours = v.getHours()
          }
        }
      },
      immediate: true
    }
  },
  created() {
    let date = this.selectedDate.date;
    if (!this.publication) {
      this.onChangeHours(date.getHours(), true);
      this.onChangeMinutes(date.getMinutes(), true);
    }
    console.log(localStorage.selectedGroups);
    if (localStorage.selectedGroups)
      this.groups = JSON.parse(localStorage.selectedGroups);
  },
  mounted() {
    document.addEventListener('click', this.closeOnOutsideClick)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeOnOutsideClick)
  }

};
</script>

<template>
  <BaseLayout>
    <div class="sp-pub_editor">
      <div class="sp-pub_editor__main">
        <div class="sp-pub_editor__container">
          <div class="sp-pub_editor-back sp-btn"
               @click="$router.push({
            name: 'ProjectPublications',
            params: { currentProject },
          })"
          >
            <base-icon width="14" height="14" icon-name="Выйти из редактора">
              <arrow-icon/>
            </base-icon>
            Выйти из редактора
          </div>
          <h1 class="sp-pub_editor__title">Создать публикацию</h1>
          <keep-alive>
          <ProjectPublicationsFormv2
              :currentProject="currentProject"
              :publication-to-edit="publicationToEdit"
              :project="project"
              :timepicker-changed="timepickerChanged"
              :selected-date="selectedDate"
              :type="type"
              :blockSubmit="formError"
              v-model:formData="data"
              @publicationSave="createPublication"
              @publicationDraft="createPublication(true)"
              @publicationTimePicker="timepickerOpen"
              @publicationToTemplate="show.templateModal = !show.templateModal"
              :showForm="!sendPreloader.active"
          >
            <template v-slot:timePicker>
              <div class="sp-pub_editor-form-timepicker"
                      v-show="show.datepicker"
              >
                <datepicker
                    @selectDate="onSelectDate"
                    mode="fromCurrent"
                    :date="selectedDate.date"
                    :classes="['sp-pub_editor-form-timepicker__top']"
                    :publications="datepickerPublications"
                >
                  <div class="sp-pub_editor-form-timepicker__bottom">
                    <timeSelect
                        @changeHours="onChangeHours"
                        @changeMinutes="onChangeMinutes"
                        :startH="selectedDate.date.getHours()"
                        :startM="selectedDate.date.getMinutes()"
                        :callbackH="callbackTime.hours"
                        :callbackM="callbackTime.minutes"
                        @clearCurrentTime="onClearCurrentTime"
                    >
                    </timeSelect>
                  </div>
                  <div class="sp-pub_editor-form-timepicker__error" v-show="formError">
                    Время публикации не может быть меньше текущего
                  </div>
                </datepicker>
              </div>
            </template>
          </ProjectPublicationsFormv2>
          </keep-alive>
          <div class="sp-pub_editor-result"
               v-show="sendPreloader.active"
          >
            <div class="sp-pub_editor-result__content">
              <base-icon class="sp-pub_editor-result-icon"
                         :class="{'sp-pub_editor-result-icon--preloader': !sendPreloader.error && !sendPreloader.success}"
                         height="30"
                         width="30"
                         icon-name=""
              >
                <success-icon v-show="sendPreloader.success"/>
                <error-icon v-show="sendPreloader.error"/>
              </base-icon>
              <div class="sp-pub_editor-result__text">
                {{ sendPreloader.text }}
              </div>
            </div>
            <div class="sp-pub_editor-result__additional"
                 v-show="sendPreloader.error"
            >
              <div class="sp-pub_editor-result__btn sp-btn sp-btn--main"
                   @click="closePreload"
              >
                Назад к редактированию
              </div>
              <div class="sp-pub_editor-result__info sp-btn">
                <base-icon height="24"
                           width="25"
                           icon-name=""
                >
                  <info-icon/>
                </base-icon>
                <div class="sp-pub_editor-result__popup sp-popup sp-popup--default sp-popup_display_none">
                  {{ sendPreloader.errorDescription }}
                </div>
              </div>
            </div>
          </div>
          <Modal v-model="show.templateModal"
                 :close="() => (show.templateModal = false)"
          >
            <div class="sp-modal sp-pub_editor-template">
              <p class="sp-modal__title sp-pub_editor-template__title">Создать шаблон</p>
              <base-input name="title" label="Название"
                          v-model="modals.template.title"
              >
              </base-input>
              <button class="sp-btn sp-btn--main sp-pub_editor-template__create"
                      @click.prevent="addTemplate"
              >
                Сохранить шаблон
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<style lang="scss">

.sp-pub_editor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  &__main {
    width: 1270px;
    margin-top: 60px;
    @include _1600 {
      padding: 0 40px;
      width: 100%;
    }
  }

  &__container {
    width: 1140px;
    @include _1600 {
      width: 100%;
    }
  }

  &__title {
    margin-top: 30px;
    @include font(30, 34, bold);
  }

  &-result {
    margin-top: 30px;
    padding: 15px 30px;
    border-radius: 8px;
    background: $white;
    border: 1px solid $borderDA;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__content {
      align-items: center;
      display: flex;
      padding: 15px 0;
    }

    &__additional {
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: 13px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      &--preloader {
        border-radius: 50%;
        background: linear-gradient(93.54deg, $publicationsEditBtn 0%, #B5E134 98.15%);
        animation: spin 1s infinite;
      }

      &__inner {
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
    }

    &__title {
      @include font(16, 20);
    }

    &__btn {
      height: 60px;
      width: 258px;
      margin-right: 20px;
      @include font(15, 20, null, $white);
    }

    &__info {
      position: relative;
      width: 27px;
      height: 24px;

      & path {
        fill: $publicationEditorInfoIcon;
      }

      &:hover {
        & .sp-popup {
          display: block;
        }

        & path {
          fill: $publicationsEditBtn;
        }
      }
    }

    &__popup {
      @include font(12, 17, null, $text1);
      padding: 16px !important;
    }
  }

  &-back {
    @include font(15, 20, None, $projectMenuTime);
    justify-content: unset;

    & > svg {
      transform: rotate(180deg);
      margin-right: 8px;

      & path {
        transition: fill .2s;
        fill: $projectMenuTime;
      }
    }

    &:hover {
      color: $text;

      & > svg path {
        fill: $text;
      }
    }
  }

  &-menu {
    display: none;
    position: absolute;
    width: 100%;
    background: $component;

    &.active {
      display: block;
    }

    &__item {
      top: 100%;
      left: 0;
      width: 100%;
      margin: 5px 0;
      padding: 2px 5px;
      color: $base;
    }
  }

  &-time {
    display: block;
  }

  &-datepicker_btn {
    width: 40em;
  }

  &-additional {
    display: block;
    width: 100%;

    &-link {
      color: $base;
      margin: 10px 0;
      font-size: 16px;
      padding: 10px;
      border: 1px solid $base;
      border-radius: 10px;

      &__input {
        font-size: 14px;
        border: 1px solid $base;
        border-radius: 10px;
        background: $component;
        padding: 0 10px;
        color: $base;
        width: calc(100% - 40px);
      }

      &-content {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-top: 1px solid $base;
      }
    }

    &-quiz {
      color: $base;
      margin: 10px 0;
      font-size: 16px;
      padding: 10px;
      border: 1px solid $base;
      border-radius: 10px;

      &-top,
      &-answer {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding: 10px;
      }

      &-answer {
        margin-left: 20px;
        border-radius: 10px;
        border: 1px solid $base;
      }

      &__input {
        width: calc(100% - 50px);
        border-radius: 10px;
        border: 1px solid $base;
        background: $component;
        color: #fff;
        padding: 0 20px;
        height: 40px;
      }

      &__btn {
        padding: 5px 10px;
        border-radius: 10px;
        border: 1px solid $base;
        width: fit-content;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }

  &-actions {
    display: flex;
    justify-content: space-between;

    width: 40em;

    &__pub_date {
      display: flex;
      justify-content: center;
      flex-flow: column;
      color: $base;
    }

    &-buttons {
      display: flex;
      margin: 0 -5px 0 20px;
      padding-bottom: 20px;
      min-width: fit-content;
    }
  }

  &-publish {
    margin: 20px 10px 0;
    border-radius: 15px;
    border: 1px solid $base;
    padding: 5px 20px;
    font-size: 14px;
    text-align: center;
    position: relative;
  }

  &-form {
    &-timepicker {
      position: absolute;
      right: 0;
      bottom: 100%;
      cursor: default;

      &__top {
        border-radius: 8px 8px 0 0 !important;
        padding: 30px 20px 10px !important;
        background: $white !important;
      }

      &__error {
        border: 1px solid $borderDA;
        background: $white;
        margin-top: 10px;
        padding: 15px 10px;
        @include font(14, 18, null, $statusError);
      }

      &__bottom {
        margin-top: -1px;
        border: 1px solid $border;
        border-radius: 0 0 8px 8px;
        padding: 14px 30px 13px;
        background: $white;
      }
    }
  }

  &-template {
    min-width: 685px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    @include _768 {
      min-width: 100%;
      width: 100%;
    }

    &__create {
      margin-top: 30.5px;
      height: 45px;
      width: 226px;
    }

    &__title {
      margin-bottom: 25px;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}

.current-date {
  color: $base;
  text-align: center;
  font-size: 12px;
  margin-bottom: 40px;
}

.datepicker-row {
  margin-top: 40px;
  width: 40em;
  display: flex;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  border: 1px solid $base;
  background: #333;
}

.preload {
  padding: 20px 40px;
  display: flex;
  width: 30em;
  align-items: center;
  flex-flow: column nowrap;
  background: #333;
  border: 1px solid $base;
  border-radius: 10px;

  &__text {
    font-size: 16px;
    color: #fff;
    margin: 10px 0;
  }

  &-progress {
    margin-top: 10px;
    width: 100%;
    height: 20px;
    border: 1px solid $base;
    border-radius: 5px;
    overflow: hidden;

    &-bar {
      height: 100%;
      background: darkgreen;

      &.error {
        background: red;
      }
    }
  }
}
</style>
