import { Model } from '@vuex-orm/core'


export default class PublicationAccount extends Model{
    static entity = 'publicationAccounts'

    static primaryKey = ['account_id', 'publication_id']

    static fields () {
        return {
            account_id: this.attr("null"),
            publication_id: this.attr("null"),
        }
    }
}