export const shortTitle = (title) => {
  let splitted = title.split(" ");
  console.log("SPLITTED", splitted)
  if (splitted.length > 1) {
    title = splitted.flatMap((v) => v[0] ? v[0].toUpperCase() : v[0]);
    title.splice(2);
    return title.join("");
  }
  return title.substr(0, 2).toUpperCase();
};

export const limitString = (string, limit) => {
  string = string.trim();
  if (string.length <= limit) return string;
  string = string.slice(0, limit);
  let lastSpace = string.lastIndexOf(" ");
  if (lastSpace > 0) {
    string = string.substr(0, lastSpace);
  }
  return string + "...";
};

export const applyOffset = (date, offset) => {
  return new Date(
    date.getTime() +
      date.getTimezoneOffset() * 60 * 1000 +
      offset * 60 * 60 * 1000
  );
};

export const leadingZero = (num) => {
  if (parseInt(num) < 10) {
    return "0" + num;
  }
  return num;
};
