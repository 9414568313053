<script>
    export default {
        props: {
            historyItem: Object,
        }
    }
</script>
<template>
    <p v-show="historyItem">

    </p>
</template>
