<script>
import ProjectMenu from "../../components/ProjectMenu";
import SettingsMenu from "../../components/SettingsMenu";

import BaseIcon from "../../components/icons/BaseIcon";
import CloseIcon from "../../components/icons/BaseClose";
import EditIcon from "../../components/icons/BaseEdit";
import DragIcon from "../../components/icons/NavbarDrag";
import BaseInput from "../../components/BaseInput";
import BaseLayout from "@/layouts/BaseLayout"

import draggable from "vuedraggable";

import {mapState} from "vuex";

import QuickLink from "../../store/models/QuickLink";

export default {
  name: "SettingsQuickLinks",
  data() {
    return {
      sortableLinks: [],
      drag: false,
      deleteModal: {
        id: -1,
        show: false
      },
      modal: {
        show: false,
        mode: 0,
        target: 0,
        form: {
          title: "",
          text: "",
        },
        openCreate() {
          this.mode = 0;
          this.show = true;
          this.form = {
            title: "",
            link: "",
          };
        },
        openChange(link) {
          this.mode = 1;
          this.show = true;
          this.target = link.id;
          this.form = {
            title: link.title,
            link: link.link,
          };
        },
      },
    };
  },
  props: {
    currentProject: Number,
  },
  methods: {
    async linkAdd() {
      let formData = new FormData(this.$refs.linkForm);
      await this.$API.post("projects/ql/add", formData)
          .then((response) => QuickLink.insert({data: response.data}));
      this.modal.show = false;
    },
    async linkChange() {
      let formData = new FormData(this.$refs.linkForm);
      formData.append("id", this.modal.target);
      await this.$API
          .post("projects/ql/change", formData)
          .then((response) => QuickLink.insert({data: response.data}));
      this.modal.show = false;
    },
    linksPositionChange() {
      let order = {};
      for (let [index, link] of this.sortableLinks.entries()) {
        order[parseInt(link.id)] = index;
      }
      let formData = new FormData();
      formData.append("order", JSON.stringify(order));
      formData.append("project", this.currentProject);
      this.$API.post("projects/ql/change/position", formData);
    },
    deleteModalOpen(account) {
      this.deleteModal.id = account.id
      this.deleteModal.show = true
    },
    async deleteModalConfirm() {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("id", this.deleteModal.id);
      await this.$API.post("projects/ql/remove", formData)
          .then(() => {
            QuickLink.delete(this.deleteModal.id);
            this.deleteModalClose()
          });
    },
    deleteModalClose() {
      this.deleteModal.id = -1
      this.deleteModal.show = false
    },
  },
  components: {
    ProjectMenu,
    SettingsMenu,
    draggable,
    BaseIcon,
    DragIcon,
    EditIcon,
    CloseIcon,
    BaseInput,
    BaseLayout,
  },
  computed: {
    links() {
      return this.project && this.project.quick_links || []
    },
    ...mapState(['project', 'projectTime'])
  },
  watch: {
    links: {
      handler: function (v) {
        if (v) this.sortableLinks = v.sort(v => v.position);
      },
      immediate: true,
    }
  },
};
</script>

<template>
  <BaseLayout>
    <div class="sp-flex">
      <ProjectMenu :project="project" :project-id="currentProject" :time="projectTime"></ProjectMenu>
      <div class="sp-settings-page">
        <h2 class="sp-settings-page__title">Быстрые ссылки</h2>
        <p class="sp-settings-page__description">
          В этом разделе вы можете настроить дополнительные пункты меню которые будут отображаться в левом меню каждого
          проекта.
        </p>
        <div class="sp-settings-page__create sp-btn sp-btn--main_outlined"
             @click="modal.openCreate"
        >
          Создать
        </div>
        <draggable class="sp-settings-page__list"
                   v-model="sortableLinks"
                   item-key="id"
                   @sort="linksPositionChange"
        >
          <template #item="{ element }">
            <div class="sp-settings-page-item sp-sortable">
              <div class="sp-sortable__content">
                <base-icon class="sp-sortable__drag"
                           height="30"
                           width="30"
                           icon-name="Сортировка"
                >
                  <drag-icon/>
                </base-icon>
                {{ element.title }}
              </div>
              <div class="sp-sortable__actions">
                <div class="sp-settings-page-item__action sp-settings-page-item__edit sp-btn"
                     @click="modal.openChange(element)"
                >
                  <base-icon height="20"
                             width="20"
                             icon-name="Редактировать"
                  >
                    <edit-icon/>
                  </base-icon>
                </div>
                <div
                    class="sp-settings-page-item__action sp-btn"
                    @click="deleteModalOpen(element)"
                >
                  <base-icon height="20"
                             width="20"
                             icon-name="Удалить"
                  >
                    <close-icon/>
                  </base-icon>
                </div>
              </div>
            </div>
          </template>
        </draggable>

      </div>
      <SettingsMenu :currentProject="currentProject"></SettingsMenu>
    </div>
  </BaseLayout>
  <Modal v-model="modal.show"
         :close="() => (modal.show = false)"
  >
    <form class="sp-modal sp-modal-edit" ref="linkForm">
      <input type="hidden" name="project" :value="currentProject">
      <p class="sp-modal__title sp-modal-edit__title">Быстрая ссылка</p>
      <base-input name="title" label="Название"
                  :input-class-list="['sp-sp-modal-edit__input']"
                  v-model="modal.form.title"
      >
      </base-input>
      <base-input name="link" label="Ссылка"
                  :input-class-list="['sp-sp-modal-edit__input']"
                  v-model="modal.form.link"
      >
      </base-input>
      <button class="sp-btn sp-btn--main sp-modal-edit__create"
              @click.prevent="modal.mode ? linkChange() : linkAdd()"
      >
        {{ modal.mode ? "Изменить" : "Добавить" }}
      </button>
    </form>
  </Modal>
  <Modal v-model="deleteModal.show" :close="deleteModalClose">
    <div class="sp-modal sp-modal-delete">
      <p class="sp-modal__title">Хотите удалить ссылку?</p>
      <div class="sp-modal-delete__bottom">
        <button class="sp-modal-delete__back sp-btn sp-btn--optional"
                @click.prevent="deleteModalClose"
        >
          Нет, вернуться
        </button>
        <button class="sp-modal-delete__delete sp-btn sp-btn--main"
                @click.prevent="deleteModalConfirm"
        >
          Да, удалить безвозвратно
        </button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.app-page__description {
  font-size: 14px;
  color: $base;
  line-height: 18px;
  margin-top: 12px;
}

.links {
  &-list {
    width: 100%;
    margin-top: 20px;

    &__item {
      width: 100%;
      display: flex;
      border-radius: 10px;
      padding: 4px 5px;
      margin: 10px 0;
      background: $component;
      align-items: center;
    }

    &__drag {
      padding: 2px 4px 2px 1px;
      width: 20px;
      color: $base;
      cursor: move;
      user-select: none;

      &-row {
        width: 100%;
        font-size: 12px;
        line-height: 12px;
      }
    }

    &__title {
      font-size: 14px;
      margin-left: 10px;
      color: $base;
      flex: 1;
    }

    &__actions {
      padding: 0 2px 0 10px;
      display: flex;
      height: 100%;

      &-item {
        font-size: 14px;
        padding: 0 10px;
        color: $base;
        height: 100%;
        cursor: pointer;
      }
    }

    &__edit {
    }

    &__delete {
    }
  }
}
</style>
