<script>
    export default {
        props: {
            item: Object,
        },
    }
</script>
<template>
    <img class="sp-history-list-media-image"
         :src="item.url"
         :alt="item.name"
    >
</template>
<style lang="scss" scoped>
    @import "src/assets/scss/env";

    .sp-history-list-media {
        &-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
</style>