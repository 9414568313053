<script setup>
// es-nocheck
import {NSpace, NH1, NConfigProvider, NButton, NCard, darkTheme} from 'naive-ui';
import AdminCards from "../components/AdminCards.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import {ref, watchEffect} from "vue";
import {API} from "../plugins/axios"
// import {limitString} from "@/logic/tools";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const projects = ref([]);
const router = useRouter();
function onPublicationClick(publication) {
  router.push({name: "AdminDebugPublication", params: {
      project: route.params.project,
      publication: publication.id,
    }})
}
watchEffect(() => {
  const fd = new FormData();
  fd.append('project', route.params.project);
  API.post('/debug/publications/', fd).then(response => {
    projects.value = response.data;
  })
})
</script>


<template>
<n-config-provider :theme="darkTheme">
    <admin-layout>
      <n-space vertical>
        <n-button @click="router.push({name:'AdminDebug'})">
          Назад
        </n-button>
        <n-h1>Панель отладки</n-h1>
        <admin-cards :items="projects" :paginateBy="20" :searchFields="['attachments', 'id']" v-slot="slotProps">
            <n-card
                size="small"
                style="cursor: pointer; height: 100%"
                v-for="item of slotProps.items"
                :key="item.title"
                @click="onPublicationClick(item)"
            >
              <div>{{item.attachments.message}}</div>
            </n-card>
           <n-card
                size="small"
                style="cursor: pointer; height: 100%"
                @click="router.push({name: 'AdminDebugProject', params: {
                  project: 28
                }})"
            >
              <div >TETetsfasdf</div>
            </n-card>
        </admin-cards>
      </n-space>
    </admin-layout>
</n-config-provider>
</template>

<style lang="scss" scoped>

</style>