import * as Sentry from "@sentry/vue";
import {BrowserTracing} from "@sentry/tracing";
import router from "@/router";

const enable = false;

export default {
    install: (app) => {
        if (!enable) return;
        Sentry.init({
            app,
            dsn: "https://8845dc4018284ed1a304c0aff5908945@o1386092.ingest.sentry.io/6706268",
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                    tracingOrigins: ["smm-plan.ru", /^\//],
                }),
            ],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
        });

    }
};