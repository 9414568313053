<template>
  <div :class="usedClasses.block">
    <label v-if="title" :class="usedClasses.label" :for="id">{{ title }}</label>
    <input
        :id="id"
        v-model="value"
        :type="type"
        :class="usedClasses.input"
        :name="name"
        @click="isFocused = true"
        @blur="isFocused = false"
        @input="onInput"
    />
  </div>
</template>

<script>
export default {
  name: "BaseInputV2",
  emits: ["update:modelValue", 'input'],
  props: {
    id: {required: true, type: String},
    name: {required: true, type: String},
    height: {default: 60, type: Number},
    modelValue: {type: String},
    title: {type: String},
    classesOverride: {
      required: false,
      default: {},
    },
    type: {default: 'text', type: String},
    classes: {
      required: false,
      default: {},
    }
  },
  data() {
    return {
      defaultClasses: {
        block: ["v-input"],
        label: ["v-input__label"],
        input: ["v-input__input"],
      },
      usedClasses: {
        block: [],
        label: [],
        input: [],
      },
      value: "",
      isFocused: false,
      labelFocused: false,
    };
  },
  computed: {
    cssH() {
      return `${this.height}px`;
    },
    cssFS() {
      return `${this.height * 0.28}px`;
    },
    cssLH() {
      return `${this.height * 0.30}px`;
    },
    cssTop() {
      return `${this.height / 3}px`
    }
  },
  methods: {
    overrideDefaultClasses() {
      for (const elem of ["block", "label", "input"]) {
        this.usedClasses[elem] = this.classes[elem] ? this.classes[elem] : this.defaultClasses[elem];
      }
    },
    useAdditionalClasses() {
      for (const elem of ["block", "label", "input"]) {
        if (this.classes[elem]) {
          this.usedClasses[elem].push(
            ...(this.classes[elem])
          );
        }
      }
    },
    onFocus() {
      if (!this.labelFocused) {
        this.usedClasses['label'].push('v-input__label--focused');
      }
      this.labelFocused = true;
    },
    onBlur() {
      this.isFocused = Boolean(this.value);
      if (this.isFocused) return;
      const index = this.usedClasses['label'].indexOf('v-input__label--focused')
      if (index > -1) {
        delete this.usedClasses['label'][index];
        this.labelFocused = false;
      }
    },
    onInput(evt) {
      this.$emit('input', evt)
    },
  },
  watch: {
    modelValue: {
      handler: function (v) {
        this.value = v;
      },
      immediate: true,
    },
    value: {
      handler: function (v) {
        if(v) this.isFocused = Boolean(v);
        if (v !== this.modelValue) {
          this.$emit('update:modelValue', v);
        }
      },
      immediate: true,
    },
    isFocused: {
      handler: function (v) {
        if (v) {
          this.onFocus();
        } else {
          this.onBlur();
        }
      },
    }
  },
  mounted() {
    this.overrideDefaultClasses();
    this.useAdditionalClasses();
    this.isFocused = Boolean(this.value);
    if (this.isFocused) {
      this.onFocus();
    } else {
      this.onBlur();
    }
  }
};
</script>

<style lang="scss" scoped>
:root {
  --input-h: v-bind(cssH);
}
.v-input {
  width: 100%;
  border: 1px solid $border;
  border-radius: 8px;
  height: v-bind(cssH);
  position: relative;
  overflow: hidden;
  background: $componentBg;

  &:not(:first-child) {
    margin-top: 12px;
  }

  $self: &;

  &--error {
    border: 1px solid $error;

    #{$self}__label {
      color: $error;
    }
  }

  &__label {
    top: v-bind(cssTop);
    left: 19px;
    font-size: v-bind(cssFS);
    line-height: v-bind(cssLH);
    font-weight: bold;
    color: $inactiveText;
    transition: font-size .2s, line-height .2s, font-weight .2s;
    position: absolute;
    user-select: none;
    pointer-events: none;

    &--focused {
      top: calc(v-bind(cssTop) / 3);
      font-size: calc(v-bind(cssFS) * 0.8);
      line-height: calc(v-bind(cssLH) * 0.8);
      font-weight: normal;
      color: $inactiveText;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    padding: 6.25px 19px 0;
    border: none;

    @include font(14, 17, normal);
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 19px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &--normal {
    height: 50px;

    & #{$self} {
      &__label {
        top: 15.5px;
        left: 20px;
        @include font(16, 19, normal, $publicationMessage);

        &--focused {
          top: 7px;
          @include font(12, 15, normal, $publicationMessage);
        }
      }

      &__input {
        padding: 10px 19px 0;
        font-weight: normal;
      }
    }
  }
}
</style>