<template>
  <div class="content-text">
    <h1>Условия о конфиденциальности персональной информации</h1>
    <p>ИП Кузнецов Сергей Васильевич (далее – Компания) уважает право каждого человека на конфиденциальность.</p>
    <p>
      Для использования некоторых служб, программ и продуктов Компании (далее — Продукты Компании)
      может понадобиться прохождение процедуры регистрации или предоставление персональной информации Компании.
      Регистрация может понадобиться для доступа к некоторым функциям программ или участия в рекламных акциях.
      Условия о конфиденциальности содержат пояснения, как используется такая информация.
      Внимательно ознакомьтесь с настоящими Условиями о конфиденциальности и при необходимости задайте вопросы.
    </p>
    <p>
      Настоящие Условия о конфиденциальности регулируют отношения между Компанией и любым физическим лицом,
      индивидуальным предпринимателем, юридическим лицом (далее – Пользователь) по обработке Персональной
      информации, включая, помимо прочего, сбор, использование, сохранение и безопасность предоставленной
      Пользователем и/или собранной у Пользователя информации. Действие настоящих Условий о
      конфиденциальности распространяются на всю информацию, которую Компания и/или его аффилированные
      или иные уполномоченные лица, действующие от имени Компании, могут получить о Пользователе во время
      использования им любого из Продуктов Компании.
    </p>
    <p>
      Обращаем Ваше внимание: использование Продуктов Компании означает Ваше полное согласие со всеми
      положениями настоящих Условий о конфиденциальности и указанными в них условиями обработки Вашей
      персональной информации. Если Вы не согласны безоговорочно принять все положения настоящих Условий
      о конфиденциальности, Вы не имеете права использовать Продукты Компании и должны удалить все их
      компоненты со своего компьютера (ЭВМ).
    </p>
    <h3>
      1. Персональная информация, собираемая и обрабатываемая Компанией в рамках настоящих Условий
      о конфиденциальности под персональной информацией понимается:
    </h3>
    <ol class="--children-ls-n">
      <li>
        <span>1.1.</span>
        Информация (включая, но не ограничиваясь, персональные данные), которую Пользователь самостоятельно
        предоставляет Компании при осуществлении действий, направленных на регистрацию Продуктов Компании (в т.ч.
        активация) либо в рамках их использования (в т.ч. создание учетной записи, обращение в службу поддержки).
        Информация предоставляется путем заполнения Пользователем соответствующих форм, при этом объем предоставления
        информации при заполнении необязательных полей указанных форм определяется Пользователем самостоятельно.
      </li>
      <li>
        <span>1.2.</span>
        Информация, получаемая автоматически при использовании Продуктов Компании Пользователем, в т.ч. из cookies (при
        загрузке материалов сайтов Компании, автоматически проверяются сведения о системе Пользователя; при
        использовании инструментов общения Пользователем, могут быть сохранены материалы и сообщения, которые он
        отправлял и получал, при этом Компания оставляет за собой право проверять содержание общения Пользователя на
        своих сайтах).
      </li>
      <li>
        <span>1.3.</span>
        Пользовательские данные – любая информация загруженная (внесенная) Пользователем в Продукты Компании.
      </li>
      <li>
        <span>1.4.</span>
        Иная информация о Пользователе, которую получает Компания исключительно в указанных в разделе 2 настоящих
        Условий о конфиденциальности целях.
      </li>
    </ol>
    <h3>
      2. Цели сбора и обработки персональной информации пользователей
    </h3>
    <p>
      Сбор, хранение и обработка указанной в разделе 1 настоящих Условий о конфиденциальности информации
      осуществляется исключительно в следующих конкретных целях:
    </p>
    <ol class="--children-ls-n">
      <li>
        <span>2.1.</span>
        Для идентификации Пользователя при использовании Продуктов Компании;

      </li>
      <li>
        <span>2.2.</span>
        Для персонализации учета посещений (в том числе с целью предоставления персонализированных услуг Пользователю) и
        регистрации действий Пользователя Компания использует Cookies.
      </li>
      <li>
        <span>2.3.</span>
        Для осуществления взаимодействия с Пользователем в рамках заключенных с Компанией договоров или соглашений.
      </li>
      <li>
        <span>2.4.</span>
        Для проведение статистических и иных исследований на основе обезличенных данных (в т.ч. для выяснения мнения
        Пользователей о различных продуктах и услугах, оказываемых Компанией).
      </li>
    </ol>
    <h3>3. Условия обработки персональной информации пользователя и её передачи третьим лицам</h3>
    <ol class="--children-ls-n">
      <li>
        <span>3.1.</span>
        Хранение (в том числе изменение и удаление) персональной информации Пользователей осуществляется в соответствии
        с функциональным назначением конкретных Продуктов Компании, технической документацией к ним, а также
        Лицензионным соглашением на их использование.
      </li>
      <li>
        <span>3.2.</span>
        Раскрытие персональной информации третьим лицам осуществляется следующих случаях:
        <ol class="--children-ls-n">
          <li>
            <span>3.2.1.</span>
            Пользователь заблаговременно выразил свое согласие на такое раскрытие.
          </li>
          <li>
            <span>3.2.2.</span>
            Передача необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по
            которому является Пользователь, а также для заключения договора по инициативе Пользователя или договора, по
            которому Пользователь будет являться выгодоприобретателем или поручителем.
          </li>
          <li>
            <span>3.2.3.</span>
            Передача необходима для защиты прав и законных интересов Компании или третьих лиц в случаях, когда
            Пользователь нарушает авторские права Компании и/или соответствующее Лицензионное соглашение.
          </li>
          <li>
            <span>3.2.4.</span>
            Передача необходима для осуществления правосудия, исполнения судебного акта, акта другого органа или
            должностного лица, подлежащих исполнению в соответствии с законодательством РФ об исполнительном
            производстве либо иных предусмотренных действующим законодательством РФ случаях.
          </li>
        </ol>
      </li>
      <li>
        <span>3.3.</span>
        При обработке персональных данных пользователей 1С-Битрикс руководствуется Федеральным законом РФ «О
        персональных данных».
      </li>

    </ol>
    <h3>4. Ограничения ответственности</h3>
    <ol class="--children-ls-n">
      <li>
        <span>4.1.</span>
        Компания не инициирует размещение персональной информации при использовании Продуктов Компании, не контролирует
        ее достоверность и актуальность, однако Компания оставляет за собой право требовать подтверждения достоверности
        переданной ему информации Пользователем.
      </li>
      <li>
        <span>4.2.</span>
        При размещении (предоставлении Компании) персональной информации третьих лиц Пользователь гарантирует, что
        получил все необходимые разрешения и согласия на указанные действия, а также гарантирует полное и безоговорочное
        согласие этих лиц со всеми положениями с положениями настоящих Условий о конфиденциальности.
      </li>
      <li>
        <span>4.3.</span>
        Компания не может сохранить конфиденциальность персональной информации Пользователя, публичный доступ к которой
        предоставлен самим Пользователем в силу функциональных назначений соответствующего Продукта Компании;
        Пользователь соглашается с тем, что при использовании таких Продуктов определённая часть его персональной
        информации становится доступной для неограниченного круга лиц.
      </li>
    </ol>
    <h3>5. Защита персональной информации</h3>
    <p>
      Компания принимает необходимые правовые, организационные и технические меры или обеспечивает их принятие для
      защиты персональной информации от неправомерного или случайного доступа к ней, уничтожения, изменения,
      блокирования, копирования, предоставления, распространения персональной информации, а также от иных неправомерных
      действий в отношении персональной информации, в том числе:
    </p>
    <ul>
      <li>Использует RSA-шифрование в Продуктах Компании.</li>
      <li>Предоставляет при необходимости двухэтапную аутентификацию для доступа к учетной записи.</li>
      <li>Осуществляет защиту авторизованных сессий.</li>
      <li>Постоянно совершенствует способы сбора, хранения и обработки данных.</li>
    </ul>
    <h3>6. Заключительные положения</h3>
    <p>
      Настоящие Условия о конфиденциальности могут быть изменены Компанией в одностороннем порядке путем размещения их
      новой редакции в сети Интернет по адресу https://smm-plan.ru/b24/privacy. В случае расхождения Условий о
      конфиденциальности с положениями Лицензионного соглашения с Пользователем, преимущественную силу имеет
      Лицензионное соглашение.
    </p>
    <h3>7. Контактная информация</h3>
    <p>Время работы техподдержки:</p>
    <p>Будние дни (пн-пт): с 09:00 до 18:00 мск</p>
    <p>Обратиться в службу технической поддержки можно по электронной почте crm@place-start.ru</p>
    <p>Контактные телефоны службы технической поддержки:</p>
    <p><a href="tel: +7 (8172) 26-41-00">+7 (8172) 26-41-00</a></p>
    <p class="last"><a class="last" href="tel: 8 (800) 777-02-53">8 (800) 777-02-53</a></p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style lang="scss" scoped>
.last {
  margin-bottom: unset !important;
}
.--children-ls-n {
  & > li {
    list-style: none !important;
  }
}

@mixin font_t($size, $lineHeight) {
  font-size: #{$size}px !important;
  line-height: #{$lineHeight}px !important;
  @include _1170 {
    font-size: calc_fluid($size - ($size / 4), $size, 320, 1920) !important;
    line-height: calc_fluid(
            $lineHeight - ($lineHeight / 4),
            $lineHeight,
            320,
            1920
    ) !important;
  }
}

.content-text {
  flex: 1;
  padding: 20px 40px;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: -16px;

  & h1,
  h2,
  h3,
  h4,
  caption {
    font-weight: bold;
    color: $base;
    width: 100%;
  }

  & h2,
  h3,
  h4 {
    margin-top: 24px;
  }

  & p {
    color: $base;
    font-size: calc_fluid(14, 16, 320, 1920);
    line-height: calc_fluid(21, 24, 320, 1920);
    margin-bottom: 16px;
    width: 100%;
  }

  & span {
    color: $base;
    font-size: calc_fluid(14, 16, 320, 1920);
    line-height: calc_fluid(21, 24, 320, 1920);
  }

  & img {
    width: 100% !important;
    object-fit: contain;
  }

  & h1 {
    margin-bottom: 28px;
    @include font_t(38, 43);
  }

  & h2 {
    @include font_t(31, 40);
    margin-bottom: 24px;
  }

  & h3 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }

  & h4 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }

  & ol,
  ul {
    padding-left: 20px;
    margin-bottom: 16px;
    width: 100%;

    & > li {
      width: 100%;
      margin-top: 12px;
      color: $base;
      font-size: calc_fluid(14, 16, 320, 1920);
      line-height: calc_fluid(19, 22, 320, 1920);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  & ol {
    & > li {
      list-style-type: decimal;
    }
  }

  & ul {
    & > li {
      position: relative;
      padding-left: 9px;
    }
  }

  & a {
    color: #eb5757 !important;
    text-decoration: underline;

    &:hover {
      color: #eb5757 !important;
      text-decoration: none;
    }

    &:visited {
      color: #7a33d4 !important;
      text-decoration: underline;
    }
  }
}
</style>
