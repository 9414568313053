<script>
    import ListChanges from "./ProjectPublicationHistoryListChanges";
    export default {
        computed: {
            beforeQuiz() {
                return this.changes.before.attachments && this.changes.before.attachments.quiz
            },
            afterQuiz() {
                return this.changes.after.attachments && this.changes.after.attachments.quiz
            },
            added() {
                return !this.beforeQuiz;
            },
            deleted() {
                return !((this.afterQuiz && this.afterQuiz.title) || (this.afterQuiz && this.afterQuiz.answers && this.afterQuiz.answers.length));
            }
        },
        props: {
            changes: Object,
        },
        components: {
            ListChanges,
        }
    }
</script>

<template>
    <list-changes v-if="beforeQuiz || afterQuiz"
                  :title='added ? "Добавлен опрос" : deleted ? "Опрос удален" : "Изменен опрос"'
                  :added="added"
                  :deleted="deleted"
    >
        <template #before>
            <div v-if="beforeQuiz && beforeQuiz.answers && beforeQuiz.answers.length">
                <p class="sp-history-list-quiz__title">
                    Название: <i>{{beforeQuiz && beforeQuiz.title}}</i>
                </p>
                <p class="sp-history-list-quiz__title">
                    Варианты ответов:
                </p>
                <ul class="sp-history-list-quiz-items">
                    <li class="sp-history-list-quiz-item"
                        v-for="answer in beforeQuiz.answers"
                        :key="answer"
                    >
                        <i>{{answer}}</i>
                    </li>
                </ul>
            </div>
        </template>
        <template #after>
            <div v-if="afterQuiz && afterQuiz.answers && afterQuiz.answers.length"
            >
                <p class="sp-history-list-quiz__title">
                    Название: <i>{{afterQuiz.title}}</i>
                </p>
                <p class="sp-history-list-quiz__title">
                    Варианты ответов:
                </p>
                <ul class="sp-history-list-quiz-items">
                    <li class="sp-history-list-quiz-item"
                        v-for="answer in afterQuiz.answers"
                        :key="answer"
                    >
                        <i>{{answer}}</i>
                    </li>
                </ul>
            </div>
        </template>
    </list-changes>

</template>

<style lang="scss" scoped>
    @import "src/assets/scss/env";

    .sp-history-list-quiz {
        &-items {
            padding-left: 40px;
        }
        &-item {

        }
        &__title {
            margin-top: 10px;
            padding-left: 10px;
            @include font(14,16)
        }
    }
</style>