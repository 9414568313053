<script>
import BaseIcon from "../components/icons/BaseIcon";
import Logo from "../components/icons/BaseLogo";
import SocialGoogle from "../components/icons/SocialGoogle";
import SocialVk from "../components/icons/SocialVk";
import SocialOk from "../components/icons/SocialOk";
//import SocialFb from "../components/icons/SocialFb";
import SuccessIcon from "../components/icons/BaseSuccess"
import BaseInput from "../components/BaseInput";
import {popupAuth, windowParameters} from "@/api";
import {createNamespacedHelpers} from "vuex";
import {API} from "@/plugins/axios";

const {mapActions} = createNamespacedHelpers('authStore')

export default {
  data() {
    return {
      states: {
        register: true,
        moreSocial: false,
      },
      formData: {name: "", email: "", password: ""},
      formError: "",
      forgotModal: {
        email: "",
        show: false,
        load: false,
        success: false,
      },
      
      policyModal: {
        show: false,
      },
      offerModal: {
        show: false,
      },

    }
  },
  components: {
    BaseIcon,
    Logo,
    SocialGoogle,
    SocialVk,
    SocialOk,
    //SocialFb,
    SuccessIcon,
    BaseInput,
  },
  methods: {
    getProjects() {
      this.$API.get("projects").then((response) => {
        this.$store.dispatch("projectsUpdate", response.data);
      })
    },
    resetPassword() {
      let formData = new FormData();
      formData.append('email', this.forgotModal.email);
      this.forgotModal.load = true;
      this.$API.post('auth/forgot', formData).then(() => {
        this.forgotModal.load = false;
        this.forgotModal.success = true;
        setTimeout(() => {
          this.forgotModal.show = false;
          this.forgotModal.load = false;
          this.forgotModal.success = false;
        }, 3000)
      });
    },
    provideAuth(syncLocalStorage=true) {
      if (syncLocalStorage) {
        this.authLocalStorage();
      }
      API.defaults.headers.Authorization = "Token " + localStorage.token;
      if (this.$route.query.invite) {
        let inviteData = new FormData();
        inviteData.append("invite", this.$route.query.invite);
        this.$API
            .post("projects/team/use_team_invite", inviteData)
            .then((response) => {
              this.$router.push({
                name: "ProjectPublications",
                params: {currentProject: response.data.project},
              });
            });
      } else {
        this.$router.replace("/");
      }
      this.getProjects();
    },
    vkAuth() {
      this.$API.post("vk.userAuth").then((response) => {
        let wnd = window.open(
            response.data.redirectLink,
            "vkPopup",
            windowParameters
        );
        popupAuth(
            wnd,
            "vkAuth",
            () => {
              this.provideAuth();
              localStorage.vkAuth = false;
            },
            () => {
              alert("Ошибка при авторизации");
            }
        );
      });
    },
    okAuth() {
      this.$API.post("ok.userAuth").then((response) => {
        let wnd = window.open(
            response.data.redirectLink,
            "okPopup",
            windowParameters
        );
        popupAuth(
            wnd,
            "okAuth",
            () => {
              this.provideAuth();
              localStorage.okAuth = false;
            },
            () => {
              alert("Ошибка при авторизации");
            }
        );
      });
    },
    async processForm(e) {
      e.preventDefault();
      let formData = new FormData(this.$refs.form);
      await this.$API
          .post("auth/" + (this.states.register ? "register" : 'login'), formData)
          .then((response) => {
            this.auth(response.data);
            //this.$refs.formMessage.classList.remove("error");
            this.provideAuth(false);
          })
          .catch((error) => {
            this.formError = error.response.data.message;
          });
    },
    ...mapActions([
      'auth',
      'authLocalStorage',
    ])
  },
}
</script>

<template>
  <div class="sp-auth">
    <div class="sp-auth-header">
      <base-icon icon-name="#всепоплану" width="215" height="20">
        <logo/>
      </base-icon>
      <a href="https://placestart.ru" target="blank" class="sp-auth-header__made">
        <span class="sp-auth-header__description">Сделано специально для вас</span>
      </a>
    </div>
    <div class="sp-auth-wrapper">
      <div class="sp-auth-page">
        <div class="sp-auth-content">
          <h1 class="sp-auth__title">
            Добро пожаловать на сервис планирования публикаций в социальных сетях
          </h1>
          <p class="sp-auth__description"
             :class="{'sp-auth__description_login': !states.register}"
          >
            {{
              states.register ? "Процедура регистрации займет у вас меньше минуты"
                  : "Настройте командную работу и обсуждайте посты с коллегами и заказчиками"
            }}
          </p>
        </div>
        <div class="sp-auth-form">
          <div class="sp-auth-form-select">
            <div class="sp-pointer sp-auth-form-select__item"
                 :class="{'sp-auth-form-select__item--active': states.register}"
                 @click="states.register = true"
            >
              Регистрация
            </div>
            <div class="sp-pointer sp-auth-form-select__item"
                 :class="{'sp-auth-form-select__item--active': !states.register}"
                 @click="states.register = false"
            >
              Вход
            </div>
          </div>
          <p class="sp-auth__label">Продолжить с</p>
          <div class="sp-auth-form-socials">
            <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_google" style="display: none">
              <base-icon icon-name="google" width="38" height="38">
                <social-google/>
              </base-icon>
              Google
            </div>
            <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_vk"
                 @click="vkAuth"
            >
              <base-icon icon-name="vk" width="45" height="45">
                <social-vk/>
              </base-icon>
              Вконтакте
            </div>
            <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_ok"
                 @click="okAuth"
            >
              <base-icon icon-name="ok" width="45" height="45">
                <social-ok/>
              </base-icon>
              Одноклассники
            </div>
            <!--                        <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_more"-->
            <!--                             v-show="!states.moreSocial"-->
            <!--                             @click="states.moreSocial = !states.moreSocial"-->
            <!--                             -->
            <!--                        >-->
            <!--                            + Показать еще-->
            <!--                        </div>-->
            <!--                        <div class="sp-pointer sp-auth-form-socials-item sp-auth-form-socials-item_fb"-->
            <!--                             v-show="states.moreSocial"-->
            <!--                        >-->
            <!--                            <base-icon icon-name="fb" width="45" height="45">-->
            <!--                                <social-fb/>-->
            <!--                            </base-icon>-->
            <!--                            Facebook-->
            <!--                        </div>-->
            <!--                  -->

          </div>
          <p class="sp-auth__label">или используйте электронную почту</p>
          <form class="sp-auth-form-items" ref="form" @submit="processForm">
            <base-input id="authName" name="name" label="Ваше имя" :auth="true"
                        v-if="states.register" :error-message="formError"
                        v-model="formData.name"
            />
            <base-input id="authEmail" type="email" name="email" label="Email" :auth="true"
                        :error-message="formError"
                        v-model="formData.email"
            />
            <base-input id="authPwd" type="password" name="password" label="Пароль" :auth="true"
                        :error-message="formError"
                        v-model="formData.password"
            />
            <button class="sp-btn sp-auth__submit">
              {{ states.register ? "Зарегистрироваться" : "Войти" }}
            </button>
            <p class="sp-auth__forgot sp-btn"
               v-show="!states.register"
               @click="forgotModal.show = true"
            >
              Забыли пароль?
            </p>
            <div class="sp-auth-personal">
              <p class="sp-auth-personal__text">
                Нажимая на кнопку, вы даете согласие на <a
                  class="sp-auth-personal__text sp-auth-personal__link" href="#" target="blank">обработку
                персональных данных</a>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
    <img class="sp-auth__bg" src="https://localhost:8000/static/images/плюшки.png" alt="">
    <div class="sp-auth__copyright">
      © 2021 Все права защищены
      <!--RouterLink class="sp-auth__link" to="/policy">Договор-оферта</RouterLink-->
      <!--RouterLink class="sp-auth__link" to="/smm-plan-prices">Тарифы</RouterLink-->

      <!-- new -->
      <a class="sp-auth__link" @click="offerModal.show = true">Договор-оферта</a>

      <!-- old: Договор-оферта -->
      <a class="sp-auth__link" @click="policyModal.show = true">Правила пользования сайтом</a>

      <a class="sp-auth__link" target="_blank" href="/smm-plan-prices">Тарифы</a>
    </div>
    <Modal v-model="forgotModal.show"
           :close="() => (forgotModal.show = false)"
    >
      <div class="sp-modal sp-auth-modal-forgot"
           :class="{'sp-modal--load': forgotModal.load, 'sp-modal--success': forgotModal.success}"
      >
        <div class="sp-modal-main">
          <p class="sp-modal__title sp-pub_editor-template__title">Сброс пароля</p>
          <base-input name="email" label="Введите email"
                      v-model="forgotModal.email"
          >
          </base-input>
          <button class="sp-btn sp-btn--main sp-auth-modal-forgot__btn"
                  @click.prevent="resetPassword"
          >
            Сбросить пароль
          </button>
        </div>
        <div class="sp-modal-load">
          <div class="sp-preloader"></div>
        </div>
        <div class="sp-modal-success">
          <base-icon height="100" width="100" view-box="0 0 20 20">
            <success-icon/>
          </base-icon>
          <p class="sp-modal__description">Новый пароль отправлен на почту.</p>
        </div>
      </div>
    </Modal>

    <Modal v-model="policyModal.show" :close="() => (policyModal.show = false)">
      <div class="sp-modal sp-auth-modal-policy">

        <div class="sp-auth-modal-policy__header">
          <div class="sp-auth-modal-policy__header__close-button" @click="policyModal.show = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
            </svg>
          </div>
        </div>

        <h2>Правила пользования сайтом</h2>

        <div class="policy__text">
          <p>
        1. Всепоплану предоставляет возможность пользоваться сервисом «как есть («as is») и не несет ответственности за любые убытки, включая упущенную выгоду, связанную с использованием или невозможностью использования какого-либо инструмента, отдельной функции или всего сервиса. Пользователь принимает на себя ответственность и все риски, связанные с использованием сервиса Всепоплану. Пользователи сервиса Всепоплану самостоятельно несут ответственность за законность любых собственных действий в связи с размещением и хранением информации с использованием сервиса Всепоплану. 
          </p>
        </div>

        <div class="policy__text">
          <p>
        2. Всепоплану предоставляет пользователям техническую возможность использования его сервиса, не участвует в формировании содержания предоставляемой пользователями информации и иных объектов, включая результаты интеллектуальной деятельности, и не контролирует указанные действия, в связи с этим Всепоплану не несет ответственности за законность действий и информацию (включая, ссылки на страницы в Интернете, где располагается информация), размещаемую пользователями, не гарантирует качество, полноту и достоверность такой информации.
          </p>
        </div>

        <div class="policy__text">
          <p>
        3. Всепоплану информирует всех заинтересованных лиц, что в силу огромного объема информации и наличия большого количества активных пользователей он лишен возможности осуществлять оперативный контроль за размещением пользователями и иными лицами информации в любой форме на Интернет-сайте сервиса и определять наличие соответствия их действий законодательству Российской Федерации и настоящим Правилам, а также проверять достоверность и полноту информации, предоставленной пользователями при регистрации и дальнейшем использовании сервиса.
          </p>
        </div>

        <div class="policy__text">
          <p>
        4. Если в соответствующих официальных документах не указано иное, Всепоплану принимает все разумные меры для обеспечения функционирования и работоспособности его проектов и сервисов и предпримет все зависящие от него меры для оперативного восстановления их работоспособности в случае технических сбоев, перерывов, несанкционированного доступа, иных незаконных действий, а также иных негативных обстоятельств, произошедших в случае действия или бездействия третьих лиц в сфере предоставления сервису Всепоплану и\или его партнерам услуг электросвязи и электроснабжения.
          </p>
        </div>

        <div class="policy__text">
        <p>5. Всепоплану не несет ответственности за:</p>
          <ul>
            <li>временные сбои и перерывы в работе сервиса Всепоплану и вызванные ими потери информации, а также сохранность информации, правильность и своевременность ее передачи и доставки;</li>

            <li>за надежность, качество и скорость работы сервиса Всепоплану и за сохранность создаваемой, используемой и получаемой пользователями информации в рамках сервиса Всепоплану;</li>
        
            <li>за сбои, возникающие в сетях электросвязи и/или энергетических сетях, действие вредоносных программ, а также недобросовестные действия лиц, направленные на несанкционированный доступ и/или выведение из строя программного и/или аппаратного комплекса, повлекших за собой недоступность сервиса Всепоплану или незаконный доступ, удаление или модификацию информации, хранящейся в рамках сервиса Всепоплану;</li>
        
            <li>за надежность, качество и скорость работы каналов связи, а также доступность сторонних сервисов, принадлежащих третьим лицам и задействованных в работе сервиса Всепоплану;</li>
        
            <li>за правильность функционирования программного и/или аппаратного обеспечения, созданного третьими лицами и используемого при работе с сервисом Всепоплану.</li>
          </ul>
        </div>

        <div class="policy__text">
          <p>
        6. Всепоплану самостоятельно и/или при участии партнеров сохраняет за собой право в любое время изменять оформление сервиса, его содержание, список инструментов и платных функций, изменять или дополнять используемое программное обеспечение и другие объекты, используемые или хранящиеся в нем, любые серверные приложения в любое время с предварительным уведомлением или без такового. Также Всепоплану самостоятельно и/или при участии партнеров сохраняет за собой право в любое время изменить функционал или закрыть любой из инструментов сервиса без предварительного уведомления и в этом случае Всепоплану не несет никакой ответственности за прекращение доступа к таким инструментам или функциям.
          </p>
        </div>
      </div>
    </Modal>

    <Modal v-model="offerModal.show" :close="() => (offerModal.show = false)">
      <div class="sp-modal sp-auth-modal-policy">

        <div class="sp-auth-modal-policy__header">
          <div class="sp-auth-modal-policy__header__close-button" @click="offerModal.show = false">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
            </svg>
          </div>
        </div>

        <h2>Договор-оферты</h2>

        <div class="policy__text">
          <p>Настоящий документ в соответствии с положениями ст. 437 ГК РФ является офертой (далее - Оферта) ООО Плейс-Старт (далее - Администратор), к заключению договора на предоставление платного функционала Сервиса и адресовано любому физическому лицу, использующему Сервис (далее – Пользователю) на изложенных ниже условиях.</p>
          <p>Оплата Тарифа или Опции является безусловным и безоговорочным принятием (акцептом) Пользователем условий Оферты.</p>
        </div>

        <h2>Термины и определения:</h2>
        
        <div class="policy__text">
          <p>Термины Оферты толкуются в значении, определенном в Пользовательском соглашении</p>
        </div>
        
        <div class="policy__text">
          <p><strong>Тариф</strong> — размер вознаграждения Администратора, соответствующий определенному объему возможностей Сервиса и сроку его предоставления Пользователю. Виды Тарифов доступны в Аккаунте.</p>
          <p><strong>Опция</strong> — дополнительный платный функционал Сервиса, не включенный в Тариф. Позволяет расширить возможности использования Сервиса Пользователем. Виды Опций доступны в Аккаунте.</p>
          <p><strong>Инцидент</strong> — любое событие, не являющееся элементом нормального функционирования Сервиса и характеризующееся прерыванием или снижением качества работы Сервиса.</p>
          <p><strong>Документация</strong> - <a @click="policyModal.show = true">Пользовательское Соглашение Политика</a></p>
        
          <p><strong>Предмет</strong></p>
          <ul>
              <li>Администратор предоставляет Пользователю неисключительную лицензию на использование платного функционала Сервиса, а Пользователь обязуется оплатить Администратору вознаграждение.</li>
          </ul>

          <p><strong>Порядок и пределы использования платного функционала Сервиса</strong></p>
          <ul>
              <li>Для подключения платного функционала Сервиса Пользователь должен:</li>
              <ul>
                <li>иметь зарегистрированный Аккаунт;</li>
                <li>пройти процедуру авторизации на Сервисе;</li>
                <li>оплатить или активировать выбранный Тариф и/или Опцию.</li>
              </ul>
          </ul>
          <ul>
              <li>Пользователь вправе использовать платный функционал Сервиса в течение срока и в объеме, предусмотренном в выбранном Тарифе или Опции, и только по прямому функциональному назначению.</li>
              <li>Доступ к платному функционалу Сервиса активируется в течение 1 (одного) календарного дня с момента оплаты. После активации в Аккаунте платного функционала Сервиса доступ считается предоставленным.</li>
              <li>Информация о подключенном Тарифе.</li>
          </ul>

          <p><strong>Условия использования</strong></p>
          <ul>
              <li>Права на платный функционал Сервиса ограничены «правом на использование», и ни одно из положений Оферты не предполагает передачу Пользователю каких-либо исключительных прав на Сервис Администратора.</li>
              <li>Право использования платного функционала Сервиса предоставляется Администратором Пользователю на срок оплаты Тарифа или Опции.</li>
              <li>Пользователь использует Сервис согласно Документации и действующему законодательству Российской Федерации.</li>
          </ul>
          <p><strong>Техническая поддержка</strong></p>
          <ul>
              <li>Администратор оказывает Пользователю техническую поддержку при использовании Сервиса. Объем и пределы поддержки, сроки ответов на запросы, каналы коммуникации, определяются Администратором в одностороннем порядке.</li>
              <li>Обеспечение работоспособности происходит удаленно через сервера Администратора.</li>
              <li>Срок устранения Инцидента не может превышать десяти (10) рабочих дней.</li>
              <li>Не считаются Инцидентом следующие случаи:</li>
              <ul>
                <li>Любое запланированное время недоступности Сервиса, о котором Администратор не менее чем за 1 (один) календарный день уведомил Пользователя.</li>
                <li>Периоды недоступности с целью обслуживания технической инфраструктуры Администратора.</li>
              </ul>
          </ul>

          <p><strong>Оформление отношений с юридическими лицами и индивидуальными предпринимат</strong>.</p>
          <ul>
              <li>Администратор предоставляет доступ к платному функционалу Сервиса юридическим лицам и индивидуальным предпринимателям на основании отдельного договора (далее - Договор).</li>
              <li>Если Пользователю потребуется заключение Договора, то ему необходимо направить уведомление с описанием требуемого функционала, периода подключения Сервиса и реквизитами организации на электронную почту Администратора.</li>
              <li>Администратор заключает Договор при условии подключения Тарифа и Опций на сумму не менее 29880 (двадцать девять восемьсот восемьдесят) рублей. В ином случае Администратор вправе отказать Пользователю в заключении Договора и оплата осуществляется от имени представителя - физического лица в соответствии с правилами, описанными в Оферте.</li>
              <li>Администратор в течение трёх (3) рабочих дней уведомляет Пользователя о своем решении.</li>
          </ul>

          <p><strong>Вознаграждение Администратора</strong></p>
          <ul>
              <li>Размер вознаграждения Администратора определяется на основании выбранного Пользователем Тарифа и/или Опции.</li>
              <li>Администратор вправе по своему усмотрению устанавливать скидки на определенные Тарифы и/или Опции.</li>
              <li>Стоимость платного функционала Сервиса не включает возможные комиссии провайдеров платежей.</li>
              <li>Оплата Оплата Тарифов и Опций Пользователями производится одним из способов, указанных в Аккаунте:</li>
              <ul>
                <li>Оплата банковскими картами.</li>
                <li>По выставленному счету</li>
              </ul>
          </ul>
          <ul>
              <li>Вознаграждение считается оплаченным с момента поступления соответствующей суммы денежных средств от Пользователя на расчетный счет Администратора.</li>
              <li>Обязательства Пользователя по оплате считаются неисполненными, если Администратор осуществил возврат денежных средств по требованию платежной организации. В этом случае Администратор вправе отказать Пользователю в доступе к платному функционалу Сервиса с момента возврата денежных средств.</li>
              <li>В случае задержки зачисления денежных средств на расчетный счет Администратора более, чем на 3 (три) дня, Пользователь вправе обратиться к Администратору с представлением доказательств перечисления денежных средств, а также самостоятельно разрешить ситуацию с платежной организацией.</li>
              <li>Пользователь выражает свое согласие с тем, что при проведении расчетов с Администратором в момент предоставления доступа к платному функционалу Сервиса ему будет направлен один кассовый чек с указанием полной оплаты.</li>
              <li>Все расчеты Сторон по Оферте производятся в российских рублях. Сумма вознаграждения не облагается НДС на основании п. 3 ст. 346.11 НК РФ.</li>
              <li>Изменение Тарифа Пользователь вправе самостоятельно изменить Тариф:</li>
              <ul>
                <li>в сторону увеличения функций. В таком случае Пользователь либо доплачивает разницу в стоимости Тарифов, либо срок действия нового Тарифа изменяется в виде уменьшения количества дней его использования.</li>
                <li>в сторону уменьшения функций. В таком случае разница засчитывается Пользователю в виде увеличения количества дней использования нового Тарифа.</li>
              </ul>
          </ul>
          <ul>
              <li>Тарифы и Опции, а также их стоимость могут изменяться по решению Администратора, при этом стоимость уже оплаченного Тарифа или Опции остается неизменной.</li>
              <li>Администратор уведомляет об изменении Тарифов и Опций путем размещения информации на Сервисе.</li>
              <li>Прекращение использования платного функционала Прекращение Пользователем использования платного функционала Сервиса, в том числе в связи с допущенным им нарушением условий Оферты, не является основанием для возврата суммы, уплаченной по Тарифу и/или Опции.</li>
          </ul>
          <p><strong>Ответственность Сторон</strong></p>
          <ul>
              <li>В случае нарушения Пользователем условий использования Сервиса, Администратор вправе применить к нему меры ответственности, предусмотренные Документацией. В таком случае, перечисленные по Договору денежные средства не возвращаются.</li>
              <li>Администратор вправе компенсировать Пользователю несвоевременное устранение Инцидента в виде соразмерного увеличения периода использования платного функционала Сервиса.</li>
              <li>В случае предъявления Администратору претензий, требований и/или исков о нарушении прав третьих лиц, Пользователь принимает на себя обязательства по их урегулированию и возмещению ущерба Администратору в случае его возникновения.</li>
              <li>Ограничение ответственности Сервис предоставляется на условиях «как есть» («as is»).</li>
              <ul>Администратор не несет ответственности и не возмещает убытки Пользователя, вызванные:
                <li>причинами, указанными в Документации;</li>
                <li>ошибками в работе аппаратно-программного комплекса системы платежей. В случае незачисления денежных средств на счет Администратора из-за ошибки, обязанности по возврату денежных средств Пользователю лежат на провайдере электронной системы платежей.</li>
                <li>перебоями в предоставлении услуг подрядчиков и (или) центров обработки и хранения данных;</li>
                <li>отсутствием подключения Сервиса к сети Интернет и/или к серверам Администратора на территории Пользователя.</li>
              </ul>
          </ul>
          <ul>
              <li>Ответственность Администратора не может превышать размер вознаграждения, перечисленного Пользователем за период, в котором произошло нарушение.</li>
          </ul>

          <p><strong>Урегулирование споров и разногласий</strong></p>
          <ul>
              <li>Все вопросы и разногласия, которые могут возникнуть между Сторонами, разрешаются путем переговоров в соответствии с законодательством Российской Федерации.</li>
              <li>Стороны обязуются соблюдать досудебный (претензионный) порядок урегулирования споров. Срок для ответа на претензию составляет 10 (десять) рабочих дней с момента ее получения Стороной-получателем.</li>
              <li>В случае невозможности разрешения спора путем переговоров и в претензионном порядке спор передается на рассмотрение в суд по месту нахождения Администратора.</li>
          </ul>
          <p><strong>Заключительные положения</strong></p>
          <ul>
              <li>Все, что не урегулировано Офертой, подлежит разрешению в соответствии с Документацией и законодательством Российской Федерации.</li>
              <li>Оферта может быть изменена или дополнена Администратором в любое время и без уведомления Пользователя. Новая редакция Оферты вступает в силу по истечении 20 (двадцати) календарных дней с момента ее размещения на Сервисе.</li>
              <li>Пользователь самостоятельно проверяет условия Оферты на предмет их изменения и/или дополнения.</li>
              <li>Продолжение использования Сервиса после внесения изменений и/или дополнений в Оферту означает принятие и согласие Пользователя с такими изменениями и/или дополнениями.</li>
              <li>Признание по тем или иным причинам одного или нескольких положений Оферты недействительными или не имеющими юридической силы, не влияет на действительность или применимость остальных положений Оферты.</li>
              <li>Оферта составлена на русском языке и может быть предоставлена Пользователю для ознакомления на английском языке. В случае расхождения русскоязычной версии Оферты и версии на ином языке, применяются положения русскоязычной версии.</li>
              <li>Действующая редакция Оферты доступна Пользователю на сайте <a href="https://smm-plan.ru/">https://smm-plan.ru/</a> .</li>
          </ul>
        </div>
        
        <div class="policy__text">
          <h4>Контактная информация и реквизиты:</h4>
          <p><strong>ООО «Плейс-Старт»</strong></p>
          <p>ИНН: 3525312527</p>
          <p>КПП: 352501001</p>
          <p>ОГРН: 1133525020210</p>
          <p>Юридический адрес: 160017, Вологодская область, г. Вологда, Тепличный мкр, д. 9, кв.46</p>
          <p>Почтовый адрес: 160017, г. Вологда, ул. Ленинградская, д. 71, 3 этаж, офис 300</p>
          <p>Вологодское отделение № 8638 ПАО Сбербанк г. Вологда</p>
          <p>БИК: 041909644</p>
          <p>К/сч: 30101810900000000644</p>
          <p>Р/сч: 40702810912000010706</p>
        </div>
      </div>
    </Modal>


  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-auth {
  flex: 1;
  background: linear-gradient(99.65deg, #20457A -22.15%, #388B59 41.92%, #AFD049 86.61%, #D8F71F 120.88%);

  &__forgot {
    margin-top: 12px;
    @include font(14, 16, null);
    text-decoration: underline;
  }

  &__label {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: $text;
  }

  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: contain;
    height: 100%;
    user-select: none;
  }

  &__copyright {
    position: absolute;
    left: 49px;
    bottom: 38px;
    @include font(13, 18, null, $white)
  }

  &__link {
    text-decoration: none;
    margin-left: 15px;

    &:hover {
        color: #cecece;
    }
 
    @include font(13, 18, null, $white)
  }

  &__submit {
    margin-top: 20px;
    @include font(15, 20, bold, $white);
    background: $mainBtn;
    height: 60px;
    border: none;

    &:hover {
      background: $mainBtnHover;
    }

    &:focus {
      border: 1px solid $borderFocused;
    }
  }

  &__title {
    font-weight: bold;
    @include font(47, 57);
    color: $white;
  }

  &-modal {
    &-forgot {
      width: 400px;
      padding: 30px 40px;

      &__btn {
        width: 100%;
        height: 50px;
        margin-top: 12px;
      }
    }

    &-policy {
      width: clamp(300px, 800px, 90%);
      padding: 30px 40px;
      margin: 3% 0 3% 0;

      &__header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;

        &__close-button {
          cursor: pointer;
        }
        
      }

      .policy__text {
        margin-bottom: 15px;
        font-size: 18px;

        a {
          color: rgb(0, 0, 238);
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        ul {
          margin-left: 25px;

          li {
            margin-bottom:10px;
          }
        }

      }

      &__content{
        &__text {
          margin-bottom: 20px;
        }
      }
    }

  }

  &-personal {
    margin-top: 12px;
    display: flex;
    justify-content: center;

    &__text {
      width: 426px;
      @include font(14, 20, normal, $fgText);
      text-align: center;
    }

    &__link {
      color: $mainBtn;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $mainBtnHover;
      }
    }
  }

  &__description {
    margin-top: 35px;
    width: 340px;
    @include font(21, 29, null, $white);

    &_login {
      width: 461px;
    }
  }

  &-form {
    z-index: 1;
    width: 591px;
    border-radius: 8px;
    height: fit-content;
    @include _h660 {
      height: 100%;
    }
    overflow: hidden;
    overflow-y: auto;
    padding: 0 40px 40px;
    background: $white;

    &-select {
      display: flex;
      border-bottom: 1px solid $border;
      margin: 0 -40px 30px;

      &__item {
        width: 50%;
        background: $inactive;
        height: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid $border;
        @include font(24, 30, bold, $inactiveText);

        &--active {
          background: $white;
          color: $text;
        }
      }
    }

    &-socials {
      margin: 10px -5px 20px;
      display: flex;
      flex-flow: row wrap;

      &-item {
        border-radius: 8px;
        display: flex;
        margin: 5px;
        height: 54px;
        align-items: center;
        @include font(16, 19, bold, $white);

        & > svg {
          margin-right: 10px;
        }

        &_google {
          padding: 0 26px 0 14px;
          color: $text;
          border: 1px solid $border1;

          & > svg {
            height: 38px;
            width: 38px;
          }
        }

        &_vk {
          background: $socialVk;
          padding: 0 21.5px 0 9.5px;

          & > svg {
            height: 45px;
            width: 45px;
          }
        }

        &_ok {
          padding: 0 15.5px 0 3.5px;
          background: $socialOk
        }

        &_fb {
          padding: 0 18px 0 6px;
          background: $socialFb;
        }

        &_more {
          padding: 0 19.5px;
          color: $text;
        }
      }
    }

    &-items {
      margin-top: 15px;
    }
  }

  &-content {
    margin-top: 90px;
    padding-right: 40px;

    @include _h768 {
      margin-top: 140px;
    }
    @include _1600 {
      padding-left: 40px;
    }
    width: 747px;
    z-index: 1;
  }

  &-header {
    position: absolute;
    left: 49px;
    top: 49px;

    &__title {
      width: 215.54px;
      height: 19.01px;
      fill: #fff;
    }

    &__made {
      margin-top: 13.22px;
      display: flex;
      text-decoration: none;
    }

    &__description {
      color: $white;
      margin-right: 8.81px;
      font-size: 13px;
      line-height: 18px;
    }
  }

  &-wrapper {
    flex: 1;
    height: 100vh;
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    @include _h768 {
      align-items: unset;
    }
  }

  &-page {
    width: 1514px;
    display: flex;
    padding-left: 82px;
    justify-content: space-between;
    @include _1600 {
      padding-left: 0;
      width: 100%;
    }

  }
}
</style>
