<script>
import BaseIcon from "./icons/BaseIcon";
import SettingsIcon from "./icons/ProjectNavbarSettings"
import HeaderNotifications from "./HeaderNotifications";

export default {
  name: "Header",
  data() {
    return {
      show: false,
    };
  },
  components: {
    BaseIcon,
    SettingsIcon,
    HeaderNotifications,
  },
  computed: {},
  methods: {
    logout() {
      this.show = false;
      this.$store.dispatch("logout");
      localStorage.useToken = false;
      localStorage.token = "";
      localStorage.name = "";
      this.$router.push({name: "Auth"});
    },
    redirectTo(routeName) {
      this.show = false;
      this.$router.push({name: routeName});
    },
    close() {
      this.show = false;
    },
  },
};
</script>

<template>
  <div class="sp-header">
    <header-notifications/>
    <div class="sp-header__user sp-btn"
         @click="show = true"
    >
      {{ $store.state.authStore.user.name.split(" ").flatMap(v => v[0]).join("") }}
    </div>
  </div>
  <Modal
      v-model="show"
      :close="close"
      :options="{ styleModalContent: { alignItems: 'flex-end' } }"
  >
    <div class="sp-user-sidebar">
      <div class="sp-user-sidebar-top">
        <div class="sp-header__user sp-header__user-big sp-btn"
             @click="show = true"
        >
          {{ $store.state.authStore.user.name.split(" ").flatMap(v => v[0]).join("") }}
        </div>
        <div class="sp-user-sidebar-top__text">{{ $store.state.authStore.user.name }}</div>
      </div>

      <div class="sp-user-sidebar-items">
        <div class="sp-user-sidebar-items-item sp-btn"
             @click="redirectTo('UserPersonalData')"
        >
          <base-icon height="24" width="24">
            <settings-icon></settings-icon>
          </base-icon>
          Персональные настройки
        </div>
        <div class="sp-user-sidebar-items-item" @click="logout">

          Выход
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/env";

.sp-user-sidebar {
  background: $inactive;
  height: 100vh;
  padding: 20px;

  &-top {
    width: 100%;
    margin: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;

    &__text {
      margin-top: 20px;
      @include font(16, 20, bold);
    }

  }

  &-items {
    margin: 40px 0 20px;

    &-item {
      @include font(15, 22, 700);
      position: relative;
      padding: 15px;
      text-decoration: none;
      justify-content: unset;

      & > svg {
        margin-right: 12px;
      }

      &:hover {
        background: $projectMenuItemBg;
      }

      &.router-link-active {
        background: $projectMenuItemBg;
      }

      &:not(:last-child):after {
        content: "";
        position: absolute;
        left: 15px;
        top: 100%;
        width: calc(100% - 30px);
        height: 1px;
        background: $border;
      }
    }
  }
}

.app-user {
  height: 50px;
  margin: 20px;
  width: 50px;
  cursor: pointer;
  background: $component;
  color: $base;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  &.border {
    border: 1px solid $base;
  }
}

.sp-header {
  background: $inactive;
  height: $headerHeight;
  flex: 1;
  display: flex;
  padding: 12px 60px 12px 37px;
  align-items: center;
  justify-content: flex-end;

  &-notify {
    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }

    display: flex;
    align-items: center;
    padding: 10px 15px;

  }

  &-notifications {
    margin-right: 16px;
    cursor: pointer;
    position: relative;

    &__modal {
      position: absolute;
      width: 450px;
      right: -5px;
      top: 100%;
      background: $white;
      border: 1px solid $border;
      border-radius: 8px;
      cursor: default;
      z-index: 2;
    }

    &__scrollbar {
      min-width: 312px;
      height: 400px;
    }
  }

  &__user {
    height: 45px;
    width: 45px;
    background: $userIconBg;
    border-radius: 50%;
    @include font(15, 15, 700, $white);

    &-big {
      height: 65px;
      width: 65px;
      @include font(20, 20, 700, $white);
    }
  }

  &__settings {
    width: unset;
  }
}
</style>
