<script>
    import BaseAccount from "./BaseAccount";

    import {mapState} from "vuex";

    export default {
        data() {
            return {
                error: false,
            }
        },
        props: {
            account: Object,
        },
        emits: ['finish', 'error'],
        components: {
            BaseAccount,
        },
        computed: {
            ...mapState(['currentProject',]),
        },
        methods: {
        },
        watch: {
            account: {
                handler: function (v) {
                    if (v) this.$emit('finish');
                    else {
                        this.$emit('error');
                        this.error = true;
                    }
                },
                immediate:true,
            }
        }
    }
</script>

<template>
    <div class="sp-account-add__finish">
        <div class="sp-account-add-account sp-btn">
            <base-account class="sp-account-add-account__avatar"
                          :account="account"
                          :clickable="false"
            >
            </base-account>
          {{account.title}}
            <div class="sp-account-add-account__badge"
                 :class="{'sp-account-add-account__badge--error': error}"
            >
                {{error ? "Учетная запись не подключена" : "Учетная запись подключена"}}
            </div>
        </div>
    </div>
    <div class="sp-account-add__actions">
        <button class="sp-account-add__btn--more sp-btn sp-btn--main"
                @click.prevent="$emit('more')"
        >
            Подключить еще
        </button>
        <button class="sp-account-add__btn--back sp-btn sp-btn--optional"
                @click.prevent="$router.push({name: 'ProjectSettings', params: { currentProject },})"
        >
            Вернуться на главную
        </button>
    </div>
</template>

<style lang="scss">
    @import "/src/assets/scss/env";
    .sp-account-add {
        &__finish {
            flex: 1;
        }
        &-account {
            display: flex;
            align-items: center;
            border-bottom: 1px solid $border;
            padding: 9px 0 14px;
            justify-content: unset;
            @include font(16,20,bold);
            &__avatar {
                margin-right: 20px;
            }
            &__badge {
                border-radius: 8px;
                border: 1px solid $mainBtn;
                padding: 6px 9px;
                @include font(11,13,null,$mainBtn);
                margin-left: 17px;
                &__error {
                    color: $error;
                    border: 1px solid $error;
                }
            }
        }
        &__actions {
            display: flex;
            margin-top: 30px;
        }
        &__btn {
            &--more {
                @include font(15,20,bold,$white);
                height: 52px;
                width: 212px;
                margin-right: 17px;
            }
            &--back {
                @include font(15,20,bold,$publicationsEditBtn);
                height: 52px;
                width: 214px;
            }
        }
    }
</style>