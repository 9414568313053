<template>
    <g>
       <g clip-path="url(#clip0_129_3728)">
            <path d="M12.9043 0C19.5319 0 24.9043 5.3736 24.9043 12C24.9043 18.6264 19.5319 24 12.9043 24C6.2767 24 0.904297 18.6264 0.904297 12C0.904297 5.3736 6.2767 0 12.9043 0ZM12.9043 2.0004C7.3903 2.0004 2.9047 6.486 2.9047 12C2.9047 17.514 7.3903 21.9996 12.9043 21.9996C18.4183 21.9996 22.9039 17.514 22.9039 12C22.9039 6.486 18.4183 2.0004 12.9043 2.0004ZM12.9043 16.2C13.2226 16.2 13.5278 16.3264 13.7528 16.5515C13.9779 16.7765 14.1043 17.0817 14.1043 17.4C14.1043 17.7183 13.9779 18.0235 13.7528 18.2485C13.5278 18.4736 13.2226 18.6 12.9043 18.6C12.586 18.6 12.2808 18.4736 12.0558 18.2485C11.8307 18.0235 11.7043 17.7183 11.7043 17.4C11.7043 17.0817 11.8307 16.7765 12.0558 16.5515C12.2808 16.3264 12.586 16.2 12.9043 16.2ZM12.9043 5.7C13.7795 5.7 14.6189 6.04768 15.2378 6.66655C15.8566 7.28542 16.2043 8.12479 16.2043 9C16.2043 10.212 15.8479 10.8888 14.9431 11.8308L14.7403 12.036C13.9939 12.7824 13.8043 13.0992 13.8043 13.8C13.8043 14.0387 13.7095 14.2676 13.5407 14.4364C13.3719 14.6052 13.143 14.7 12.9043 14.7C12.6656 14.7 12.4367 14.6052 12.2679 14.4364C12.0991 14.2676 12.0043 14.0387 12.0043 13.8C12.0043 12.588 12.3607 11.9112 13.2655 10.9692L13.4683 10.764C14.2147 10.0176 14.4043 9.7008 14.4043 9C14.4031 8.61633 14.2548 8.24772 13.9901 7.96998C13.7254 7.69225 13.3644 7.5265 12.9812 7.50682C12.5981 7.48715 12.2219 7.61505 11.9302 7.86422C11.6384 8.11338 11.4532 8.46488 11.4127 8.8464L11.4043 9C11.4043 9.2387 11.3095 9.46761 11.1407 9.6364C10.9719 9.80518 10.743 9.9 10.5043 9.9C10.2656 9.9 10.0367 9.80518 9.8679 9.6364C9.69912 9.46761 9.6043 9.2387 9.6043 9C9.6043 8.12479 9.95197 7.28542 10.5708 6.66655C11.1897 6.04768 12.0291 5.7 12.9043 5.7Z" fill="#59B9FF"/>
        </g>
        <defs>
            <clipPath id="clip0_129_3728">
                <rect width="24" height="24" fill="white" transform="translate(0.904297)"/>
            </clipPath>
        </defs>
    </g>
</template>