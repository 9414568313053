<template>
  <div class="sp-policy">

    <section class="policy__block">
      <div class="container">
        <p class="policy__text">
        1. Всепоплану предоставляет возможность пользоваться сервисом «как есть («as is») и не несет ответственности за любые убытки, включая упущенную выгоду, связанную с использованием или невозможностью использования какого-либо инструмента, отдельной функции или всего сервиса. Пользователь принимает на себя ответственность и все риски, связанные с использованием сервиса Всепоплану. Пользователи сервиса Всепоплану самостоятельно несут ответственность за законность любых собственных действий в связи с размещением и хранением информации с использованием сервиса Всепоплану. 
        </p>

        <p class="policy__text">
        2. Всепоплану предоставляет пользователям техническую возможность использования его сервиса, не участвует в формировании содержания предоставляемой пользователями информации и иных объектов, включая результаты интеллектуальной деятельности, и не контролирует указанные действия, в связи с этим Всепоплану не несет ответственности за законность действий и информацию (включая, ссылки на страницы в Интернете, где располагается информация), размещаемую пользователями, не гарантирует качество, полноту и достоверность такой информации.
        </p>

        <p class="policy__text">
        3. Всепоплану информирует всех заинтересованных лиц, что в силу огромного объема информации и наличия большого количества активных пользователей он лишен возможности осуществлять оперативный контроль за размещением пользователями и иными лицами информации в любой форме на Интернет-сайте сервиса и определять наличие соответствия их действий законодательству Российской Федерации и настоящим Правилам, а также проверять достоверность и полноту информации, предоставленной пользователями при регистрации и дальнейшем использовании сервиса.
        </p>

        <p class="policy__text">
        4. Если в соответствующих официальных документах не указано иное, Всепоплану принимает все разумные меры для обеспечения функционирования и работоспособности его проектов и сервисов и предпримет все зависящие от него меры для оперативного восстановления их работоспособности в случае технических сбоев, перерывов, несанкционированного доступа, иных незаконных действий, а также иных негативных обстоятельств, произошедших в случае действия или бездействия третьих лиц в сфере предоставления сервису Всепоплану и\или его партнерам услуг электросвязи и электроснабжения.
        </p>

        <p class="policy__text">
        5. Всепоплану не несет ответственности за:
        * временные сбои и перерывы в работе сервиса Всепоплану и вызванные ими потери информации, а также сохранность информации, правильность и своевременность ее передачи и доставки;
        * за надежность, качество и скорость работы сервиса Всепоплану и за сохранность создаваемой, используемой и получаемой пользователями информации в рамках сервиса Всепоплану;
        * за сбои, возникающие в сетях электросвязи и/или энергетических сетях, действие вредоносных программ, а также недобросовестные действия лиц, направленные на несанкционированный доступ и/или выведение из строя программного и/или аппаратного комплекса, повлекших за собой недоступность сервиса Всепоплану или незаконный доступ, удаление или модификацию информации, хранящейся в рамках сервиса Всепоплану;
        * за надежность, качество и скорость работы каналов связи, а также доступность сторонних сервисов, принадлежащих третьим лицам и задействованных в работе сервиса Всепоплану;
        * за правильность функционирования программного и/или аппаратного обеспечения, созданного третьими лицами и используемого при работе с сервисом Всепоплану.
        </p>

        <p class="policy__text">
        6. Всепоплану самостоятельно и/или при участии партнеров сохраняет за собой право в любое время изменять оформление сервиса, его содержание, список инструментов и платных функций, изменять или дополнять используемое программное обеспечение и другие объекты, используемые или хранящиеся в нем, любые серверные приложения в любое время с предварительным уведомлением или без такового. Также Всепоплану самостоятельно и/или при участии партнеров сохраняет за собой право в любое время изменить функционал или закрыть любой из инструментов сервиса без предварительного уведомления и в этом случае Всепоплану не несет никакой ответственности за прекращение доступа к таким инструментам или функциям.
        </p>
      </div>
    </section> 

  </div>
</template>

<style lang="scss">
@import "src/assets/scss/env";

.sp-policy {
  background-color: $white;
  width: 100%;

  .container {
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1250px
  }

  .policy {
    &__block {
      margin-top: 45px;
    }

    &__text {
      margin-bottom: 15px;
    }

  }
}


</style>
