export const socialShortById = Object.freeze({
  1: "vk",
  2: "im",
  3: "ok",
  4: "fb",
  5: "tg",
});
export const publicationTypes = Object.freeze({ Post: 1, Story: 2 });
export const idToShortCode = Object.freeze({
  1: ["Вконтакте", "vkIcon"],
  2: ["Инстаграм", "imIcon"],
  3: ["Одноклассники", 'okIcon'],
  4: ["Фейсбук", 'fbIcon'],
  5: ["Телеграм", 'tgIcon'],
})
export const socialLinks = Object.freeze({
  1: "https://vk.com/club",
  2: "",
  3: "https://ok.ru/group/",
  4: "",
  5: "",
})