<script>
import BaseAccount from "./BaseAccount";
import Account from "../store/models/Account";

import { mapState } from "vuex";

export default {
  data() {
    return {
      accounts: []
    };
  },
  props: {
    social: String,
    tempToken: String
  },
  emits: ["accountSelect", "finish", "error", 'more', 'success'],
  components: {
    BaseAccount
  },
  computed: {
    ...mapState(["currentProject"])
  },
  methods: {
    okSelect() {
      this.$API.post("ok.userGroups", "auth_token=" + this.tempToken)
        .then((response) => {
          this.accounts = response.data;
        });
    },
    imSelect() {
      this.$API.post("im.userGroups", "auth_token=" + this.tempToken)
        .then((response) => {
          this.accounts = response.data;
        });
    },
    vkSelect() {
      this.$API.post("vk.userGroups", "auth_token=" + this.tempToken)
        .then((response) => {
          this.accounts = response.data;
        });
    },
    tgSelect() {
      this.$API.post("tg.userGroups", "auth_token=" + this.tempToken)
        .then((response) => {
          this.accounts = response.data;
        });
    },
    selectTgTarget(account) {
      let formData = new FormData();
      formData.append("token", this.tempToken);
      formData.append("group", account.id);
      formData.append("group_title", account.title);
      formData.append("project", this.currentProject);
      this.$API
        .post("tg.connect", formData)
        .then((response) => {
          Account.insert({ data: response.data });
          this.$emit("accountSelect", response.data);
          this.$emit("success");
        })
        .catch(() => {
          this.$emit("error");
        });
    },
    selectGroup(account) {
      if (this.social === "tg") {
        this.selectTgTarget(account);
        return;
      }
      let formData = new FormData();
      formData.append("intermediate_token", this.tempToken);
      formData.append("social", this.social);
      formData.append("group", account.id);
      formData.append("group_title", account.title);
      formData.append("project", this.currentProject);
      this.$API.post("projects/accounts/connect", formData)
        .then((response) => {
          Account.insert({ data: response.data });
          console.log(response.data, "INSERTED");
          console.log(this.$store.getters["entities/accounts/query"]().where("project", this.currentProject).get());
          this.$emit("accountSelect", response.data);
          this.$emit("success");
        }).catch(() => {
        this.$emit("error");
      });
    }
  },
  beforeMount() {
    console.log(this.props)
  },
  created() {
    this[`${this.social}Select`]();
  }
};
</script>

<template>
  <div class="sp-account-add__accounts">
    <div class="sp-account-add-account sp-btn"
         v-for="account in accounts"
         :key="account.id"
         @click="selectGroup(account)"
    >
      <base-account class="sp-account-add-account__avatar"
                    :account="account"
                    :clickable="false"
      >
      </base-account>
      {{ account.title }}
    </div>
  </div>
</template>

<style lang="scss">
@import "/src/assets/scss/env";

.sp-account-add {
  &__accounts {
    display: flex;
    flex-flow: row wrap;
    flex: 1;
  }

  &-account {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $border;
    padding: 9px 40px 14px;
    justify-content: unset;
    @include font(16, 20, bold);

    &__avatar {
      margin-right: 20px;
    }
  }
}
</style>