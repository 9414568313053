<script setup>
import {defineProps, ref, computed} from "vue";
import {NInput, NSpace, NPagination} from 'naive-ui';
const props = defineProps({
  items: Array,
  paginateBy: Number,
  searchFields: Array
});

const search = ref("");
const displayItems = computed(() => {
  return props.items.filter(v => {
    if (!search.value) return true;
    for (const searchField of props.searchFields) {
      if (String(v[searchField]).toLowerCase().includes(search.value.toLowerCase())) {
        return true
      }
    }
  })
});

const page = ref(1);

const pageItems = computed(() => {
  let startItem = (page.value - 1) * props.paginateBy,
      endItem = page.value * props.paginateBy
  return displayItems.value.slice(startItem, endItem);
});
const pageCount = computed(() => {
  return Math.ceil(displayItems.value.length / props.paginateBy);
})
</script>

<template>
<n-space vertical>
  <n-input placeholder="Поиск" v-model:value="search" size="medium"></n-input>
  <n-space item-style="width: calc(100% / 5 - 10px);">
    <slot :items="pageItems">

    </slot>

  </n-space>
  <n-pagination
      v-if="pageCount > 1"
      v-model:page="page"
      :page-size="props.paginateBy"
      v-model:page-count="pageCount"
  >

  </n-pagination>
</n-space>
</template>



<style lang="scss" scoped>

</style>