<script>
import { projectRoutes } from "../router/index";
import {leadingZero} from "@/logic/tools";
import BaseIcon from "./icons/BaseIcon";
import PublicationsIcon from "./icons/ProjectNavbarPublications";
import TeamIcon from "./icons/ProjectNavbarTeam";
import SettingsIcon from "./icons/ProjectNavbarSettings";
import ReportIcon from "./icons/ProjectNavbarReport";

export default {
  name: "ProjectMenu",
  props: {
    project: Object,
    projectId: Number,
    time: Date,
  },
  components: {
    BaseIcon,
    PublicationsIcon,
    TeamIcon,
    SettingsIcon,
    ReportIcon
  },
  data() {
    return {
      routes: projectRoutes,
    };
  },
  methods: {
    leadingZero,
    replacePath(path) {
      return path.replace(":currentProject", this.projectId);
    },
  },
  computed: {
    ql() {
      return this.project && this.project.quick_links || []
    }
  },
  watch: {
    ql: {
      handler: function (v) {
        this.$store.dispatch('qlChange', v);
      },
      immediate: true,
    }
  },
};
</script>

<template>
  <div class="sp-project-navbar">
    <h1 class="sp-project-navbar__title">{{project && project.title}}</h1>
    <p class="sp-project-navbar__time">{{time ? `${leadingZero(time.getHours())}:${leadingZero(time.getMinutes())}` : ""}}</p>
    <div class="sp-project-navbar-items">
      <router-link
        class="sp-project-navbar-item sp-btn"
        v-for="item in routes"
        :key="item.path"
        :to="replacePath(item.path)"
        :class="{ selected: $route.path === replacePath(item.path) }"
      >
        <base-icon v-show="item.icon_name" width="24" height="24" :icon-name="item.verbose_name"><component :is="item.icon_name"/></base-icon>{{ item.verbose_name }}
      </router-link>
      <a class="sp-project-navbar-item sp-btn"
        v-for="link in ql"
         :key="link.id"
         :href="link.link"
         target="_blank"
      >
        {{link.title}}
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";
.sp-project-navbar {
  padding: 20px 30px;
  margin-right: 30px;
  min-width: 291px;
  order: -2;
  width: 291px;
  background: $inactive;
  min-height: calc(100vh - #{$headerHeight});
  &-item {
    @include font(15,22,700);
    position: relative;
    padding: 15px;
    text-decoration: none;
    justify-content: unset;
    & > svg {
      margin-right: 12px;
    }
    &:hover {
      background: $projectMenuItemBg;
    }
    &.router-link-active {
      background: $projectMenuItemBg;
    }
    &:not(:last-child):after {
      content: "";
      position: absolute;
      left: 15px;
      top: 100%;
      width: calc(100% - 30px);
      height: 1px;
      background: $border;
    }
  }
  &-items {
    margin-top: 15px;
  }
  &__title {
    @include font(30,34,700);
    padding: 0 14px 0 14px;
  }
  &__time {
    margin: 12px 0 0 15px;
    @include font(14,17);
    color: $projectMenuTime;
  }
}
</style>
