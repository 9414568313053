<script>
import { data, search } from "/src/assets/sprites.js";
import { server } from "@/plugins/axios";
import BaseSelector from "./BaseSelector";
import BaseIcon from "./icons/BaseIcon";
import EmojiPeopleIcon from "./icons/emojiPeople";
import EmojiAnimalsIcon from "./icons/emojiAnimals";
import EmojiFoodIcon from "./icons/emojiFood";
import EmojiTravelIcon from "./icons/emojiTravel";
import EmojiEventsIcon from "./icons/emojiEvents";
import EmojiObjectsIcon from "./icons/emojiObjects";
import EmojiSymbolsIcon from "./icons/emojiSymbols";
import EmojiRecentIcon from "./icons/emojiRecent";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import EmojiFlagsIcon from "./icons/emojiFlags";
export default {
  data() {
    return {
      sprites: {
        data: data,
        recent: {
          ids: [],
          items: [],
        },
        current: 0,
        search: "",
        searchTimeout: 0,
        searchWords: search,
        categoryIcons: {
          0: EmojiPeopleIcon,
          1: EmojiAnimalsIcon,
          2: EmojiFoodIcon,
          3: EmojiTravelIcon,
          4: EmojiEventsIcon,
          5: EmojiObjectsIcon,
          6: EmojiSymbolsIcon,
          7: EmojiFlagsIcon,
        },
      },

      socialSelector: {
        current: 1,
        currentTitle: "",
        items: [
          { id: 1, title: "vk" },
          { id: 2, title: "fb" },
          { id: 3, title: "tw" },
          { id: 4, title: "google" },
        ],
      },
      observe: [],
      emojiRoot: `https://${server}/static/emoji/`,
    };
  },
  components: {
    BaseSelector,
    BaseIcon,
    EmojiAnimalsIcon,
    EmojiPeopleIcon,
    EmojiFoodIcon,
    EmojiTravelIcon,
    EmojiEventsIcon,
    EmojiObjectsIcon,
    EmojiSymbolsIcon,
    EmojiFlagsIcon,
    PerfectScrollbar,
    EmojiRecentIcon,
  },
  computed: {
    activeEmoji() {
      let result = [];
      Object.keys(this.sprites.searchWords)
        .filter((v) => v.startsWith(this.sprites.search.toLowerCase()))
        .forEach((v) => {
          result = [...result, ...this.sprites.searchWords[v]];
        });
      return result;
    },
    recentVisible() {
      return Boolean(this.sprites.recent.ids.length);
    },
  },
  methods: {
    onScroll(e) {
      console.log(e, this.$refs.scrollContainer);
      this.sprites.current = this.observe
        .flatMap((v) => v.offsetTop)
        .findIndex((v, i, o) => {
          return (
            (i + 1 < o.length && e.target.scrollTop + 140 < o[i + 1]) ||
            i + 1 === o.length
          );
        });
    },
    search(emoji) {
      console.log(emoji);
      if (!emoji) return;
      return this.sprites.search
        ? emoji.search.indexOf(this.sprites.search) != -1
        : true;
    },
    scrollTo(index) {
      this.observe[index].scrollIntoView();
    },
    searchKeyUp(evt) {
      clearTimeout(this.sprites.searchTimeout);
      this.sprites.searchTimeout = setTimeout(() => {
        this.sprites.search = evt.target.value;
      }, 200);
    },
    selectEmoji(emoji) {
      let ind = this.sprites.recent.ids.indexOf(emoji.id);
      if (ind === -1) {
        if (this.sprites.recent.ids.length > 15) {
          this.sprites.recent.ids.pop();
          this.sprites.recent.items.pop();
        }
        this.sprites.recent.ids.unshift(emoji.id);
        this.sprites.recent.items.unshift(emoji);
      } else {
        this.sprites.recent.ids.splice(ind, 1);
        this.sprites.recent.ids.unshift(emoji.id);
        this.sprites.recent.items.splice(ind, 1);
        this.sprites.recent.items.unshift(emoji);
      }
      localStorage.setItem("recentEmoji", JSON.stringify(this.sprites.recent));
      this.$emit("selectEmoji", emoji);
    },
  },
  watch: {
    "socialSelector.current": {
      handler: function (v) {
        let value = v.length ? parseInt(v.join()) : v;
        this.socialSelector.currentTitle = this.socialSelector.items.find(
          (v) => v.id === value
        ).title;
      },
      immediate: true,
    },
    "socialSelector.currentTitle": {
      handler: function () {
        this.$emit("typeChange", this.socialSelector.currentTitle);
      },
      immediate: true,
    },
    recentVisible: {
      handler: function (v) {
        if (v) {
          this.observe = Array.from(
            this.$refs.scrollContainer.$el.querySelectorAll(".sp-emoji__title")
          );
        }
      },
      immediate: true,
    },
    modelValue(v) {
      this.text = v;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.observe = Array.from(
        this.$refs.scrollContainer.$el.querySelectorAll(".sp-emoji__title")
      );
    });
    let recentEmoji = localStorage.getItem("recentEmoji");
    if (recentEmoji && recentEmoji.length) {
      this.sprites.recent = JSON.parse(recentEmoji);
    }
  },
  created() {
    this.target = this.textBlock;
  },
};
</script>

<template>
  <div class="sp-emoji">
    <div class="sp-emoji__categories">
      <div
        class="sp-emoji-category"
        v-show="sprites.recent.items.length"
        :class="{
          'sp-emoji-category--active': recentVisible
            ? sprites.current === 0
            : sprites.current === 0,
        }"
        @click="scrollTo(0)"
        :data-test="-1"
      >
        <base-icon width="24" height="24" icon-name="Недавно использованные">
          <emoji-recent-icon />
        </base-icon>
      </div>
      <div
        class="sp-emoji-category"
        v-for="(category, index) in sprites.data"
        :key="category.id"
        :class="{
          'sp-emoji-category--active': recentVisible
            ? sprites.current === index + 1
            : sprites.current === index + 1,
        }"
        @click="scrollTo(index + 1)"
        :data-test="category.id"
      >
        <base-icon width="24" height="24" :icon-name="category.title">
          <component :is="sprites.categoryIcons[category.id]"></component>
        </base-icon>
      </div>
    </div>
    <div class="sp-emoji__search">
      <input
        class="sp-emoji__input"
        type="text"
        placeholder="Поиск"
        @keyup="searchKeyUp"
      />
    </div>
    <PerfectScrollbar
      class="sp-emoji-scrollbar sp-emoji__container"
      @ps-scroll-y="onScroll"
      ref="scrollContainer"
      :options="{ minScrollbarLength: 40 }"
    >
      <div v-show="sprites.recent.items.length">
        <p class="sp-emoji__title">Недавно использованные</p>
        <div class="sp-emoji-list">
          <div
            class="sp-emoji-list__item"
            v-for="emoji in sprites.recent.items"
            v-show="
              emoji[socialSelector.currentTitle] &&
              activeEmoji.indexOf(emoji.id) != -1
            "
            :key="emoji"
            @click="selectEmoji(emoji)"
          >
            <img
              class="sp-emoji-list__img"
              :src="`${emojiRoot}ebg.png`"
              :style="{
                background:
                  'url(' +
                  emojiRoot +
                  emoji[socialSelector.currentTitle] +
                  ') ' +
                  -emoji.offset[1] +
                  'px ' +
                  -emoji.offset[0] +
                  'px',
              }"
              :alt="emoji.title"
            />
          </div>
        </div>
      </div>
      <div
        v-for="category in sprites.data"
        :key="category.id"
        v-show="category.ids.filter((v) => activeEmoji.includes(v)).length"
      >
        <p class="sp-emoji__title">{{ category.title }}</p>

        <div class="sp-emoji-list">
          <div
            class="sp-emoji-list__item"
            v-for="emoji in category.emoji"
            v-show="
              emoji[socialSelector.currentTitle] &&
              activeEmoji.indexOf(emoji.id) != -1
            "
            :key="emoji"
            @click="selectEmoji(emoji)"
          >
            <img
              class="sp-emoji-list__img"
              :src="`${emojiRoot}ebg.png`"
              :style="{
                background:
                  'url(' +
                  emojiRoot +
                  emoji[socialSelector.currentTitle] +
                  ') ' +
                  -emoji.offset[1] +
                  'px ' +
                  -emoji.offset[0] +
                  'px',
              }"
              :alt="emoji.title"
            />
          </div>
        </div>
      </div>
    </PerfectScrollbar>
    <div class="sp-emoji__socials" v-show="false">
      <BaseSelector
        :selected-class="'sp-emoji-social--selected'"
        :items="socialSelector.items"
        v-model="socialSelector.current"
        :first-selected="true"
        #default="slot"
      >
        <div class="sp-emoji-social">{{ slot.item.title }}</div>
      </BaseSelector>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";
.sp-emoji {
  position: absolute;
  background: $white;
  z-index: 1;
  border: 1px solid $border;
  border-radius: 8px;
  padding: 20px;
  width: 340px;
  height: 372px;
  top: calc(100% + 3px);
  right: 0;
  &-social {
    margin-right: 10px;
    width: fit-content;
  }
  &__title {
    margin: 15px 0 2px;
    @include font(14, 18, null, $projectMenuTime);
  }
  &-list {
    display: flex;
    flex-flow: row wrap;
    &__item {
      padding: 5px;
      height: 30px;
      width: 30px;
    }
    &__img {
      height: 20px;
      width: 20px;
    }
  }
  &__categories {
    margin: -5px;
    display: flex;
  }
  &__search {
    margin-top: 8.5px;
    overflow: hidden;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: 1px solid $border;
    position: relative;
  }
  &__input {
    border: none;
    appearance: none;
    background: $white;
    width: 100%;
    padding: 0 60px 0 20px;
    height: 100%;
    &,
    &::placeholder {
      @include font(14, 20, normal, $fgText);
    }
    color: $text;
  }
  &__socials {
    margin-top: 10px;
    display: flex;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/env";
.sp-emoji {
  &-category {
    padding: 5px;
    & path {
      fill: $fgText;
    }
    cursor: pointer;
    &--active {
      & path {
        fill: $mainBtn;
      }
    }
  }
  &-social {
    &--selected {
      color: $base;
    }
  }
}
.sp-emoji-scrollbar {
  min-width: 312px;
  margin-top: 16px;
  height: 231px;
  & .ps {
    &__rail-y,
    &__rail-y:hover {
      width: 2px !important;
      background: $scrollbarRailBg !important;
      right: 3px !important;
    }
    &__thumb-y,
    &__thumb-y:hover {
      width: 4px !important;
      background: $scrollbarThumbBg !important;
      right: -1px !important;
    }
    &__rail-y,
    &__rail-y:hover,
    &__thumb-y,
    &__thumb-y:hover {
      display: block !important;
      opacity: 1 !important;
      border-radius: 8px !important;
    }
  }
}
</style>