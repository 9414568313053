<script>
    import BaseIcon from "./icons/BaseIcon";
    import SuccessIcon from "./icons/BaseSuccess";
    export default {
        data() {
            return {
                actionMasks: {
                    'bk': (items) => {
                        const i = items.findIndex(v => v.id === this.initialStep)
                        if (i > 0) {
                            return [this.initialStep, items[i - 1].id]
                        }
                        return [this.initialStep]
                    },
                    'nt': (items) => {
                        const i = items.findIndex(v => v.id === this.initialStep)
                        if (items.length - 1 > i) {
                            return [this.initialStep, items[i + 1].id]
                        } else {
                            return [this.initialStep, -1];
                        }
                    },
                    'ay': (items) => {
                        return items.flatMap(v => v.id)
                    },
                    'cn': (items, prev=true) => {
                        const i = items.findIndex(v => v.id === this.initialStep);
                        if (items[i]) {
                            return [this.initialStep, items[i][prev ? 'prev_target' : 'next_target']]
                        }
                        return [this.initialStep]
                    },
                    'ne': () => [this.initialStep],
                    'ae': () => [this.initialStep, -1]
                },
                initialStep: 0,
                initialSet: false,
            }
        },
        components: {
            BaseIcon,
            SuccessIcon,
        },
        props: {
            project: Object,
            modelValue: Number,
            initialValue: Number,
            editable: {
                type: Boolean,
                default: true,
            },
            callback: Object,
            callbackArgs: Array,
        },
        computed: {
            available() {
                if (!this.editable || !this.project) return []
                if  (this.initialStep === -1) return [-2,-1,...this.project.steps.flatMap(v => v.id)]
                let defaultSteps = [-2]
                let currentStep = this.project.steps[this.project.steps.findIndex(v => v.id === this.initialStep)]
                if (!currentStep) {
                    defaultSteps = [-2, -1]
                    currentStep = this.project.steps[0]
                    if (!currentStep) return defaultSteps
                }
                let prev_available = this.actionMasks[currentStep.prev_action](this.project.steps, true),
                    next_available= this.actionMasks[currentStep.next_action](this.project.steps, false);
                return Array.from(new Set([...defaultSteps, ...prev_available, ...next_available]))
            }
        },
        methods: {
            selectStep(stepId) {
                if (this.available.indexOf(stepId) === -1) return;
                this.$emit('update:modelValue', stepId)
                this.callback && this.callback(...this.callbackArgs, stepId);
            }
        },
        watch: {
            initialValue: {
                handler: function (v) {
                    if (v) {
                        this.initialStep = v
                        this.initialSet = true
                    }
                },
                immediate: true,
            },
            modelValue: {
                handler: function (v) {
                    if (!v && this.project && this.project.steps.length) {
                        this.$emit('update:modelValue', this.project.steps[0].id)
                    }
                    if (v && !this.initialSet) {
                        console.log('MODEL VALUE IN')
                        this.initialStep = v
                        this.initialSet = true
                    }
                },
                immediate: true,
            },
            project(v) {
                if(!this.modelValue && v && v.steps.length) {
                    this.$emit('update:modelValue', v.steps[0].id)
                }
            }
        }
    }
</script>

<template>
    <div class="sp-publication-stages" v-if="project">
        <div class="sp-publication-stages-item"
             :class="{'sp-btn': available.indexOf(-2) !== -1, 'sp-publication-stages-item--current': -2 === modelValue}"
             @click="selectStep(-2)"
        >
            <i class="sp-publication-stages__icon sp-publication-stages__icon--draft"></i>
            Черновик
        </div>
        <div class="sp-publication-stages-item"
            :class="{'sp-btn': available.indexOf(step.id) !== -1, 'sp-publication-stages-item--current': step.id === modelValue}"
            v-for="step in project.steps"
            :key="step.id"
            @click="selectStep(step.id)"
        >
            <div class="sp-publication-stages__icon"></div>
            {{step.title}}
        </div>
        <div class="sp-publication-stages-item"
             :class="{'sp-btn': available.indexOf(-1) !== -1, 'sp-publication-stages-item--current': -1 === modelValue}"
             @click="selectStep(-1)"
        >
            <base-icon width="20" height="20" icon-name="Согласовать"><success-icon/></base-icon>
            Согласовать
        </div>
    </div>
</template>

<style lang="scss">
    @import "src/assets/scss/env";
    .sp-publication {
        &-stages {
            display: flex;
            flex-flow: row wrap;
            width: 100%;
            margin-top: 20px;
            &-item {
                background: $white;
                padding: 8.5px 12px;
                align-items: center;
                display: flex;
                border-radius: 8px;
                width: unset;
                @include font(14,17,null, $projectMenuTime);
                &--current {
                    background: $focusedBtn;
                    border: 1px solid $border;
                }
                & > svg {
                    margin-right: 6px;
                }
            }
            &__icon {
                width: 12px;
                height: 12px;
                margin-right: 6px;
                border-radius: 50%;
                background: $publicationsEditBtn;
                &--draft {
                    background: $draftColor;
                }
            }
        }
    }
</style>