<script setup>
// es-nocheck
import {NSpace, NH1, NConfigProvider, NCard} from 'naive-ui';
import { darkTheme } from 'naive-ui'
import AdminCards from "../components/AdminCards.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import {onMounted, ref} from "vue";
import {API} from "../plugins/axios"
import {useRouter} from "vue-router";

const projects = ref([]);
onMounted(() => {
  API.post('/debug/projects/').then(response => {
    projects.value = response.data;
  })
})

const router = useRouter();

function onProjectClick(item) {
  router.push({name:"AdminDebugProject", params: {
    project: item.id
  }})
}
</script>


<template>
<n-config-provider :theme="darkTheme">
    <admin-layout>
      <n-space vertical>
        <n-h1>Панель отладки</n-h1>
        <admin-cards :items="projects" :paginateBy="20" :searchFields="['title', 'id']" v-slot="slotProps">
            <n-card
                size="small"
                style="cursor: pointer"
                v-for="item of slotProps.items"
                :key="item.title"
                @click="onProjectClick(item)"
            >
             {{item.title}}
            </n-card>
        </admin-cards>
      </n-space>
    </admin-layout>
</n-config-provider>
</template>

<style lang="scss" scoped>

</style>