<template>
    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.0435 20.3184C40.0435 31.3639 31.089 40.3184 20.0435 40.3184C8.99788 40.3184 0.043457 31.3639 0.043457 20.3184C0.043457 9.27279 8.99788 0.318359 20.0435 0.318359C31.089 0.318359 40.0435 9.27279 40.0435 20.3184Z" fill="url(#paint0_linear_43_331)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M27.3369 24.5373C27.3369 26.5646 25.6986 28.1251 23.7491 28.1251H16.3378C14.3105 28.1251 12.75 26.4868 12.75 24.5373V17.126C12.75 15.0987 14.3883 13.5382 16.3378 13.5382H23.7491C25.7764 13.5382 27.3369 15.1765 27.3369 17.126V24.5373ZM23.7492 11.6665H16.3379C13.297 11.6665 10.8784 14.0839 10.8784 17.126V24.5373C10.8784 27.5783 13.297 29.9968 16.3379 29.9968H23.7492C26.7913 29.9968 29.2087 27.5783 29.2087 24.5373V17.126C29.2087 14.0839 26.7124 11.6665 23.7492 11.6665ZM20.0052 23.6788C18.4446 23.6788 17.1187 22.3516 17.1187 20.7934C17.1187 19.2329 18.4446 17.9058 20.0052 17.9058C21.5657 17.9058 22.8917 19.2329 22.8917 20.7934C22.8917 22.4306 21.6435 23.6788 20.0052 23.6788ZM20.0048 16.1119C17.4306 16.1119 15.2466 18.2182 15.2466 20.8701C15.2466 23.4443 17.3528 25.6295 20.0048 25.6295C22.579 25.6295 24.763 23.5221 24.763 20.8701C24.763 18.2182 22.6567 16.1119 20.0048 16.1119ZM25.9324 16.1127C25.9324 15.4659 25.4087 14.9423 24.7631 14.9423C24.1164 14.9423 23.5927 15.4659 23.5927 16.1127C23.5927 16.7582 24.1164 17.283 24.7631 17.283C25.4087 17.283 25.9324 16.7582 25.9324 16.1127Z" fill="white"/>
        <defs>
            <linearGradient id="paint0_linear_43_331" x1="20.1928" y1="59.8702" x2="59.8941" y2="20.169" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FFD12A"/>
                <stop offset="0.531888" stop-color="#E3009A"/>
                <stop offset="1" stop-color="#5628CA"/>
            </linearGradient>
        </defs>
    </g>
</template>