// Стор переехал в main.js
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";

import Project from "./models/Project";
import Member from "./models/Member";
import Caption from "./models/Caption";
import MessengerTag from "./models/MessengerTag";
import QuickLink from "./models/QuickLink";
import Step from "./models/Step";
import Publication from "./models/Publication";
import Notification from "./models/Notification";
import PublicationAccount from "./models/PublicationAccount";
import PublicationTemplate from "./models/PublicationTemplate";
import Account from "./models/Account";
import Comment from "./models/Comment";
import PublicationComment from "./models/PublicationComment";
import Client from "@/store/models/Client";
import Reciever from "@/store/models/Reciever";


import authStore from "./modules/auth";

const database = new VuexORM.Database();

// Register Models to Database.
database.register(Project);
database.register(Member);
database.register(Caption);
database.register(MessengerTag);
database.register(QuickLink);
database.register(Step);
database.register(Publication);
database.register(Account);
database.register(PublicationAccount);
database.register(PublicationTemplate);
database.register(Notification);
database.register(Comment);
database.register(PublicationComment);
database.register(Client);
database.register(Reciever);


export const store = new Vuex.Store({
    state: {
        projects: [],
        archivedProjects: [],
        project: undefined,
        preloader: false,
        errorModal: false,
        projectTime: undefined,

        cleanPage: false,
        currentProject: 0,
        ql: [],
        currentPublicationToEdit: {},
        dPublications: []
    },
    getters: {},

    mutations: {
        PRELOADER(state, isShown) {
            state.preloader = isShown;
        },
        CLEAN_PAGE_CHANGE(state, isClean) {
            state.cleanPage = isClean;
        },
        DEBUG_PUBLICATIONS_UPDATE(state, publications) {
            state.dPublications = publications;
        },
        PROJECTS_UPDATE(state, projects) {
            state.projects = projects;
        },
        ARCHIVE_PROJECTS_UPDATE(state, projects) {
            state.archivedProjects = projects;
        },
        PROJECT_CHANGE(state, project) {
            state.project = project;
        },
        PROJECT_TIME_CHANGE(state, time) {
            state.projectTime = time;
        },
        CURRENT_PROJECT_UPDATE(state, id) {
            state.currentProject = id;
        },
        QUICK_LINKS_UPDATE(state, ql) {
            state.ql = ql;
        },
        CURRENT_PUBLICATION_TO_EDIT(state, publication) {
            state.currentPublicationToEdit = publication;
        }
    },
    actions: {
        clearPage({commit}) {
            commit("CLEAN_PAGE_CHANGE", true);
        },
        authConfirm({commit}) {
            commit("USE_TOKEN_CHANGE", true);
        },
        updateDebugPublications({commit}, publications) {
            commit("DEBUG_PUBLICATIONS_UPDATE", publications)
        },
        logout({commit}) {
            commit("USE_TOKEN_CHANGE", false);
        },
        tokenLogout({commit}) {
            commit("USE_TOKEN_CHANGE", false);
            commit("AUTH_COMPLETE_CHANGE", false);
        },
        qlChange({commit}, ql) {
            commit("QUICK_LINKS_UPDATE", ql);
        },
        projectsUpdate({commit}, projects) {
            commit("PROJECTS_UPDATE", projects);
        },
        archiveProjectsUpdate({commit}, projects) {
            commit("ARCHIVE_PROJECTS_UPDATE", projects);
        },
        projectSelect({commit}, id) {
            commit("CURRENT_PROJECT_UPDATE", id);
        },
        currentEditPublicationChange({commit}, publication) {
            commit("CURRENT_PUBLICATION_TO_EDIT", publication);
        },
        loadOpen({commit}) {
            commit("PRELOADER", true);
        },
        loadClose({commit}) {
            commit("PRELOADER", false);
        }
    },
    modules: {
        authStore,
    },
    plugins: [VuexORM.install(database)]
});
