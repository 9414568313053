<script>
  import BaseIcon from "./icons/BaseIcon";
  import {leadingZero} from "../logic/tools";
  import ArrowIcon from "./icons/BaseArrow";
export default {
  name: "DatepickerTimeSelect",
  data() {
    return {
      hour: 0,
      minute: 0,
      allowedKeys: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
      ],
      hours: (() => {
        let result = []
        let i = 0
        while (i < 24)  {
          result.push(`${i < 10 ? '0' : ''}${i}`)
          i++
        }
        return result
      })(),
      minutes: (() => {
        let result = []
        let i = 0
        while (i < 60)  {
          result.push(`${i < 10 ? '0' : ''}${i}`)
          i++
        }
        return result
      })(),
      show: {
        hours: false,
        minutes: false,
      }
    };
  },
  components: {
    BaseIcon,
    ArrowIcon,
  },
  props: ["startH", "startM", 'callbackH', 'callbackM'],
  methods: {
    onChangeHours(value) {
      this.hour = parseInt(value)
      this.$emit("changeHours", parseInt(value));
    },
    onChangeMinutes(value) {
      this.minute = parseInt(value)
      this.$emit("changeMinutes", parseInt(value));
    },
    onClear() {
      this.$emit('clearCurrentTime')
      this.hour = this.callbackH
      this.minute = this.callbackM
    },
    leadingZero,
  },
  watch: {
    callbackH(v) {
      console.log('callbackH',v)
      this.hour = v
    },
    callbackM(v) {
      this.minute = v
    }
  },
  mounted() {
    this.hour = this.startH;
    this.minute = this.startM;
  },
};
</script>

<template>
  <div class="sp-time-select">
    <button class="sp-time-select-picker sp-btn"
      @click.prevent="show.hours = !show.hours"
    >
      {{leadingZero(hour)}}
      <base-icon height="14" width="14" icon-name="Выбор часа">
        <arrow-icon/>
      </base-icon>
      <div class="sp-time-select-picker-dropdown"
        v-show="show.hours"
      >
        <div class="sp-time-select-picker__number sp-btn"
          v-for="n in hours"
          :key="n"
          @click="onChangeHours(n)"
        >
          {{n}}
        </div>
      </div>
    </button>
    <button class="sp-time-select-picker sp-btn"
      @click.prevent="show.minutes = !show.minutes"
    >
      {{leadingZero(minute)}}
      <base-icon height="14" width="14" icon-name="Выбор минуты">
        <arrow-icon/>
      </base-icon>
      <div class="sp-time-select-picker-dropdown"
        v-show="show.minutes"
      >
        <div class="sp-time-select-picker__number sp-btn"
             v-for="n in minutes"
             :key="n"
             @click="onChangeMinutes(n)"
        >
          {{n}}
        </div>
      </div>
    </button>
    <button class="sp-time-select__clear sp-btn"
      @click="onClear"
    >
      Сбросить
    </button>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/env";
.sp-time-select {
  display: flex;
  align-items: center;
  &-hours {
    background: $component;
    border: 1px solid $base;
    border-radius: 3px;
    text-align: center;
    padding: 5px 10px;
    color: $base;
    width: 40px;
  }
  &-picker {
    position: relative;
    margin-right: 10px;
    padding: 15px 14.5px;
    height: 50px;
    width: 75px;
    border: 1px solid $border;
    display: flex;
    align-items: center;

    & > svg {
      transform: rotate(90deg);
      margin-left: 15px;
      & path {
        fill: $text1;
      }
    }
    $self: &;
    &-dropdown {
      position: absolute;
      left: 0;
      bottom: 100%;
      background: $white;
      padding: 5px 0;
      width: 100%;
      max-height: 300px;
      overflow-y: scroll;
      border-radius: 8px 8px 0 0;
      border: 1px solid $border;
      @include _h960min {
        bottom: unset;
        border-radius: 0 0 8px 8px;
        top: 100%;
      }
    }
    &, &__number {
      @include font(15,20);
    }
    &__number {
      padding: 5px 14.5px;
      width: 100%;
      justify-content: unset;
    }
    &:focus {
      border-radius: 0 0 8px 8px;
      @include _h960min {
        border-radius: 8px 8px 0 0;
      }
    }
  }

  &__clear {
    border: 1px solid $publicationsEditBtn;
    margin-left: 7px;
    background: $white;
    height: 50px;
    @include font(14,17,bold, $publicationsEditBtn);
    &:hover {
      border: 1px solid $text;
      color: $text;
    }
    &:focus {
      background: $focusedBtn;
    }
  }
  &__sep {
    color: $base;
    font-size: 12px;
  }
}
</style>
