<script>
    import BaseIcon from "./icons/BaseIcon";
    import BaseArrow from "./icons/BaseArrow";

    export default {
        data() {
            return {

            }
        },
        components: {
            BaseIcon,
            BaseArrow
        },
        props: {
            prev: Function,
            next: Function,
            date: Date,
        },
    }
</script>

<template>
    <div class="sp-publications-day_picker">
        <a href="#" class="sp-publications-day_picker__btn sp-btn sp-publications-day_picker__btn--prev"
           @click="prev"
        >
            <base-icon width="13" height="13" icon-name="Предыдущий день">
                <base-arrow/>
            </base-icon>
        </a>
        <span class="sp-publications-day_picker__date">
            {{
              date.toLocaleString("ru", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}
          </span>
        <a href="#" class="sp-publications-day_picker__btn sp-btn"
           @click="next()"
        >
            <base-icon width="13" height="13" icon-name="Следующий день">
                <base-arrow/>
            </base-icon>
        </a>
    </div>
</template>

<style lang="scss">
    @import "src/assets/scss/env";
    .sp-publications {
        &-day_picker {
            display: flex;
            align-items: center;

            &__btn {
                width: 24px;
                height: 24px;
                border: 1px solid $border;
                border-radius: 2px;

                &--prev {
                    & > svg {
                        transform: rotate(180deg);
                    }
                }
            }

            &__date {
                margin: 0 22px;
                @include font(14, 18)
            }
        }
    }
</style>