<script>
    export default {
        props: {
            item: Object,
        },
    }
</script>
<template>
    <img class="sp-history-list-media-video"
           :src="item.url"
           :alt="item.name"
    >


</template>
<style lang="scss" scoped>
    @import "src/assets/scss/env";

    .sp-history-list-media {
        &-video {
            width: 100%;
            height: 100%;
        }
    }
</style>