<template>
  <div class="sp-auth-preloader">
    <BasePreloader
        :show="preloader"
        radius="70"
    >
      <div class="sp-select" v-if="projects.length > 0">
        <h1 class="sp-home__heading">Ваши проекты</h1>
        <div class="sp-select-projects">

          <div
              @click="$router.push({name: 'ClientView', params: {projectUUID: project.clientUUID,}})"
              v-for="project in projects"
              :key="project.id"
              class="sp-home-project sp-btn sp-select-project">
            <div class="sp-home-project__content">
              <p class="sp-home-project__title">{{ project.title }}</p>
            </div>

          </div>
        </div>
      </div>
      <div class="sp-select" v-else>
        <h1 class="sp-home__heading">У вас нет проектов</h1>
      </div>
    </BasePreloader>
  </div>
</template>

<script>
import BasePreloader from "@/components/BasePreloader";

export default {
  name: "B24Auth",
  components: {BasePreloader,},
  props: {
    token: {required: true,}
  },
  data() {
    return {
      projects: [],
      preloader: true,
    }
  },
  async mounted() {
    const portal = `https://${window.name.split('|')[0]}/`;
    const api_url = `${portal}rest/user.current.json`;
    const fd = new FormData();
    fd.append('auth', this.token)
    fetch(api_url, {
      method: "POST",
      body: `auth=${this.token}&`,
    }).then(response => {
      return response.json()
    }).then(response => {
      const fd = new FormData();
      fd.append('user', response.result.ID);
      fd.append('portal', window.name.split('|')[0])
      this.$API.post('b24/user_projects', fd).then(response => {
        this.projects = response.data;
        this.preloader = false;
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.sp-auth-preloader {
  height: 100vh;
  width: 100vw;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.sp-select {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  &-projects {
    width: 100%;
    max-width: 600px;
    margin-top: 40px;
  }
}

.sp-select-project {
  padding-left: 25px;
}
</style>