import { Model } from '@vuex-orm/core'
import Publication from "./Publication";


export default class PublicationTemplate extends Model{
    static entity = 'publicationTemplates'
    static fields () {
        return {
            id: this.attr(null),
            project: this.attr(''),
            publication_id: this.attr(null),
            title: this.attr(''),
            publication: this.belongsTo(Publication, 'publication_id'),
        }
    }

}