<script>
  import BaseIcon from "./icons/BaseIcon";
  import CloseIcon from "./icons/BaseClose";
  import DragIcon from "./icons/NavbarDrag";
  import draggable from "vuedraggable";
export default {
  data() {
    return {
      localAnswers: [],
      titleTimeout: 0,
      answersTimeout: 0,
    };
  },
  components: {
    BaseIcon,
    CloseIcon,
    DragIcon,
    draggable,
  },
  props: {
    show: Boolean,
    answers: Object,
    title: String,
  },
  methods: {
    quizClear() {
      this.$emit("update:title", "");
      this.$emit("update:show", false);
      this.$emit("update:answers", ["", ""]);
    },
    quizAnswerRemove(answer) {
      let newAnswers = JSON.parse(JSON.stringify(this.answers));
      const index = this.answers.indexOf(answer);
      newAnswers = [
        ...this.answers.slice(0, index),
        ...this.answers.slice(index + 1),
      ];
      this.$emit("update:answers", newAnswers);
    },
    quizAnswerAdd() {
      let newAnswers = JSON.parse(JSON.stringify(this.answers));
      newAnswers.push("");
      this.$emit("update:answers", newAnswers);
    },
    answerUpd(e, index) {
      clearTimeout(this.answersTimeout)
      this.answersTimeout = setTimeout(() => {
        this.localAnswers[index] = e.target.value;
        this.$emit("update:answers", this.localAnswers);
      }, 100)
    },
    titleUpd(evt) {
      clearTimeout(this.titleTimeout);
      this.titleTimeout = setTimeout(() => {
        this.$emit('update:title', evt.target.value)
      }, 100)
    },
    sort() {
      this.$emit("update:answers", this.localAnswers);
    }
  },
  watch: {
    answers: {
      handler: function (v) {
        this.localAnswers = v;
      },
      immediate: true
    }
  },
};
</script>
<template>
  <div class="sp-pub_editor-quiz sp-pub_editor-block" v-show="show">
    <div class="sp-pub_editor-quiz__top">
      <div class="sp-pub_editor-quiz__heading">Опрос</div>
      <div class="sp-pub_editor-quiz__remove sp-btn" @click="quizClear">
        <base-icon height="16" width="16" icon-name="Открепить опрос"><close-icon/></base-icon>
      </div>
    </div>
    <input
            type="text"
            class="sp-pub_editor-quiz__input sp-pub_editor-quiz__title sp-pub_editor-block"
            placeholder="Ваша тема"
            :value="title"
            @keyup="titleUpd"
    />
    <draggable class="sp-pub_editor-quiz__answers"
               v-model="localAnswers"
               item-key="id"
               @sort="sort"
    >
      <template #item="{ element, index }">
        <div class="sp-pub_editor-quiz-answer">
          <base-icon class="sp-pub_editor-quiz-answer__drag"
                     height="30" width="30" icon-name="Сортировка">
            <drag-icon/>
          </base-icon>
          <input
            type="text"
            class="sp-pub_editor-quiz__input sp-pub_editor-block"
            :value="element"
            @input="answerUpd($event, index)"
            placeholder="Вариант ответа"
          />
          <div class="sp-pub_editor-quiz__remove sp-btn" @click="quizAnswerRemove(element)">
            <base-icon width="16" height="16" icon-name="Удалить вариант ответа"><close-icon/></base-icon>
          </div>
        </div>
      </template>
    </draggable>
    <button
      class="sp-pub_editor-quiz__btn sp-pub_editor-block sp-btn"
      @click.prevent="quizAnswerAdd"
    >
      + Добавить вариант ответа
    </button>
  </div>
</template>
<style lang="scss"></style>
