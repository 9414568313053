<script>
    const DAYS_IN_WEEK = 7;
    import BaseIcon from "./icons/BaseIcon";
    import BaseArrow from "./icons/BaseArrow"


    export default {
        name: "Datepicker",
        data() {
            return {
                range: [
                    {
                        date: 0,
                        year: 0,
                        month: 0,
                        day: 0,
                    },
                    {
                        date: 0,
                        year: 0,
                        month: 0,
                        day: 0,
                    },
                ],
                rangeSelectionStarted: false,
                rangeEmpty: true,
                viewYear: 0,
                viewMonth: 0,
                daysOfWeek: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
                months: [
                    "Январь",
                    "Февраль",
                    "Март",
                    "Апрель",
                    "Май",
                    "Июнь",
                    "Июль",
                    "Август",
                    "Сентябрь",
                    "Октябрь",
                    "Ноябрь",
                    "Декабрь",
                ],
            };
        },
        props: {
            publications: Array,
            classes: Array,
        },
        components: {
            BaseIcon,
            BaseArrow
        },
        emits: ["rangeSelect"],
        computed: {
            startDay() {
                // Зависимость от day для рекомпута после выбора дня
                this.day;
                const firstDayInMonth = new Date(
                    this.viewYear,
                    this.viewMonth,
                    1
                ).getDay();
                const daysToStartWeek =
                    firstDayInMonth > 0 ? firstDayInMonth - 1 : DAYS_IN_WEEK - 1;
                return new Date(this.viewYear, this.viewMonth, 1 - daysToStartWeek);
            },
            endDay() {
                // Зависимость от day для рекомпута после выбора дня
                this.day;
                const lastDayInMonth = new Date(
                    this.viewYear,
                    this.viewMonth + 1,
                    0
                ).getDay();
                const daysToEndWeek =
                    lastDayInMonth > 0
                        ? DAYS_IN_WEEK - lastDayInMonth + 1
                        : DAYS_IN_WEEK - lastDayInMonth + 1;
                const endDay = new Date(
                    this.viewYear,
                    this.viewMonth + 1,
                    Math.abs(daysToEndWeek)
                );
                const daysDifference = Math.floor(
                    Math.abs(this.startDay - endDay) / (1000 * 60 * 60 * 24)
                );
                return daysDifference === 42
                    ? endDay
                    : new Date(endDay.setDate(endDay.getDate() + 7));
            },
            datesPerWeek() {
                let dates = [];
                let stDay = new Date(this.startDay.getTime()),
                    endDay = new Date(this.endDay.getTime());
                while (stDay.toLocaleDateString() !== endDay.toLocaleDateString()) {
                    const currentDay = new Date(stDay.getTime());
                    const isRangeBoundary = (date) => {
                        let year = date.getFullYear(),
                            month = date.getMonth(),
                            day = date.getDate();
                        return (
                            this.range[0].year === year &&
                            this.range[0].month === month &&
                            this.range[0].day === day

                        ) || (
                            this.range[1].year === year &&
                            this.range[1].month === month &&
                            this.range[1].day === day
                        )
                    }
                    let date = {
                        day: currentDay.getDate(),
                        month: currentDay.getMonth(),
                        year: currentDay.getFullYear(),
                        date: currentDay,
                        active: currentDay.getMonth() === this.viewMonth,

                        current: isRangeBoundary(currentDay)
                    }
                    if (this.range[0].year !== 0) {
                        const inRange = (date) => {

                            return (
                                this.range[0].date < date
                            ) && (
                                this.range[1].date > date
                            )
                        }
                        date.range = inRange(currentDay)
                    }
                    dates.push(date);

                    stDay = new Date(stDay.setDate(stDay.getDate() + 1));
                }
                return dates;
            },
            monthStages() {
                let result = {
                    published: 0,
                    count: 0,
                    draft: 0,
                    wait: 0,
                    error: 0,
                }
                for (const date of this.datesPerWeek) {
                    for (const key of Object.keys(result)) {
                        if (date[key]) result[key] += date[key]
                    }
                }
                return result
            }
        },
        methods: {
            setDate(date) {
                this.year = date.getFullYear();
                this.month = date.getMonth();
                this.day = date.getDate();
                this.viewMonth = this.month;
                this.viewYear = this.year;
            },
            setView(date) {
                this.viewYear = date.getFullYear();
                this.viewMonth = date.getMonth();
            },
            nextMonth() {
                this.setView(new Date(this.viewYear, this.viewMonth + 1, 1));
            },
            prevMonth() {
                this.setView(new Date(this.viewYear, this.viewMonth - 1, 1));
            },
            dateSelect(date) {
                let rangeIndex = 0
                let dateObj = {
                    date: date,
                    year: date.getFullYear(),
                    month: date.getMonth(),
                    day: date.getDate(),
                }
                if (!this.rangeEmpty) {
                    if (this.rangeSelectionStarted) {
                        rangeIndex = 1
                        if (dateObj.date < this.range[0].date) {
                            rangeIndex = 0
                        }
                    } else {
                        if (dateObj.date > this.range[1].date) {
                            rangeIndex = 1
                        }
                    }
                } else {
                    this.rangeEmpty = false;
                }
                this.range[rangeIndex] = dateObj
                this.rangeSelectionStarted = !this.rangeSelectionStarted;
                this.$emit("rangeSelect",
                    [this.range[0].date.getTime() / 1000, this.range[1].date.getTime() / 1000]
                );
            },

        },
        watch: {
            date(value) {
                this.setDate(value);
            },
        },
        created() {
            this.setDate(this.date || new Date(Date.now()));
        },
    };
</script>

<template>
    <div class="sp-datepicker" :class="classes">
        <div class="sp-datepicker-month">
            <div class="sp-datepicker-month-nav__btn sp-btn sp-datepicker-month-nav__btn--prev"
                 @click="prevMonth"
            >
                <base-icon width="13" height="13" icon-name="Предыдущий месяц"><base-arrow/></base-icon>
            </div>

            <div class="sp-datepicker-month__title">
                <b>{{ months[viewMonth] }}</b> {{ viewYear }}
            </div>

            <div class="sp-datepicker-month-nav__btn sp-btn"
                 @click="nextMonth"
            >
                <base-icon width="13" height="13" icon-name="Следующий месяц"><base-arrow/></base-icon>
            </div>

        </div>
        <div class="sp-datepicker-wrapper">
            <div class="sp-datepicker-header">
                <div class="sp-datepicker-header-item" v-for="wd in daysOfWeek" :key="wd">
                    {{ wd }}
                </div>
            </div>
            <div class="sp-datepicker-days">
                <div
                        class="sp-datepicker-item sp-btn"
                        v-for="date in datesPerWeek"
                        :key="date.date"
                        :class="{ active: date.active, current: date.current, range: date.range}"
                        @click="dateSelect(date.date)"
                >
                    <div>{{ date.day }}</div>
                </div>
            </div>
        </div>
    </div>
    <slot></slot>
</template>

<style lang="scss" scoped>
    @import "@/assets/scss/env";
    .sp-datepicker {
        width: 360px;
        padding: 30px 20px;
        background: $inactive;
        border-radius: 8px;
        border: 1px solid $border;
        &-month {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: -20px;
            &-nav {
                width: 73px;
                display: flex;
                justify-content: space-between;
                &__btn {
                    height: 60px;
                    width: 60px;
                    cursor: pointer !important;
                    &--prev {
                        & > svg {
                            transform: rotate(180deg);
                        }

                    }
                }
            }
            &__title {
                @include font(15,22);
                &> b {
                    margin-right: 4px;
                }
            }
        }
        &-days,
        &-header {
            display: flex;
        }
        &-days {
            margin: -2.95px;
            flex-wrap: wrap;
        }
        &-header {
            margin: 20px 0px 13px;
            &-item {
                width: 37px;
                margin: 0 2.95px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include font(13,15.73);
            }
        }
        &-item {
            width: 41.01px;
            height: 41.01px;
            margin: 2.63px;
            cursor: pointer !important;
            border: 1px solid $border;
            @include font(13,16 , normal, $fgText);
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 2.63px 5.26px;
            &__stages {
                position: absolute;
                top: 17px;
                left: 4.21px;
                display: flex;
                flex-flow: row wrap;
            }
            &-stage {
                display: flex;
                height: 8px;
                width: 9px;
                padding-top: 0.2px;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                margin: 1px 1px;
                @include font(8, null, bold, $white);
                &--status_ {
                    &error {
                        background: $statusError;
                    }
                    &success {
                        background: $statusSuccess;
                    }
                    &stage {
                        background: $statusStage;
                    }
                    &wait {
                        background: $statusWait;
                    }
                    &draft {
                        background: $statusDraft;
                    }
                }
            }
            &-bottom {
                display: flex;
                flex-flow: row wrap;
            }
            &__status {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                font-size: 8px;
                align-items: center;
                color: #fff;
                &.approved {
                    background: limegreen;
                }
                &.waiting {
                    background: #2aabd2;
                }
                &.draft {
                    background: #999999;
                }
            }
            &.active {
                color: $text;
            }
            &.current {
                background: rgba(89,185,255, 0.5);
            }
            &.range {
                background: rgba(89,185,255, 0.3);
            }
            &:hover {
                background: #fff;
                border: 1px solid $border;
                color: $mainBtn;
            }
        }
        &-total {
            margin-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            &-item {
                display: flex;
                align-items: center;
                margin-right: 10px;
                @include font(12,14);
                &__icon {
                    width: 11.6px;
                    height: 11.6px;
                    border-radius: 50%;
                    margin-right: 6.95px;
                    &_draft {
                        background: $statusDraft;
                    }
                    &_wait {
                        background: $statusWait;
                    }
                    &_stage {
                        background: $statusStage;
                    }
                    &_success {
                        background: $statusSuccess;
                    }
                    &_error {
                        background: $statusError;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
</style>
