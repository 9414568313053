<script>
import BaseInput from "../../components/BaseInput";
import BaseLayout from "@/layouts/BaseLayout"

export default {
  name: "ProjectCreate",
  components: {
    BaseInput,
    BaseLayout,
  },
  data() {
    return {
      title: ""
    }
  },
  methods: {
    submit() {
      const data = new URLSearchParams(
          new FormData(this.$refs.projectCreateForm)
      ).toString();
      this.$API.post("projects/create", data).then((response) => {
        this.$store.dispatch("projectsUpdate", response.data.projects);
        this.$store.dispatch("projectSelect", response.data.created);
      });
    },
  },
};
</script>

<template>
  <BaseLayout>
    <div class="sp-project-create">
      <h2 class="sp-project-create__title">Создание проекта</h2>
      <form class="sp-project-create-form" ref="projectCreateForm">
        <base-input
            class="sp-project-create__input"
            name="title"
            v-model="title"
            label="Название проекта"
        />
        <div class="sp-project-create__btn sp-btn sp-btn--main" @click="submit">Создать проект</div>
      </form>
    </div>
  </BaseLayout>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/env";

.sp-project-create {
  margin-top: 40px;
  margin-left: 30px;
  flex: 1;

  &__title {
    @include font(24, 30, bold);
    margin-left: 30px;
  }

  &-form {
    width: 32em;
    border-radius: 8px;
    padding: 20px 30px;
  }

  &__btn {
    margin-top: 20px;
    height: 50px;
    width: 450px;
  }
}
</style>
