// set api root

// check auth using popup window
export function popupAuth(wnd, field, successCallback, errorCallback) {
  const timerRecursion = (wnd) => {
    console.log('99')
    if (wnd.closed) {
      console.log('98')
      if (localStorage[field]) {
        console.log('97')
        successCallback && successCallback();
        console.log('96')
      } else {
        console.log('95')
        errorCallback && errorCallback();
        console.log('94')
      }
    } else {
      console.log('93')
      setTimeout(() => timerRecursion(wnd), 100);
      console.log('92')
    }
  };
  console.log('91')
  timerRecursion(wnd);
  console.log('90')
}

export const windowParameters = "width=600,height=600";


export function popupAuthNoValidation(wnd, callback) {
  const timerRecursion = (wnd) => {
    if (wnd.closed) {
      callback && callback()
    } else {
      setTimeout(() => timerRecursion(wnd), 100);
    }
  };
  timerRecursion(wnd);
}