<template>
  <form class="sp-request-form" @submit.prevent="onSubmit">
    <slot>

    </slot>

  </form>
  <BaseRequestModal
        v-model="requestModal.show"
        :error="requestModal.error"
        :errorDescription="requestModal.errorDescription"
        :loading="requestModal.loading"
        :successDescription="requestModal.successDescription"
    >
  </BaseRequestModal>
</template>

<script>
export default {
  name: "BaseRequestForm",
  props: {
    dataCallback: {required: false, default: undefined},
    responseCallback: {required: false, default: undefined},
    errorCallback: {required: false, default: undefined},
    action: {required: true},
    marginTop: {default: 0, type: Number},
    maxWidth: {default: -1, type: Number},
  },
  data() {
    return {
      requestModal: {
        show: false,
        loading: true,
        error: false,
        errorDescription: "Попробуйте повторить запрос позже",
        successDescription: "",
      }
    }
  },
  methods: {
    onSubmit(evt) {
      let data = this.getFormData(evt.target);
      console.log('DATA', data)
      if (this.dataCallback) {
        this.dataCallback(data);
      }
      this.requestModal.loading = true;
      this.requestModal.show = true;
      this.$API.post(this.action, this.buildFormData(data))
          .then(response => {
                if (response.data.details) {
                  this.requestModal.successDescription = response.data.details;
                }
                if (this.responseCallback) {
                  this.responseCallback(response)
                }
                this.requestModal.error = false;
                this.requestModal.loading = false;
              }
          ).catch(error => {
            if (error.response.data && error.response.data.details) {
              this.requestModal.errorDescription = error.response.data.details
            }
            if (this.errorCallback) {
              this.errorCallback(error)
            }
            this.requestModal.error = true;
            this.requestModal.loading = false;
          }
      );
    },
    getFormData(form) {
      //  Будет перезаписывать последним значением по ключу (не создает списки)
      const fd = new FormData(form);
      const result = {};
      for (const [k, v] of fd) {
        result[k] = v
      }
      return result
    },
    buildFormData(data) {
      const fd = new FormData();
      for (const [k, v] of Object.entries(data)) {
        fd.append(k, v)
      }
      return fd
    }
  },
  computed: {
    cssMarginTop() {
      return `${this.marginTop}px`;
    },
    cssMaxWidth() {
      return this.maxWidth > -1 ? `${this.maxWidth}px` : "100%";
    }
  },
}
</script>

<style lang="scss">
  .sp-request-form {
    margin-top: v-bind(cssMarginTop);
    width: v-bind(cssMaxWidth);
    max-width: v-bind(cssMaxWidth);
  }
</style>