import axios from "axios";

const production = true;

export const server = production ? "smm-plan.ru" : "localhost:8000";
export const API = axios.create({
  baseURL: `https://${
    server
  }/api/`,
  validateStatus: function(status) {
    return status < 400;
  },

});
API.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    console.log(error.stack, error)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default {
  install: (app) => {
    app.config.globalProperties.$API = API
  }
};