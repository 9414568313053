<template>
  <div class="sp-settings-b24-search_result"
       @click="add_b24_user"
  >
    <BaseAvatar
        :photo="user.PERSONAL_PHOTO"
        :radius="20"
        :text="text"
    ></BaseAvatar>
    <p class="sp-settings-b24-search_result__title">
      {{ text }}
    </p>
  </div>
</template>

<script>
import BaseAvatar from "@/components/BaseAvatar";
import {mapState} from "vuex";
import Client from "@/store/models/Client";
import Reciever from "@/store/models/Reciever";

export default {
  name: "ProjectSettingsB24SearchResult",
  emits: ["selected"],
  components: {
    BaseAvatar,
  },
  props: {
    user: {required: true},
    entity: {default: "client"}
  },
  computed: {
    text() {
      if (!this.user) return "Name PH";
      return `${this.user.NAME.trim()} ${this.user.LAST_NAME.trim()}`
    },
    ...mapState(['currentProject']),
  },
  methods: {
    add_b24_user() {
      const fd = new FormData();
      fd.append('b24_id', this.user.ID);
      fd.append('photo', this.user.PERSONAL_PHOTO);
      fd.append('name', this.text);
      fd.append('project', this.currentProject);
      this.$API.post(`projects/${this.entity}/add`, fd).then(response => {
        if (this.entity === "client") {
          Client.insert({data: response.data})
        } else {
          Reciever.insert({data: response.data})
        }
        this.$emit('selected');
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sp-settings-b24 {
  &-search_result {
    cursor: pointer;
    padding: 8px 10px;
    display: flex;
    width: 50%;
    align-items: center;

    &__title {
      margin-left: 12px;
      @include font(16, 20, bold, $text);
    }
  }
}
</style>