<script>
    import MediaItems from "./ProjectPublicationHistoryListItemMediaItems";
    import ListChanges from "./ProjectPublicationHistoryListChanges";

    export default {
        computed: {
            beforeMedia() {
                return this.changes.before.attachments && this.changes.before.attachments.media
            },
            afterMedia() {
                return this.changes.after.attachments && this.changes.after.attachments.media
            }
        },
        components: {
            MediaItems,
            ListChanges,
        },
        props: {
            changes: Object,
        },
    }
</script>

<template>
    <list-changes title="Изменения во вложениях">
        <template #before>
            <MediaItems :items="beforeMedia"/>
        </template>
        <template #after>
            <MediaItems :items="afterMedia"/>
        </template>
    </list-changes>
</template>
