<template>
  <div class="v-input">
    <label
        :class="{'v-input__label--focused': isFocused,}"
        class="v-input__label"
    >
      {{ label }}
    </label>
    <textarea
        class="v-input__input v-input__textarea"
        ref="textarea"
        v-model="value"
        @click="onFocus"
        @blur="onBlur"
        @input="calcTextareaHeight"
        :name="name"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "BaseTextarea",
  emits: ["update:modelValue", 'input'],
  props: {
    label: String,
    modelValue: String,
    name: String,
  },
  data() {
    return {
      value: "",
      isFocused: false,
    }
  },
  methods: {
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = Boolean(this.value);
    },
    calcTextareaHeight() {
      if (!this.$refs.textarea) return;
      this.$refs.textarea.style.height = 'fit-content';
      this.$nextTick(() => {
        this.$refs.textarea.style.height = (this.$refs.textarea.scrollHeight) + "px";
      })
    }
  },
  watch: {
    modelValue: {
      handler: function (v) {
        this.value = v;
        this.calcTextareaHeight();
      },
      immediate: true,
    },
    value: {
      handler: function (v) {
        if(v) this.isFocused = Boolean(v);
        if (v !== this.modelValue) {
          this.$emit('update:modelValue', v);
        }
      },
      immediate: true,
    },
    isFocused: {
      handler: function (v) {
        if (v) {
          this.onFocus();
        } else {
          this.onBlur();
        }
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-input {
  width: 100%;
  border: 1px solid $border;
  border-radius: 8px;
  min-height: 60px;
  position: relative;
  overflow: hidden;
  background: $componentBg;

  &:not(:first-child) {
    margin-top: 12px;
  }

  $self: &;
  &__textarea {
    padding-top: 25px !important;
    resize: none;
    overflow: hidden;
    padding-bottom: 16px !important;
  }
  &--error {
    border: 1px solid $error;

    #{$self}__label {
      color: $error;
    }
  }

  &__label {
    top: 11px;
    left: 19px;
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: $inactiveText;
    transition: font-size .2s, line-height .2s, font-weight .2s;
    position: absolute;
    user-select: none;
    pointer-events: none;

    &--focused {
      top: 4px;
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;
      color: $inactiveText;
    }
  }

  &__input {
    width: calc(100% - 8px);
    height: 100%;
    padding: 6.25px 19px 0;
    margin: 4px 4px 0 4px;
    border-radius: 8px;
    border: none;

    @include font(14, 17, normal);
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 19px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &--normal {
    height: 50px;

    & #{$self} {
      &__label {
        top: 15.5px;
        left: 20px;
        @include font(16, 19, normal, $publicationMessage);

        &--focused {
          top: 7px;
          @include font(12, 15, normal, $publicationMessage);
        }
      }

      &__input {
        padding: 10px 19px 0;
        font-weight: normal;
      }
    }
  }
}
</style>