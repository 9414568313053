import { Model } from '@vuex-orm/core'

export default class QuickLink extends Model{
    static entity = "quickLinks"
    static fields () {
        return {
            id: this.attr(null),
            title: this.attr(""),
            link: this.attr(""),
            project: this.attr(null),
            position: this.attr(""),
        }
    }
}