<template>
    <g>
        <path d="M8.2034 19.5243H11.8172L12.5129 15.2962H14.889L15.5033 11.6823H13.1001L13.5699 8.86354H15.937L16.5514 5.24971H14.1572L14.8529 1.02152H11.239L10.5434 5.24971H7.65229L8.34795 1.02152H4.73412L4.03846 5.24971H1.66236L1.04801 8.86354H3.45121L2.98141 11.6823H0.614352L0 15.2962H2.39416L1.6985 19.5243H5.31233L6.008 15.2962H8.89906L8.2034 19.5243ZM6.59524 11.6823L7.06504 8.86354H9.95611L9.48631 11.6823H6.59524Z" :fill="fill"/>
        <path d="M18.9106 19.5243H27.62C31.6404 19.5243 34.1249 17.3289 34.1249 14.2843C34.1249 11.6733 32.2005 9.96576 29.8244 9.87541V9.69472C31.9656 9.27009 33.4021 7.68904 33.4021 5.64723C33.4021 2.82844 31.1615 1.02152 26.9333 1.02152H18.9106V19.5243ZM23.9339 15.513V11.6101H26.3551C27.882 11.6101 28.8125 12.3328 28.8125 13.6338C28.8125 14.8715 27.9723 15.513 26.2829 15.513H23.9339ZM23.9339 8.50216V4.9606H26.066C27.3128 4.9606 28.2343 5.59302 28.2343 6.73138C28.2343 7.86974 27.3128 8.50216 25.9938 8.50216H23.9339Z" :fill="fill"/>
        <path d="M54.1965 7.96008C53.7989 3.37051 50.4381 0.768555 45.7762 0.768555C40.7891 0.768555 36.8501 4.09328 36.8501 10.2729C36.8501 16.4255 40.6807 19.7773 45.7762 19.7773C51.197 19.7773 53.9435 16.0189 54.1965 12.8749L49.101 12.8388C48.848 14.4198 47.7277 15.4046 45.8846 15.4046C43.4363 15.4046 42.0178 13.6699 42.0178 10.2729C42.0178 7.05662 43.3911 5.14129 45.9208 5.14129C47.8542 5.14129 48.9564 6.27965 49.101 7.96008H54.1965Z" :fill="fill"/>
        <path d="M57.4931 19.5243H70.792V15.4769H62.5163V12.2967H70.1415V8.24919H62.5163V5.06902H70.8281V1.02152H57.4931V19.5243Z" :fill="fill"/>
        <path d="M91.3221 1.02152H75.1683V19.5243H80.1192V4.99674H86.3711V19.5243H91.3221V1.02152Z" :fill="fill"/>
        <path d="M113.478 10.2729C113.478 4.09328 109.503 0.768555 104.48 0.768555C99.4206 0.768555 95.4815 4.09328 95.4815 10.2729C95.4815 16.4164 99.4206 19.7773 104.48 19.7773C109.503 19.7773 113.478 16.4526 113.478 10.2729ZM108.311 10.2729C108.311 13.5977 106.974 15.4046 104.48 15.4046C101.986 15.4046 100.649 13.5977 100.649 10.2729C100.649 6.94821 101.986 5.14129 104.48 5.14129C106.974 5.14129 108.311 6.94821 108.311 10.2729Z" :fill="fill"/>
        <path d="M133.805 1.02152H117.651V19.5243H122.602V4.99674H128.854V19.5243H133.805V1.02152Z" :fill="fill"/>
        <path d="M137.423 19.5243H138.651C143.349 19.5243 145.12 17.2115 145.445 10.7427L145.734 4.92446H149.673V19.5243H154.516V1.02152H141.109L140.82 8.79126C140.639 13.7422 139.772 15.2962 137.928 15.5853L137.423 15.6575V19.5243Z" :fill="fill"/>
        <path d="M162.718 19.5243L163.784 15.9828H169.892L170.958 19.5243H176.379L170.271 1.02152H163.405L157.298 19.5243H162.718ZM164.923 12.2244L166.766 6.11703H166.91L168.754 12.2244H164.923Z" :fill="fill"/>
        <path d="M179.178 19.5243H184.202V12.2967H190.851V19.5243H195.874V1.02152H190.851V8.24919H184.202V1.02152H179.178V19.5243Z" :fill="fill"/>
        <path d="M201.951 19.7773H203.469C206.649 19.7773 208.131 17.7536 209.179 15.4769L215.539 1.02152H210.48L207.517 8.53829L203.65 1.02152H198.518L205.457 13.7784L205.276 14.3204C204.915 15.5491 204.445 15.8744 202.855 15.8744H201.951V19.7773Z" :fill="fill"/>
    </g>
</template>

<script>
    export default {
        props: {
            fill: {type: String, default: "white"}
        }
    }
</script>