<script>
export default {
  name: "ProjectSettingsAccountTimezone",
  data() {
    return {
      timezones: {},
    };
  },
  methods: {
    getTimezoneList() {
      this.$API.get("projects/timezone/list").then((response) => {
        this.timezones = response.data;
      });
    },
    isSelectedTimezone(timezone) {
      console.log(this.project, timezone.name, this.project && this.project.timezone_name)
      return this.project && timezone.name === this.project.timezone_name
    }
  },
  props: ["currentProject", 'project'],
  created() {
    this.getTimezoneList();
  },
};
</script>

<template>
  <div class="sp-timezone" ref="form">
    <input type="hidden" name="project" :value="currentProject" />
    <select class="sp-timezone__select"
      name="timezone"
    >
      <option
        v-for="timezone in timezones"
        :key="timezone.name"
        :selected="isSelectedTimezone(timezone)"
        :value="timezone.name"
      >
        {{ timezone.verbose_name }}
      </option>
    </select>
    <p class="sp-timezone__placeholder">Временная зона</p>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/env";
.sp-timezone {
  position: relative;
  &__select {
    width: 100%;
    background: $white;
    border-radius: 8px;
    border: 1px solid $border;
    padding: 23.5px 15px 9.5px;
    margin-top: 12px;
    appearance: none;
    @include font(16,20);
  }
  &__placeholder {
    top: 19px;
    @include font(12,15,normal,$inactiveText);
    left: 20px;
    position: absolute;
    user-select: none;
  }
}
</style>
