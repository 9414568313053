<script>
import ProjectPublicationsSteps from "./ProjectPublicationsSteps";
import BaseAccount from "./BaseAccount";
import BaseIcon from "./icons/BaseIcon";
import MenuIcon from "./icons/BaseMenu"
import QuizIcon from "./icons/ProjectPublicationEditorQuiz"
import LinkIcon from "./icons/ProjectPublicationEditorLink"
import {publicationTypes, socialLinks} from "../logic/enums";
import Publication from "../store/models/Publication";
import ImagePreview from "./ImagePreview";
import Comments from "./ProjectPublicationComments";
import {mapState} from "vuex";
import ProjectPublicationHistory from "./ProjectPublicationHistory";
import BaseMdiIcon from "./icons/BaseMdiIcon";
import {mdiCommentTextOutline} from '@mdi/js';

export default {
  name: "ProjectPublication",
  data() {
    return {
      show: {
        link: false,
        quiz: false,
        historyModal: false,
        comments: false,
        menu: false,
      },
      currentAccountId: 0,
      publicationTypes,
      socialLinks,
      socialPostLinks: {
        1: (groupId, postId) => {
          return `${this.socialLinks[1]}${Math.abs(groupId)}?w=wall${groupId}_${postId}`
        },
        3: (groupId, postId) => {
          return `${this.socialLinks[3]}${Math.abs(groupId)}/topic/${postId}`
        }
      },
      editablePublication: {},
      icons: {
        mdiCommentTextOutline,
      }
    }
  },
  props: {
    deleteDialog: Object,
    publishNow: Object,
    publication: Object,
  },
  methods: {
    openMenu() {
      console.log('IN');
      this.show.menu = true;
    },
    clickOutsideMenu(evt) {
       let target = evt.target || evt.currentTarget;
        if(!target.closest('.sp-publication__menu')) {
          this.show.menu = false;
        }
    },
    publicationStatus(publication) {
      console.log("!!!!", publication.errors && publication.errors.length)
      let result = ""
      if (publication.error || (publication.errors && publication.errors.length)) result = "sp-publication__stage_error";
      else if (publication.published) result = "sp-publication__stage_published";
      else if (publication.draft) result = "sp-publication__stage_draft";
      else if (publication.approved) result = "sp-publication__stage_wait";
      return result
    },
    editPublication(publication) {
      this.$store.dispatch("currentEditPublicationChange", publication);
      this.$router.push({
        name: "ProjectPublicationsEdit",
        params: {
          currentProject: this.currentProject,
          publication: publication.id,
        },
      });
    },
    checkQuiz(quiz) {
      if (!quiz) return false;
      let answersLen = quiz.answers.filter((v) => Boolean(v)).length;
      return quiz.title && answersLen > 1;
    },
    openLink() {
      let account = this.publication.accounts.find(v => v.id === this.currentAccountId),
          groupId = account.social_group,
          postId = this.publication.post_ids[account.social_group],
          link = this.socialPostLinks[account.social](groupId, postId);

      window.open(link, "_blank")
    },
    publicationPublishNowSkeleton(publication) {
      return this.show.link ||
          this.show.quiz ||
          !(publication.attachments.link || this.checkQuiz(publication.attachments.quiz))

    },
    async changeStage(publication, stageId) {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("publication", publication.id);
      formData.append("stage", stageId);
      await this.$API
          .post("projects/publications/stage", formData)
          .then((response) => {
            Publication.insert({data: response.data});
          });
    },
    openHistory() {
      this.show.historyModal = true;
    }
  },
  components: {
    ProjectPublicationsSteps,
    BaseAccount,
    BaseIcon,
    MenuIcon,
    LinkIcon,
    QuizIcon,
    ImagePreview,
    ProjectPublicationHistory,
    Comments,
    BaseMdiIcon,
  },
  computed: {
    ...mapState(['project', 'currentProject'])
  },
  watch: {
    publication: {
      handler: function (v) {
        this.editablePublication = v
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', this.clickOutsideMenu)
    });
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutsideMenu)
  },
}
</script>
<template>
  <div class="sp-publication sp-component">
    <div class="sp-publication__top">
      <ProjectPublicationsSteps v-model="editablePublication.stage"
                                :project="project"
                                :initial-value="publication.stage"
                                v-show="editablePublication.stage && !editablePublication.published"
                                :callback="changeStage"
                                :callback-args="[editablePublication,]"
      ></ProjectPublicationsSteps>
      <div class="sp-publication__top__right">
        <div class="sp-publication__popup_hover">
          <div class="sp-publication__stage "
               :class="[publicationStatus(editablePublication)]"
          >
            <div class="sp-popup_display_none sp-publication-popup sp-popup" v-if="publication.errors && publication.errors.length">
              <p style="font-weight: bold; color: #333;font-size: 12px;">Не опубликовано в группах:</p>
              <p class="sp-publication-error"
                 v-for="error in publication.errors"
                 :key="error.error"
                 style="color: red; padding: 6px 0 12px;"
              >

                {{error.group ? error.group.title : error.group}}
              </p>
            </div>
          </div>
          {{ editablePublication.pub_time }}
        </div>
        <button class="sp-publication__menu sp-btn 12"
                @click.prevent="openMenu"
        >
          <base-icon width="24" height="24">
            <menu-icon/>
          </base-icon>

          <div class="sp-publication-popup sp-popup sp-popup_display_none" v-show="show.menu">
            <div class="sp-publication-popup__item sp-btn"
                 @click="editPublication(editablePublication)"
            >
              Редактировать
            </div>
            <div class="sp-publication-popup__item sp-btn"
                 @click="openHistory(publication)"
            >
              История
            </div>
            <div class="sp-publication-popup__item sp-btn"
                 v-show="publication.published && currentAccountId"
                 @click="openLink"
            >
              Открыть в соц. сети
            </div>
            <div class="sp-publication-popup__item sp-btn"
                 @click="deleteDialog(editablePublication)"
            >
              Удалить запись
            </div>
          </div>
        </button>

      </div>
    </div>
    <div class="sp-publication__accounts">
      <base-account class="sp-publications-account sp-btn"
                    v-for="account in editablePublication.accounts"
                    :key="account.id"
                    :account="account"
                    :class="{'sp-publications-account__selected': currentAccountId === account.id}"
                    @click="currentAccountId = account.id"
      >
      </base-account>
    </div>


    <p class="sp-publication__text"
       v-show="publication.attachments.message && publication.type === publicationTypes.Post"
       v-html="publication.attachments.message"
       :class="{'sp-publication__text--published': publication.approved}"
    ></p>
    <div class="sp-publication-files-list"
         v-show="publication.attachments.media"
    >
      <div
          class="sp-publication-files-list-item"
          v-for="file in publication.attachments.media"
          :key="file.url"
      >
        <div
            class="sp-publication-files-list-item-container"
            v-if="file.type === 1"
        >
          <image-preview :url="file.url">
            <template v-slot:default="{ onClick }">
              <img @click="onClick"
                   class="sp-publication-files-list-item__image"
                   :src="file.url"
                   :alt="file.name"
              />
            </template>
          </image-preview>
        </div>

      </div>
    </div>
    <div class="sp-publication-additional__control"
         v-show="publication.attachments.link || checkQuiz(publication.attachments.quiz)"
    >
      <div class="sp-publication-show__item sp-btn"
           v-show="publication.attachments.link"
           @click="show.link = !show.link"
      >
        <base-icon
            width="24"
            height="24"
            :icon-name="publication.show ? 'Скрыть прикрепленную ссылку' : 'Показать прикрепленную ссылку'"
            :icon-color="'#9BBF2E'"
        >
          <link-icon/>
        </base-icon>
      </div>
      <div class="sp-publication-show__item sp-btn"
           v-show="checkQuiz(publication.attachments.quiz)"
           @click="show.quiz = !show.quiz"
      >
        <base-icon
            width="24"
            height="24"
            :icon-name="publication.show ? 'Скрыть прикрепленный опрос' : 'Показать прикрепленный опрос'"
            :icon-color="'#9BBF2E'"
        >
          <quiz-icon/>
        </base-icon>
      </div>
    </div>
    <div class="sp-publication-additional"
         v-show="
                show.link || show.quiz
              "
    >
      <p class="sp-publication-link"
         v-if="show.link"
      >
        {{ publication.attachments.link }}
      </p>
      <div class="sp-publication-quiz sp-publication-additional-block"
           v-if="show.quiz"
      >
        <p class="sp-publication-quiz__header">{{ publication.attachments.quiz.title }}</p>
        <div class="sp-publication-quiz__answers">
          <div
              class="sp-publication-quiz__answer sp-publication-additional-block"
              v-for="answer in publication.attachments.quiz.answers"
              :key="answer"
              v-show="answer"
          >
            {{ answer }}
          </div>
        </div>
      </div>
    </div>
    <div class="sp-publication-bottom">

      <div class="sp-publication-actions__skeleton"
           v-show="publicationPublishNowSkeleton(publication)"
      ></div>

      <div class="sp-publication-actions">
        <div class="sp-publication-actions-comments"
             :class="{'sp-publication-actions-comments_active': show.comments}"
        >
          <base-mdi-icon name="Показать/скрыть омментарии"
                         :path="icons.mdiCommentTextOutline"
                         :padding="10"
                         @click="show.comments = !show.comments"
          />
          <div class="sp-publication-actions-comments__count">
            {{ publication.comments_count }}
          </div>
        </div>
        <button
            class="sp-publication-actions-btn sp-publication-actions__publish sp-btn"
            @click="publishNow(publication)"
            v-show="!publication.published"
        >
          Опубликовать сейчас
        </button>
      </div>
    </div>
    <keep-alive>
      <comments :publication="publication"
                v-if="show.comments"
      />
    </keep-alive>
  </div>
  <ProjectPublicationHistory :show="show.historyModal"
                             :close="()=> show.historyModal = false"
                             :publication="publication"
  >
  </ProjectPublicationHistory>
</template>
<style lang="scss">
@import "src/assets/scss/env";

.sp {
  &-publication {
    margin-top: 20px;
    padding: 20px 30px 30px;
    background: #fff;
    border: 1px solid $borderDA;

    &__popup_hover {
      display: flex;
      cursor: pointer;
      align-items: center;
      & .sp-popup_display_none {
        display: none;
      }
      &:hover {
        & .sp-publication-popup {
          display: block;
        }
      }
    }

    &__text {
      @include font(16, 19);
      margin-bottom: 19px;
    }

    &-grid {
      border: 1px solid $borderDA;
      position: relative;
      margin: 5.5px;
      border-radius: 8px;
      width: calc(100% / 3 - 11px);

      &:before {
        content: "";
        display: inline-block;
        padding-bottom: 100%;
        vertical-align: top;
      }

      &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }

      &--has_img {
        border: 1px solid transparent;
      }

      &__date {
        position: absolute;
        width: 100%;
        z-index: 1;
        text-align: center;
        bottom: 19px;
        left: 0;
        @include font(14, 18, null, $white);
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding-right: 120px;
      margin-bottom: 15px;

      &__right {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        @include font(14, 20, null, $projectMenuTime);
        justify-content: flex-end;
      }
    }

    &__accounts {
      display: flex;
      margin-bottom: 30px;
    }

    &__stage {
      width: 10px;
      min-width: 10px;
      height: 10px;
      background: $publicationsEditBtn;
      margin-right: 6px;
      border-radius: 50%;

      &--draft {
        background: $draftColor;
      }

      &_draft {
        background: $statusDraft;
      }

      &_published {
        background: $statusSuccess;
      }

      &_error {
        background: $statusError;
      }

      &_wait {
        background: $statusWait;
      }
    }

    &-show {
      &__item {
        & path {
          fill: $mainBtn;
        }

        height: 42px;
        width: 42px;
        border: 1px solid $mainBtn;
        margin-right: 12px;
      }
    }

    &-additional {
      display: block;
      width: 100%;

      &-block {
        padding: 20.5px 20px;
        border-radius: 8px;
        border: 1px solid $border;
      }

      &__control {
        display: flex;
        margin-top: 15px;
      }

    }

    &-link {
      margin-top: 12px;
      @include font(16, 24);
    }

    &-quiz {
      margin-top: 12px;

      &__header {
        @include font(16, 22, bold);
        width: 100%;
        text-align: center;
      }

      &__answer {
        width: 100%;
        margin-top: 6px;
        padding: 14px 20px;
        @include font(15, 18, null, $text1)
      }

      &__answers {
        margin-top: 15.5px;
      }
    }

    &-bottom {
      display: flex;
      flex-flow: row wrap;
      position: relative;
    }

    &-files-list {
      display: flex;
      flex-flow: row wrap;
      margin: 9px -6px -6px;

      &-item {
        cursor: pointer;
        object-fit: cover;
        margin: 6px;
        position: relative;
        border-radius: 8px;
        width: 86px;
        height: 63px;
        user-select: none;

        &-container {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          overflow: hidden;
        }

        &__image,
        &__video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &.selected {
          border: 1px solid limegreen;
        }
      }
    }

    &-actions {
      &, &__skeleton {
        margin-top: 16px;
      }

      &-comments {
        display: flex;
        align-items: center;
        margin-right: 10px;
        cursor: pointer;
        user-select: none;

        & path {
          fill: $fgText;
        }

        &__count {
          top: 8px;
          right: 8px;
          position: absolute;
          pointer-events: none;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $white;
          z-index: 1;
          @include font(10, 14, null, $fgText);
        }

        position: relative;

        &.sp-publication-actions-comments_active {
          & path {
            fill: $mainBtn;
          }

          & .sp-publication-actions-comments__count {
            color: $mainBtn;
          }
        }

      }

      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-self: flex-end;

      &-btn {
        height: 46px;
        @include font(14, 17, bold);

        &:first-child {
          margin-right: 10px;
        }

        &:focus {
          background: $focusedBtn;
        }
      }

      &__edit {
        width: 153px;
        border: 1px solid $publicationsEditBtn;
        color: $publicationsEditBtn;

        &:hover {
          border: 1px solid $text;
          color: $text;
        }
      }

      &__publish {
        width: 203px;
        color: $mainBtn;
        border: 1px solid $mainBtn;

        &:hover {
          color: $white;
          background: $mainBtn;
        }
      }

      &__skeleton {
        width: 380px;
        height: 46px;
      }
    }

    &-stages {
      margin-top: 0 !important;
    }
  }

  &-account {
    margin-right: 15px;
  }

  &-publications {
    &-account {
      &__selected {
        border: 2px solid $borderFocused;
      }
    }
  }
}
</style>