import { Model } from '@vuex-orm/core'

export default class MessengerTag extends Model{
    static entity = "messengerTags"
    static fields () {
        return {
            id: this.attr(null),
            project: this.attr(null),
            color: this.attr(""),
            title: this.attr(""),
            position: this.attr("")
        }
    }

}