import {createApp} from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import VueUniversalModal from "vue-universal-modal";
import router from "./router";
import axios from "@/plugins/axios";
import sentry from "@/plugins/sentry";
import {store} from "./store";
import "vue-universal-modal/dist/index.css";

import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";


import globalBaseComponents from "@/plugins/globalBaseComponents";
//import FlyTypograf from "@/assets/fly-typograf";
const app = createApp(App);


async function loadB24Lib() {
    try {
        const module = await import("./assets/b24");
        app.config.globalProperties.$B24 = window.BX24;
        return module
    } catch (e) {
        console.log('b24 auth not provided')
    }
}


console.log('MAIN')
app.config.globalProperties.$B24Import = loadB24Lib()
//app.config.globalProperties.$TYPOGRAF = FlyTypograf;
// store.watch(function (state) {
//   return store.getters['entities/projects/query']()
// }, function () {
//   store.commit('PROJECT_CHANGE', store.getters['entities/projects/query']().where('id', this.$store.state.currentProject).withAllRecursive().get()[0])
// })

app
    .use(store)
    .use(router)
    .use(sentry)
    .use(PerfectScrollbar)
    .use(VueUniversalModal, {
        teleportTarget: "#modals",
    })
    .use(axios)
    .use(globalBaseComponents)
    .mount("#app");
