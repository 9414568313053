<script>
    import BasePreloader from "./BasePreloader";
    import ProjectPublicationHistoryListApproved from "./ProjectPublicationHistoryListApproved";
    import Collapse from "./ProjectPublicationHistoryListCollapse";
    import ListItemStage from "./ProjectPublicationHistoryListItemStage";
    import ListItemAccounts from "./ProjectPublicationHistoryListItemAccount";
    import ListItemText from "./ProjectPublicationHistoryListItemText";
    import ListItemMedia from "./ProjectPublicationHistoryListItemMedia";
    import ListItemLink from "./ProjectPublicationHistoryListItemLink";
    import ListItemQuiz from "./ProjectPublicationHistoryListItemQuiz";

    import {mapState} from "vuex";

    export default {
        components: {
            BasePreloader,
            ProjectPublicationHistoryListApproved,
            Collapse,
            ListItemStage,
            ListItemAccounts,
            ListItemText,
            ListItemMedia,
            ListItemLink,
            ListItemQuiz,
        },
        props: {
            publication: Object,
        },
        data() {
            return {
                types: {
                    1: "Публикация создана",
                    2: "Стадия изменена",
                    3: "Публикация согласована",
                    4: "Публикация изменена"
                },
                typeClass: {
                    1: 'sp-history-list-item__type_create',
                    2: 'sp-history-list-item__type_stage',
                    3: 'sp-history-list-item__type_approve',
                    4: 'sp-history-list-item__type_change',
                },
                preloader: true,
                history: [],
            }
        },
        computed: {
            ...mapState(['currentProject',])
        },
        methods: {
            getHistory(publication_id) {
                let formData = new FormData();
                formData.append('project', this.currentProject);
                formData.append('publication', publication_id);
                this.$API.post('projects/publications/history', formData).then(response => {
                    this.history = response.data;
                    this.preloader = false;
                });
            }
        },
        watch: {
            publication: {
                handler: function (v) {
                    if (v) {
                        this.getHistory(v.id)

                    }
                },
                immediate: true,
            }
        }
    }
</script>

<template>

    <base-preloader :show="preloader" :radius="70">
        <div class="sp-history-list">
            <div class="sp-history-list-item"
                 v-for="item in history"
                 :key="item.id"
            >
                <div class="sp-history-list-item__info">
                    <p class="sp-history-list-item__type"
                       :class="typeClass[item.type]"
                    >
                        {{types[item.type]}}
                    </p>
                    <p class="sp-history-list-item__date">
                        {{item.date}}
                    </p>
                </div>
                <div class="sp-history-list-item__content">
                    <p>
                        Пользователем {{item.user && item.user.name}} ({{item.user && item.user.email}})
                    </p>
                    <project-publication-history-list-approved :history-item="item"/>
                    <collapse v-if="item.changes">
                        <div class="sp-history-list__changes">
                            <div class="sp-history-list-item__container"
                                 v-show="item.changes && (item.changes.before.pub_date || item.changes.before.pub_time)">

                                    <p v-show="item.changes && item.changes.before.pub_date">
                                        Дата публикации изменена с {{item.changes.before.pub_date}} на {{item.changes.after.pub_date}}
                                    </p>
                                    <p v-show="item.changes && item.changes.before.pub_time">
                                        Время публикации изменено с {{item.changes.before.pub_time}} на {{item.changes.after.pub_time}}
                                    </p>
                            </div>
                            <list-item-stage v-show="item.changes && item.changes.before.stage"
                                             :changes="item.changes"
                            />
                            <list-item-accounts v-show="item.changes && item.changes.before.accounts"
                                                :changes="item.changes"
                            />
                            <list-item-text v-show="item.changes && item.changes.before && item.changes.before.attachments && item.changes.before.attachments.message"
                                            :changes="item.changes"
                            />
                            <list-item-media v-if="item.changes && item.changes.before && item.changes.before.attachments && item.changes.before.attachments.media && item.changes.before.attachments.media.length"
                                             :changes="item.changes"
                            />
                            <list-item-link v-show="item.changes && item.changes.before && item.changes.before.attachments"
                                             :changes="item.changes"
                            />
                            <list-item-quiz v-show="item.changes && item.changes.before && item.changes.before.attachments"
                                            :changes="item.changes"
                            />
                        </div>
                    </collapse>
                </div>
            </div>
        </div>
    </base-preloader>

</template>

<style lang="scss" scoped>
    @import "src/assets/scss/env";
    .sp-history-list {
        width: 100%;
        height: 100%;
        &-item {
            margin: 10px 5px;
            &__info {
                display: flex;
                justify-content: space-between;
            }
            &__type {
                &_create {
                    color: $psText;
                }
                &_stage {
                    color: $statusStage;
                }
                &_approve {
                    color: $statusSuccess;
                }
                &_change {
                    color: $statusWait;
                }
            }
        }
        &__changes {
            margin: 10px;
        }
    }
</style>
<style lang="scss">
    @import "src/assets/scss/env";
    .sp-history-list {
        &-item {
            &__content {
                margin-top: 5px;
                @include font(14,18);
            }
            &__title {
                @include font(14,16,bold, $statusStage);
                margin-bottom: 8px;
                &_deleted {
                    color: $statusError !important;
                }
                &_created {
                    color: $statusSuccess !important;
                }
            }
            &__container {
                margin: 10px 0;
                padding-bottom: 10px;
                &:not(:last-child) {
                    border-bottom: 1px solid $border;
                }
            }
        }
    }

</style>