import { Model } from '@vuex-orm/core'

export default class Comment extends Model{
    static entity = "comments"
    static fields () {
        return {
            id: this.attr(null),
            text: this.attr(null),
            date: this.attr(null),
            user: this.attr(''),
        }
    }
}