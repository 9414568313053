<script>
    export default {
        props: {
            iconName: {
                type: String,
                default: 'box'
            },
            width: {
                type: [Number, String],
                default: 18
            },
            height: {
                type: [Number, String],
                default: 18
            },
            iconColor: {
                type: String,
                default: 'currentColor'
            },
            viewBox: {
                type: String,
                default: undefined
            }
        }
    }
</script>

<template>
    <svg
            xmlns="http://www.w3.org/2000/svg"
            :width="width"
            :height="height"
            :aria-labelledby="iconName"
            :viewBox="viewBox"
            role="presentation"
    >
        <title :id="iconName" lang="en">{{iconName}}</title>
        <g :fill="iconColor">
          <slot />
        </g>

  </svg>
</template>