<script>
import {shortTitle} from "../logic/tools";

export default {
  props: {
    photo: String,
    alt: String,
    text: String,
    radius: {
      type: Number,
      default: 20,
    }
  },
  methods: {
    shortTitle,
  },
  computed: {
    radiusCss() {
      return "" + this.radius * 2 + "px"
    },
    checkedPhoto() {
      if (this.photo === "null") return null
      return this.photo
    }
  }
}
</script>

<template>
  <div class="sp-avatar">
    <img v-if="checkedPhoto" :alt="alt" :src="checkedPhoto" class="sp-avatar__photo">
    <p v-else class="sp-avatar__text">{{ shortTitle(text) }}</p>
  </div>

</template>

<style lang="scss">
@import "src/assets/scss/env";

.sp-avatar {
  border-radius: 50%;
  height: v-bind('radiusCss');
  width: v-bind('radiusCss');
  min-width: v-bind('radiusCss');
  overflow: hidden;
  display: flex;
  align-items: center;
  user-select: none;
  background: $publicationsEditBtn;

  &__photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text {
    width: 100%;
    text-align: center;
    @include font(15, 16, bold, $white);
    letter-spacing: 2px;
  }
}
</style>