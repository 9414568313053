<script>
    import {mapState} from 'vuex';

    export default {
        computed: {
            ...mapState(['project']),
            stages() {
                return [
                    {
                        id: -2,
                        title: "Черновик"
                    },
                    {
                        id: -1,
                        title: "Согласовано"
                    },
                    ...this.project.steps
                ]
            },
            beforeItem() {
                return this.stages[this.stages.findIndex(v => v.id === this.changes.before.stage)]
            },
            afterItem() {
                return this.stages[this.stages.findIndex(v => v.id === this.changes.after.stage)]
            }
        },
        props: {
            changes: Object,
        },
        components: {
        }
    }
</script>

<template>
    <div class="sp-history-list-item__container">
        <p class="sp-history-list-item__title">Изменена стадия</p>
        <div class="sp-history-list-stage__body">
            <div class="sp-history-list-stage">
                <div class="sp-history-list-stage__icon"
                     :class="{'sp-history-list-stage__icon--draft': beforeItem && beforeItem.id === -2, 'sp-history-list-stage__icon--success': beforeItem && beforeItem.id === -1}"
                ></div>
                {{beforeItem && beforeItem.title}}
            </div>
            <p class="sp-history-list-stage__sep">></p>
            <div class="sp-history-list-stage">
                <div class="sp-history-list-stage__icon"
                     :class="{'sp-history-list-stage__icon--draft': afterItem && afterItem.id === -2, 'sp-history-list-stage__icon--success': afterItem && afterItem.id === -1}"
                ></div>
                {{afterItem && afterItem.title}}
            </div>
        </div>
    </div>

</template>

<style lang="scss">
    @import "src/assets/scss/env";
    .sp-history-list-stage {
        border: 1px solid $border;
        background: $white;
        padding: 8.5px 12px;
        align-items: center;
        display: flex;
        border-radius: 8px;
        width: unset;
        &__body {
            display: flex;
        }
        &__sep {
            @include font(16,20);
            margin: 0 10px;
            display: flex;
            align-items: center;
        }
        @include font(14,17,null, $projectMenuTime);
        &--current {
            background: $focusedBtn;
            border: 1px solid $border;
        }
        & > svg {
            margin-right: 6px;
        }
        &__container {
            display: flex;
            margin: 10px 0 !important;
            padding-top: 10px !important;
            border-top: 1px solid $border;
        }
        &__icon {
            width: 12px;
            height: 12px;
            margin-right: 6px;
            border-radius: 50%;
            background: $publicationsEditBtn;
            &--draft {
                background: $draftColor;
            }
            &--success {
                background: $statusSuccess;
            }
        }
    }
</style>