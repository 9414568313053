import { Model } from '@vuex-orm/core'
import PublicationAccount from "./PublicationAccount";
import Account from "./Account";
import Comment from "./Comment";
import PublicationComment from "./PublicationComment";


export default class Publication extends Model{
    static entity = 'publications'
    static fields () {
        return {
            id: this.attr(null),
            project: this.attr(null),
            accounts: this.belongsToMany(Account, PublicationAccount, 'publication_id', 'account_id'),
            comments: this.belongsToMany(Comment, PublicationComment, 'publication_id', 'comment_id'),
            attachments: this.attr(''),
            stage: this.attr(''),
            draft: this.attr(''),
            template: this.attr(''),
            errors: this.attr(''),
            approved: this.attr(''),
            published: this.attr(''),
            type: this.attr(''),
            comments_count: this.attr(''),
            error: this.attr(''),
            post_ids: this.attr(''),
            pub_date: this.attr(''),
            pub_time: this.attr(''),
        }
    }

}