<script>
import { socialShortById, publicationTypes } from "/src/logic/enums";
import TypeSelector from "./ProjectPublicationsCreateFormTypeSelector";
import GroupSelector from "./ProjectPublicationsCreateFormGroupSelector";
import MediaUploader from "./ProjectPublicationMediaUploader";
import MessageInput from "./ProjectPublicationsCreateFormMessage";
import AttachmentLink from "./ProjectPublicationsCreateFormLink";
import AttachmentQuiz from "./ProjectPublicationsCreateFormQuiz";
import Emoji from "./Emoji";
import BaseIcon from "./icons/BaseIcon";
import EmojiIcon from "./icons/ProjectPublicationEditorEmoji";
import ImageIcon from "./icons/ProjectPublicationEditorImage";
import LinkIcon from "./icons/ProjectPublicationEditorLink";
import QuizIcon from "./icons/ProjectPublicationEditorQuiz";
import CaptionIcon from "./icons/ProjectPublicationEditorCaption";
import ArrowIcon from "./icons/BaseArrow";
import ProjectPublicationsSteps from "./ProjectPublicationsSteps";
import { validateMedia } from "../logic/project/publications/mediaValidatorv2";
import { leadingZero } from "../logic/tools";

export default {
  name: "ProjectPublicationsForm",
  data() {
    return {
      socialShortById,
      publicationTypes,
      // 0 - add, 1 - edit
      viewType: 0,
      debug: [],
      data: {
        type: 1,
        groups: [],
        message: "",
      },
      editMessage: "",
      emoji: {
        current: {},
        social: "vk",
      },
      captionTransfer: "",
      files: [],
      step: -1,
      initialStep: undefined,
      groupsInfo: [],
      link: {
        value: "",
        show: false,
      },
      quiz: {
        title: "",
        answers: ["", ""],
        show: false,
      },
      show: {
        captionMenu: false,
        emoji: false,
        templates: false,
        publishNowModal: false,
      },
    };
  },
  components: {
    TypeSelector,
    GroupSelector,
    MediaUploader,
    MessageInput,
    Emoji,
    BaseIcon,
    EmojiIcon,
    ImageIcon,
    LinkIcon,
    QuizIcon,
    ArrowIcon,
    AttachmentLink,
    AttachmentQuiz,
    CaptionIcon,
    ProjectPublicationsSteps,

  },
  props: {
    currentProject: Number,
    editId: Object,
    formData: Object,
    project: Object,
    publicationToEdit: Object,
    timepickerChanged: Boolean,
    selectedDate: Object,
    type: Number,
    blockSubmit: Boolean,
    showForm: Boolean,
  },
  emits: ["onFormChange", "update:modelValue"],
  methods: {
    async fillEditInfo(publicationToEdit) {
      this.initialStep = publicationToEdit.stage;
      this.step = publicationToEdit.stage;
      let quiz = publicationToEdit.attachments.quiz;
      this.data.groups = publicationToEdit.accounts.flatMap((v) => v.id);
      this.data.type = publicationToEdit.type;
      this.data.message = publicationToEdit.attachments.edit_message;
      this.editMessage = this.data.message;
      this.link.value = publicationToEdit.attachments.link;
      if (this.link.value) this.link.show = true;
      if (quiz) {
        this.quiz.title = quiz.title;
        this.quiz.answers = quiz.answers;
        if (this.quiz.answers.filter((v) => Boolean(v)).length > 1) {
          this.quiz.show = true;
        }
      }
      if (publicationToEdit.attachments.media) {
        for (const file of publicationToEdit.attachments.media) {
          this.files.push({
            type: file.type,
            name: file.name,
            uploaded: true,
            url: file.url,
            id: file.id,
          });
        }
      }
    },
    // async getGroupsInfo() {
    //   this.groupsInfo = await this.$API
    //     .get("projects/accounts/list?project=" + this.currentProject)
    //     .then((response) => response.data);
    //   for (let i = 0; i < this.groupsInfo.length; i++) {
    //     this.groupsInfo[i].warns = [];
    //   }
    // },
    // async getCaptions() {
    //   this.captions = await this.$API.get("projects/captions?project=" + this.currentProject)
    //           .then((response) => response.data);
    // },
    onSelectedGroupsChange(groups) {
      this.data.groups = groups;
      localStorage.selectedGroups = JSON.stringify(this.data.groups);
    },
    getObjectUrl(photo) {
      return URL.createObjectURL(photo);
    },
    getGroupsSocialList() {
      return this.groupsInfo
        .filter((v) => this.data.groups.indexOf(v.id) !== -1)
        .flatMap((v) => v.social);
    },

    onChangeFileInput(evt) {
      for (const file of evt.target.files) {
        const type = file.type.slice(0, 5);
        this.files.push({
          name: file.name,
          size: file.size,
          file: file,
          type: type === "image" ? 1 : 2,
          url: this.getObjectUrl(file),
          active: false,
          errors: [],
          warns: [],
        });
      }
      validateMedia(this.getGroupsSocialList(), this.data.type, this.files);
    },
    // EMOJI
    onEmojiSelect(emoji) {
      this.emoji.current = emoji;
    },
    onEmojiPasted() {
      this.emoji.current = null;
    },
    onEmojiTypeChange(currentTitle) {
      this.emoji.social = currentTitle;
    },
    onEmojiToggle(event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      let target = event.target || event.currentTarget;
      if (!target.closest(".sp-emoji")) {
        this.show.emoji = true;
      }
    },
    onTimePickerClick(evt) {
      let target = evt.target || evt.currentTarget;
      console.log(
        evt,
        target,
        target.closest(".sp-pub_editor-form-timepicker, .sp-datepicker-days")
      );
      if (
        target.closest(
          ".sp-pub_editor-form-timepicker, .sp-datepicker-days, .sp-datepicker-item"
        )
      )
        return;
      this.$emit("publicationTimePicker", this.timepickerChanged);
    },
    savePublication() {
      if (this.blockSubmit) return;
      this.show.publishNowModal = false;
      this.$emit("publicationSave");
    },
    checkPubNow() {
      const now = this.timepickerChanged || this.step !== -1;
      if (now) {
        this.show.publishNowModal = true;
      }
      return now;
    },
    closeOnOutsideClick(evt) {
      let target = evt.target || evt.currentTarget;
      if (!target.closest(".sp-pub_editor-form-message-emoji") && !target.closest('.sp-pub_editor-form-message')) {
        this.show.emoji = false;
      }
    },
    openModalOrSave() {
      if (this.timepickerChanged) {
        this.savePublication();
      } else {
        this.show.publishNowModal = true;
      }
    },
  },
  computed: {
    computedData() {
      let data = {};
      //fill default data
      data.type = this.data.type;
      data.message = this.data.message;
      data.groups = this.data.groups.join(",");
      data.stage = this.step;
      //fill files info
      let fileIndex = 1;
      let mediaInfo = {};
      let uploadedFiles = [];
      for (const file of this.files) {
        const filename = `file${fileIndex}`;
        if (file.uploaded) {
          uploadedFiles.push(file.id);
          continue;
        }
        data[filename] = file.file;
        mediaInfo[filename] = file.name;
        fileIndex++;
      }

      data.uploaded = uploadedFiles.join(",");
      data["media_info"] = JSON.stringify(mediaInfo);
      // fill link info if exists
      if (this.link.value) {
        data["link"] = this.link.value;
      }
      // fill quiz info if correct
      if (
        this.quiz &&
        this.quiz.title &&
        this.quiz.answers.filter((v) => v.length).length > 1
      ) {
        data.quiz = JSON.stringify(this.quiz);
      } else {
        data.quiz = null;
      }

      return data;
    },
    selectedDateTitle() {
      return this.timepickerChanged
        ? `${this.selectedDate.date
            .toLocaleString("ru", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
            .slice(0, -3)}, в ${leadingZero(
            this.selectedDate.hours
          )}:${leadingZero(this.selectedDate.minutes)}`
        : "Сейчас";
    },
    projectTemplates() {
      return this.$store.getters["entities/publicationTemplates/query"]()
        .where("project", this.currentProject)
        .withAllRecursive()
        .get();
    },
    projectCaptions() {
      return this.$store.getters["entities/captions/query"]()
        .where("project", this.currentProject)
        .get();
    },
    projectAccounts() {
      return this.$store.getters["entities/accounts/query"]()
        .where("project", this.currentProject)
        .get();
    },
  },
  watch: {
    computedData(v) {
      this.$emit("update:formData", v);
    },
    projectAccounts: {
      handler: function (v) {
        if (v) this.data.groups = v.flatMap((e) => e.id);
      },
      immediate: true,
    },
    type: {
      handler: function (v) {
        this.data.type = v;
      },
      immediate: true,
    },
    publicationToEdit: {
      handler: function (v) {
        if (v) {
          this.fillEditInfo(v);
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {
    document.addEventListener("click", this.closeOnOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeOnOutsideClick);
  },
};
</script>

<template>
  <form class="sp-pub_editor-form" ref="form" v-show="showForm">

    <TypeSelector v-model="data.type">
      <template v-slot:templates>
        <div
          class="sp-pub_editor__template sp-pub_editor__template--use sp-btn"
          @click="show.templates = !show.templates"
        >
          Использовать шаблон
          <div
            class="sp-popup sp-pub_editor__template-popup"
            v-show="show.templates"
          >
            <div
              class="sp-popup-item sp-btn"
              v-for="template in projectTemplates"
              :key="template.id"
              @click="fillEditInfo(template.publication)"
            >
              {{ template.title }}
            </div>
          </div>
        </div>
      </template>
    </TypeSelector>
    <div class="sp-pub_editor-form__content">
      <GroupSelector
        :currentProject="currentProject"
        :type="data.type"
        v-model="data.groups"
        :base-accounts="projectAccounts"
        @update:model-value="onSelectedGroupsChange"
      >
      </GroupSelector>
      <div
        class="sp-pub_editor-form-message__wrapper"
        v-show="data.type != publicationTypes.Story"
      >
        <MessageInput
          v-model="data.message"
          :type="data.type"
          :emoji="emoji"
          :editMessage="editMessage"
          :caption="captionTransfer"
          @captionBeforePaste="captionTransfer = ''"
          @onEmojiPasted="onEmojiPasted"
        >
        </MessageInput>
        <div
          class="sp-pub_editor-form-message-emoji sp-btn"
          @click="onEmojiToggle"
          :class="{ 'sp-pub_editor-form-message-emoji--active': show.emoji }"
        >
          <base-icon height="24" width="24" icon-name="Эмоджи">
            <emoji-icon />
          </base-icon>
          <div class="sp-pub_editor-form-message-emoji-container">
            <Emoji
              v-show="show.emoji"
              @selectEmoji="onEmojiSelect"
              @typeChange="onEmojiTypeChange"
            ></Emoji>
          </div>
        </div>
      </div>
      <div class="sp-pub_editor-form-actions">
        <div class="sp-pub_editor-form-actions-content">
          <label
            for="filesInput"
            class="sp-pub_editor-form-actions-content-item sp-btn"
          >
            <base-icon
              height="24"
              width="24"
              icon-name="Прикрепить изображения"
            >
              <image-icon />
            </base-icon>
          </label>
          <input
            id="filesInput"
            type="file"
            accept="image/*,video/*"
            multiple
            style="display: none"
            ref="filesInput"
            @change="onChangeFileInput"
            @click="$event.target.value = null"
          />
          <div
            class="sp-pub_editor-form-actions-content-item sp-btn"
            :class="{
              'sp-pub_editor-form-actions-content-item--active': link.show,
            }"
            @click="link.show = !link.show"
            v-if="data.type !== publicationTypes.Story"
          >
            <base-icon height="24" width="24" icon-name="Прикрепить ссылку">
              <link-icon />
            </base-icon>
          </div>
          <div
            class="sp-pub_editor-form-actions-content-item sp-btn"
            :class="{
              'sp-pub_editor-form-actions-content-item--active': quiz.show,
            }"
            @click="quiz.show = !quiz.show"
            v-if="data.type !== publicationTypes.Story"
          >
            <base-icon height="24" width="24" icon-name="Прикрепить опрос">
              <quiz-icon />
            </base-icon>
          </div>
          <div
            class="
              sp-pub_editor-form-actions-content-item
              publication-form-item
              sp-btn
            "
            @click="show.captionMenu = !show.captionMenu"
            v-if="data.type !== publicationTypes.Story && projectCaptions.length"
          >
            <base-icon height="24" width="24" icon-name="Использовать подпись">
              <caption-icon />
            </base-icon>
            <div class="sp-popup sp-popup--default" v-show="show.captionMenu">
              <div
                class="sp-popup-item sp-btn"
                v-for="caption in projectCaptions"
                :key="caption.id"
                @click="captionTransfer = caption.text"
              >
                {{ caption.title }}
              </div>
            </div>
          </div>
        </div>
        <div class="sp-pub_editor-form-actions-content sp-ai_c">
          <div
            class="sp-pub_editor-form-message__length"
            v-if="data.type === publicationTypes.Post"
          ></div>
        </div>
      </div>

      <div class="sp-pub_editor-form-additional">
        <AttachmentLink v-model:show="link.show" v-model:value="link.value">
        </AttachmentLink>
        <AttachmentQuiz
          v-model:show="this.quiz.show"
          v-model:answers="this.quiz.answers"
          v-model:title="this.quiz.title"
        >
        </AttachmentQuiz>
      </div>
      <MediaUploader
        v-model="files"
        :groups-info="this.groupsInfo"
        :groups="this.data.groups"
        :type="this.data.type"
      >
      </MediaUploader>
      <ProjectPublicationsSteps
        v-model="step"
        :project="project"
        :initial-value="initialStep"
      />
      <div class="sp-pub_editor__actions">
        <p
          class="sp-pub_editor__template sp-btn"
          @click="$emit('publicationToTemplate')"
        >
          Шаблон из поста
        </p>
        <div class="sp-flex">
          <button
            class="sp-pub_editor__time_select sp-btn"
            :class="{ 'sp-pub_editor__time_select_error': blockSubmit }"
            ref="timepickerBtn"
            @click.prevent="onTimePickerClick"
          >
            {{ selectedDateTitle }}
            <base-icon width="14" height="14">
              <arrow-icon />
            </base-icon>
            <slot name="timePicker"></slot>
          </button>
          <button
            class="sp-pub_editor__publish sp-btn"
            :class="{ 'sp-pub_editor__publish_disabled': blockSubmit }"
            @click.prevent="openModalOrSave"
          >
            {{
              timepickerChanged || step !== -1
                ? "Запланировать"
                : "Опубликовать"
            }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <Modal
    v-model="show.publishNowModal"
    :close="() => (show.publishNowModal = false)"
  >
    <div class="sp-modal sp-modal-delete sp-modal-publish_now">
      <p class="sp-modal__title">
        Вы действительно хотите опубликовать сейчас?
      </p>
      <div class="sp-modal-delete__bottom">
        <button
          class="sp-modal-delete__back sp-btn sp-btn--optional"
          @click.prevent="show.publishNowModal = false"
        >
          Нет, вернуться
        </button>
        <button
          class="sp-modal-delete__delete sp-btn sp-btn--main"
          @click.prevent="savePublication"
        >
          Да
        </button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss">
@import "../assets/scss/env";
.sp-modal {
  &-publish_now {
    width: 520px;
  }
}
.sp-pub_editor {
  &-block {
    padding: 20.5px 20px;
    border: 1px solid $border;
    border-radius: 8px;
  }
  &-form {
    margin-top: 30px;
    width: 100%;
    border-radius: 8px;
    background: $white;
    border: 1px solid $borderDA;
    &__content {
      padding: 30px;
    }
    &-actions {
      display: flex;
      justify-content: space-between;
      &-content {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -5px;
        &-item {
          margin: 0 5px;
          width: 42px;
          height: 42px;
          border: 1px solid $border;
          background: $white;
          & path {
            fill: $fgText;
          }
          &:hover {
            border: 1px solid transparent;
            box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.25);
            & path {
              fill: $text;
            }
          }
          &--active {
            background: $focusedBtn;
            & path {
              fill: $mainBtn;
            }
          }
        }
      }
    }
    &-additional {
      display: block;
      width: 100%;
    }
    &-message {
      margin: 0 0 15px;
      padding: 15px 20px;
      min-height: 111px;
      word-break: break-word;
      border-radius: 8px;
      border: 1px solid $border;
      background: $white;
      white-space: pre-wrap;
      resize: none;
      width: 100%;
      overflow-y: hidden;
      display: inline-block;
      &,
      &__ph {
        @include font(16, 19, null);
      }
      &__ph {
        color: $publicationMessage;
        position: absolute;
        left: 20px;
        top: 15px;
        user-select: none;
        pointer-events: none;
        &--focused {
          display: none;
        }
      }
      &__wrapper {
        position: relative;
      }
      &__length {
        color: $base;
      }
      &-emoji {
        position: absolute;
        bottom: 27px;
        right: 15.5px;
        width: unset;
        &--active {
          & > svg path {
            fill: $mainBtn;
          }
        }
      }
    }
  }
  &-link {
    margin-top: 10px;
    &__top {
      display: flex;
      justify-content: space-between;
    }
    &__title {
      @include font(15, 22, bold);
    }
    &__remove {
      height: 26px;
      width: 26px;
      & path {
        fill: $text;
      }
    }
    &__input {
      margin-top: 15px;
      width: 100%;
      &,
      &::placeholder {
        @include font(16, 19, null, $publicationMessage);
      }
      color: $text !important;
    }
  }
  &-quiz {
    margin-top: 20px;
    &-answer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 0;
      &__drag {
        margin: 0 12px;
      }
    }
    &__answers {
    }
    &__top {
      display: flex;
      justify-content: space-between;
    }
    &__heading {
      @include font(15, 22, bold);
    }
    &__remove {
      height: 26px;
      width: 26px;
      & path {
        fill: $text;
      }
    }
    &__input {
      &,
      &::placeholder {
        @include font(16, 19, null, $publicationMessage);
      }
      color: $text !important;
      width: calc(100% - 88px);
      margin-right: 16.5px;
    }
    &__title {
      width: 100%;
      margin: 15px 0 8.5px;
    }
    &__btn {
      margin: 6px 0 6px 53px;
      padding: 15px 20px;
      border: 1px solid $borderDA;
      width: calc(100% - 96px);
      @include font(15, 20, bold);
      &:hover {
        background: $btnHoverBg;
      }
      &:focus {
        border: 1px solid $borderFocused;
      }
    }
  }
  &__actions {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__template {
    @include font(14, 17, null, $projectMenuTime);
    position: relative;
    text-decoration: underline;
    width: unset;
    &:hover {
      color: $mainBtn;
    }
    &-popup {
      width: 205px;
      right: -2px;
      top: calc(100% + 5px);
    }
    &--use {
      text-decoration: none;
      position: relative;
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        left: 0;
        top: 100%;
        border-bottom: 1px dashed $borderAB;
      }
    }
  }
  &__publish {
    width: 203px;
    height: 46px;
    border: 1px solid $mainBtn;
    @include font(14, 17, bold, $mainBtn);
    &:hover {
      color: $white;
      background: $mainBtn;
    }
    &:focus {
      background: $focusedBtn;
      color: $mainBtn;
    }
    &_disabled {
      &,
      &:hover,
      &:focus {
        opacity: 0.4;
        cursor: not-allowed;
        color: $statusError !important;
        border: 1px solid $statusError !important;
        background: $white !important;
      }
    }
  }
  &__draft {
    width: 122px;
    height: 46px;
    margin-right: 10px;
    @include font(14, 17, bold, $borderFocused);
    border: 1px solid $borderFocused;
    &:hover {
      border: 1px solid $text;
      color: $text;
    }
    &:focus {
      background: $focusedBtn;
    }
  }
  &__time_select {
    width: unset;
    position: relative;
    margin-right: 20px;

    & > svg {
      margin-left: 8px;
      transform: rotate(90deg);
    }
    &_error {
      color: $statusError !important;
      & > svg path {
        fill: $statusError !important;
      }
    }
  }
}
.publication-form-item {
  position: relative;
  &__menu {
    display: none;
    left: -50px;
    top: calc(100% + 10px);
    position: absolute;
    width: 100px;
    background: $component;
    &.active {
      display: block;
    }
  }
  &__child {
    padding: 10px 5px;
    color: $base;
  }
}
</style>
