<script>
import ProjectMenu from "../../components/ProjectMenu";
import Datepicker from "../../components/Datepicker";
import {idToShortCode, publicationTypes, socialLinks} from "../../logic/enums";
import {shortTitle} from "../../logic/tools";
//import ProjectPublicationsFilter from "../../components/ProjectPublicationsFilter";
import BaseIcon from "../../components/icons/BaseIcon";
import BaseArrow from "../../components/icons/BaseArrow";
import MenuIcon from "../../components/icons/BaseMenu";
// import ListViewIcon from "../../components/icons/ProjectPublicationsViewList";
// import GridViewIcon from "../../components/icons/ProjectPublicationsViewGrid";
import Publication from "../../store/models/Publication";
import BaseSelector from "../../components/BaseSelector";
import PlaceholderIcon from "../../components/icons/ProjectPublicationsPlaceholder";
import ProjectPublication from "../../components/ProjectPublication";

import {mapState} from "vuex";
import Step from "../../store/models/Step";
import BaseLayout from "@/layouts/BaseLayout"

export default {
  name: "ProjectPublications",
  data() {
    return {
      publicationTypes,
      //publications: [],
      ID_TO_SHORT_CODE: idToShortCode,
      SOCIAL_LINKS: socialLinks,
      currentDate: new Date(Date.now()),
      show: {
        publicationCreateButtonDropdown: false,
        alternativeDisplayDropdown: false,
      },
      lockPCButton: false,
      stageIds: [],
      filters: {
        items: {
          type: [{id: 0, title: "Все"}, {id: 1, title: "Посты"}, {id: 2, title: "Истории"},],
          status: [
            {id: 0, title: "Все статусы"}, {id: 1, title: "Черновик"},
            {id: 2, title: "В работе"}, {id: 3, title: "Ожидание публикации"},
            {id: 4, title: "Опубликовано"}, {id: 5, title: "Ошибки"},
          ],
        },
        current: {
          type: [0],
          status: [0],
        },
        checkers: {
          status: {
            1: (pub) => pub.draft,
            2: (pub) => pub.stage && !pub.approved && !pub.draft,
            3: (pub) => pub.approved && !pub.published,
            4: (pub) => pub.published,
            5: (pub) => Boolean(pub.error),
          }
        }
      },
      lastMonth: 0,
      monthStages: [],
      deleteModal: {
        show: false,
        publication_id: -1,
      },
      alternativeDisplay: 0,
      displayType: 1,
      publicationsShowToggle: {},
      preloader: {
        show: false,
        text: "",
        error_: false,
        success_: false,
        error(text) {
          this.text = text;
          this.error_ = true;
          this.success_ = false;
        },
        success(text) {
          this.text = text;
          this.error_ = false;
          this.success_ = true;
        },
        open(text) {
          this.text = text;
          this.show = true;
          this.error_ = false;
          this.success_ = false;
        },
        close() {
          console.log('TIMEOUT CLOSE')
          this.show = false;
          console.log(this, this.show)
          this.text = "";
          this.error_ = false;
          this.success_ = false;
        },
      },
    };
  },
  props: {
    currentProject: Number,
  },
  components: {
    ProjectMenu,
    Datepicker,
    //ProjectPublicationsFilter,
    BaseIcon,
    MenuIcon,
    BaseArrow,
    PlaceholderIcon,
    BaseSelector,
    // ListViewIcon,
    // GridViewIcon,
    ProjectPublication,
    BaseLayout,
  },
  methods: {
    shortTitle,
    async changeStage(publication, stageId) {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("publication", publication.id);
      formData.append("stage", stageId);
      await this.$API
          .post("projects/publications/stage", formData)
          .then((response) => {
            Publication.insert({data: response.data});
          });
    },
    async base64FromImageUrl(url) {
      return await fetch(url)
          .then(response => response.blob())
          .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = reject
            reader.readAsDataURL(blob)
          }))
    },
    onDateSelect(date) {
      this.currentDate = date;
    },
    nextDay() {
      this.currentDate = new Date(
          this.currentDate.setDate(this.currentDate.getDate() + 1)
      );
    },
    prevDay() {
      this.currentDate = new Date(
          this.currentDate.setDate(this.currentDate.getDate() - 1)
      );
    },
    openSocialAccount(account) {
      window.open(this.SOCIAL_LINKS[account.social] + Math.abs(account.social_group), '_blank')
    },
    editPublication(publication) {
      this.$store.dispatch("currentEditPublicationChange", publication);
      this.$router.push({
        name: "ProjectPublicationsEdit",
        params: {
          currentProject: this.currentProject,
          publication: publication.id,
        },
      });
    },
    async deletePublication() {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("publication", this.deleteModal.publication_id);
      await this.$API.post("projects/publications/delete", formData)
          .then(() => {
            Publication.delete(this.deleteModal.publication_id);
            this.deleteModal.show = false;
          });
    },
    publishNow(publication) {
      this.preloader.open("Публикация...");
      let formData = new FormData();
      formData.append("publication", publication.id);
      formData.append("project", this.currentProject);
      this.$API
          .post("projects/publications/publish", formData)
          .then((response) => {
            this.publications = this.publications.map((v) => {
              if (v.id === response.data.id) {
                return response.data;
              }
              return v;
            });

            this.preloader.show = false;
          })
          .catch(() => {
            this.preloader.error("Во время публикации произошла ошибка");
            setTimeout(this.preloader.close, 3000);
          });
    },
    closePreloader() {
      this.preloader.show = false;
    },
    checkQuiz(quiz) {
      if (!quiz) return false;
      let answersLen = quiz.answers.filter((v) => Boolean(v)).length;
      return quiz.title && answersLen > 1;
    },
    showToggle(publication, key) {
      if (!this.publicationsShowToggle[publication.id]) this.publicationsShowToggle[publication.id] = {}
      this.publicationsShowToggle[publication.id][key] = !this.publicationsShowToggle[publication.id][key]
    },
    isVisible(publication, key) {
      let pubShowData = this.publicationsShowToggle[publication.id];
      return pubShowData && pubShowData[key]
    },
    PCBDropdown(evt) {
      let target = evt.target || evt.currentTarget;
      if (target.closest('.sp-publications-create__popup, .sp-publications-create__right')) return
      this.show.publicationCreateButtonDropdown = false;
    },
    PCBClick(evt, type = 'post') {
      if (this.lockPCButton) return;
      let target = evt.target || evt.currentTarget;
      if (target.closest('.sp-publications-create__right')) return
      console.log(type)

      this.$router.push({
        name: 'ProjectPublicationsCreate',
        params: {currentProject: this.currentProject},
        query: {type}
      })


    },
    publicationStatus(publication) {
      let result = ""
      if (publication.published) result = "sp-publication__stage_published";
      else if (publication.draft) result = "sp-publication__stage_draft";
      else if (publication.error) result = "sp-publication__stage_error";
      else if (publication.approved) result = "sp-publication__stage_wait";
      return result
    },
    publicationPublishNowSkeleton(publication) {
      return this.isVisible(publication, 'link') ||
          this.isVisible(publication, 'quiz') ||
          !(publication.attachments.link || this.checkQuiz(publication.attachments.quiz))

    },
    publicationDeleteDialog(publication) {
      this.deleteModal.publication_id = publication.id
      this.deleteModal.show = true;
    },
    publicationPreview(publication) {
      if (publication.preview) return publication.preview.url
      publication.preview = publication.attachments.media.find(v => v.type === 1)
      return publication.preview && publication.preview.url
    },
  },
  computed: {
    publications() {
      return this.$store.getters['entities/publications/query']()
          .where('project', this.currentProject)
          .where('template', false).withAllRecursive().get()
    },
    monthPublicationsWithStage() {
      return this.$store.getters['entities/publications/query']()
          .where('project', this.currentProject)
          .where('template', false)
          .where('pub_date', (v) => {
            let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
            let pub_date = new Date(v.replace(pattern, '$3-$2-$1'));
            return pub_date.getMonth() === this.currentDate.getMonth()
                && pub_date.getFullYear() === this.currentDate.getFullYear()
          })
          .where('template', false)
          .where('published', false)
          .where('approved', false)
          .where('stage', v => v && v > 0)
          .get()
    },
    dayPublications() {
      if (!this.project) return;
      const currentDateString = this.currentDate.toLocaleDateString();
      let query = Publication.query()
          .where('project', this.project.id)
      if (this.displayType === 2) return query.where('template', false).where('published', false).withAll().get()
      if (this.alternativeDisplay) {
        if (this.alternativeDisplay === 1) {
          query.where('template', true)
        } else {
          query.where('draft', true)
        }
      } else {
        query.where('template', false)
            .where('pub_date', currentDateString)
      }
      let filterType = this.filters.current.type[0],
          filterStatus = this.filters.current.status[0];
      if (filterType) query.where('type', filterType)
      if (filterStatus) {
        query.where(this.filters.checkers.status[filterStatus])
      }
      return query.withAll().get()
    },
    datepickerPublications() {
      let publications = [];
      let addedDates = [];
      if (!this.publications) return
      for (const publication of this.publications) {
        if (!publication) continue;
        if (addedDates.indexOf(publication.pub_date) === -1) {
          publications[publication.pub_date] = {
            published: 0,
            count: 0,
            draft: 0,
            wait: 0,
            error: 0,
          };
          addedDates.push(publication.pub_date);
        }
        if (publication.published) publications[publication.pub_date].published++;
        else if (publication.draft) publications[publication.pub_date].draft++;
        else if (publication.error) publications[publication.pub_date].error++;
        else if (publication.approved) publications[publication.pub_date].wait++;
        else if (publication.stage) publications[publication.pub_date].count++;
      }

      return publications;
    },
    filtersUsed() {
      return !((this.filters.current.status[0] + this.filters.current.type[0]) === 0)
    },
    ...mapState(["project", "projectTime", 'currentProject']),
  },
  watch: {
    dayPublications: {
      handler: function (dayPublications) {
        if (!dayPublications) return;
        for (const publication of dayPublications) {
          let file_ids = [];
          for (const media_file of publication.attachments.media) {
            if (media_file['social'] !== 4) {
              continue
            }
            file_ids.push(media_file['id']);
          }
          if (file_ids.length === 0) {
            return;
          }
          this.$API.get(`common/tg/getCloudFiles?project=${this.currentProject}&files=${file_ids.join(',')}`).then(response => {
            const file_id_to_url = response.data;
            for (const media_file of publication.attachments.media) {
              if (!(media_file['id'] in file_id_to_url)) {
                continue
              }
              media_file['url'] = file_id_to_url[media_file['id']]
            }
          })
        }
      }
    },
    monthPublicationsWithStage: {
      handler: function (v) {
        let stageIndexes = {};
        this.monthStages = []
        for (let publication of v) {
          if (!publication.published && !publication.approved && !publication.template && !publication.draft) {
            let stage = Step.find(publication.stage);
            if (!stage) continue
            if (!(stage.id in stageIndexes)) {
              this.monthStages.push({id: stage.id, title: stage.title, count: 0})
              stageIndexes[stage.id] = this.monthStages.length - 1
            }
            this.monthStages[stageIndexes[stage.id]].count += 1
          }
        }
      },
      immediate: true,
    }
  },
  mounted() {
    document.addEventListener('click', this.PCBDropdown)
  },
  beforeUnmount() {
    this.lockPCButton = true;
    document.removeEventListener('click', this.PCBDropdown)
  },
};
</script>

<template>
  <BaseLayout>
    <div class="sp-publications">
      <ProjectMenu :project="project" :project-id="currentProject" :time="projectTime"></ProjectMenu>
      <div class="sp-publications__wrapper">
        <div class="sp-publications__content">
          <div class="sp-publications__top">
            <div class="sp-publications-day_picker">
              <a class="sp-publications-day_picker__btn sp-btn sp-publications-day_picker__btn--prev" href="#"
                 @click="prevDay"
              >
                <base-icon height="13" icon-name="Предыдущий день" width="13">
                  <base-arrow/>
                </base-icon>
              </a>
              <span class="sp-publications-day_picker__date">
            {{
                  currentDate.toLocaleString("ru", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                }}
          </span>
              <a class="sp-publications-day_picker__btn sp-btn" href="#"
                 @click="nextDay"
              >
                <base-icon height="13" icon-name="Следующий день" width="13">
                  <base-arrow/>
                </base-icon>
              </a>
            </div>
            <div class="sp-publications__actions">
              <!--                    <div class="sp-publications-view"-->
              <!--                         v-show="!alternativeDisplay"-->
              <!--                    >-->
              <!--                        <div class="sp-publications-view__item sp-btn"-->
              <!--                             :class="{'sp-publications-view__item&#45;&#45;active': displayType === 1}"-->
              <!--                             @click="displayType = 1"-->
              <!--                        >-->
              <!--                            <base-icon width="21" height="21" icon-name="Вид публикаций списком">-->
              <!--                                <list-view-icon/>-->
              <!--                            </base-icon>-->
              <!--                        </div>-->
              <!--                        <div class="sp-publications-view__item sp-btn"-->
              <!--                             :class="{'sp-publications-view__item&#45;&#45;active': displayType === 2}"-->
              <!--                             @click="displayType = 2"-->
              <!--                        >-->
              <!--                            <base-icon width="21" height="21" icon-name="Вид публикаций сеткой">-->
              <!--                                <grid-view-icon/>-->
              <!--                            </base-icon>-->
              <!--                        </div>-->
              <!--                    </div>-->
              <button :class="{'sp-publications-create--active': show.publicationCreateButtonDropdown}"
                      class="sp-publications-create sp-btn"
                      @click="PCBClick"
              >
                Создать
                <div class="sp-h100">
                  <div class="sp-publications-create__right sp-btn"
                       @click.prevent="show.publicationCreateButtonDropdown = true"
                  >
                    <p class="sp-publications-create-plus">
                      +
                    </p>
                  </div>
                </div>
                <div v-show="show.publicationCreateButtonDropdown"
                        class="sp-publications-create-popup sp-popup"
                >
                  <div class="sp-publications-create-popup__item sp-btn"
                       @click="PCBClick"
                  >
                    Пост
                  </div>
                  <div class="sp-publications-create-popup__item sp-btn"
                       @click="PCBClick($event, 'story')"
                  >
                    История
                  </div>
                </div>
              </button>
              <button class="sp-publications__display sp-btn"
                      @click.prevent="show.alternativeDisplayDropdown = !show.alternativeDisplayDropdown"
              >
                <base-icon height="30" icon-name="Отобразить" width="30">
                  <menu-icon/>
                </base-icon>
                <div v-show="show.alternativeDisplayDropdown" class="sp-publication-popup sp-popup">
                  <div class="sp-publication-popup__item sp-btn"
                       @click="alternativeDisplay = 0"
                  >
                    Публикации
                  </div>
                  <div class="sp-publication-popup__item sp-btn"
                       @click="alternativeDisplay = 1"
                  >
                    Шаблоны
                  </div>
                  <div class="sp-publication-popup__item sp-btn"
                       @click="alternativeDisplay = 2"
                  >
                    Черновики
                  </div>
                </div>
              </button>
            </div>


          </div>
          <div v-show="dayPublications && dayPublications.length || filtersUsed">
            <div v-show="!alternativeDisplay"
                 class="sp-publications-filters"
            >
              <div class="sp-publications-filters-group">
                <base-selector
                    v-model="filters.current.type"
                    :class-list="['sp-publications__filter', 'sp-btn']"
                    :first-selected="true"
                    :items="filters.items.type"
                    selected-class="sp-publications__filter--selected"
                >
                  <template v-slot:default="slot">
                    {{ slot.item.title }}
                  </template>
                </base-selector>
              </div>
              <div class="sp-publications-filters-group sp-publications-filters-group--right">
                <base-selector
                    v-model="filters.current.status"
                    :class-list="['sp-publications__filter', 'sp-btn']"
                    :first-selected="true"
                    :items="filters.items.status"
                    selected-class="sp-publications__filter--selected"
                >
                  <template v-slot:default="slot">
                    {{ slot.item.title }}
                  </template>
                </base-selector>
              </div>
            </div>
            <div v-if="displayType === 1" class="sp-publications__list">
              <ProjectPublication v-for="publication in dayPublications"
                                  :key="publication.id"
                                  :delete-dialog="publicationDeleteDialog"
                                  :publication="publication"
                                  :publish-now="publishNow"
              />

            </div>
            <div v-else class="sp-publications__grid">
              <div v-for="publication in dayPublications"
                   :key="publication.id"
                   class="sp-publication-grid sp-component"
              >
                <img v-show="publicationPreview(publication)"
                     :src="publicationPreview(publication)"
                     class="sp-publication-grid__img"
                >
                <p class="sp-publication-grid__date">{{ publication.pub_date }}</p>
              </div>
            </div>
          </div>
          <div v-show="dayPublications && !dayPublications.length"
               class="sp-publications-placeholder"
          >
            <base-icon class="sp-publications-placeholder__icon"
                       height="30px"
                       icon-name=""
                       width="30px"
            >
              <placeholder-icon/>
            </base-icon>
            <div class="sp-publications-placeholder__text">
              Нет активных публикаций, создайте новую с помощью кнопки «Создать» выше. Вы можете как запланировать
              публикацию, так и опубликовать её сразу после редакции.

            </div>
          </div>
        </div>
        <div class="sp-publications-calendar">
          <Datepicker
              :date="currentDate"
              :publications="datepickerPublications"
              @selectDate="onDateSelect"
          >
          </Datepicker>
          <div v-show="monthStages && monthStages.length"
               class="sp-publications-stages"
          >
            <div class="sp-publications-stages__top">
              <p class="sp-publications-stages__title">Стадии</p>
              <div class="sp-publications-stages-link sp-btn"
                   @click="$router.push({name: 'ProjectKanban',params: { currentProject: project.id },})"
              >
                Канбан
                <base-icon class="sp-publications-stages-link__icon" height="13.09" width="13.09">
                  <base-arrow/>
                </base-icon>
              </div>
            </div>
            <div class="sp-publications-stages__content">
              <!--                <div class="sp-publications-stages-item" v-show="dayStages.draft">-->
              <!--                    <div class="sp-publications-stages-item__content">-->
              <!--                        <i class="sp-publications-stages-item__icon sp-publications-stages-item__icon&#45;&#45;draft"></i>-->
              <!--                        Черновик-->
              <!--                    </div>-->
              <!--                    {{dayStages.draft}}-->
              <!--                </div>-->
              <div v-for="stage in monthStages"
                   :key="stage.id"
                   class="sp-publications-stages-item"
              >
                <div class="sp-publications-stages-item__content">
                  <i class="sp-publications-stages-item__icon"></i>
                  {{ stage.title }}
                </div>
                {{ stage.count }}
              </div>
              <!--                <div class="sp-publications-stages-item" v-show="dayStages.wait">-->
              <!--                    <div class="sp-publications-stages-item__content">-->
              <!--                        <i class="sp-publications-stages-item__icon sp-publications-stages-item__icon&#45;&#45;wait"></i>-->
              <!--                        В ожидании публикации-->
              <!--                    </div>-->
              <!--                    {{dayStages.wait}}-->
              <!--                </div>-->
              <!--                <div class="sp-publications-stages-item" v-show="dayStages.error">-->
              <!--                    <div class="sp-publications-stages-item__content">-->
              <!--                        <i class="sp-publications-stages-item__icon sp-publications-stages-item__icon&#45;&#45;error"></i>-->
              <!--                        Ошибки-->
              <!--                    </div>-->
              <!--                    {{dayStages.error}}-->
              <!--                </div>-->
              <!--                <div class="sp-publications-stages-item" v-show="dayStages.published">-->
              <!--                    <div class="sp-publications-stages-item__content">-->
              <!--                        <i class="sp-publications-stages-item__icon sp-publications-stages-item__icon&#45;&#45;published"></i>-->
              <!--                        Опубликовано-->
              <!--                    </div>-->
              <!--                    {{dayStages.published}}-->
              <!--                </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
  <Modal v-model="deleteModal.show" :close="() => deleteModal.show = !deleteModal.show">
    <div class="sp-modal sp-modal-delete">
      <p class="sp-modal__title">Хотите удалить запись?</p>
      <div class="sp-modal-delete__bottom">
        <button class="sp-modal-delete__back sp-btn sp-btn--optional"
                @click.prevent="deleteModal.show = false"
        >
          Нет, вернуться
        </button>
        <button class="sp-modal-delete__delete sp-btn sp-btn--main"
                @click.prevent="deletePublication"
        >
          Да, удалить безвозвратно
        </button>
      </div>
    </div>
  </Modal>
  <Modal v-model="preloader.show" :close="closePreloader">
    <div class="sp-modal sp-publications-modal">
      <div class="sp-preloader"></div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp {
  &-publications {
    display: flex;
    &__wrapper {
      display: flex;
      flex-flow: row wrap;
      @include _1600 {
        flex-flow: row wrap-reverse;
      }
    }
    &__content {
      margin-right: 30px;
      flex: 1;
    }

    &__grid {
      display: flex;
      flex-flow: row wrap;
      margin: 9.5px -5.5px -5.5px;
    }

    &-modal {
      padding: 30px;
    }

    &-stages {
      margin-top: 20px;
      border-radius: 8px;
      border: 1px solid $border;
      background: $white;
      @include _1600 {
        display: none;
      }
      &__title {
        @include font(15, 22, bold);
      }

      &__top {
        padding: 20px 30px;
        display: flex;
        justify-content: space-between;
        background: $inactive;
        border-bottom: 1px solid $border;
      }

      &__content {
        padding: 16px 30px;
      }

      &-link {
        @include font(14, 20, bold, $mainBtn);
        display: flex;
        width: unset;
        align-items: center;

        &__icon {
          margin-left: 8px;

          & path {
            fill: $mainBtn !important;
          }
        }

        &:hover {
          color: $text;

          &__icon path {
            fill: $text !important;
          }
        }
      }

      &-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        @include font(16, 19);

        &__content {
          display: flex;
          align-items: center;
          color: $projectMenuTime;
        }

        &__icon {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 10px;
          background: $statusStage;

          &--wait {
            background: $statusWait;
          }

          &--error {
            background: $statusError;
          }

          &--success {
            background: $statusSuccess;
          }

          &--draft {
            background: $statusDraft;
          }
        }
      }
    }

    &-modal {
      &-delete {
        padding: 45px 63px;

        &__bottom {
          display: flex;
          margin-top: 30px;
        }

        &__back {
          width: 150px;
          height: 46px;
          margin-right: 12px;
        }

        &__delete {
          height: 45px;
          width: 242px;
        }
      }
    }

    &-placeholder {
      display: flex;
      padding: 25px 53px 25px 40px;
      border-radius: 8px;
      align-items: center;
      margin-top: 15px;
      border: 1px solid $borderDA;
      background: $white;

      &__icon {
        margin-right: 40px;
        min-width: 30px;
        min-height: 30px;
      }

      &__text {
        @include font(16, 24);
      }
    }

    &-calendar {
      margin: 20px 60px 0 0;
      @include _1600 {
        margin: 20px 30px 0 0;
        display: flex;
        justify-content: center;
        flex: 1;
      }
      //@include _1600 {
      //  order: -1;
      //  flex: 1;
      //}
    }

    &-filters {
      display: flex;
      margin-top: 20px;
      justify-content: space-between;

      &-group {
        display: flex;
        flex-flow: row wrap;
        margin: -4.5px;

        &:not(:last-child) {
          margin-right: 40px;
        }

        &--right {
          justify-content: flex-end;
        }
      }
    }

    &-account {
      margin-right: 15px;
    }

    &__accounts {
      display: flex;
    }

    &-day_picker {
      display: flex;
      align-items: center;

      &__btn {
        width: 24px;
        height: 24px;
        border: 1px solid $border;
        border-radius: 2px;

        &--prev {
          & > svg {
            transform: rotate(180deg);
          }
        }
      }

      &__date {
        margin: 0 22px;
        @include font(14, 18)
      }
    }

    &-create {
      position: relative;
      height: 52px;
      width: 188px;
      justify-content: space-between;
      padding-left: 31px;
      @include font(15, 20, bold, $white);
      text-align: center;
      background: $mainBtn;

      &:hover {
        background: $mainBtnHover;
      }

      &:focus {
        border: 1px solid $borderFocused;
      }

      &--active {
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid $borderFA35;
      }

      &-popup {
        padding: 2.5px 20px 12px !important;
        background: $mainBtn;
        border-radius: 0 0 8px 8px;
        border: 1px solid $mainBtn;
        border-top: 1px solid $borderFA35;
        width: calc(100% + 2px);
        right: -1px;

        &__item {
          justify-content: unset;
          @include font(14, 17, bold, $white);
          padding: 10px 0;
        }
      }

      &__right {
        width: 60px;
        min-width: 60px;
        border-left: 1px solid $borderFA35;
        height: 100%;
        border-radius: unset;
      }

      &__plus {
        @include font(24, 29, normal, $white);
        letter-spacing: 0.04em;
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }

  }

  &-publication {
    margin-top: 20px;
    padding: 20px 30px 30px;
    background: #fff;
    border: 1px solid $borderDA;

    &__text {
      @include font(16, 19);
      margin-bottom: 19px;
    }

    &-grid {
      border: 1px solid $borderDA;
      position: relative;
      margin: 5.5px;
      border-radius: 8px;
      width: calc(100% / 3 - 11px);

      &:before {
        content: "";
        display: inline-block;
        padding-bottom: 100%;
        vertical-align: top;
      }

      &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }

      &--has_img {
        border: 1px solid transparent;
      }

      &__date {
        position: absolute;
        width: 100%;
        z-index: 1;
        text-align: center;
        bottom: 19px;
        left: 0;
        @include font(14, 18, null, $white);
      }
    }

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding-right: 120px;
      margin-bottom: 15px;

      &__right {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        @include font(14, 20, null, $projectMenuTime);
        justify-content: flex-end;
      }
    }

    &__accounts {
      display: flex;
      margin-bottom: 30px;
    }

    &__stage {
      width: 10px;
      min-width: 10px;
      height: 10px;
      background: $publicationsEditBtn;
      margin-right: 6px;
      border-radius: 50%;

      &--draft {
        background: $draftColor;
      }

      &_draft {
        background: $statusDraft;
      }

      &_published {
        background: $statusSuccess;
      }

      &_error {
        background: $statusError;
      }

      &_wait {
        background: $statusWait;
      }
    }

    &-show {
      &__item {
        & path {
          fill: $mainBtn;
        }

        height: 42px;
        width: 42px;
        border: 1px solid $mainBtn;
        margin-right: 12px;
      }
    }

    &-additional {
      display: block;
      width: 100%;

      &-block {
        padding: 20.5px 20px;
        border-radius: 8px;
        border: 1px solid $border;
      }

      &__control {
        display: flex;
        margin-top: 15px;
      }

    }

    &-link {
      margin-top: 12px;
      @include font(16, 24);
    }

    &-quiz {
      margin-top: 12px;

      &__header {
        @include font(16, 22, bold);
        width: 100%;
        text-align: center;
      }

      &__answer {
        width: 100%;
        margin-top: 6px;
        padding: 14px 20px;
        @include font(15, 18, null, $text1)
      }

      &__answers {
        margin-top: 15.5px;
      }
    }

    &-bottom {
      display: flex;
      flex-flow: row wrap;
      position: relative;
    }

    &-files-list {
      display: flex;
      flex-flow: row wrap;
      margin: 9px -6px -6px;

      &-item {
        cursor: pointer;
        object-fit: cover;
        margin: 6px;
        position: relative;
        border-radius: 8px;
        width: 86px;
        height: 63px;
        user-select: none;

        &-container {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          overflow: hidden;
        }

        &__image,
        &__video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }

        &.selected {
          border: 1px solid limegreen;
        }
      }
    }

    &-actions {
      &, &__skeleton {
        margin-top: 16px;
      }

      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-self: flex-end;

      &-btn {
        height: 46px;
        @include font(14, 17, bold);

        &:first-child {
          margin-right: 10px;
        }

        &:focus {
          background: $focusedBtn;
        }
      }

      &__edit {
        width: 153px;
        border: 1px solid $publicationsEditBtn;
        color: $publicationsEditBtn;

        &:hover {
          border: 1px solid $text;
          color: $text;
        }
      }

      &__publish {
        width: 203px;
        color: $mainBtn;
        border: 1px solid $mainBtn;

        &:hover {
          color: $white;
          background: $mainBtn;
        }
      }

      &__skeleton {
        width: 380px;
        height: 46px;
      }
    }

  }
}

.publication {
  display: block;
  flex: 1;
  padding: 40px;

  &-preload {
    background: #333;
    color: $base;
    border: 1px solid $component;
    padding: 20px 15px;
  }

  &-actions {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-edit {
      border: 1px solid rgba(0, 0, 139, 0.5);
      background: rgba(0, 0, 139, 0.3);

      &:hover {
        background: rgba(0, 0, 139, 0.5);
      }
    }

    &-pub {
      border: 1px solid rgba(0, 100, 0, 0.5);
      background: rgba(0, 100, 0, 0.3);

      &:hover {
        background: rgba(0, 100, 0, 0.5);
      }
    }
  }

  &-btn {
    padding: 5px 10px;
    margin: 0 10px;
    border-radius: 10px;
    color: $base;
    user-select: none;
    cursor: pointer;
  }

  &-create {
    padding: 10px 20px;
    width: 100%;
    height: 40px;
    font-size: 15px;
    border-radius: 5px;
    text-align: center;
    background: $component;
    color: $base;
  }

  &__text {
    margin: 10px 0;
    border: 1px solid $base;
    color: $base;
    padding: 5px 10px;
    border-radius: 10px;
  }

  &__label {
    margin: 10px 0 0;
    color: $base;
  }

  &-list {
    margin-top: 20px;
    width: 100%;

    &-item {
      width: 100%;
      padding: 15px 20px;
      background: $component;
      border-radius: 10px;
      margin: 10px 0;
      position: relative;

      &-status {
        position: absolute;
        top: 20px;
        right: 15px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #5181b8;

        &-complete {
          background: forestgreen;
        }

        &-error {
          background: #ac2925;
        }

        &-draft {
          background: #999999;
        }
      }

      &-time {
        position: absolute;
        right: 50px;
        top: 20px;
        color: $base;
        font-size: 14px;
      }

      &-groups {
        display: flex;
        flex-flow: row wrap;
        margin: -5px;
        padding-right: 80px;

        &-item {
          margin: 5px;
          padding: 5px 10px;
          border-radius: 10px;
          background: #333;
          color: $base;
        }
      }
    }
  }

  &-additional {
    display: block;
    width: 100%;

    &-link {
      margin-top: 10px;
      @include font(16, 24);
    }

    &-quiz {
      margin-top: 12px;
      font-size: 16px;
      border: 1px solid $base;
      border-radius: 10px;

      &-content {
        border-top: 1px solid $base;
        width: 100%;
        padding: 10px;
      }

      &-top,
      &-answer {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        padding: 5px;
      }

      &__header {
        padding: 5px 10px;
      }

      &__title {
        color: $base;
        text-align: center;
        width: 100%;
      }

      &-answer {
        margin-left: 5px;
        border-radius: 10px;
      }
    }
  }

  &-top {
    display: flex;
    align-items: center;
    height: 40px;

    &__action {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      color: $base;
    }

    &__date {
      padding: 0 10px;
      color: $base;
    }
  }
}
</style>

<style lang="scss">
@import "src/assets/scss/env";

.sp-publications {
  &-stages {
    &-link {
      &__icon path {
        fill: $mainBtn;
      }

      &:hover path {
        fill: $text;
      }
    }
  }

  &__display {
    position: relative;
    min-width: 33px;
    width: 33px;
    height: 30px;

    & > svg {
      transform: rotate(90deg);
    }

    margin-left: 11px;

    & path {
      fill: $fgText;
    }

    &:hover, &:focus {
      & path {
        fill: $text1;
      }
    }

    &:focus {
      & .sp-publication-popup {
        display: block;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__filter {
    border: 1px solid $border;
    width: unset;
    padding: 7.5px 9px;
    @include font(13, 19, null, $projectMenuTime);
    background: $focusedBtn;
    margin: 4.5px;

    &:hover {
      color: $text;
      background: $white;
      border: 1px solid $white;
      box-shadow: 0px 3px 11px rgba(0, 0, 0, 0.25);
    }

    &--selected {
      background: $white;
      border: 1px solid $border1E;
      color: $text;

      &:hover {
        box-shadow: none;
        border: 1px solid $border1E;
      }
    }
  }

  &-view {
    display: flex;
    margin-right: 29.5px;

    &__item {
      width: 37px;
      height: 37px;
      margin-left: 9px;
      border: 1px solid $border;

      & path {
        fill: $projectMenuTime;
      }

      &--active {
        border: 1px solid $mainBtn;

        & path {
          fill: $mainBtn;
        }
      }
    }
  }
}

.sp-publication {
  &__menu {
    margin-left: 14px;

    & path {
      fill: $fgText;
    }

    &:hover, &:focus {
      & path {
        fill: $text1;
      }
    }
  }

  &-popup {
    width: 210px;
    cursor: default;
    display: block;

    &__item {
      @include font(14, 17, null, $text1);
      padding: 10px 15px;
      justify-content: unset;

      &:hover {
        background: $inactive;
      }
    }
  }
}
</style>