<script>
    import ListChanges from "./ProjectPublicationHistoryListChanges";

    export default {
        computed: {
            beforeMessage() {
                return this.changes.before.attachments && this.changes.before.attachments.message
            },
            afterMessage() {
                return this.changes.after.attachments && this.changes.after.attachments.message
            }
        },
        props: {
            changes: Object,
        },
        components: {
            ListChanges,
        }
    }
</script>

<template>
    <list-changes title="Изменения в сообщении">
        <template #before>
            <div class="sp-history-list__text"
                 v-html="beforeMessage"
            >
            </div>
        </template>
        <template #after>
            <div class="sp-history-list__text"
                 v-html="afterMessage"
            >
            </div>
        </template>
    </list-changes>
</template>

<style lang="scss">
    @import "src/assets/scss/env";
    .sp-history-list__text {
        margin: 5px;
        width: 100%;
        padding: 5px;
        border-radius: 8px;
        font-size: 12px;
        border: 1px solid $border;
    }
</style>