<script>
    export default {
        name: "BaseSelector",
        data() {
            return {
                selected: [],
            }
        },
        props: {
            many: Boolean,
            firstSelected: Boolean,
            items: Array,
            classList: Array,
            modelValue: Array,
            selectedClass: String,
        },
        methods: {
            toggle(item) {
                if (this.many) {
                    if (this.selected.indexOf(item.id) === -1) {
                        this.selected.push(item.id)
                    } else {
                        this.selected.splice(this.selected.indexOf(item.id), 1)
                    }
                } else {
                    this.selected = [item.id]
                }
                this.$emit('update:modelValue', this.selected)
            }
        },
        computed: {
            classListToUse() {
              return this.classList ? this.classList : []
            },
        },
        watch: {
            modelValue(v) {
                this.selected = JSON.parse(JSON.stringify(v))
            },
            items: {
                handler: function (v) {
                    if (this.firstSelected && !this.selected.length && v.length) {
                        this.selected.push(v[0].id)
                        this.$emit('update:modelValue', this.selected)
                    }
                },
                immediate: true
            }
        }
    }
</script>

<template>
    <div class="sp-selector-item"
        v-for="item in items"
         :key="item.id"
        @click="toggle(item)"
        :class="[...classListToUse, selected.indexOf(item.id) !== -1 ? selectedClass : '']"
    >
        <slot v-bind:item="item"></slot>
    </div>
</template>

<style lang="scss" scoped>
    .sp-selector-item {
        cursor: pointer;
        user-select: none;
    }
</style>