<script>
import Rangepicker from "../../components/Rangepicker";
import BaseAccount from "../../components/BaseAccount";
import BitrixIcon from "../../components/icons/Bitrix"
import BaseIcon from "../../components/icons/BaseIcon";
import {server} from "@/plugins/axios";
import Account from "../../store/models/Account";
import {mapState} from 'vuex';
import ProjectMenu from "../../components/ProjectMenu";
import BasePreloader from "../../components/BasePreloader";

import BaseLayout from "@/layouts/BaseLayout"

export default {
  name: "ProjectReport",
  data() {
    return {
      createModal: {
        show: false,
        range: undefined,
        account: undefined,
      },
      server,
      reports: [],
      firstAccountSelected: false,
      bitrix24Modal: {
        show: false,
        loaded: false,
        success: false,
      }
    }
  },
  props: {
    currentProject: Number,
  },
  components: {
    BaseAccount,
    BitrixIcon,
    BaseIcon,
    Rangepicker,
    ProjectMenu,
    BasePreloader,
    BaseLayout,
  },
  computed: {
    projectAccounts() {
      return this.$store.getters['entities/accounts/query']()
          .where('project', this.currentProject).get()
    },
    ...mapState(['project', 'projectTime'])
  },
  methods: {
    createReport() {
      let data = new FormData();
      data.append('range', this.createModal.range)
      data.append('account', this.createModal.account)
      data.append('project', this.currentProject)
      this.$API.post('projects/report/create', data)
          .then(() => {
            this.updateReports()
            this.createModal.show = false
          }).catch(result => {
        Account.insert({data: result.response.data})
      })
    },
    copyLink(report) {
      navigator.clipboard.writeText(`https://${server}/report/${report.slug}`)
    },
    openLink(report) {
      window.open(`https://${server}/report/${report.slug}`)
    },
    updateReports() {
      let formData = new FormData();
      formData.append('project', this.currentProject);
      this.$API.post('projects/report/list', formData)
          .then(response => {
            this.reports = response.data
          })
    },
    sendToBitrix(report) {
      let data = new FormData();
      data.append('report', report.id)
      data.append('project', this.currentProject)
      this.openBitrixModal()
      this.$API.post('projects/report/bitrix', data)
          .then(() => {
            this.bitrix24Modal.success = true;
            this.bitrix24Modal.loaded = true;
            setTimeout(this.closeBitrixModal, 3000)
          }).catch(() => {
        this.bitrix24Modal.loaded = true;
        setTimeout(this.closeBitrixModal, 3000);
      })
    },
    openBitrixModal() {
      this.bitrix24Modal.success = false;
      this.bitrix24Modal.loaded = false;
      this.bitrix24Modal.show = true;
    },
    closeBitrixModal() {
      this.bitrix24Modal.show = false;
    }
  },
  watch: {
    projectAccounts(v) {
      if (!this.firstAccountSelected && v && v.length) {
        this.firstAccountSelected = true
        this.createModal.account = v[0].id
      }
    },
    currentProject: {
      handler: function () {
        this.updateReports()
      },
      immediate: true,
    }
  }
}
</script>

<template>
  <BaseLayout>
    <div class="sp-report">
      <ProjectMenu :project="project" :project-id="currentProject" :time="projectTime"></ProjectMenu>
      <div class="sp-report__content">
        <div class="sp-report-top">
          <div class="sp-report__create sp-btn sp-btn--main"
               @click="createModal.show = true"
          >
            Создать отчет
          </div>
        </div>
        <div class="sp-report-main">
          <div class="sp-report-item"
               v-for="report in reports"
               :key="report.id"
          >
            <div class="sp-report-item__info">
              <div class="sp-report-item__date">{{ report.date }}</div>
              <div class="sp-report-item__period">{{ report.period }}</div>
            </div>
            <div class="sp-report-item__content">
              <div class="sp-report-item__btn sp-btn sp-btn--optional"
                   @click="copyLink(report)"
              >
                Скопировать ссылку
              </div>
              <div class="sp-report-item__btn sp-report-item__btn_open sp-btn sp-btn--main"
                   @click="openLink(report)"
              >
                Открыть отчет
              </div>
              <div class="sp-report-item__btn sp-report-item__btn_bitrix sp-btn sp-btn--optional"
                   @click="sendToBitrix(report)"
              >
                <base-icon width="110" height="20" view-box="75 -7 40 40" icon-name="">
                  <bitrix-icon/>
                </base-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
  <Modal v-model="createModal.show"
         :close="() => (createModal.show = false)"
  >
    <div class="sp-modal sp-report-create">
      <p class="sp-modal__title">Создать отчет</p>
      <div class="sp-report-accounts">
        <base-account class="sp-report-account sp-btn"
                      v-for="account in projectAccounts"
                      :account="account"
                      :key="account.id"
                      :class="{'sp-report-account__selected': createModal.account === account.id}"
                      @click="createModal.account = account.id"
        >
        </base-account>
      </div>
      <rangepicker @rangeSelect="v => createModal.range = v">

      </rangepicker>
      <button class="sp-btn sp-btn--main sp-report-create__btn"
              @click.prevent="createReport"
      >
        Создать отчет
      </button>
    </div>
  </Modal>
  <Modal v-model="bitrix24Modal.show"
         :close="() => bitrix24Modal.show = false"
  >
    <div class="sp-modal sp-report-bitrix">
      <base-preloader :radius="60" :show="!bitrix24Modal.loaded">
        <p class="sp-modal__title">
          {{
            bitrix24Modal.success ? "Успешно отправлено!" : "Введите корректные данные bitrix24 в настройках проекта"
          }}</p>
      </base-preloader>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-report {
  margin-right: 30px;
  display: flex;
  flex: 1;

  &__content {
    flex: 1;
    margin-top: 20px;
  }

  &-monthpicker {
    margin-top: 30px;
  }

  &-bitrix {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-create {
    padding: 20px 40px;
    min-width: 400px;

    &__btn {
      margin-top: 20px;
      height: 52px;

    }
  }

  &-account {
    margin-right: 10px;

    &__selected {
      border: 3px solid $borderFocused;
    }
  }

  &-accounts {
    display: flex;
    margin: 20px 0 30px;
  }

  &__create {
    height: 52px;
    width: 176px;
    margin-right: 14px;
    @include font(15, 20, bold, $white);
  }

  &__send {
    height: 52px;
    width: 214px;
    @include font(15, 20, bold, $borderFocused);
  }

  &-top {
    display: flex;
    justify-content: flex-end;
  }

  &-main {
    margin-top: 20px;
    width: 100%;
  }

  &-item {
    padding: 15px 27px;
    border-radius: 8px;
    margin: 10px 0;
    border: 1px solid $borderDA;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__content {
      display: flex;

    }

    &__info {
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    &__btn {
      height: 40px;
      width: 200px;
      margin-left: 20px;

      &_open {
        width: 162px;
      }

      &_bitrix {
        width: 123px;
      }
    }

    &__date {
      @include font(12, 14, null, $projectMenuTime)
    }

    &__period {
      @include font(14, 18, null, $text)
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/env";
</style>