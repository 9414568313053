<script>
import BaseIcon from "./icons/BaseIcon";
import QuizIcon from "./icons/ProjectPublicationEditorQuiz";
import LinkIcon from "./icons/ProjectPublicationEditorLink";

import BaseAccount from "./BaseAccount";

import ImagePreview from "./ImagePreview";

import { publicationTypes } from "../logic/enums";
import Publication from "../store/models/Publication";
import Comments from "./ProjectPublicationComments";
import BaseMdiIcon from "./icons/BaseMdiIcon";
import { mdiCommentTextOutline } from "@mdi/js";

export default {
  data() {
    return {
      show: {
        link: false,
        quiz: false,
        historyModal: false,
        comments: false,
      },
      icons: {
        mdiCommentTextOutline,
      },
      publicationTypes,
    };
  },
  components: {
    BaseIcon,
    LinkIcon,
    QuizIcon,
    BaseAccount,
    ImagePreview,
    Comments,
    BaseMdiIcon,
  },
  props: {
    publication: Object,
    uuid: Object,
    approveStage: Number,
  },
  methods: {
    publicationStatus(publication) {
      let result = "";
      if (publication.published) result = "sp-publication__stage_published";
      else if (publication.draft) result = "sp-publication__stage_draft";
      else if (publication.error) result = "sp-publication__stage_error";
      else if (publication.approved) result = "sp-publication__stage_wait";
      return result;
    },
    checkQuiz(quiz) {
      if (!quiz) return false;
      let answersLen = quiz.answers.filter((v) => Boolean(v)).length;
      return quiz.title && answersLen > 1;
    },
    publicationPublishNowSkeleton(publication) {
      return !publication.approved && (this.show.link ||
        this.show.quiz ||
        !(publication.attachments.link || this.checkQuiz(publication.attachments.quiz)));

    },
    approvePublication() {
      let formData = new FormData();
      formData.append("uuid", this.uuid);
      formData.append("publication", this.publication.id);
      this.$API
        .post("projects/publications/client_approve", formData)
          .then((response) => {
          Publication.insert({ data: response.data });
          this.$emit("approve");
      });
    }
  }
};
</script>

<template>
  <div class="sp-publication sp-component">
    <div class="sp-client-publication__top sp-publication__top">
      <div class="sp-publication__accounts">
        <base-account
          class="sp-publications-account sp-btn"
          v-for="account in publication.accounts"
          :key="account.id"
          :account="account"
          :disable-errors="true"
        >
        </base-account>
      </div>
      <div class="sp-publication__top__right">
        <div
          class="sp-publication__stage"
          :class="[publicationStatus(publication)]"
        ></div>
        {{ publication.pub_time }}
      </div>
    </div>
    <p
      class="sp-publication__text"
      v-show="
        publication.attachments.message &&
        publication.type === publicationTypes.Post
      "
      v-html="publication.attachments.message"
      :class="{
        'sp-publication__text--published': publication.approved,
      }"
    ></p>
    <div
      class="sp-publication-files-list"
      v-show="publication.attachments.media"
    >
      <div
        class="sp-publication-files-list-item"
        v-for="file in publication.attachments.media"
        :key="file.url"
      >
        <div
          class="sp-publication-files-list-item-container"
          v-if="file.type === 1"
        >
          <image-preview :url="file.url">
            <template v-slot:default="{ onClick }">
              <img @click="onClick"
                   class="sp-publication-files-list-item__image"
                   :src="file.url"
                   :alt="file.name"
              />
            </template>
          </image-preview>
        </div>
        <img
            class="sp-publication-files-list-item__video"
            v-else-if="file.preview"
            :src="file.preview"
            :alt="file.name"
        >
        <video
            class="sp-publication-files-list-item__video"
            v-else
            :src="file.url"
            @play="$event.target.stop()"
        ></video>
      </div>
    </div>
    <div class="sp-publication-additional__control"
         v-show="publication.attachments.link || checkQuiz(publication.attachments.quiz)"
    >
      <div class="sp-publication-show__item sp-btn"
           v-show="publication.attachments.link"
           @click="show.link = !show.link"
      >
        <base-icon
          width="24"
          height="24"
          :icon-name="publication.show ? 'Скрыть прикрепленную ссылку' : 'Показать прикрепленную ссылку'"
          :icon-color="'#9BBF2E'"
        >
          <link-icon />
        </base-icon>
      </div>
      <div class="sp-publication-show__item sp-btn"
           v-show="checkQuiz(publication.attachments.quiz)"
           @click="show.quiz = !show.quiz"
      >
        <base-icon
          width="24"
          height="24"
          :icon-name="publication.show ? 'Скрыть прикрепленный опрос' : 'Показать прикрепленный опрос'"
          :icon-color="'#9BBF2E'"
        >
          <quiz-icon />
        </base-icon>
      </div>
    </div>
    <div class="sp-publication-additional"
         v-show="
                show.link || show.quiz
              "
    >
      <p class="sp-publication-link"
         v-if="show.link"
      >
        {{ publication.attachments.link }}
      </p>
      <div class="sp-publication-quiz sp-publication-additional-block"
           v-if="show.quiz"
      >
        <p class="sp-publication-quiz__header">{{ publication.attachments.quiz.title }}</p>
        <div class="sp-publication-quiz__answers">
          <div
            class="sp-publication-quiz__answer sp-publication-additional-block"
            v-for="answer in publication.attachments.quiz.answers"
            :key="answer"
            v-show="answer"
          >
            {{ answer }}
          </div>
        </div>
      </div>
    </div>
    <div class="sp-publication-bottom">

      <div class="sp-publication-actions__skeleton"
           v-show="publicationPublishNowSkeleton(publication)"
      ></div>
      <div class="sp-publication-actions">
        <div class="sp-publication-actions-comments"
             :class="{'sp-publication-actions-comments_active': show.comments}"
        >
          <base-mdi-icon name="Показать/скрыть омментарии"
                         :path="icons.mdiCommentTextOutline"
                         :padding="10"
                         @click="show.comments = !show.comments"
          />
          <div class="sp-publication-actions-comments__count">
            {{ publication.comments_count }}
          </div>
        </div>
        <button class="sp-publication-actions-btn sp-publication-actions__publish sp-btn"
                @click="approvePublication"
                v-show="!publication.published && publication.stage === approveStage"
        >
          Согласовать
        </button>
      </div>
    </div>
    <keep-alive>
      <comments :uuid="uuid"
                :publication="publication"
                :client-view="true"
                @commentAdd="$emit('approve')"
                v-if="show.comments"
      />
    </keep-alive>
  </div>
</template>

<style lang="scss">
.sp-client-publication {
  &__top {
    margin-bottom: 0;
  }
}
.sp-publication-actions__skeleton {
  @include _480 {
    width: 200px;
  }
}
</style>