<script>
import BaseInput from "../../components/BaseInput";
import {mapState} from "vuex"
import BaseLayout from "@/layouts/BaseLayout"

export default {
  name: "PersonalData",
  data() {
    return {
      name: "",
      email: "",
      pwd: "",
      pwd1: "",
    }
  },
  components: {
    BaseInput,
    BaseLayout,
  },
  computed: {
    ...mapState({
      user: state => state.authStore.user
    })
  },
  methods: {
    userChangeData() {
      let formData = new FormData(this.$refs.dataForm);
      this.$API
          .post("user/change_data", formData)
          .then((response) => {
            localStorage.user = JSON.stringify(response.data.user);
            this.$store.state.authStore.dispatch("userChange", response.data.user);
            this.$refs.dataFormMessage.innerText = "Изменения успешно сохранены";
            this.$refs.dataFormMessage.classList.remove("error");
          })
          .catch((response) => {
            this.$refs.dataFormMessage.innerText = response.data.message;
            this.$refs.dataFormMessage.classList.add("error");
          });
    },
    userChangePassword() {
      let formData = new FormData(this.$refs.pwdForm);
      this.$API
          .post("user/change_password", formData)
          .then((response) => {
            localStorage.user = JSON.stringify(response.data.user);
            this.$store.state.authStore.dispatch("userChange", response.data.user);
            this.$refs.pwdFormMessage.innerText = "Изменения успешно сохранены";
            this.$refs.pwdFormMessage.classList.remove("error");
          })
          .catch((error) => {
            const response = error.response;
            this.$refs.pwdFormMessage.innerText = response.data.message;
            this.$refs.pwdFormMessage.classList.add("error");
          });
    },
  },
  watch: {
    user: {
      handler: function (v) {
        this.name = v.name;
        this.email = v.email;
      },
      immediate: true,
    }
  },
};
</script>

<template>
  <BaseLayout>
    <section class="sp-pd">
    <h2 class="sp-pd__title">Настройки аккаунта</h2>
    <form ref="dataForm" class="personal-data-form">
      <base-input id="PDName"
                  class="sp-input"
                  name="name"
                  label="Имя"
                  v-model="name"
                  :value="user.name"
      />
      <base-input id="PDEmail"
                  class="sp-input"
                  name="email"
                  label="Email"
                  v-model="email"
                  :value="user.email"
      />
      <p class="sp-pd-message" ref="dataFormMessage"></p>
      <div class="sp-pd__btn-main sp-btn sp-btn--main" @click="userChangeData">Сохранить изменения</div>
    </form>
    <h2 class="sp-pd__title">Смена пароля</h2>
    <form ref="pwdForm" class="personal-data-form">
      <base-input
          id="PDPass"
          class="sp-input"
          name="pwd"
          type="password"
          label="Введите пароль"
          v-model="pwd"
      />
      <base-input
          id="PDPass2"
          class="sp-input"
          name="pwd1"
          type="password"
          v-model="pwd1"
          label="Повторите пароль"
      />
      <p class="sp-pd-message" ref="pwdFormMessage"></p>
      <div class="sp-pd__btn-main sp-btn sp-btn--optional" @click="userChangePassword">Изменить пароль</div>
    </form>
  </section>
  </BaseLayout>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.personal-data-form {
  margin-top: 20px;
  width: 300px;
}

.sp-pd {
  margin-left: 60px;

  &__title {
    @include font(24, 30, bold);
    margin-top: 40px;
  }

  &__btn-main {
    margin-top: 20px;
    height: 50px;
    width: 300px;
  }

  &-message {
    margin-top: 10px;
    @include font(14, 16, bold, $statusError);
    max-width: 300px;
  }

}
</style>
