<script>
    import ListChanges from "./ProjectPublicationHistoryListChanges";

    export default {
        computed: {
            beforeLink() {
                return this.changes.before.attachments && this.changes.before.attachments.link
            },
            afterLink() {
                return this.changes.after.attachments && this.changes.after.attachments.link
            },
            added() {
                return !this.beforeLink
            },
            deleted() {
                return !this.afterLink
            }
        },
        components: {
            ListChanges,
        },
        props: {
            changes: Object,
        },
    }
</script>

<template>
    <list-changes v-if="beforeLink || afterLink"
                  :title='added ? "Добавлена ссылка" : deleted ? "Удалена ссылка" : "Изменена ссылка"'
                  :added="added"
                  :deleted="deleted"
    >
        <template #before>
            <div class="sp-history-list__text"
                 v-show="beforeLink"
            >
                {{beforeLink}}
            </div>
        </template>
        <template #after>
            <div class="sp-history-list__text"
                 v-show="afterLink"
            >
                {{afterLink}}
            </div>
        </template>
    </list-changes>
<!--    <div class="sp-history-list-item__container" v-if="beforeLink || afterLink">-->
<!--        <p class="sp-history-list-item__title">{{added ? "Добавлена ссылка" : deleted ? "Удалена ссылка" : "Изменена ссылка"}}</p>-->
<!--        <div class="sp-history-list-account__body" v-show="!deleted">-->
<!--            <div class="sp-history-list-account-section sp-history-list-account__before" v-show="!added">-->
<!--                <p class="sp-history-list-account__title">До</p>-->
<!--                <div class="sp-history-list-account-section__items">-->
<!--                    <div class="sp-history-list__text"-->
<!--                         v-show="beforeLink"-->
<!--                    >-->
<!--                        {{beforeLink}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="sp-history-list-account-section sp-history-list-account__after">-->
<!--                <p class="sp-history-list-account__title" v-show="!added">После</p>-->
<!--                <div class="sp-history-list-account-section__items">-->
<!--                    <div class="sp-history-list__text"-->
<!--                         v-show="afterLink"-->
<!--                    >-->
<!--                        {{afterLink}}-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->

</template>
