<script>
  import BaseIcon from "./icons/BaseIcon";
  import CloseIcon from "./icons/BaseClose";
export default {
  data() {
    return {
      valueTimeout: 0,
    };
  },
  components: {
    BaseIcon,
    CloseIcon,
  },
  props: {
    value: String,
    show: Boolean,
  },
  methods: {
    onLinkKeyUp(e) {
      clearTimeout(this.valueTimeout)
      this.valueTimeout = setTimeout(() => {
        this.$emit("update:value", e.target.value);
      }, 100)
    },
    onLinkRemove() {
      this.$emit("update:show", false);
      this.$emit("update:value", "");
    },
    created() {},
  },
};
</script>
<template>
  <div class="sp-pub_editor-link sp-pub_editor-block" v-show="show">
      <div class="sp-pub_editor-link__top">
        <div class="sp-pub_editor-link__title">Ссылка</div>
        <div
            class="sp-pub_editor-link__remove sp-btn"
            @click="onLinkRemove"
        >
          <base-icon width="16" height="16" icon-name="Открепить ссылку">
            <close-icon/>
          </base-icon>
        </div>


    </div>
      <input
              type="text"
              name="link"
              class="sp-pub_editor-link__input sp-pub_editor-block"
              @keyup="onLinkKeyUp"
              :value="value"
              placeholder="https://"
      />
  </div>
</template>
<style lang="scss"></style>
