<template>
    <g id="Canvas" fill="none">
        <g id="bitrix24-logo-ru">
            <g id="Group">
                <g id="Vector">
                    <path d="M 0 25.4L 17.6 25.4L 17.6 21.5L 6 21.5C 7.6 15.1 17.4 13.7 17.4 6.6C 17.4 2.8 14.8 0 9.3 0C 5.9 0 2.89999 1 0.899994 2L 2.10001 5.6C 3.90001 4.7 6.00001 3.9 8.60001 3.9C 10.6 3.9 12.5 4.8 12.5 7.1C 12.5 12.3 1 12.7 0 25.4ZM 18.1 19.2L 29.4 19.2L 29.4 25.4L 33.9 25.4L 33.9 19.2L 37.7 19.2L 37.7 15.4L 33.9 15.4L 33.9 0L 30.6 0L 18.1 16.2L 18.1 19.2ZM 23.2 15.6L 29.6 7C 29.6 7.7 29.4 9.9 29.4 11.9L 29.4 15.5L 26.4 15.5C 25.5 15.5 23.8 15.6 23.2 15.6Z" transform="translate(132.5 0)" fill="#005893"/>
                </g>
                <g id="Vector_2">
                    <path d="M 4.7 21.5L 4.7 13.6L 6.6 13.6C 8.3 13.6 9.7 13.8 10.7 14.4C 11.7 15 12.3 16 12.3 17.6C 12.3 20.3 10.7 21.5 6.9 21.5L 4.7 21.5ZM 0.1 25.3L 6.8 25.3C 14.3 25.3 17 22 17 17.4C 17 14.3 15.7 12.2 13.4 11C 11.6 10 9.3 9.7 6.5 9.7L 4.7 9.7L 4.7 3.7L 14.8 3.7L 16 2.38419e-08L 0 2.38419e-08L 0 25.3L 0.1 25.3ZM 20.1 25.3L 24.5 25.3L 30.2 17.1C 31.3 15.6 32.1 14.1 32.6 13.3L 32.7 13.3C 32.6 14.4 32.5 15.8 32.5 17.2L 32.5 25.2L 37 25.2L 37 7.2L 32.6 7.2L 26.9 15.4C 25.9 16.9 25 18.4 24.5 19.2L 24.4 19.2C 24.5 18.1 24.6 16.7 24.6 15.3L 24.6 7.3L 20.1 7.3L 20.1 25.3ZM 44.7 25.3L 49.3 25.3L 49.3 11L 54.7 11L 55.9 7.2L 39.2 7.2L 39.2 11L 44.7 11L 44.7 25.3ZM 57.5 34.4L 62 34.4L 62 25.3C 62.9 25.6 63.8 25.7 64.8 25.7C 70.5 25.7 74.2 21.8 74.2 16.2C 74.2 10.4 70.8 6.7 64.3 6.7C 61.8 6.7 59.4 7.2 57.4 7.8L 57.4 34.4L 57.5 34.4ZM 62 21.4L 62 10.8C 62.7 10.6 63.3 10.5 64.1 10.5C 67.4 10.5 69.5 12.3 69.5 16.2C 69.5 19.7 67.8 21.9 64.4 21.9C 63.5 21.9 62.8 21.7 62 21.4ZM 76.9 25.3L 81.3 25.3L 87 17.1C 88.1 15.6 88.9 14.1 89.4 13.3L 89.5 13.3C 89.4 14.4 89.3 15.8 89.3 17.2L 89.3 25.2L 93.8 25.2L 93.8 7.2L 89.4 7.2L 83.7 15.4C 82.7 16.9 81.8 18.4 81.3 19.2L 81.2 19.2C 81.3 18.1 81.4 16.7 81.4 15.3L 81.4 7.3L 76.9 7.3L 76.9 25.3ZM 97.4 25.3L 102 25.3L 102 17.8L 104.7 17.8C 105.2 17.8 105.7 18.3 106.3 19.5L 108.6 25.3L 113.5 25.3L 110.2 18.4C 109.6 17.2 109 16.5 108.1 16.2L 108.1 16.1C 109.6 15.2 109.8 12.6 110.7 11.3C 111 10.9 111.4 10.7 112 10.7C 112.3 10.7 112.7 10.7 113 10.9L 113 7C 112.5 6.8 111.6 6.7 111.1 6.7C 109.5 6.7 108.5 7.3 107.8 8.3C 106.3 10.5 106.3 14.3 104.1 14.3L 102 14.3L 102 7.2L 97.4 7.2L 97.4 25.3ZM 123.7 25.7C 126.2 25.7 128.5 24.9 129.9 23.9L 128.6 20.8C 127.3 21.5 126.1 22 124.4 22C 121.3 22 119.3 20 119.3 16.3C 119.3 13 121.3 10.4 124.7 10.4C 126.5 10.4 127.8 10.9 129.1 11.8L 129.1 7.9C 128.1 7.3 126.5 6.7 124.2 6.7C 118.8 6.7 114.6 10.7 114.6 16.4C 114.6 21.6 117.8 25.7 123.7 25.7Z" transform="translate(0 0.100006)" fill="#0BBBEF"/>
                </g>
                <g id="Vector_3">
                    <path d="M 8.90001 17.8C 13.8 17.8 17.8 13.8 17.8 8.9C 17.8 4 13.8 9.53674e-08 8.90001 9.53674e-08C 4.00001 9.53674e-08 -3.05176e-06 4 -3.05176e-06 8.9C 0.0999969 13.8 4.00001 17.8 8.90001 17.8Z" stroke-width="1.7688" transform="translate(176.2 1.39999)" stroke="#005893"/>
                </g>
                <g id="Vector_4">
                    <path d="M 6.2 4.9L 1.3 4.9L 1.3 9.53674e-08L 0 9.53674e-08L 0 6.2L 6.2 6.2L 6.2 4.9Z" transform="translate(184.5 5.39999)" fill="#005893"/>
                </g>
            </g>
        </g>
    </g>
</template>