<script>
import FormPasswordHide from "../components/icons/FormPasswordHide";
import FormPasswordShow from "../components/icons/FormPasswordShow";
import BaseIcon from "../components/icons/BaseIcon";

export default {
  name: "BaseInput",
  data() {
    return {
      isFocused: false,
      hidePassword: true,
    }
  },
  props: {
    name: String,
    label: String,
    type: {type: String, default: "text"},
    id: String,
    value: String,
    errorMessage: String,
    auth: {type: Boolean, default: false},
    inputClassList: {type: Array, default: () => []},
    labelClassList: {type: Array, default: () => []},
    modelValue: String,
  },
  components: {
    BaseIcon,
    FormPasswordHide,
    FormPasswordShow
  },
  computed: {
    focused() {
      return this.isFocused ? this.isFocused : Boolean(this.$refs.input && this.$refs.input.value)
    },
    checkedType() {
      return this.type === "password" ? !this.hidePassword ? "text" : "password" : this.type
    },
    message() {
      return this.errorMessage ? this.errorMessage : this.label
    }
  },
  methods: {
    togglePasswordShow() {
      this.hidePassword = !this.hidePassword
    },
    log() {
      console.log('SUBMIT FROM INPUT')
    }
  },
  watch: {
    modelValue: {
      handler: function (v) {
        if (v) this.isFocused = true;
      },
      immediate: true,
    },
  },
  mounted() {
    //this.$refs.input.closest('form').addEventListener('submit')
  },
  beforeUnmount() {

  }
}
</script>


<template>
  <div class="sp-input"
       :class="{'sp-input--error': errorMessage && errorMessage.length, 'sp-input--normal': !auth}"
  >
    <label class="sp-input__label" :for="id"
           :class="{'sp-input__label--focused': focused, 'sp-input__label--error': errorMessage && errorMessage.length, ...labelClassList}"
    >
      {{ message }}
    </label>
    <input class="sp-input__input" :type="checkedType" :name="name" :id="id"
           @focus="isFocused = true"
           @blur="isFocused = false"
           :class="inputClassList"
           :style="{'padding-right': (38 + ($refs.actions && $refs.actions.clientWidth)) + 'px'}"
           @input="$emit('update:modelValue', $event.target.value)"
           :value="modelValue"
           ref="input"
           @keydown="$emit('kd', $event)"
    >
    <div class="sp-pointer sp-input__actions"
         ref="actions"
         v-show="type==='password'"
    >
      <base-icon icon-name="showPassword" width="24" height="24"
                 v-show="hidePassword"
                 @click="togglePasswordShow"
      >
        <form-password-show/>
      </base-icon>
      <base-icon icon-name="hidePassword" width="24" height="24"
                 v-show="!hidePassword"
                 @click="togglePasswordShow"
      >
        <form-password-hide/>
      </base-icon>
    </div>
  </div>
</template>


<style lang="scss" scoped>

.sp-input {
  width: 100%;
  border: 1px solid $border;
  border-radius: 8px;
  height: 60px;
  position: relative;
  overflow: hidden;
  background: $componentBg;

  &:not(:first-child) {
    margin-top: 12px;
  }

  $self: &;

  &--error {
    border: 1px solid $error;

    #{$self}__label {
      color: $error;
    }
  }

  &__label {
    top: 21.5px;
    left: 19px;
    @include font(14, 17, bold, $inactiveText);
    transition: font-size .2s, line-height .2s, font-weight .2s;
    position: absolute;
    user-select: none;
    pointer-events: none;

    &--focused {
      top: 11.25px;
      @include font(12, 15, normal, $inactiveText);
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    padding: 6.25px 19px 0;
    border: none;

    @include font(14, 17, bold);
  }

  &__actions {
    position: absolute;
    top: 0;
    right: 19px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &--normal {
    height: 50px;

    & #{$self} {
      &__label {
        top: 15.5px;
        left: 20px;
        @include font(16, 19, normal, $publicationMessage);

        &--focused {
          top: 7px;
          @include font(12, 15, normal, $publicationMessage);
        }
      }

      &__input {
        padding: 10px 19px 0;
        font-weight: normal;
      }
    }
  }
}
</style>