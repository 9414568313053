<script>
import ProjectMenu from "../../components/ProjectMenu";
import SettingsMenu from "../../components/SettingsMenu";
import ProjectSettingsB24UserList from "@/components/ProjectSettingsB24UserList";
//
// import CloseIcon from "../../components/icons/ProjectSettingsB24Remove";
// import EditIcon from "../../components/icons/BaseEdit";
// import BaseInput from "../../components/BaseInputV2";
import BaseLayout from "@/layouts/BaseLayout"
import BaseRequestForm from "@/components/BaseRequestForm";
import BaseRequestFormBtn from "@/components/BaseRequestFormBtn";
// import BaseAvatar from "@/components/BaseAvatar";
import Client from "@/store/models/Client";
import {mapState} from "vuex";
import Project from "@/store/models/Project";
import ProjectSettingsB24SearchResult from "@/components/ProjectSettingsB24SearchResult";

export default {
  name: "SettingsB24",
  data() {
    return {
      form: {
        projectTitle: "",
        whook: "",
        task: "",
        author: "",
      },
      userAddModal: {
        show: false,
        q: "",
        timeout: 0,
        users: [],
      },
      notificationRecieverModal: {
        show: false,
        q: "",
        timeout: 0,
        users: [],
      },
      notification_whook: "",
      validB24Whook: false,
    };
  },
  props: {
    currentProject: Number,
  },
  methods: {
    infoFormResponseHandler(response) {
      Project.insert({data: response.data});
    },
    onUserB24SearchInput(evt, modal) {
      clearTimeout(this.userAddModal.timeout);
      this[modal].timeout = setTimeout(() => {
        const target = evt.target || evt.currentTarget;
        this.$API.get(`b24/users/find?project=${this.currentProject}&q=${target.value}`,).then(response => {
          this[modal].users = response.data.result;
        })
      }, 100)
    },
    onClientB24Remove(response) {
      Client.delete(response.data.deleted)
    }
  },
  computed: {
    clients() {
      return this.project && this.project.clients || []
    },
    recievers() {
      return this.project && this.project.recievers || []
    },
    b24Link() {
      if (!(this.project && this.project.b_whook && this.project.b_whook)) return ""
      const index = this.project.b_whook.indexOf('rest/');
      if (index === -1) return "";
      return this.project.b_whook.slice(0, index);
    },
    ...mapState(["project", 'currentProject', "projectTime"]),
  },
  watch: {
    project: {
      handler: function (v) {
        if (v) {
          this.form.projectTitle = v.title;
          this.form.whook = v.b_whook;
          this.form.author = v.b_user;
          this.form.task = v.b_task;
          this.notification_whook = v.b_notification_whook;
        }
      },
      immediate: true
    }
  },
  components: {
    ProjectMenu,
    ProjectSettingsB24UserList,
    SettingsMenu,
    BaseRequestForm,
    // EditIcon,
    // CloseIcon,
    // BaseInput,
    BaseRequestFormBtn,
    ProjectSettingsB24SearchResult,
    BaseLayout,
    // BaseAvatar,
  },
  mounted() {
     this.$API.get(`b24/users/find?project=${this.currentProject}&q=`,).then(response => {
       if (response.data.error) {
         this.validB24Whook = false;
         return;
       }
        this.userAddModal.users = response.data.result;
        this.validB24Whook = true;
      })
  }
};
</script>

<template>
  <BaseLayout>
    <div class="sp-flex">
      <ProjectMenu :project="project" :project-id="currentProject" :time="projectTime"></ProjectMenu>
      <div class="sp-settings-page">
        <h2 class="sp-settings-page__title">Интеграция Bitrix24</h2>
        <p class="sp-settings-page__description">
          Вебхук B24 необходим для отправки комментариев в задачу о смене стадии публикации и/или составленном отчете.
        </p>
        <BaseRequestForm :response-callback="infoFormResponseHandler" action="projects/change/">
          <input :value="currentProject" name="project" type="hidden">
          <BaseInput id="whook" v-model="form.whook" :height="50" name="whook" title="Вебхук B24"
                     type="password"></BaseInput>
          <BaseInput id="b_task" v-model="form.task" :height="50" name="task" title="ID задачи в битрикс"></BaseInput>
          <BaseInput id="b_author" v-model="form.author" :height="50" name="author"
                     title="ID ответсвенного в битрикс"></BaseInput>
          <BaseRequestFormBtn :max-width="215">Сохранить изменения</BaseRequestFormBtn>
        </BaseRequestForm>
        <br>
        <br>
        <div class="sp-section" :class="{'sp-section--disabled': !(project && project.b_whook && validB24Whook)}">

        <h3 class="sp-settings__title sp-settings__title_h3">Получатели уведомлений о новых комментариях и ошибках в B24</h3>
          <BaseRequestForm :margin-top="20" :response-callback="infoFormResponseHandler" action="projects/b24/notifications/whook">
          <input :value="currentProject" name="project" type="hidden">
            <BaseInput id="notification_whook"
                     v-model="notification_whook"
                     :height="50"
                     name="notification_whook"
                     title="Вебхук с правами im"
                     type="password"
                     @input="onUserB24SearchInput($event, 'notificationRecieverModal')"
          ></BaseInput>
             <BaseRequestFormBtn :max-width="215">Сохранить изменения</BaseRequestFormBtn>
      </BaseRequestForm>
          <div class="sp-settings-page__create sp-btn sp-btn--main_outlined" @click="notificationRecieverModal.show = true">Добавить получателя в B24
        </div>
          <ProjectSettingsB24UserList :clients="recievers" mapping="reciever" :project="project" :currentProject="currentProject"></ProjectSettingsB24UserList>
          <br>
          <br>
        <div v-show="!(project && project.b_whook && validB24Whook)">
          <p style="color: orangered">Чтобы добавлять клиентов из B24 необходимо указать верный Вебхук портала. Убедитесь что у вебхука пристуствуют права на пользователей.</p>
          <br>
        </div>

        <h3 class="sp-settings__title sp-settings__title_h3">Аккаунты клиентов в B24</h3>
        <div class="sp-settings-page__create sp-btn sp-btn--main_outlined" @click="userAddModal.show = true">Добавить
        </div>
        <ProjectSettingsB24UserList :clients="clients" mapping="client" :project="project" :currentProject="currentProject"></ProjectSettingsB24UserList>
<!--          <div class="sp-settings-b24__clients">-->
<!--          <div class="sp-settings-b24-client"-->
<!--               v-for="client in clients"-->
<!--               :key="client.id"-->
<!--          >-->
<!--            <a class="sp-settings-b24-client__link" target="_blank" :href="`${b24Link}company/personal/user/${client.client.b24_id}/`">-->
<!--              <object class="sp-settings-b24-client__info">-->
<!--                <BaseAvatar :radius="25" :text="client.client.name" :photo="client.client.photo"></BaseAvatar>-->
<!--                <p class="sp-settings-b24-client__title">{{ client.client.name }}</p>-->
<!--              </object>-->
<!--            </a>-->
<!--            <div class="sp-settings-b24-client__actions">-->
<!--              <BaseRequestForm action="projects/client/remove" :response-callback="onClientB24Remove">-->
<!--                <button type="submit"-->
<!--                    class="sp-settings-page-item__action sp-btn"-->
<!--                >-->
<!--                  <input type="hidden" name="b24_id" :value="client.client.b24_id">-->
<!--                  <input type="hidden" name="project" :value="currentProject">-->
<!--                  <base-icon height="20"-->
<!--                             width="20"-->
<!--                             icon-name="Удалить"-->
<!--                  >-->
<!--                    <close-icon/>-->
<!--                  </base-icon>-->
<!--                </button>-->
<!--              </BaseRequestForm>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
          <br>

         </div>
      </div>
      <SettingsMenu :currentProject="currentProject"></SettingsMenu>
    </div>
  </BaseLayout>
  <Modal v-model="notificationRecieverModal.show" :close="() => notificationRecieverModal.show = false">
    <div class="sp-modal sp-modal-edit sp-modal-b24settings">
      <p class="sp-modal__title">Поиск пользователя на портале</p>
      <BaseRequestForm :margin-top="20">
        <BaseInput id="nb24_q"
                   v-model="notificationRecieverModal.q"
                   :height="50"
                   name="q"
                   title="Начните вводить для поиска"
                   @input="onUserB24SearchInput($event, 'notificationRecieverModal')"
        ></BaseInput>
      </BaseRequestForm>
      <div class="sp-settings-b24__search_list">
          <ProjectSettingsB24SearchResult
              v-for="user in notificationRecieverModal.users.slice(0, 6)"
              :key="user.ID"
              :user="user"
              :entity="'reciever'"
              @selected="notificationRecieverModal.show = false"
          />
      </div>
    </div>
  </Modal>


  <Modal v-model="userAddModal.show" :close="() => userAddModal.show = false">
    <div class="sp-modal sp-modal-edit sp-modal-b24settings">
      <p class="sp-modal__title">Поиск пользователя на портале</p>
      <BaseRequestForm :margin-top="20">
        <BaseInput id="b42_q"
                   v-model="userAddModal.q"
                   :height="50"
                   name="q"
                   title="Начните вводить для поиска"
                   @input="onUserB24SearchInput($event, 'userAddModal')"
        ></BaseInput>
      </BaseRequestForm>
      <div class="sp-settings-b24__search_list">
          <ProjectSettingsB24SearchResult
              v-for="user in userAddModal.users.slice(0, 6)"
              :key="user.ID"
              :user="user"
              @selected="userAddModal.show = false"
          />
      </div>
    </div>
  </Modal>
</template>

<style lang="scss">
.sp-modal {
  &-b24settings {
    max-width: 685px;
  }
}
.sp-section {
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }
 }
.sp-settings-b24 {
  &__clients {
    margin-top: 20px;
  }

  &__search_list {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  &-search_result {
    width: 100%;
    display: flex;
    align-items: center;

    &__title {
      margin-left: 12px;
      @include font(16, 20, bold, $text);
    }
  }

  &-client {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
    border-top: 1px solid $border;

    &:last-child {
      border-bottom: 1px solid $border;
    }

    &__info {
      display: flex;
      align-items: center;
    }

    &__link {
      text-decoration: unset;
    }

    &__title {
      @include font(16, 20, bold, $text);
      margin-left: 12px;
    }

    &__actions {
      display: flex;
    }
  }
}
</style>