<template>
  <div class="sp-prices">
    <section class="price-table__block">
      <div class="container">
        <div class="inner-container">

          <div class="title__wraper">
            <h1 class="title">Тарифы smm-plan</h1>
          </div>

          <table>
            <tbody>

              <tr class="table-header">
                <td><strong>Базовый</strong></td>
                <td><strong>Стандартный</strong></td>
                <td><strong>Профессиональный</strong></td>
              </tr>

              <tr class="table-row">
                <td>Для эффективной работы небольших компаний и отделов продаж</td>
                <td>Для совместной работы всей компании или рабочих групп</td>
                <td>Для совместной работы всей компании или рабочих групп</td>
              </tr>
              <tr class="table-row">
                <td>1</td>
                <td>50</td>
                <td>100</td>
              </tr>
              <tr class="table-row">
                <td>пользователей</td>
                <td>пользователей</td>
                <td>пользователей</td>
              </tr>
              <tr class="table-row">
                <td>29 880 ₽/год.</td>
                <td>1 494 880 ₽/год.</td>
                <td>2 988 880 ₽/год.</td>
              </tr>
              <tr class="table-row">
                <td>КУПИТЬ</td>
                <td>КУПИТЬ</td>
                <td>КУПИТЬ</td>
              </tr>
              <tr class="table-row">
                <td><strong>24 ГБ</strong></td>
                <td><strong>128 ГБ</strong></td>
                <td><strong>1 ТБ</strong></td>
              </tr>
              <tr class="table-row">
                <td>Совместная работа</td>
                <td>Совместная работа</td>
                <td>Совместная работа</td>
              </tr>
              <tr class="table-row">
                <td>Чат</td>
                <td>Чат</td>
                <td>Чат</td>
              </tr>
              <tr class="table-row">
                <td>CRM</td>
                <td>CRM</td>
                <td>CRM</td>
              </tr>
              <tr class="table-row">
                <td>Диск</td>
                <td>Диск</td>
                <td>Диск</td>
              </tr>
              <tr class="table-row">
                <td>&nbsp;</td>
                <td>Календарь</td>
                <td>Календарь</td>
              </tr>
              <tr class="table-row">
                <td>&nbsp;</td>
                <td>Соцсеть компании</td>
                <td>Соцсеть компании</td>
              </tr>
              <tr class="table-row">
                <td>&nbsp;</td>
                <td>База знаний</td>
                <td>База знаний</td>
              </tr>
              <tr class="table-row">
                <td>&nbsp;</td>
                <td>Поддежка тикеты</td>
                <td>Канбан</td>
              </tr>
              <tr class="table-row">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Поддежка 24/7</td>
              </tr>
              <tr class="table-row">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Маркетинг</td>
              </tr>
              <tr class="table-row">
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>Автоматизация</td>
              </tr>
            </tbody>
          </table>
        </div>      
      </div>
    </section>

  </div>
</template>

<style lang="scss">
@import "src/assets/scss/env";

.sp-prices {
  background-color: $white;
  width: 100%;

  .container {
    margin: 0 auto;
    padding: 0 15px;
    width: 100%;
    max-width: 1250px
  }

  .price-table__block {
    .title {
      color: $text;
      font-weight: 700;
      font-size: 45px;
      padding-bottom: 25px;
      margin-top: 45px;
    }

    td {
      font-size: 15px;
      border-bottom: 1px solid $border1;
      padding: 20px;
    }

    .table-header {
      td {
        color: $text1;
      }
    }

  }
}


</style>
