<template>
  <teleport to="#modals">
    <div v-show="show" class="sp-modal__bg" @click="close">
      <BasePreloader :radius="90" :show="loading" @click.prevent.stop/>
      <div v-show="!loading" class="sp-modal" @click.prevent.stop>
        <div v-if="!error" class="sp-modal-brm">
          <BaseIcon :height="120" :width="120" viewBox="0 0 30 30" iconName="Успешно">
            <SuccessIcon/>
          </BaseIcon>
          <div class="sp-modal__title">{{ successTitle }}</div>
          <div class="sp-modal__description">{{ successDescription }}</div>
        </div>
        <div v-else class="sp-modal-brm">
          <BaseIcon :height="120" :width="120" viewBox="0 0 30 30" iconName="Произошла ошибка">
            <ErrorIcon/>
          </BaseIcon>
          <div class="sp-modal__title">{{ errorTitle }}</div>
          <div class="sp-modal__description">{{ errorDescription }}</div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
import ErrorIcon from '@/components/icons/ProjectPublicationEditorError';
import SuccessIcon from '@/components/icons/ProjectPublicationEditorSuccess';

export default {
  name: "requestModal",
  emits: ['update:modelValue'],
  props: {
    modelValue: {default: false, type: Boolean},
    loading: {default: true, type: Boolean},
    error: {default: false, type: Boolean,},
    successTitle: {default: "Запрос успешно отправлен", type: String},
    successDescription: {default: "", type: String},
    errorTitle: {default: "Произошла ошибка", type: String},
    errorDescription: {default: "Попробуйте позже", type: String}
  },
  components: {
    ErrorIcon,
    SuccessIcon,
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {
    close() {
      this.$emit('update:modelValue', false);
    }
  },
  watch: {
    modelValue: {
      handler: function (v) {
        this.show = v;
      },
      immediate: true
    }
  },
}
</script>

<style scoped>
.sp-modal__bg {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.44);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sp-modal-brm {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>