import { Model } from '@vuex-orm/core'


export default class PublicationComment extends Model{
    static entity = 'publicationComments'

    static primaryKey = ['comment_id', 'publication_id']

    static fields () {
        return {
            comment_id: this.attr("null"),
            publication_id: this.attr("null"),
        }
    }
}