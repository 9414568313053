<script>
    export default {
        props: {
            title: String,
            added: Boolean,
            deleted: Boolean,
        }
    }
</script>
<template>
    <div class="sp-history-list-item__container">
        <p class="sp-history-list-item__title"
           :class="{'sp-history-list-item__title_deleted': deleted, 'sp-history-list-item__title_created': added}"
        >
            {{title}}
        </p>
        <div class="sp-history-list-changes__body">
            <div class="sp-history-list-changes-section sp-history-list-changes__before" v-show="!added">
                <p class="sp-history-list-changes__title" v-show="!deleted">До</p>
                <div class="sp-history-list-changes-section__items">
                    <slot name="before"></slot>
                </div>
            </div>
            <div class="sp-history-list-changes-section sp-history-list-changes__after" v-show="!deleted">
                <p class="sp-history-list-changes__title" v-show="!added">После</p>
                <div class="sp-history-list-changes-section__items">
                    <slot name="after"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import "src/assets/scss/env";

    .sp-history-list-changes {
        display: flex;
        border: 1px solid $border;
        border-radius: 8px;
        min-width: 90%;
        margin: 5px 0;
        padding: 5px;
        align-items: center;
        @include font(16, 18);
        &__body {
            display: flex;
        }
        & > .sp-account {
            transform: scale(.8);
            min-width: 47px;
            margin-right: 12px;
        }
        &-section {
            width: 50%;
            &__items {
                display: flex;
                flex-flow: row wrap;
            }
        }
    }
</style>