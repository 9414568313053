<script>
import { publicationTypes } from "@/logic/enums";
/*import rangy from "rangy"
import 'rangy/lib/rangy-selectionsaverestore';
 */
import {server} from "@/plugins/axios"
import BaseDraggableWindow from "@/components/BaseDraggableWindow";

export default {
  data() {
    return {
      publicationTypes,
      input: {
        placeholder: "Сообщение",
        focused: false,
        selection: null,
        backSkip: false,
        updateTimeout: 0,
      },
      debug: [],
      emojiRoot: `https://${server}/static/emoji/`,
    };
  },
  props: {
    modelValue: String,
    type: Number,
    emoji: Object,
    editMessage: String,
    caption: String,
  },
  components: {
    BaseDraggableWindow,
  },
  methods: {
    onInput() {
        document.execCommand("defaultParagraphSeparator", false, "");
        // if (window.typo) {
        //   window.typo.process();
        // }
    },
    onKeyUp() {
      this.setSelection()
      console.log(this.$refs.textarea.childNodes)
      this.debug = Array.from(this.$refs.textarea.childNodes);
      clearTimeout(this.updateTimeout)
      this.updateTimeout = setTimeout(() => {
        this.$emit('update:modelValue', this.$refs.textarea.innerHTML)
      }, 100)
    },
    onPaste(e) {
      let clipboardData, pastedData;
      e.stopPropagation();
      e.preventDefault();
      clipboardData = e.clipboardData || window.clipboardData;
      const htmlData = clipboardData.getData('text/html');
      pastedData = "";
      if (htmlData.search(/(?<img><img.*?data-emoji=".*?".*?>)/gm) !== -1) {
        pastedData = htmlData;
      } else {
        pastedData = clipboardData.getData('Text')
      }
      console.log("PASTED TEXT BEFORE", clipboardData.getData('Text'))
      console.log("PASTED DATA BEFORE", pastedData)
      pastedData = pastedData.replace(/<br.*?>/gm, '\n')
      pastedData = pastedData.replace(/<p.*?>/gm, '\n')
      pastedData = pastedData.replace(/<\/p>/gm, '')
      pastedData = pastedData.replace(/<!--EndFragment-->|<!--StartFragment-->/gm, '')
      // pastedData = pastedData.replace(/(?<img><img.*?data-emoji=".*?".*?>)|<.*?>/gm, '$1').trim()
      // pastedData = pastedData.replace(/(?<img><img.*?data-emoji=".*?".*?>)|<.*>(?<other>.*)<\/.*>/gm, '$1$2').trim()

      //pastedData = pastedData.replace(/<.*?>(?<other>\s*?[^<].*?)<\/\w+>/gm, '$<other>')
      //pastedData = pastedData.replace(/<.*?>|<\/.*?>/gm, '')
      // pastedData = pastedData.replace(/<img.*?data-emoji=".*?".*?>/gm, '').trim()
      // pastedData = pastedData.replace(/<.*?(?<!data-emoji=".*?".*?)>/gm, '').trim()
      console.log("PASTED DATA AFTER", pastedData)
      document.execCommand("insertHTML", false, pastedData);
      this.$emit("update:modelValue", this.$refs.textarea.innerHTML)
    },
    node_walk(node, func) {
      let result = func(node);
      for(node = node.firstChild; result !== false && node; node = node.nextSibling)
        result = this.node_walk(node, func);
      return result;
    },
    getCaretPosition(elem) {
      let sel = window.getSelection();
      let cum_length = [0, 0];

      if(sel.anchorNode == elem)
        cum_length = [sel.anchorOffset, sel.extentOffset];
      else {
        let nodes_to_find = [sel.anchorNode, sel.extentNode];
        if(!elem.contains(sel.anchorNode) || !elem.contains(sel.extentNode))
          return undefined;
        else {
          let found = [0,0];
          let i;
          this.node_walk(elem, function(node) {
            for(i = 0; i < 2; i++) {
              if(node === nodes_to_find[i]) {
                found[i] = true;
                if(found[i === 0 ? 1 : 0])
                  return false; // all done
              }
            }

            if(node.textContent && !node.firstChild) {
              for(i = 0; i < 2; i++) {
                if(!found[i])
                  cum_length[i] += node.textContent.length;
              }
            }
          });
          cum_length[0] += sel.anchorOffset;
          cum_length[1] += sel.extentOffset;
        }
      }
      if(cum_length[0] <= cum_length[1])
        return cum_length;
      return [cum_length[1], cum_length[0]];
    },
    setSelection(evt) {
      if (evt) {
        const target = evt.target || evt.currentTarget;
        if (target.closest(".sp-pub_editor-form-message-emoji")) return;
      }
      this.input.selection = null;
      this.input.selection = this.selectionCopy(document.getSelection());
      console.log('SELECTION CHANGED', this.input.selection)
      let input = this.$refs.textarea;
      console.log(this.getCaretPosition(input));
    },
    selectionCopy(selection) {
      // for safari support
      return {
        anchorNode: selection.anchorNode,
        anchorOffset: selection.anchorOffset,
        baseNode: selection.baseNode,
        baseOffset: selection.baseOffset,
        extentNode: selection.extentNode,
        extentOffset: selection.extentOffset,
        focusNode: selection.focusNode,
        focusOffset: selection.focusOffset,
        isCollapsed: selection.isCollapsed,
        rangeCount: selection.rangeCount,
        type: selection.type,
        setBaseAndExtent: selection.setBaseAndExtent,
      };
    },
  },
  computed: {
    message: function () {
      return this.input.focused || this.modelValue
        ? this.modelValue
        : this.input.placeholder;
    },
  },
  watch: {
    emoji() {

      /*`<img src="${this.emojiRoot}ebg.png" data-emoji="${emoji['symbol']}" style="background: url('${this.emojiRoot}${emoji[this.socialSelector.currentTitle]}') ${-emoji.offset[1]}px ${-emoji.offset[0]}px;width: 16px;height: 16px;display: inline-block;vertical-align: middle;">`

       */
    },
    caption(v) {
      if (v) this.$emit('captionBeforePaste')
      else return
      let input = this.$refs.textarea;
      input.append(v)
      this.$emit('update:modelValue', this.$refs.textarea.innerHTML)

    },
    // "input.selection": {
    //   handler: function (v) {
    //     // setInterval(() => {
    //     //   console.log("SELECTION MONITORING", v);
    //     // }, 500)
    //
    //   },
    // },
    "emoji.social": {
      handler: function (currentTitle) {
        let message = this.$refs.textarea.childNodes
        if (!message || !message.length) return
        for (let node of message) {
          if (node.tagName === "IMG") {
            let filepath = node.style.background.match(/".*"/g)[0].split('_'),
                    coords = node.style.background.matchAll(/\)\s.*/g).next().value[0].split(' ').slice(-2);
            filepath[1] = currentTitle
            node.style.background = `url(${filepath.join('_')}) ${coords[0]} ${coords[1]}`
          }
        }
      }
    },
    "emoji.current": {
      handler: function (emoji) {
        if (!emoji) return;
        let emojiElement = document.createElement("img");
        emojiElement.src = `${this.emojiRoot}ebg.png`;
        emojiElement.style.background = `url('${this.emojiRoot}${emoji[this.emoji.social]}') ${-emoji.offset[1]}px ${-emoji.offset[0]}px`;
        emojiElement.style.width = "20px";
        emojiElement.style.height = "20px";
        emojiElement.style.transform = "scale(0.9)";
        emojiElement.style.display = "inline-block";
        emojiElement.style.verticalAlign = "middle";
        emojiElement.style.pointerEvents = "none";
        emojiElement.dataset.emoji = emoji["symbol"]
        let input = this.$refs.textarea;
        if (input.hasChildNodes()
                && input.childNodes.length > 2
                && (
                        input.childNodes[input.childNodes.length - 1].tagName === "BR"
                        || Array.from(input.childNodes).splice(-2).filter(v => v.textContent==="\n").length === 2 )) {
          input.removeChild(input.childNodes[input.childNodes.length - 1])
        }
        let selection = this.input.selection;
        console.log("SELECTION IN PASTE METHOD", selection);
        console.log(selection, selection.focusNode === input)
        if (
          selection &&
          (selection.focusNode === input ||
            (selection.focusNode &&
              selection.focusNode.parentElement === input)) &&
          selection.focusNode.parentElement === input
        ) {
          if (selection.focusNode.nodeType === 3) {
            if (selection.focusNode.textContent.length - 1 !== selection.focusOffset) {
              let newNode = selection.focusNode.splitText(selection.focusOffset);
              input.insertBefore(emojiElement, newNode)
              console.log(1)
              console.log(input.childNodes, selection.focusNode, selection.focusNode.nextSibling, )
              if (selection.focusNode.nextSibling) {
                const focusNodeIndex = Array.prototype.indexOf.call(input.childNodes, selection.focusNode);
                console.log("FNI", focusNodeIndex);
                window.getSelection().setBaseAndExtent(input, focusNodeIndex + 2, input, focusNodeIndex + 2)

                console.log("NEW SELECTION", window.getSelection())
                this.setSelection()
              } else {
                window.getSelection().setBaseAndExtent(input, input.childNodes.length -1, input, input.childNodes.length -1)
                console.log("NEW SELECTION", window.getSelection())
                this.setSelection()
              }

            } else {
              input.insertBefore(emojiElement, selection.focusNode.nextSibling)
              console.log(2)
            }
          } else {
            input.insertBefore(emojiElement, selection.focusNode)
            console.log(3)
          }
        } else if (selection.focusNode === input) {
          console.log(5)
          input.insertBefore(emojiElement, input.childNodes[selection.focusOffset])
          window.getSelection().setBaseAndExtent(input, selection.focusOffset + 1, input, selection.focusOffset + 1)
          console.log("NEW SELECTION", window.getSelection())
          this.setSelection()
        } else {
          input.append(emojiElement)
          console.log(4)
        }
        this.$emit('update:modelValue', this.$refs.textarea.innerHTML)
        this.$emit("onEmojiPasted");
      }
    },
    editMessage: {
      handler: function (v) {
        this.$nextTick(() => {
          this.input.focused = true;
          this.$refs.textarea.innerHTML = v;
          this.$emit('update:modelValue', this.$refs.textarea.innerHTML);
        })

      },
      immediate: true,
    }
  },
};
</script>
<template>
    <BaseDraggableWindow v-if="false">
      <div class="dcolumn">
      <div class="dselection" v-if="input.selection">
        <div class="dselection-target" >
          <div class="debug__row">{{input.selection.anchorNode.nodeName}}</div>
          <div class="debug__row">{{input.selection.anchorNode.textContent}}</div>
          <div class="debug__row">{{input.selection.anchorNode.nodeValue}}</div>
          <div class="debug__row">next: {{input.selection.anchorNode.nextSibling}}</div>
          <div class="debug__row">prev: {{input.selection.anchorNode.previousSibling}}</div>
        </div>
          <div class="debug__row">offset: {{input.selection.anchorOffset}}</div>
          <div class="debug__row">isCollapsed: {{input.selection.isCollapsed}}</div>
          <div class="debug__row">range: {{input.selection.rangeCount}}</div>
          <div class="debug__row">type: {{input.selection.type}}</div>
        {{input.selection}}
      </div>
      <div style="display: flex;flex-flow: row wrap">
        <div class="debug" v-for="elem in debug" :key="elem">
          <div class="debug__row">{{elem.nodeName}}</div>
          <div class="debug__row">{{elem.textContent}}</div>
          <div class="debug__row">{{elem.nodeValue}}</div>
          <div class="debug__row">next: {{elem.nextSibling}}</div>
          <div class="debug__row">prev: {{elem.previousSibling}}</div>
        </div>
      </div>
        </div>
    </BaseDraggableWindow>
  <div
    contenteditable="true"
    class="sp-pub_editor-form-message"
    ref="textarea"
    @focus="input.focused = true"
    @blur="input.focused = false"
    @focusout="input.focused = false"
    @input="onInput"
    @keyup="onKeyUp"
    @paste="onPaste"
    @click="setSelection"
  >

  </div>
    <p class="sp-pub_editor-form-message__ph"
       :class="{'sp-pub_editor-form-message__ph--focused': input.focused || $refs.textarea && $refs.textarea.innerText.trim().length}"
    >
      Сообщение
    </p>
</template>
<style lang="scss">
  .debug {
    width: 20%;
    border: 1px solid red;
    &__row {
      font-size: 12px;
    }

  }
  .dcolumn {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .dselection {
    border-bottom: 4px solid aqua;
    &-target {
      border: 1px solid coral;
    }
    &__row {
      font-size: 12px;
    }
  }
</style>
