<script>
import BaseIcon from "./icons/BaseIcon";
import NotifyIcon from "./icons/Notify"
import {PerfectScrollbar} from 'vue3-perfect-scrollbar'
import HeaderNotifyApproved from "./HeaderNotificationsNotifyApproved";
import HeaderNotifyError from "./HeaderNotificationsNotifyError";
import HeaderNotifyPlaceholder from "./HeaderNotificationsNotifyPlaceholder";
import Notification from "../store/models/Notification";
import {mapState} from "vuex";
import {server} from "@/plugins/axios";


export default {
  components: {
    BaseIcon,
    NotifyIcon,
    PerfectScrollbar,
    HeaderNotifyPlaceholder,
  },
  data() {
    return {
      notificationsInfo: {
        show: false,
        checked: [],
        audio: new Audio('https://' + server + '/static/SPNOTIFY3000.mp3'),
        components: {
          1: HeaderNotifyApproved,
          2: HeaderNotifyError,
        },
        updInterval: 0,
        lastUncheckedCount: 0,
      }
    }
  },
  methods: {
    toggleModal(e) {
      let target = e.target || e.currentTarget;
      if (target.closest('.sp-header-notifications__modal')) return;
      this.notificationsInfo.show = !this.notificationsInfo.show;
    },
    checkNotification(notification) {
      if (!this.notificationsInfo.checked.includes(notification.id)) {
        this.notificationsInfo.checked.push(notification.id)
        if (this.notificationsInfo.checked.length > 100) {
          this.notificationsInfo.checked.shift()
        }
        localStorage.checkedNotifications = JSON.stringify(this.notificationsInfo.checked);
      }
    },
    closeModal(e) {
      let target = e.target || e.currentTarget;
      if (target.closest('.sp-header-notifications')) return;
      this.notificationsInfo.show = false;
    },
    updateNotifications() {
      let formData = new FormData()
      formData.append('project', this.currentProject)
      if (this.notifications[0]) {
        formData.append('notification', this.notifications[0].id)
      }
      this.$API.post('projects/notifications/update', formData).then(response => {
        if (response.data.length) {
          Notification.insert({data: response.data})
        }
      })
    },
    openNotificationsPage() {
      this.notificationsInfo.show = false
      this.$router.push({name: 'notifications', params: {'currentProject': this.currentProject,}})
    }
  },
  computed: {
    notifications() {
      return this.$store.getters['entities/notifications/query']()
          .where('project', this.currentProject).orderBy('id', 'desc').withAllRecursive().get().slice(0, 10)
    },
    hasUnchecked() {
      let notifications = this.notifications.map(v => v.id),
          checked = this.notificationsInfo.checked;
      let intersection = notifications.filter(v => !checked.includes(v))
      return intersection.length
    },
    ...mapState(['currentProject']),
  },
  watch: {
    hasUnchecked(v) {
      if (v && v > this.notificationsInfo.lastUncheckedCount) {
        this.notificationsInfo.audio.volume = 0.1
        this.notificationsInfo.audio.play();
      }
      this.notificationsInfo.lastUncheckedCount = v;
    }
  },
  beforeMount() {
    if (localStorage.checkedNotifications) {
      this.notificationsInfo.checked = JSON.parse(localStorage.checkedNotifications)
    }
    document.addEventListener('click', this.closeModal);
    this.notificationsInfo.updInterval = setInterval(this.updateNotifications, 5000)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeModal);
    clearInterval(this.notificationsInfo.updInterval)
  }
}
</script>
<template>
  <div class="sp-header-notifications"
       @click="toggleModal"
  >
    <base-icon height="40"
               width="40"
               icon-name="Уведомления"
    >
      <notify-icon :checked="!hasUnchecked"></notify-icon>
    </base-icon>
    <div class="sp-header-notifications__modal" v-show="notificationsInfo.show">
      <PerfectScrollbar class="sp-header-notifications__scrollbar"
                        shallowRef="scrollContainer"
                        :options="{minScrollbarLength: 40}"
      >
        <div class="sp-header-notify"
             :class="{'sp-header-notify_unchecked': !notificationsInfo.checked.includes(notify.id)}"
             v-for="notify in notifications"
             :key="notify.id"
             @mouseenter="checkNotification(notify)"
        >
          <component :is="notificationsInfo.components[notify.type]" :notify="notify"></component>
        </div>
        <div class="sp-header-notify" v-show="notifications && !notifications.length">
          <HeaderNotifyPlaceholder/>
        </div>
        <div class="sp-header-notifications__more sp-btn sp-btn--optional"
             v-show="notifications&& notifications.length"
             @click="openNotificationsPage"
        >
          Еще уведомления
        </div>
      </PerfectScrollbar>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-header {

  height: $headerHeight;
  flex: 1;
  display: flex;
  padding: 12px 60px 12px 37px;
  align-items: center;
  justify-content: flex-end;

  &-notify {
    background: $white;

    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }

    &_unchecked {
      background: rgba(214, 255, 91, .13) !important;
    }

    display: flex;
    align-items: center;
    padding: 10px 15px;

  }

  &-notifications {
    margin-right: 16px;
    cursor: pointer;
    position: relative;

    &__more {
      height: 40px;
      margin: 20px 10px;
      width: calc(100% - 20px);
    }

    &__modal {
      position: absolute;
      width: 450px;
      right: -5px;
      top: 100%;
      background: $white;
      border: 1px solid $border;
      border-radius: 8px;
      cursor: default;
      z-index: 2;
    }

    &__scrollbar {
      min-width: 312px;
      height: 400px;
    }
  }
}
</style>