<template>
  <button class="sp-btn--main sp-btn brf-btn" type="submit"><slot></slot></button>
</template>

<script>
export default {
  name: "BaseRequestFormBtn",
  props: {
    height: {default: 50, type: Number},
    marginTop: {default: 15, type: Number},
    maxWidth: {default: -1, type: Number},
  },
  computed: {
    cssH() {
      return `${this.height}px`;
    },
    cssMarginTop() {
      return `${this.marginTop}px`;
    },
    cssMaxWidth() {
      return this.maxWidth !== -1 ? `${this.maxWidth}px` : 'unset';
    }
  }
}
</script>

<style lang="scss" scoped>
.brf-btn {
  margin-top: v-bind(cssMarginTop);
  height: v-bind(cssH);
  max-width: v-bind(cssMaxWidth);
}
</style>