import { Model } from '@vuex-orm/core'
import Member from "./Member";
import Account from "./Account";

export default class Notification extends Model {
    static entity = "notifications"
    static fields () {
        return {
            id: this.attr(null),
            project: this.attr(null),
            member_id: this.attr(null),
            account_id: this.attr(null),
            account: this.belongsTo(Account, 'account_id'),
            member: this.belongsTo(Member, 'member_id'),
            date: this.attr(null),
            type: this.attr(null),
            title: this.attr(null),
            description: this.attr(null),
        }
    }

}