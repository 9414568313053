<script>
    import {idToShortCode} from "@/logic/enums";
    import { shortTitle } from "@/logic/tools";
    import {server} from "@/plugins/axios";

    import BaseIcon from "./icons/BaseIcon";
    import VkIcon from "./icons/SocialMiniVk";
    import OkIcon from "./icons/SocialMiniOk";
    import AccountWarnIcon from "./icons/BaseWarnMini"
    import ImIcon from "./icons/SocialMiniInsta";
    import TgIcon from "./icons/SocialMiniTg";

    export default {
        data() {
            return {
                idToShortCode,
                staticImgSrcPlaceholder: `https://${server}/static/iph.png`,
            }
        },
        props: {
            account: Object,
            disableErrors: {
                type: Boolean,
                default: false,
            }
        },
        components: {
            BaseIcon,
            VkIcon,
            OkIcon,
            ImIcon,
            TgIcon,
            AccountWarnIcon,
        },
        methods: {
            shortTitle,
        }
    }
</script>

<template>
    <div
        class="sp-account"
        :class="{'sp-btn': clickable}"
        role="img"
        :alt="account.title"
    >
        <img class="sp-account__bg"
             :src="account.photo || staticImgSrcPlaceholder"
             :alt="account.title"
             v-show="account.photo"
        >
        <p class="sp-account__title"
           v-show="!account.photo"
        >
            {{ shortTitle(account.title) }}
        </p>

        <div class="sp-account-error" v-show="!disableErrors && account.error">
            <base-icon height="17"
                       width="17"
                       icon-name=""
                       view-box="1 1 19 19"
            >
                <account-warn-icon/>
            </base-icon>
            <div class="sp-account-error-container">
                <div class="sp-account-error-info">
                    <p class="sp-account-error-info__title">
                        {{account.error && account.error.title ? account.error.title : ""}}
                    </p>
                    <p class="sp-account-error-info__text">
                        {{account.error && account.error.description ? account.error.description : ""}}
                    </p>
                </div>
            </div>
        </div>
        <base-icon
                height="20"
                width="20"
                :icon-name="idToShortCode[account.social][0]"
                view-box="0 0 41 41"
                class="sp-account__social"
        >
            <component :is="idToShortCode[account.social][1]"></component>
        </base-icon>
    </div>
</template>

<style lang="scss">
    @import "src/assets/scss/env";
    .sp-account {
        border-radius: 50%;
        position: relative;
        width: 47px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $publicationsAccountBg;
        z-index: 0;
        &__title {
            @include font(15,18,bold,$white);
            user-select: none;
        }
        &__bg {
            width: 100%;
            height: 100%;
            border-radius: 50%;
        }
        &-error {
            position: absolute;
            top: -5px;
            right: -3.15px;
            z-index: 1;
            width: 20px;
            height: 20px;
            border: 1px solid $border;
            border-radius: 50%;
            display: flex;
            background: #fff;
            align-items: center;
            justify-content: center;
            &-container {
                position: relative;
            }
            &-info {
                display: none;
                position: absolute;
                min-width: 200px;
                max-width: 400px;
                left: 100%;
                bottom: 100%;
                background: $white;
                padding: 10px 15px !important;
                border: 1px solid $border;
                border-radius: 8px;
                z-index: 2;
                &__title {
                    @include font_important(12,14,bold,$statusError);
                }
                &__text {
                    margin-top: 8px !important;
                    @include font_important(14,16);
                }
            }
            $self: &;
            &:hover {
                #{$self}-info {
                    display: block;
                }
            }
            & path {
                fill: $statusError;
            }
        }
        & > .sp-account__social {
            position: absolute;
            z-index: 1;
            bottom: -5px;
            right: -3.15px;
        }

    }
</style>