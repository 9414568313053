<script>
    import BaseIcon from "./icons/BaseIcon";
    import ArrowIcon from "./icons/BaseArrow";
    import ProjectPublicationHistoryList from "./ProjectPublicationHistoryList";

    export default {
        props: {
            show: Boolean,
            close: Function,
            publication: Object,
        },
        components: {
            ArrowIcon,
            BaseIcon,
        },
        data() {
            return {
                showModal: false,
                details: true,
                detailsDynamicComponents: {
                    true: ProjectPublicationHistoryList,
                    false: ProjectPublicationHistoryList,
                },
                detailsItem: {}
            }
        },
        watch: {
            show: {
                handler: function (v) {
                    this.showModal = v;
                },
                immediate: true
            }
        }
    }
</script>

<template>
    <Modal v-model="showModal" :close="close">
        <div class="sp-modal sp-history">
            <div class="sp-history__back sp-btn"
                 @click="close"
            >
                <base-icon height="14" width="14">
                    <arrow-icon/>
                </base-icon>
                Назад
            </div>
            <div class="sp-history__content">
                <component :is="detailsDynamicComponents[details]"
                           :publication="publication"
                />
            </div>
        </div>
    </Modal>
</template>

<style lang="scss" scoped>
    @import "src/assets/scss/env";
    .sp-history {
        height: 90vh;
        width: 600px;
        &__content {
            height: calc(100% - 75px);
            width: 100%;
            display: flex;
            padding: 0 25px 30px;
            justify-content: center;
            overflow-y: scroll;
            align-items: center;
        }
        &__back {
            padding: 15px;
            width: unset;
            justify-content: unset;
            display: flex;
            align-items: center;
            & > svg {
                margin-right: 12px;
                transform: rotate(180deg);
            }
            @include font(16, 18, null, $projectMenuTime);
        }
    }
</style>