<template>
  <div class="content-text">
    <h1>ЛИЦЕНЗИОННОЕ СОГЛАШЕНИЕ</h1>
    <h2>НА ИСПОЛЬЗОВАНИЕ ПРОГРАММЫ ДЛЯ ЭВМ</h2>
    <p>«#ВсеПоПлану - Кабинет клиента»</p>
    <p>
      Уважаемый Пользователь! Перед началом установки, копирования либо иного использования ПО внимательно ознакомьтесь
      с условиями настоящего Соглашения, являющегося стандартной формой договора присоединения и заключаемого в
      письменной или иной форме, предусмотренной действующим законодательством Российской Федерации. Если вы не согласны
      с условиями настоящего Соглашения, вы не можете использовать ПО. Установка, запуск или иное начало использования
      ПО означает Ваше полное согласие со всеми условиями настоящего Соглашения и его надлежащее заключение в порядке,
      предусмотренном в пункте 3 статьи 1286 Гражданского Кодекса Российской Федерации. Настоящее Соглашение является
      юридически обязательным соглашением, если Вы не согласны принять на себя его условия, Вы не имеете права
      устанавливать ПО и должны удалить все его компоненты со своего портала Битрикс24.
    </p>
    <p>
      Настоящее Лицензионное соглашение (далее – Соглашение) заключается между ИП Кузнецов Сергей Васильевич (далее –
      Лицензиар) и Пользователем (любым физическим лицом, индивидуальным предпринимателем, юридическим лицом (далее –
      Пользователь) Программы для ЭВМ «#ВсеПоПлану - Кабинет клиента» (далее – ПО).
    </p>
    <p>
      Основные термины настоящего Соглашения:
    </p>
    <p>
      ПО – программа для ЭВМ «#ВсеПоПлану - Кабинет клиента» (как в целом, так и ее компоненты), исключительные
      имущественные права на которую на территории, определенной в п. 1.4. Соглашения, принадлежат Лицензиару;
    </p>
    <p>
      Демо-версия ПО – версия ПО «#ВсеПоПлану - Кабинет клиента», в которой установлено ограничение по сроку ее
      использования и которая предназначена исключительно для самостоятельного ознакомления Пользователем с
      функциональными возможностями ПО на условиях настоящего Соглашения и не предназначена для продажи или иного
      отчуждения третьим лицам.
    </p>
    <h3>1. Предмет СОГЛАШЕНИЯ</h3>
    <ol class="--children-ls-n">
      <li>
        <span>1.1.</span>
        В порядке и на условиях, предусмотренных настоящим Соглашением, Лицензиар предоставляет Пользователю право
        использования ПО (простая неисключительная лицензия), реализуемое путем установки (инсталляции) и запуска
        Пользователем ПО в соответствии с его технической документацией и условиями настоящего Соглашения.
      </li>
      <li>
        <span>1.2.</span>
        Все положения настоящего Соглашения распространяются как на ПО в целом, так и на его отдельные компоненты. ПО
        лицензируется как единая программа для ЭВМ, его компоненты не могут быть разделены и использоваться на разных
        порталах Битрикс24.
      </li>
      <li>
        <span>1.3.</span>
        Настоящее Соглашение заключается до или непосредственно в момент начала использования ПО и действует на
        протяжении всего срока действия исключительного права Лицензиара на ПО, при условии надлежащего выполнения
        Пользователем условий настоящего Соглашения.
      </li>
      <li>
        <span>1.4.</span>
        Лицензиар предоставляет Пользователю право использования ПО на территории следующих стран Российская Федерация,
        Украина, Республика Беларусь, Республика Казахстан, Киргизская Республика, Республика Узбекистан, Туркменистан,
        Республика Таджикистан, Литовская Республика, Латвийская Республика, Эстонская Республика, Республика Молдова,
        Грузия, Республика Армения, Республика Азербайджан на условиях и в порядке, предусмотренных действующим
        законодательством Российской Федерации и настоящим Соглашением.
      </li>
    </ol>
    <h3>2. Авторские права</h3>
    <ol class="--children-ls-n">
      <li>
        <span>2.1.</span>
        ПО является результатом интеллектуальной деятельности и объектом авторских прав как программа для ЭВМ, которые
        регулируются и защищены законодательством Российской Федерации об интеллектуальной собственности и нормами
        международного права.
      </li>
      <li>
        <span>2.2.</span>
        ПО содержит коммерческую тайну и иную конфиденциальную информацию, принадлежащую Лицензиару. Любое использование
        ПО в нарушение условий настоящего Соглашения рассматривается как нарушение прав Лицензиара и является
        достаточным основанием для лишения Пользователя предоставленных по настоящему Соглашению прав.

      </li>
      <li>
        <span>2.3.</span>
        Лицензиар гарантирует, что обладает всеми правами использования ПО, включая документацию к ней, необходимыми для
        предоставления Пользователю прав на использование ПО по настоящему Соглашению.
      </li>
      <li>
        <span>2.4.</span>
        В случае нарушения авторских прав предусматривается ответственность в соответствии с действующим
        законодательством Российской Федерации.
      </li>
    </ol>
    <h3>3. Условия использования ПО и ограничения</h3>
    <ol class="--children-ls-n">
      <li>
        <span>3.1.</span>
        Настоящее Соглашение предоставляет право установки (инсталляции), запуска и использования законно приобретенной
        одной копии ПО в рамках его функциональных возможностей на одном портале Битрикс24.
      </li>
      <li>
        <span>3.2.</span>
        Пользователь имеет право, уведомив Лицензиара, однократно уступить (передать) свои права и обязанности по
        настоящему Соглашению другому Пользователю в полном объеме, кроме предусмотренного в настоящем пункте Соглашения
        права последующей уступки (передачи) прав по настоящему Соглашению другим Пользователям, что ограничивает
        возможность повторной передачи прав по настоящему Соглашению. Указанная уступка (передача) прав и обязанностей
        осуществляется при условии полного и безоговорочного согласия нового пользователя со всеми положениями и
        условиями настоящего Соглашения. Передавая права использования ПО, Пользователь обязуется полностью уничтожить
        все копии ПО, установленные на компьютерах Пользователя, включая резервные копии. Пользователь обязан
        предоставить полные данные нового Пользователя для перерегистрации на него прав использования ПО в соответствии
        с настоящим Соглашением.
      </li>
      <li>
        Уступка (передача) прав по настоящему Соглашению не может быть осуществлена (i) косвенно или через какое-либо
        третье лицо, а также (ii) в случае использования Пользователем Демо-версии ПО, в отношении которой
        устанавливается полный запрет отчуждения первоначальным Пользователем.
      </li>
      <li>
        <span>3.3.</span>
        Пользователь вправе изменять, добавлять или удалять любые файлы приобретенного ПО только в случаях,
        предусмотренных Законодательством Российской Федерации об авторском праве.
      </li>
      <li>
        <span>3.4.</span>
        Запрещается удалять любую информацию об авторских правах.
      </li>
      <li>
        <span>3.5.</span>
        Запрещается любое использование ПО, противоречащее действующему законодательству Российской Федерации.
      </li>
    </ol>
    <h3>4. Ответственность сторон</h3>
    <ol class="--children-ls-n">
      <li>
        <span>4.1.</span>
        За нарушение условий настоящего Соглашения наступает ответственность, предусмотренная законодательством
        Российской Федерации.
      </li>
      <li>
        <span>4.2.</span>
        Лицензиар не несет ответственности перед Пользователем за любой ущерб, любую потерю прибыли, информации или
        сбережений, связанных с использованием или с невозможностью использования ПО, даже в случае предварительного
        уведомления со стороны Пользователя о возможности такого ущерба, или по любому иску третьей стороны.
      </li>
    </ol>
    <h3>5. Ограниченная гарантия</h3>
    <ol class="--children-ls-n">
      <li>
        <span>5.1.</span>
        Лицензиар предоставляет Пользователю право получения Технической поддержки консультирования Пользователя по
        вопросам, связанным с функциональностью ПО, особенностями установки и эксплуатации на стандартных конфигурациях
        поддерживаемых (популярных) операционных, почтовых и иных систем на условиях и в течение всего срока действия
        настоящего Соглашения, а также в соответствии с действующим законодательством Российской Федерации без выплаты
        дополнительного вознаграждения.
      </li>
      <li>
        <span>5.2.</span>
        Все обновления ПО являются ее неотъемлемой частью и используются исключительно вместе с ПО как единая программа
        для ЭВМ в порядке, предусмотренном в настоящем Соглашении, если иные условия использования таких обновлений не
        будут предусмотрены в отдельном лицензионном договоре.
      </li>
      <li>
        <span>5.3.</span>
        Если при использовании ПО будут обнаружены ошибки, Лицензиар обязуется исправить их в максимально короткие сроки
        и выпустить новую, исправленную версию ПО. Стороны соглашаются, что точное определение срока устранения ошибки
        не может быть установлено, так как ПО тесно взаимодействует с другими программами для ЭВМ сторонних
        разработчиков, операционной системой и аппаратными ресурсами компьютера Пользователя, и работоспособность и
        время устранения проблем в полной мере не зависят только от Лицензиара.
      </li>
      <li>
        <span>5.4.</span>
        В случае несоблюдения любого из пунктов раздела 3 настоящего Соглашения, Пользователь автоматически теряет право
        на получение обновлений (новых версий) ПО.
      </li>
    </ol>
    <h3>6. Действие, изменение и расторжение СОГЛАШЕНИЯ</h3>
    <ol class="--children-ls-n">
      <li>
        <span>6.1.</span>
        Настоящее Соглашение заключено и подлежит толкованию в соответствии с законодательством Российской Федерации.
      </li>
      <li>
        <span>6.2.</span>
        В случае нарушения Пользователем условий настоящего Соглашения по использованию ПО Лицензиар имеет право в
        одностороннем порядке расторгнуть настоящее Соглашение, уведомив об этом Пользователя.
      </li>
      <li>
        <span>6.3.</span>
        При расторжении настоящего Соглашения Пользователь обязан прекратить использование ПО полностью и уничтожить все
        копии ПО, установленные на своем портале Битрикс24, включая резервные копии и все компоненты ПО.
      </li>
      <li>
        <span>6.4.</span>
        Пользователь вправе расторгнуть настоящее Соглашение в любое время, полностью удалив ПО.
      </li>
      <li>
        <span>6.5.</span>
        В случае если компетентный суд признает какие-либо положения настоящего Соглашения недействительными, Соглашение
        продолжает действовать в остальной части.
      </li>
      <li>
        <span>6.6.</span>
        Настоящее Соглашение также распространяется на все обновления (новые версии) ПО, предоставляемые Пользователю в
        течение срока его действия, если только при обновлении ПО Пользователю не будет предложено ознакомиться и
        принять отдельный лицензионный договор или дополнения к настоящему Соглашению.
      </li>

    </ol>
    <h3>7. Контактная информация Лицензиора</h3>
    <p>Время работы техподдержки:</p>
    <p>Будние дни (пн-пт): с 09:00 до 18:00 мск</p>
    <p>Обратиться в службу технической поддержки можно по электронной почте crm@place-start.ru</p>
    <p>Контактные телефоны службы технической поддержки:</p>
    <p><a href="tel: +7 (8172) 26-41-00">+7 (8172) 26-41-00</a></p>
    <p class="last"><a class="last" href="tel: 8 (800) 777-02-53">8 (800) 777-02-53</a></p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style lang="scss" scoped>
.last {
  margin-bottom: unset !important;
}

.--children-ls-n {
  & > li {
    list-style: none !important;
  }
}

@mixin font_t($size, $lineHeight) {
  font-size: #{$size}px !important;
  line-height: #{$lineHeight}px !important;
  @include _1170 {
    font-size: calc_fluid($size - ($size / 4), $size, 320, 1920) !important;
    line-height: calc_fluid(
            $lineHeight - ($lineHeight / 4),
            $lineHeight,
            320,
            1920
    ) !important;
  }
}

.content-text {
  flex: 1;
  padding: 20px 40px;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: -16px;

  & h1,
  h2,
  h3,
  h4,
  caption {
    font-weight: bold;
    color: $base;
    width: 100%;
  }

  & h2,
  h3,
  h4 {
    margin-top: 24px;
  }

  & p {
    color: $base;
    font-size: calc_fluid(14, 16, 320, 1920);
    line-height: calc_fluid(21, 24, 320, 1920);
    margin-bottom: 16px;
    width: 100%;
  }

  & span {
    color: $base;
    font-size: calc_fluid(14, 16, 320, 1920);
    line-height: calc_fluid(21, 24, 320, 1920);
  }

  & img {
    width: 100% !important;
    object-fit: contain;
  }

  & h1 {
    margin-bottom: 28px;
    @include font_t(38, 43);
  }

  & h2 {
    @include font_t(31, 40);
    margin-bottom: 24px;
  }

  & h3 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }

  & h4 {
    @include font_t(28, 36);
    margin-bottom: 20px;
  }

  & ol,
  ul {
    padding-left: 20px;
    margin-bottom: 16px;
    width: 100%;

    & > li {
      width: 100%;
      margin-top: 12px;
      color: $base;
      font-size: calc_fluid(14, 16, 320, 1920);
      line-height: calc_fluid(19, 22, 320, 1920);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  & ol {
    & > li {
      list-style-type: decimal;
    }
  }

  & ul {
    & > li {
      position: relative;
      padding-left: 9px;
    }
  }

  & a {
    color: #eb5757 !important;
    text-decoration: underline;

    &:hover {
      color: #eb5757 !important;
      text-decoration: none;
    }

    &:visited {
      color: #7a33d4 !important;
      text-decoration: underline;
    }
  }
}
</style>
