<script>
import Project from "../store/models/Project";
import BasePreloader from "../components/BasePreloader";
import BaseIcon from "../components/icons/BaseIcon";
import Logo from "../components/icons/BaseLogo";
import DayPicker from "../components/ProjectPublicationsDayPicker";
import Datepicker from "../components/Datepicker";
import ClientPublication from "../components/ClientPublication";
import {createNamespacedHelpers} from "vuex";
const { mapActions } = createNamespacedHelpers('authStore')
export default {
  name: "ClientView",
  data() {
    return {
      loaded: false,
      datepickerShow: false,
      projectId: -1,
      approveStage: -1,
      publications: [],
      dayPublications: [],
      date: {
        day: 0,
        month: 0,
        year: 0,
        object: 0,
        update: false,
      },
    };
  },
  components: {
    BasePreloader,
    BaseIcon,
    Logo,
    DayPicker,
    Datepicker,
    ClientPublication,
  },
  props: {
    projectUUID: String,
  },
  methods: {
    initView(projectUUID) {
      const data = new FormData();
      data.append("uuid", projectUUID);
      this.$API.post("projects/clientInfo", data).then((response) => {
        this.projectId = response.data.id;
        this.approveStage = response.data.approve_step;
        Project.insert({ data: response.data });
        this.publications = this.getPublicationsQuery()
          .withAllRecursive()
          .get();
        const urlDateParam = this.$route.query.date;
        this.date.update = false;
        if (urlDateParam) {
          let [year, month, day] = urlDateParam.split("-");
          console.log(year, month, day);
          this.date.object = new Date(year, month - 1, day);
        } else {
          this.date.object = new Date(Date.now());
        }
        this.date.update = false;
        this.loaded = true;
      });
    },
    nextDay() {
      this.date.object = new Date(
        this.date.object.setDate(this.date.object.getDate() + 1)
      );
    },
    prevDay() {
      this.date.object = new Date(
        this.date.object.setDate(this.date.object.getDate() - 1)
      );
    },
    getPublicationsQuery() {
      return this.$store.getters["entities/publications/query"]()
        .where("project", this.projectId)
        .where("template", false)
        .where("draft", false);
    },
    onDateSelect(date) {
      this.date.object = date;
    },
    updateDayPublications() {
      this.dayPublications = this.getPublicationsQuery()
        .where("pub_date", this.date.object.toLocaleDateString())
        .withAllRecursive()
        .get();
    },
    ...mapActions([
        'updateUserNoCache',
    ])
  },
  computed: {
    datepickerPublications() {
      let dpPublications = [];
      let addedDates = [];
      for (const publication of this.publications) {
        if (addedDates.indexOf(publication.pub_date) === -1) {
          dpPublications[publication.pub_date] = {
            published: 0,
            count: 0,
            wait: 0,
            error: 0,
          };
          addedDates.push(publication.pub_date);
        }
        if (publication.published) {
          dpPublications[publication.pub_date].published++;
        } else if (publication.error) {
          dpPublications[publication.pub_date].error++;
        } else if (publication.approved) {
          dpPublications[publication.pub_date].wait++;
        } else if (publication.stage) {
          dpPublications[publication.pub_date].count++;
        }
      }

      return dpPublications;
    },
  },
  watch: {
    projectUUID: {
      handler: function (v) {
        if (v) this.initView(v);
      },
      immediate: true,
    },
    "date.object": {
      handler: function (v) {
        if (this.date.update) return;
        this.date.day = v.getDate();
        this.date.year = v.getFullYear();
        this.date.month = v.getMonth();
        this.$router.push({
          query: {
            date: `${this.date.year}-${this.date.month + 1}-${this.date.day}`,
          },
        });
        this.dayPublications = this.getPublicationsQuery()
          .where("pub_date", this.date.object.toLocaleDateString())
          .withAllRecursive()
          .get();
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch("clearPage");
  },
  mounted() {
    document.addEventListener("click", (evt) => {
      let target = evt.target || evt.currentTarget;
      if (!target.closest(".sp-client-cshow")) {
        this.datepickerShow = false;
      }
    });
    let formData = new FormData();
    formData.append("uuid", this.projectUUID);
    this.$API.post("projects/client", formData).then((response) => {
      this.updateUserNoCache(response.data);
    });
  },
};
</script>
<template>
  <div class="sp-client">
    <base-preloader :radius="80" :show="!loaded">
      <div class="sp-client-header">
        <base-icon icon-name="#всепоплану" width="215" height="20">
          <logo fill="#1E1E1E" />
        </base-icon>
      </div>
      <div class="sp-client__wrapper">
        <div class="sp-client__container">
          <div class="sp-client__top">
            <day-picker :date="date.object" :next="nextDay" :prev="prevDay" />
            <a href="#" class="sp-client-cshow" @click="datepickerShow = true">
              Показать календарь
              <div
                class="sp-client-cshow-datepicker"
                v-show="datepickerShow"
              >
                <Datepicker
                  @selectDate="onDateSelect"
                  :date="date.object"
                  :publications="datepickerPublications"
                ></Datepicker>
              </div>
            </a>
          </div>
          <div class="sp-client__publications">
            <client-publication
              v-for="publication in dayPublications"
              :key="publication.id"
              :publication="publication"
              :uuid="projectUUID"
              :approveStage="approveStage"
              @approve="updateDayPublications"
            />
          </div>
        </div>
      </div>
    </base-preloader>
  </div>
</template>

<style lang="scss">

.sp-client {
  flex: 1;
  height: 100vh;
  background: $white;

  &-header {
    height: 69px;
    width: 100%;
    background: $inactive;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    display: flex;
    justify-content: center;
  }

  &__container {
    width: 880px;
    @include _979 {
      width: 100%;
      padding: 0 15px 20px;
    }
  }

  &__top {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include _480 {
      flex-direction: column;
      margin-top: 15px;
    }
  }

  &-cshow {
    @include font(14, 18);
    display: block;
    border-bottom: 1px dashed $borderA8;
    height: fit-content;
    text-decoration: none;
    position: relative;
    user-select: none;

    &-datepicker {
      position: absolute;
      right: 0;
      z-index: 4;
      top: calc(100% + 4px);
    }
  }
}
.sp-publications-day_picker {
  @include _480 {
    margin-bottom: 10px;
  }
}
</style>
