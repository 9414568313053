<script>

export default {
  name: "VkConfirmGroupAccess",
  methods: {
    ConfirmAuth() {
      const code = this.$route.query.code;
      /*const state = this.$route.query.state;*/
      if (!code) this.$router.replace("/");
      console.log(code);
      let form = new FormData()
      form.append('project', localStorage.extendGroupProject)
      form.append('group', localStorage.extendGroup)
      form.append('code', code)
      this.$API
        .post("projects/accounts/group_token/", form)
        .then(() => {
          window.close();
        });

    },
  },
  beforeCreate() {
    this.$store.commit('PRELOADER', true);
  },
  created() {
    this.ConfirmAuth();
  },
};
</script>

<template>
  <div class="sp-preload-page">
    <div class="sp-preloader"></div>
  </div>
</template>
