<script>
import { socialShortById, idToShortCode } from "/src/logic/enums";
import BaseIcon from "../components/icons/BaseIcon";
import VkIcon from "../components/icons/SocialMiniVk";
import OkIcon from "../components/icons/SocialMiniOk";
import TgIcon from "../components/icons/SocialMiniTg";
import ImIcon from "../components/icons/SocialMiniInsta";
import AccountWarnIcon from "../components/icons/BaseWarnMini"

const typeValidator = {
  1: function (group) {
    group.warns = [];
    return true;
  },
  2: function (group) {
    group.warns = [];
    if (group.social === 3)
      group.warns.push(
        "Одноклассники не поддерживают публикацию историй от имени групп. Запись будет опубликована как пост."
      );
    if (group.social === 2)
      group.warns.push(
        "Инстаграм API на данный момент не поддерживают публикацию историй. Запись будет опубликована как пост."
      );
    return true;
  },
};

export default {
  name: "ProjectPublicationsCreateGroupSelector",
  data() {
    return {
      socialShortById,
      idToShortCode,
      accounts: [],
    };
  },
  components: {
    BaseIcon,
    VkIcon,
    OkIcon,
    ImIcon,
    TgIcon,
    AccountWarnIcon,
  },
  props: {
    currentProject: Number,
    modelValue: Array,
    type: Number,
    baseAccounts: Array,
  },
  computed: {
    displayAccounts() {
      let display = [];
      console.log(this.accounts);
      if (!this.accounts) return display;
      for (const group of this.accounts) {
        if (typeValidator[this.type](group)) display.push(group);
      }
      return display;
    },
  },
  methods: {
    onGroupClick(id) {
      id = parseInt(id);
      let value = this.modelValue;
      if (!Array.isArray(value)) value = value.split(",");
      let select = [];
      const index = value.indexOf(id);
      if (index !== -1) {
        select = [...value.slice(0, index), ...value.slice(index + 1)];
      } else {
        select = value.slice(0);
        select.push(id);
      }
      this.$emit("update:modelValue", select);
    },
    inSelected(id) {
      id = parseInt(id);
      return this.modelValue.indexOf(id) !== -1;
    },
  },
  watch: {
    baseAccounts() {
      this.accounts = this.baseAccounts;
    },
  },
  created() {
    this.accounts = this.baseAccounts;
  },
};
</script>

<template>
  <div class="group-selector">
    <div
      class="group-selector-item sp-btn"
      v-for="account in displayAccounts"
      :key="account.id"
      @click="onGroupClick(account.id)"
      :class="{ selected: inSelected(account.id), warn: account.warns.length }"
    >
      <div class="group-selector-item-info">
        <div class="group-selector-item-info-warn"
             v-show="account.warns.length"
        >
          <base-icon height="17"
                     width="17"
                     view-box="1 1 19 19"
                     icon-name="Предупреждение">
            <account-warn-icon/>
          </base-icon>
          <div
            class="group-selector-item-info-warn-hint"
            v-show="account.warns.length"
          >
            <div
              class="group-selector-item-info-warn-hint-item"
              v-for="warn in account.warns"
              :key="warn"
            >
              {{ warn }}
            </div>
          </div>
        </div>
        <div class="sp-account-error" v-show="account.error">
          <base-icon height="17"
                     width="17"
                     icon-name=""
                     view-box="1 1 19 19"
          >
            <account-warn-icon/>
          </base-icon>
          <div class="sp-account-error-container">
            <div class="sp-account-error-info">
              <p class="sp-account-error-info__title">
                {{account.error && account.error.title ? account.error.title : ""}}
              </p>
              <p class="sp-account-error-info__text">
                {{account.error && account.error.description ? account.error.description : ""}}
              </p>
            </div>
          </div>
        </div>
      </div>

      <base-icon
              width="20"
              height="20"
              :icon-name="idToShortCode[account.social][0]"
              view-box="0 0 41 41"
      >
        <component :is="idToShortCode[account.social][1]"/>
      </base-icon>
      {{ account.title }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/env";
.sp-account-error {
  position: unset !important;
  margin-left: 5px;
}
.group-selector {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-items: center;
  margin: -7.5px -7.5px 12.5px;
  &-item {
    width: unset;
    padding: 14px 20.5px;
    @include font(14,17,bold,$white);
    background: $publicationsAccountBg;
    position: relative;
    margin: 7.5px;
    &-info {
      position: absolute;
      top: -10px;
      right: -10px;
      height: 20px;
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin: 5px;
      &-warn {
        position: relative;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        background: $white;
        border: 1px solid $border;
        display: flex;
        justify-content: center;
        align-items: center;
        $warn: &;
        &-hint {
          display: none;
          z-index: 2;
          position: absolute;
          top: calc(100% + 2px);
          left: 0;
          width: 200px;
          border-radius: 8px;
          background: $white;
          border: 1px solid $border;
          &-item {
            @include font(12,17,normal,$text1);
            padding: 15.5px 15.5px 15px;
          }
        }
        &:hover {
          #{$warn}-hint {
            display: block;
          }
        }
      }
    }
    & > svg {
      position: absolute;
      right: -4px;
      bottom: -5px;
    }
    &.selected {
      background: $mainBtn;
    }
  }
}
</style>
