<script>
    import BaseIcon from "./icons/BaseIcon";
    import ListIcon from "./icons/BaseList";

    export default {
        components: {
            BaseIcon,
            ListIcon
        },
        props: {
            notify: Object,
        }
    }
</script>
<template>
    <base-icon class="sp-header-notify__icon"
               height="40"
               width="40"
               view-box="0 0 24 24"
    >
        <list-icon/>
    </base-icon>
    <div class="sp-header-notify__body">
        <p class="sp-header-notify__title">Новых уведомлений нет</p>
        <p class="sp-header-notify__description">
            Уведомления о ошибках и согласовании появятся в этом разделе
        </p>
    </div>
</template>

<style lang="scss" scoped>
    @import "src/assets/scss/env";
    .sp-header-notify {
        &__body {
            margin-top: 2px;
        }
        &__title {
            @include font(14,18,null, )
        }
        &__date {
            width: 100%;
            @include font(12,16,null,);
        }
        &__description {
            @include font(12,16, null, );
            margin-top: 5px;
        }
        &__icon {
            min-width: 40px;
            margin-right: 12px;
        }
    }
</style>

