import { Model } from '@vuex-orm/core'
import Account from "./Account";
import Publication from "./Publication";
import QuickLink from "./QuickLink";
import Step from "./Step";
import Member from "./Member";
import Notification from "./Notification";
import MessengerTag from "./MessengerTag";
import Caption from "./Caption";
import PublicationTemplate from "./PublicationTemplate";
import Client from "@/store/models/Client";
import Reciever from "@/store/models/Reciever";

export default class Project extends Model {
    static entity = "projects"
    static fields () {
        return {
            id: this.attr(null),
            title: this.attr(""),
            b_task: this.attr(""),
            b_whook: this.attr(""),
            b_user: this.attr(""),
            b_notification_whook: this.attr(""),
            timezone: this.attr(""),
            clientUUID: this.attr(""),
            approve_step: this.attr(""),
            timezone_name: this.attr(""),
            accounts: this.hasMany(Account, 'project'),
            publications: this.hasMany(Publication, 'project'),
            quick_links: this.hasMany(QuickLink, 'project'),
            members: this.hasMany(Member, 'project'),
            notifications: this.hasMany(Notification, 'project'),
            steps: this.hasMany(Step, 'project'),
            clients: this.hasMany(Client, 'project'),
            recievers: this.hasMany(Reciever, 'project'),
            templates: this.hasMany(PublicationTemplate, 'project'),
            messenger_tags: this.hasMany(MessengerTag, 'project'),
            captions: this.hasMany(Caption, 'project'),
        }
    }

}