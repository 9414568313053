<script>
import ProjectMenu from "../../components/ProjectMenu";
import SettingsMenu from "../../components/SettingsMenu";

import BaseIcon from "../../components/icons/BaseIcon";
import CloseIcon from "../../components/icons/BaseClose";
import EditIcon from "../../components/icons/BaseEdit";
import DragIcon from "../../components/icons/NavbarDrag";
import BaseInput from "../../components/BaseInput";
import BaseLayout from "@/layouts/BaseLayout"

import draggable from "vuedraggable";
import {mapState} from "vuex";
import Step from "../../store/models/Step";


export default {
  name: "SettingsStages",
  data() {
    return {
      sortableStages: [],
      drag: false,
      deleteModal: {
        id: -1,
        show: false
      },
      modal: {
        show: false,
        mode: 0,
        target: 0,
        form: {
          title: "",
          prev: "ne",
          next: "ne",
          prevTarget: "",
          nextTarget: "",
          notify: false,
        },
        openCreate() {
          this.mode = 0;
          this.show = true;
          this.form = {
            title: "",
            prev: "ne",
            next: "ne",
            prevTarget: "",
            nextTarget: "",
          };
        },
        openChange(stage) {
          this.mode = 1;
          this.show = true;
          this.target = stage.id;
          this.form = {
            title: stage.title,
            prev: stage.prev_action,
            next: stage.next_action,
            prevTarget: stage.prev_target,
            nextTarget: stage.next_target,
            notify: stage.notify,
          };
        },
      },
    };
  },
  methods: {
    async stageAdd() {
      console.log('in')
      let formData = new FormData(this.$refs.stageForm);
      await this.$API
        .post("projects/stages/add", formData)
        .then((response) => Step.insert({data: response.data}));
      this.modal.show = false;
    },
    async stageChange() {
      let formData = new FormData(this.$refs.stageForm);
      formData.append("id", this.modal.target);
      await this.$API
        .post("projects/stages/change", formData)
        .then((response) => Step.insert({data: response.data}));
      this.modal.show = false;
    },
    deleteModalOpen(account) {
      this.deleteModal.id = account.id
      this.deleteModal.show = true
    },
    async deleteModalConfirm() {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("id", this.deleteModal.id);
      await this.$API.post("projects/stages/remove", formData)
              .then(() => {
                Step.delete(this.deleteModal.id);
                this.deleteModalClose()
              });
    },
    deleteModalClose() {
      this.deleteModal.id = -1
      this.deleteModal.show = false
    },
    stagesPositionChange() {
      let order = {};
      for (let [index, stage] of this.sortableStages.entries()) {
        order[parseInt(stage.id)] = index;
      }
      let formData = new FormData();
      formData.append("order", JSON.stringify(order));
      formData.append("project", this.currentProject);
      this.$API.post("projects/sortableStages/change/position", formData);
    },
  },
  components: {
    ProjectMenu,
    SettingsMenu,
    draggable,
    BaseIcon,
    DragIcon,
    EditIcon,
    CloseIcon,
    BaseInput,
    BaseLayout,
  },
  computed: {
    stages() {
      return this.project && this.project.steps || []
    },
    ...mapState(['currentProject', 'project', 'projectTime']),
  },
  watch: {
    stages: {
      handler: function (v) {
        if (v) this.sortableStages = v.sort(v => v.position);
      },
      immediate: true,
    }
  },
};
</script>

<template>
  <BaseLayout>
    <div class="sp-flex">
    <ProjectMenu :project-id="currentProject" :project="project" :time="projectTime"></ProjectMenu>
    <div class="sp-settings-page">
      <h2 class="sp-settings-page__title">Этапы проекта</h2>
      <p class="sp-settings-page__description">
        Вы можете создать и настроить любую цепь этапов для создания поста, таким
        образом вы сможете переносить пост из одного этапа в другой до финального
        согласования и публикации поста. Это поможет организовать совместную
        работу над созданием, обсуждением и согласованием постов среди ваших
        коллег и клиентов.
      </p>
      <div class="sp-settings-page__create sp-btn sp-btn--main_outlined"
           @click="modal.openCreate"
      >
        Создать
      </div>
      <draggable class="sp-settings-page__list"
                 v-model="sortableStages"
                 item-key="id"
                 @sort="stagesPositionChange"
      >
          <template #item="{ element }">
            <div class="sp-settings-page-item sp-sortable">
              <div class="sp-sortable__content">
                <base-icon class="sp-sortable__drag"
                           height="30"
                           width="30"
                           icon-name="Сортировка"
                >
                 <drag-icon/>
                </base-icon>
                {{element.title}}
              </div>
              <div class="sp-sortable__actions">
                <div class="sp-settings-page-item__action sp-settings-page-item__edit sp-btn"
                  @click="modal.openChange(element)"
                >
                  <base-icon height="20"
                             width="20"
                             icon-name="Редактировать"
                  >
                    <edit-icon/>
                  </base-icon>
                </div>
                <div
                  class="sp-settings-page-item__action sp-btn"
                  @click="deleteModalOpen(element)"
                >
                  <base-icon height="20"
                             width="20"
                             icon-name="Удалить"
                  >
                    <close-icon/>
                  </base-icon>
                </div>
              </div>
            </div>
          </template>
        </draggable>
    </div>
    <SettingsMenu :current-project="currentProject"></SettingsMenu>
  </div>
  </BaseLayout>
  <Modal v-model="modal.show"
         :close="() => (modal.show = false)"
  >
    <form class="sp-modal sp-modal-edit" ref="stageForm">
      <input type="hidden" name="project" :value="currentProject">
      <p class="sp-modal__title sp-modal-edit__title">Этап</p>
      <base-input name="title" label="Название"
                  :input-class-list="['sp-sp-modal-edit__input']"
                  v-model="modal.form.title"
      >
      </base-input>
      <div class="sp-modal-edit-select sp-btn">
        <p class="sp-modal-edit-select__placeholder">
          Действие назад
        </p>
        <select class="sp-modal-edit-select__select"
                id="stageAddPrev"
                name="prev_action"
                v-model="modal.form.prev"
        >
          <option value="ne">Никаких действий</option>
          <option value="ay">Перейти к любому этапу</option>
          <option value="bk">Перейти к предыдущему этапу</option>
          <option value="nt">Перейти к следующему этапу</option>
          <option value="cn">Перейти к определенному этапу</option>
          <option value="ae">Утвердить</option>
        </select>
      </div>
      <div class="sp-modal-edit-select sp-btn" v-show="modal.form.prev === 'cn'">
        <p class="sp-modal-edit-select__placeholder">
          Определенный этап
        </p>
        <select class="sp-modal-edit-select__select"
                id="stageAddPrevTarget"
                name="prev_target"
                v-model="modal.form.prevTarget"
        >
          <option v-for="stage in sortableStages" :key="stage.id" :value="stage.id">
            {{ stage.title }}
          </option>
        </select>
      </div>
      <div class="sp-modal-edit-select sp-btn">
        <p class="sp-modal-edit-select__placeholder">
          Действие вперед
        </p>
        <select class="sp-modal-edit-select__select"
                id="stageAddNext"
                name="next_action"
                v-model="modal.form.next"
        >
          <option value="ne">Никаких действий</option>
          <option value="ay">Перейти к любому этапу</option>
          <option value="bk">Перейти к предыдущему этапу</option>
          <option value="nt">Перейти к следующему этапу</option>
          <option value="cn">Перейти к определенному этапу</option>
          <option value="ae">Утвердить</option>
        </select>
      </div>
      <div class="sp-modal-edit-select sp-btn" v-show="modal.form.next === 'cn'">
        <p class="sp-modal-edit-select__placeholder">
          Определенный этап
        </p>
        <select class="sp-modal-edit-select__select"
                id="stageAddNextTarget"
                name="next_target"
                v-model="modal.form.nextTarget"
        >
          <option v-for="stage in sortableStages" :key="stage.id" :value="stage.id">
            {{ stage.title }}
          </option>
        </select>
      </div>
      <div class="sp-btn">
        <label for="stageNotify">Уведомление в битрикс</label>
        <input id="stageNotify" type="checkbox" name="notify" v-model="modal.form.notify">
      </div>
      <button class="sp-btn sp-btn--main sp-modal-edit__create"
              @click.prevent="modal.mode ? stageChange() : stageAdd()"
      >
        {{ modal.mode ? "Изменить" : "Добавить" }}
      </button>
    </form>
  </Modal>
  <Modal v-model="deleteModal.show" :close="deleteModalClose">
    <div class="sp-modal sp-modal-delete">
      <p class="sp-modal__title">Хотите удалить этап?</p>
      <div class="sp-modal-delete__bottom">
        <button class="sp-modal-delete__back sp-btn sp-btn--optional"
                @click.prevent="deleteModalClose"
        >
          Нет, вернуться
        </button>
        <button class="sp-modal-delete__delete sp-btn sp-btn--main"
                @click.prevent="deleteModalConfirm"
        >
          Да, удалить безвозвратно
        </button>
      </div>
    </div>
  </Modal>
</template>

