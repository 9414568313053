<script>

import BaseIcon from "../../components/icons/BaseIcon";
import ArrowIcon from "../../components/icons/BaseArrow";
import BaseAccount from "../../components/BaseAccount";
import BaseLayout from "@/layouts/BaseLayout"
import {limitString} from "../../logic/tools";
import draggable from "vuedraggable";

import {mapState} from "vuex"
import Publication from "../../store/models/Publication";

export default {
  name: "ProjectKanban",
  data() {
    return {
      orderedKanban: {
        items: [],
        indexes: {},
        getStage(stageId) {
          return this.items[this.indexes[stageId]]
        },
        push(stage, items) {
          this.items.push({id: stage.id.toString(), title: stage.title, items: items});
          this.indexes[stage.id] = this.items.length - 1;
        },
        pushItem(stageId, item) {
          this.getStage(stageId).items.push(item);
        },
        findStageByItem(item, index) {
          for (let stage of this.items) {
            if (stage.items.length - 1 < index) continue;
            if (stage.items[index].id === item.id) {
              return stage
            }
          }
        },
        sort(stages) {
          this.items.sort(v => {
            return stages[v.id].position
          })
          this.indexes = {}
          for (let [index, item] of Object.entries(this.items)) {
            this.indexes[item.id] = index
          }
        },
        refill(stages) {
          this.items = []
          this.indexes = {}
          for (let stage of Object.entries(stages).flatMap(v => v[1])) {
            this.push(stage, [])
          }
        }
      },
      arrowIntervalIds: {
        left: 0,
        right: 0,
      },
      isMounted: false,
    };
  },
  components: {
    BaseIcon,
    BaseAccount,
    draggable,
    ArrowIcon,
    BaseLayout,
  },
  props: {
    currentProject: Number,
  },
  methods: {
    move(evt) {
      if ('added' in evt) {
        let publication = evt['added']['element']
        let stage = this.orderedKanban.findStageByItem(publication, evt['added']['newIndex']);
        this.changeStage(publication.id, stage.id)
      }
    },
    async changeStage(publicationId, stageId) {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("publication", publicationId);
      formData.append("stage", stageId);
      await this.$API.post("projects/publications/stage", formData)
          .then((response) => {
            Publication.insert({data: response.data});
          });
    },
    getStageColor(stage) {
      if (stage.id === "-1") {
        return "sp-kanban-stage__header_bg_wait"
      } else if (stage.id === "-2") {
        return "sp-kanban-stage__header_bg_draft"
      } else if (stage.id === "-3") {
        return "sp-kanban-stage__header_bg_approved"
      } else {
        return "sp-kanban-stage__header_bg_stage"
      }
    },
    limitString,
    log: function (evt) {
      window.console.log(evt);
    },
    leftMouseOver() {
      this.arrowIntervalIds.left = setInterval(() => {
        this.$refs.kanbanBody.scrollLeft -= 2
      }, 5)
    },
    leftMouseOut() {
      clearInterval(this.arrowIntervalIds.left)
    },
    rightMouseOver() {
      this.arrowIntervalIds.right = setInterval(() => {
        this.$refs.kanbanBody.scrollLeft += 2
      }, 5)
    },
    rightMouseOut() {
      clearInterval(this.arrowIntervalIds.right)
    },
  },
  computed: {
    stagesDict() {
      let steps = this.$store.getters['entities/steps/query']()
          .where('project', this.currentProject).get()
      let result = {
        "-1": {id: -1, title: "Ожидает публикации", position: 998},
        "-3": {id: -3, title: "Опубликовано", position: 999},
        "-2": {id: -2, title: "Черновик", position: -1000},

      }
      if (steps) {
        for (let step of steps) {
          result[step.id.toString()] = step
        }
      }
      return result
    },
    publications() {
      return this.$store.getters['entities/publications/query']()
          .where('project', this.currentProject)
          .where('template', false).withAllRecursive().get()
    },
    ...mapState(['project',])
  },
  watch: {
    publications: {
      handler: function (v) {
        if (v) {
          console.log(this.orderedKanban.items, this.stagesDict)
          this.orderedKanban.refill(this.stagesDict);
          console.log(this.orderedKanban.items, this.stagesDict)
          for (let publication of v) {
            let stage = publication.stage;
            if (!stage) {
              if (publication.published) {
                stage = "-3"
              } else if (publication.approved) {
                stage = "-1"
              } else {
                stage = "-2"
              }
            }
            this.orderedKanban.pushItem(stage.toString(), publication)
          }
          this.orderedKanban.sort(this.stagesDict)
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<template>
  <BaseLayout>
    <div class="sp-kanban">
    <div class="sp-kanban-back sp-btn"
         @click="$router.push({name: 'ProjectPublications',params: { currentProject: project.id },})">
      <base-icon height="14" width="14" icon-name="">
        <arrow-icon/>
      </base-icon>
      К публикациям
    </div>
    <h1 class="sp-kanban__title">Канбан</h1>
    <div class="sp-kanban-wrapper">
      <div class="sp-kanban-scroll sp-kanban-scroll__left"
           @mouseover="leftMouseOver"
           @mouseout="leftMouseOut"
      >
        <div class="sp-kanban-scroll__inner">
          <div class="sp-kanban-scroll__circle sp-kanban-scroll__circle-left sp-btn">
            <base-icon height="24"
                       width="24"
                       view-box="0 0 14 14"
                       icon-name=""
            >
              <arrow-icon></arrow-icon>
            </base-icon>
          </div>
        </div>
      </div>
      <div class="sp-kanban-body" ref="kanbanBody">
        <div class="sp-kanban-stage"
             v-for="stage of orderedKanban.items"
             :key="stage.id"
        >
          <div class="sp-kanban-stage__header"
               :class="getStageColor(stage)"
          >
            {{ stage.title }}
          </div>
          <draggable class="sp-kanban-stage-container"
                     :list="stage.items"
                     :sort="false"
                     group="kanban"
                     @change="move"
          >
            <template #item="{ element }">
              <div class="sp-kanban-publication">
                <div class="sp-kanban-publication__text"
                     v-show="element.attachments.message"
                >
                  {{ limitString(element.attachments.message, 98) }}
                </div>
                <!--                       <div class="sp-kanban-publication-images"></div>-->
                <!--                       <div class="sp-kanban-publication-attachments"></div>-->
                <div class="sp-kanban-publication-accounts">
                  <base-account class="sp-kanban-publication-account"
                                v-for="account of element.accounts"
                                :key="account.id"
                                :account="account"
                  />
                </div>
              </div>
            </template>
          </draggable>
        </div>
      </div>
      <div class="sp-kanban-scroll sp-kanban-scroll__right"
           @mouseover="rightMouseOver"
           @mouseout="rightMouseOut"
      >
        <div class="sp-kanban-scroll__inner">
          <div class="sp-kanban-scroll__circle sp-kanban-scroll__circle-right sp-btn">
            <base-icon height="24"
                       width="24"
                       view-box="0 0 14 14"
                       icon-name=""
            >
              <arrow-icon></arrow-icon>
            </base-icon>
          </div>
        </div>
      </div>
    </div>

  </div>
  </BaseLayout>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-kanban {
  width: calc(100vw - 268px);
  margin: 59px 0;
  padding: 0 60px;

  &-wrapper {
    position: relative;
    overflow: hidden;
  }

  &-scroll {
    position: absolute;
    top: 50vh;
    z-index: 2;
    height: 128px;
    transform: translateY(-50%);

    &-inner {
      position: relative;
      transform: translateY(-50%);
    }

    &__circle {
      position: absolute;
      top: 0;
      width: 128px;
      border-radius: 50%;
      height: 128px;
      background: rgba(226, 226, 226, 0.49);

      &:hover {
        background: #E2E2E2;
      }

      &-left {
        left: -64px;
      }

      &-right {
        right: -64px;
      }
    }

    &__left {
      left: 0;
    }

    &__right {
      right: 0;
    }
  }

  &-body {
    position: relative;
    display: flex;
    margin-top: 30px;
    overflow-x: scroll;
  }

  &__title {
    margin-left: 130px;
    margin-top: 30px;
    @include font(30, 34, bold);
  }

  &-back {
    margin-left: 130px;
    width: unset;
    justify-content: unset;

    & > svg {
      transform: rotate(180deg);
      margin-right: 8px;

      & path {
        fill: $projectMenuTime;
      }
    }

    color: $projectMenuTime;
  }

  &-stage {
    width: 360px;
    min-width: 360px;
    min-height: 647px;

    &:first-child {
      border-radius: 8px 0 0 0;
    }

    &:last-child {
      border-radius: 0 8px 0 0;
      margin-right: 90px;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }

    border: 1px solid $borderDA;

    &__header {
      @include font(18, 30, bold, $white);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46.5px;

      &_bg_ {
        &draft {
          background: $borderC4;
          border-radius: 8px 0 0 0;
        }

        &stage {
          background: $borderFocused;
        }

        &approved {
          background: $statusSuccess;
          border-radius: 0 8px 0 0;
        }

        &wait {
          background: $statusWait;
        }
      }
    }

    &-container {
      padding: 20.5px 21px;
      height: calc(100% - 46.5px);
    }
  }

  &-publication {
    background: $white;
    border-radius: 8px;
    border: 1px solid $borderDA;
    padding: 17px;

    &:not(:first-child) {
      margin-top: 10.85px;
    }

    &__text {
      @include font(14, 21,)
    }

    &-accounts {
      margin-top: 15px;
      margin-left: -10px;
      display: flex;
    }

    &-account {
      transform: scale(0.741063829787234);
    }
  }

}
</style>
<style lang="scss">
@import "src/assets/scss/env";

.sp-kanban {
  &-scroll {
    &__circle {
      & svg {
        & path {
          fill: $text;
        }
      }

      &-left {
        & svg {
          margin-left: 30px;
          transform: rotate(180deg);
        }
      }

      &-right {
        & svg {
          margin-right: 30px;
        }
      }
    }
  }
}
</style>
