<script>
import ProjectMenu from "../../components/ProjectMenu";
import Timezone from "../../components/ProjectSettingsTimezone";
import SettingsMenu from "../../components/SettingsMenu";
import {windowParameters, popupAuthNoValidation} from "@/api";
import BaseIcon from "../../components/icons/BaseIcon";
import DragIcon from "../../components/icons/NavbarDrag";
import CloseIcon from "../../components/icons/BaseClose";
import BaseAccount from "../../components/BaseAccount";
import draggable from "vuedraggable";
import BaseInput from "../../components/BaseInputV2";

import {mapState} from "vuex"
import Account from "../../store/models/Account";
import Project from "../../store/models/Project";
import {popupAuth} from "@/api";
import BaseLayout from "@/layouts/BaseLayout"
import BaseRequestForm from "@/components/BaseRequestForm";
import BaseRequestFormBtn from "@/components/BaseRequestFormBtn";

export default {
  name: "ProjectSettings",
  data() {
    return {
      sortableAccounts: [],
      deleteModal: {
        id: -1,
        show: false
      },
      arhiveModalShow: false,
      form: {
        projectTitle: "",
        whook: "",
        task: "",
        author: "",
      }
    };
  },
  props: {
    currentProject: Number,
  },
  methods: {
    extendGroupPermissions(group_id) {
      let formData = new FormData()
      formData.append('group', group_id)
      formData.append('project', this.currentProject)
      localStorage.extendGroup = group_id
      localStorage.extendGroupProject = this.currentProject
      this.$API.post("projects/accounts/group_token/", formData)
          .then((response) => {
            let wnd = window.open(
                response.data.redirectLink,
                "vkPopup",
                windowParameters
            );
            popupAuthNoValidation(wnd, () => {

            })
          });
    },
    sort() {
      let order = {};
      for (let [index, stage] of this.sortableAccounts.entries()) {
        order[parseInt(stage.id)] = index;
      }
      let formData = new FormData();
      formData.append("order", JSON.stringify(order));
      formData.append("project", this.currentProject);
      this.$API.post("projects/accounts/position", formData);
    },
    deleteModalOpen(account) {
      this.deleteModal.id = account.id
      this.deleteModal.show = true
    },
    async deleteModalConfirm() {
      let formData = new FormData();
      formData.append("project", this.currentProject);
      formData.append("id", this.deleteModal.id);
      await this.$API.post("projects/accounts/delete", formData)
          .then(() => {
            Account.delete(this.deleteModal.id);
            this.deleteModalClose()
          });
    },
    deleteModalClose() {
      this.deleteModal.id = -1
      this.deleteModal.show = false
    },
    async projectFormResponseHandler(response) {
        Project.insert({data: response.data});
    },
    getProjects() {
      this.$API.get("projects").then((response) => {
        this.projects = response.data;
        this.$store.dispatch("projectsUpdate", response.data);
        this.$API.get("projects?archive=1").then((response) => {
          this.projects = response.data;
          this.$store.dispatch("archiveProjectsUpdate", response.data);
        })
      })
    },
    async archiveProject() {
      let formData = new FormData();
      formData.append('project', this.currentProject)
      await this.$API.post("projects/archive", formData)
          .then(() => {
            Project.delete(this.currentProject)
            this.getProjects()
          });
      this.$router.push({name: "Home"})
    },
    reconnectAccount(account) {
      this.$API.post("vk.userAuth", "state=project").then((response) => {
        let wnd = window.open(
            response.data.redirectLink,
            "vkPopup",
            windowParameters
        );
        popupAuth(
            wnd,
            "tempAuthVk",
            () => {
              let formData = new FormData();
              formData.append('project', this.currentProject)
              formData.append('account', account.id)
              formData.append('temp_token', localStorage.tempAuthVk)
              this.$API.post('projects/accounts/reconnect', formData).then(response => {
                Account.insert({data: response.data});
              })
              localStorage.vkAuth = false;
            },
            () => {
              console.log('ERROR')
            }
        );
      });

    },
    // openClientView() {
    //   this.$router.push({name: "ClientView", params: {projectUUID: this.project.clientUUID}});
    // },
  },
  computed: {
    accounts() {
      return this.$store.getters['entities/accounts/query']()
          .where('project', this.currentProject).get()
    },
    projectTitle() {
      return this.form.projectTitle ? this.form.projectTitle : this.project && this.project.title
    },
    ...mapState(["project", "projectTime"]),
  },
  components: {
    ProjectMenu,
    Timezone,
    SettingsMenu,
    BaseIcon,
    DragIcon,
    CloseIcon,
    BaseAccount,
    draggable,
    BaseInput,
    BaseLayout,
    BaseRequestForm,
    BaseRequestFormBtn,
  },
  watch: {
    accounts: {
      handler: function (v) {
        console.log("ACCOUNTS CHANGED", v)
        if (v) this.sortableAccounts = v.sort(v => v.position);
      },
      immediate: true,
    },
    project: {
      handler: function (v) {
        if (v) {
          this.form.projectTitle = v.title;
          this.form.whook = v.b_whook;
          this.form.author = v.b_user;
          this.form.task = v.b_task;
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    console.log('BM')
  },
  mounted() {
    console.log('mounted')
  },
  beforeUnmount() {
    console.log('BuM')
  },
  activated() {
    console.log('activated')
  },

};
</script>

<template>
  <BaseLayout>
    <div class="sp-flex">
      <ProjectMenu :project="project" :project-id="currentProject" :time="projectTime"></ProjectMenu>
      <div class="sp-settings">
        <h2 class="sp-settings__title">Аккаунты</h2>
        <draggable class="sp-settings__accounts" v-model="sortableAccounts" item-key="id" @sort="sort">
          <template #item="{ element }">
            <div class="sp-settings-account sp-sortable sp-btn">
              <div class="sp-sortable__content">
                <base-icon class="sp-sortable__drag"
                           width="30"
                           height="30"
                           icon-name="Сортировать"
                >
                  <drag-icon/>
                </base-icon>
                <base-account class="sp-settings__account"
                              :account="element"
                >
                </base-account>
                {{ element.title }}
              </div>
              <div class="sp-sortable__actions">
                <div class="sp-settings__extend sp-btn--main sp-btn"
                     v-show="element.reconnect"
                     @click="reconnectAccount(element)"
                >
                  Переродключить
                </div>
                <div class="sp-settings__extend sp-btn--optional sp-btn"
                     v-show="!element.extended && element.social === 1"
                     @click="extendGroupPermissions(element.id)"
                >
                  Расширить права
                </div>
                <base-icon height="20"
                           width="20"
                           icon-name="Отвязать аккаунт"
                           @click="deleteModalOpen(element)"
                >
                  <close-icon/>
                </base-icon>

              </div>
            </div>
          </template>
        </draggable>
        <div class="sp-settings__add-project sp-btn--main sp-btn"
             @click="$router.push({
            name: 'SettingsAccountAdd',
            params: { currentProject },
          })"
        >
          Подключить новый аккаунт
        </div>
        <div class="sp-settings__main">
          <h2 class="sp-settings__title">Настройки проекта</h2>
          <div class="sp-settings__archive sp-settings__extend sp-btn--optional sp-btn"
               @click="arhiveModalShow = true"
          >
            Архивировать проект
          </div>

          <router-link :to='{name: "ClientView", params: {projectUUID: project ? project.clientUUID : "blank"}}' target="_blank"
                       class="sp-settings__archive sp-settings__extend sp-btn--optional sp-btn"
          >
            Кабинет клиента
          </router-link>
         <BaseRequestForm action="projects/change/" :response-callback="projectFormResponseHandler" :margin-top="15">
            <BaseInput id="p_title" v-model="form.projectTitle" :height="50" name="title" title="Название"></BaseInput>
            <Timezone :currentProject="currentProject" :project="project"></Timezone>
            <BaseRequestFormBtn :maxWidth="215">Сохранить изменения</BaseRequestFormBtn>
         </BaseRequestForm>
<!--          <form class="sp-settings-form" ref="form">-->
<!--            <base-input class="sp-settings-input"-->
<!--                        name="title"-->
<!--                        label="Название"-->
<!--                        :input-class-list="['sp-settings-input__input']"-->
<!--                        v-model="form.projectTitle"-->
<!--            >-->
<!--            </base-input>-->
<!--            <Timezone :currentProject="currentProject" :project="project"></Timezone>-->
<!--&lt;!&ndash;            <h3 class="sp-settings__title sp-settings__title_h3">Отправка отчетов в bitrix24</h3>&ndash;&gt;-->
<!--&lt;!&ndash;            <base-input class="sp-settings-input"&ndash;&gt;-->
<!--&lt;!&ndash;                        name="whook"&ndash;&gt;-->
<!--&lt;!&ndash;                        label="Вебхук bitrix24"&ndash;&gt;-->
<!--&lt;!&ndash;                        :input-class-list="['sp-settings-input__input']"&ndash;&gt;-->
<!--&lt;!&ndash;                        v-model="form.whook"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--&lt;!&ndash;            <base-input class="sp-settings-input"&ndash;&gt;-->
<!--&lt;!&ndash;                        name="task"&ndash;&gt;-->
<!--&lt;!&ndash;                        label="Id задачи bitrix24"&ndash;&gt;-->
<!--&lt;!&ndash;                        :input-class-list="['sp-settings-input__input']"&ndash;&gt;-->
<!--&lt;!&ndash;                        v-model="form.task"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--&lt;!&ndash;            <base-input class="sp-settings-input"&ndash;&gt;-->
<!--&lt;!&ndash;                        name="author"&ndash;&gt;-->
<!--&lt;!&ndash;                        label="Id ответственного в задаче bitrix24"&ndash;&gt;-->
<!--&lt;!&ndash;                        :input-class-list="['sp-settings-input__input']"&ndash;&gt;-->
<!--&lt;!&ndash;                        v-model="form.author"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--            <div class="sp-settings-form__btn sp-btn&#45;&#45;main sp-btn"-->
<!--                 @click="projectFormSave"-->
<!--            >-->
<!--              Сохранить изменения-->
<!--            </div>-->
<!--          </form>-->

        </div>

      </div>
      <SettingsMenu :currentProject="currentProject"></SettingsMenu>
    </div>
  </BaseLayout>
  <Modal v-model="deleteModal.show" :close="deleteModalClose">
    <div class="sp-modal sp-modal-delete">
      <p class="sp-modal__title">Хотите удалить аккаунт?</p>
      <div class="sp-modal-delete__bottom">
        <button class="sp-modal-delete__back sp-btn sp-btn--optional"
                @click.prevent="deleteModalClose"
        >
          Нет, вернуться
        </button>
        <button class="sp-modal-delete__delete sp-btn sp-btn--main"
                @click.prevent="deleteModalConfirm"
        >
          Да, удалить безвозвратно
        </button>
      </div>
    </div>
  </Modal>
  <Modal v-model="arhiveModalShow" :close="()=> this.archiveModalShow = false">
    <div class="sp-modal sp-modal-delete">
      <p class="sp-modal__title">Хотите архивировать проект?</p>
      <div class="sp-modal-delete__bottom">
        <button class="sp-modal-delete__back sp-btn sp-btn--optional"
                @click.prevent="arhiveModalShow = false"
        >
          Нет, вернуться
        </button>
        <button class="sp-modal-delete__delete sp-btn sp-btn--main"
                @click.prevent="archiveProject"
        >
          Да
        </button>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-settings {
  flex: 1;
  margin: 40px 0;
  padding-right: 60px;

  &__archive {
    margin-top: 20px;
    height: 50px;
    width: 240px !important;
    font-size: 16px;
    line-height: 20px;
  }

  &__extend {
    height: 46px;
    width: 164.5px;
    margin-right: 40px;
  }

  &__add-project {
    margin-top: 20px;
    height: 52px;
    width: 261px;
    @include font(15, 20, bold, $white);
  }

  &__main {
    margin-top: 70px;
  }

  &-input {
    height: 54px;
  }

  &-form {
    margin-top: 30px;

    &__btn {
      @include font(15, 20, bold, $white);
      height: 52px;
      width: 214px;
      margin-top: 25px;
    }
  }

  &__title {
    @include font(24, 30, bold);

    &_h3 {
      margin-top: 20px;
      margin-bottom: 10px;
      @include font(18, 20, bold)
    }
  }

  &__accounts {
    margin-top: 20px;
  }

  &__account {
    margin-right: 20px;
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/env";

.sp-settings {
  &-input {
    &__input {
      padding: 11px 20px 0 !important;
      font-size: 16px !important;
      line-height: 20px !important;
    }
  }

  &-page {
    margin-top: 40px !important;
    flex: 1;
    padding: 0 60px 0 30px !important;

    &__title {
      @include font(24, 30, bold);
    }

    &__description {
      @include font(16, 24, normal);
      margin-top: 20px !important;
      max-width: 800px;
    }

    &-item {
      &__action {
        margin-left: 15px !important;
        height: 20px;

        &:hover {
          & path {
            fill: $text !important;
          }
        }
      }

      &__edit {
        & path {
          fill: none !important;
          stroke: $fgText !important;

        }

        &:hover {
          & path {
            fill: none !important;
            stroke: $text !important;
          }
        }
      }
    }

    &__create {
      height: 40px;
      padding: 0 15px;
      width: fit-content;

      margin-top: 20px !important;
    }

    &__list {
      margin-top: 40px !important;
      border-top: 1px solid $border;
    }
  }
}
</style>
