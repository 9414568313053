<script>
import HeaderNotifyApproved from "../../components/HeaderNotificationsNotifyApproved";
import HeaderNotifyError from "../../components/HeaderNotificationsNotifyError";
import HeaderNotifyPlaceholder from "../../components/HeaderNotificationsNotifyPlaceholder";
import {mapState} from 'vuex';
import Notification from "../../store/models/Notification";
import ProjectMenu from "../../components/ProjectMenu";
import BaseLayout from "@/layouts/BaseLayout"

export default {
  data() {
    return {
      dynamicComponents: {
        2: HeaderNotifyError,
        1: HeaderNotifyApproved,
      },
      currentPage: 1,
      maxPage: 2,
    }
  },
  components: {
    HeaderNotifyPlaceholder,
    ProjectMenu,
    BaseLayout,
  },
  computed: {
    notifications() {
      return this.$store.getters['entities/notifications/query']()
          .where('project', this.currentProject).orderBy('id', 'desc').withAllRecursive().get()
    },
    ...mapState(['currentProject', 'projectTime', 'project'])
  },
  methods: {
    getPage(page) {
      let formData = new FormData();
      formData.append('project', this.currentProject);
      formData.append('page', page);
      this.$API.post('projects/notifications/pagination', formData).then(response => {
        Notification.insert({data: response.data.items})
        this.maxPage = response.data.pages
      }).catch(() => {
        this.maxPage = this.currentPage
      })
    }
  },
  watch: {
    currentPage: {
      handler: function (v) {
        if (v <= this.maxPage) {
          this.getPage(v)
        }
      },
    }
  },
}
</script>

<template>
  <BaseLayout>
    <div class="sp-notifications">
    <ProjectMenu :project="project" :project-id="currentProject" :time="projectTime"></ProjectMenu>
    <div class="sp-notifications__content">
      <h1 class="sp-notifications__title">Уведомления проекта</h1>
      <div class="sp-notifications__items">
        <div class="sp-notifications-notify"
             v-for="notify in notifications"
             :key="notify.id"
        >
          <component :is="dynamicComponents[notify.type]"
                     :notify="notify"
          />
        </div>
        <div class="sp-notifications-notify"
             v-show="notifications && !notifications.length"
        >
          <HeaderNotifyPlaceholder/>
        </div>
        <div class="sp-notifications__more sp-btn sp-btn--optional"
             v-show="currentPage < maxPage"
             @click="currentPage += 1"
        >
          Загрузить еще
        </div>
      </div>
    </div>
  </div>
  </BaseLayout>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-notifications {
  flex: 1;
  display: flex;

  &__content {
  }

  &__title {
    margin-top: 30px;
    @include font(24, 30, bold);
  }

  &__items {
    margin-top: 30px;
    max-width: 450px;
    padding-bottom: 40px;
  }

  &-notify {
    display: flex;
    padding: 10px 15px;

    &:not(:last-child) {
      border-bottom: 1px solid $border;
    }
  }

  &__more {
    margin-top: 20px;
    height: 40px;
    width: 450px;
  }
}
</style>