<script>
    import MediaVideo from "./ProjectPublicationHistoryListItemMediaItemsVideo";
    import MediaImage from "./ProjectPublicationHistoryListItemMediaItemsImage";

    export default {
        data() {
            return {
                mediaType: {
                    1: MediaImage,
                    2: MediaVideo,
                }
            }
        },
        props: {
            items: Array,
        },
    }
</script>
<template>
    <div class="sp-history-list-media-item"
         v-for="item in items"
         :key="item.id"
    >
        <component :is="mediaType[item.type]" :item="item"/>
    </div>
</template>
<style lang="scss" scoped>
    @import "src/assets/scss/env";

    .sp-history-list-media {
        &-item {
            margin: 5px;
            width: 40px;
            height: 40px;
            border-radius: 8px;
            border: 1px solid $border;
            overflow: hidden;
            position: relative;
        }
    }
</style>