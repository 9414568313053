<script>
    export default {
        data() {
            return {
                modal: {
                    show: false,
                    loaded: false,
                    img: new Image,
                }
            }
        },
        props: {
            url: String,
        },
        methods: {
            open() {
                this.modal.show = true
            }
        },
        mounted() {
            this.modal.img.src = this.url;
            this.modal.img.onload = () => this.modal.loaded = true

        }
    }
</script>
<template>
    <slot :onClick="open"></slot>
    <Modal v-model="modal.show" :close="() => modal.show = !modal.show">
        <div class="sp-modal sp-preview-modal"
             :class="{'sp-preview-modal__h600': modal.loaded && modal.img.height > 599, 'sp-preview-modal__loading': !modal.loaded}"
        >
            <div class="sp-preloader"></div>
            <img :src="url">
        </div>
    </Modal>
</template>

<style lang="scss">
    .sp-preview-modal {
        padding: 10px;
        max-height: 600px;
        max-width: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        & > .sp-preloader {
            display: none;
            height: 200px;
            width: 200px;
        }
        &__loading {
            height: 300px;
            width: 300px;
            & > div {
                display: block !important;
            }
            & > img {
                display: none;
            }
        }
        &__h600 {
            height: 600px;
        }
        & > img {
            width: 100%;
            height: 100%;
        }
    }
</style>