import {API} from "@/plugins/axios";

export default {
    namespaced: true,
    state: () => ({
        authCompleted: false,
        token: "",
        user: {},
    }),
    mutations: {
        TOKEN_CHANGE(state, token) {
            state.token = token;
            localStorage.token = token;
        },
        AUTH_COMPLETED_CHANGE(state, completed) {
            state.authCompleted = completed;
            localStorage.authCompleted = true;
        },
        USER_CHANGE(state, user) {
            state.user = user;
            localStorage.user = JSON.stringify(user);
        },
        USER_CHANGE_NO_CACHE(state, user) {
            state.user = user;
        },
    },
    actions: {
        auth({commit}, {token, user}) {
            API.defaults.headers.Authorization = "Token " + token;
            commit("AUTH_COMPLETED_CHANGE", true);
            commit("TOKEN_CHANGE", token);
            if (user) {
                commit("USER_CHANGE", user);
            }
        },
        updateUserNoCache({commit}, user) {
            commit("USER_CHANGE_NO_CACHE", user);
        },
        authLocalStorage({commit}) {
            API.defaults.headers.Authorization = "Token " + localStorage.token;
            commit("AUTH_COMPLETED_CHANGE", true);
            commit("TOKEN_CHANGE", localStorage.token);
            commit("USER_CHANGE", JSON.parse(localStorage.user));
        }
    }
}