<script>
    export default {
        props: {
            show: Boolean,
            radius: Number,
        },
    }
</script>

<template>
    <div class="sp-preloader__container"
         v-if="show"
    >
        <div class="sp-preloader"
             :style="{width: radius * 2 + 'px', height: radius * 2 + 'px'}"

        ></div>
    </div>
    <slot v-else>

    </slot>
</template>

<style lang="scss" scoped>
    @import "src/assets/scss/env";

    .sp-preloader {

        border-radius: 50%;
        background: linear-gradient(93.54deg, $publicationsEditBtn 0%, #B5E134 98.15%);
        animation: spin 1s infinite, gradient 3s infinite;
      background-size: 400% 400%;
      transform: rotate3d(1, 1, 0, 0deg);
        &__container {
            height: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>
