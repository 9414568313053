<script>
import BaseAvatar from './BaseAvatar'
// import BaseInput from "./BaseInput";
import {mdiSend} from '@mdi/js';
import BaseMdiIcon from "./icons/BaseMdiIcon";
import BasePreloader from "./BasePreloader";
import BaseTextarea from "@/components/BaseTextarea";
import Publication from "../store/models/Publication";


export default {
  data() {
    return {
      form: {
        text: ""
      },
      comments: [],
      loaded: false,
      icons: {
        mdiSend
      },
    }
  },
  components: {
    BaseAvatar,
    // BaseInput,
    BaseMdiIcon,
    BasePreloader,
    BaseTextarea,
  },
  props: {
    publication: Object,
    uuid: {
      type: String,
      default: "",
    },
    clientView: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    get_comments() {
      let formData = new FormData();
      formData.append('publication', this.publication.id);
      if (this.uuid) formData.append('uuid', this.uuid);
      if (!this.clientView) formData.append('project', this.publication.project)
      this.$API.post(
          this.clientView ? 'projects/client/publications/comments' : 'projects/publications/comments',
          formData
      )
          .then(response => {
            this.comments = response.data
            this.loaded = true
          })
    },
    onKeypress(evt) {
      if (evt.key === "Enter") {
        evt.preventDefault();
      }
    },
    onKeyup(evt) {
      if (evt.key === "Enter") {
        if (evt.ctrlKey) {
          this.send()
        } else {
          this.form.text += "\n"
        }

      }
      console.log(evt);
    },
    async send() {
      if (!this.form.text) return
      let formData = new FormData(this.$refs.form);
      formData.append('publication', this.publication.id);
      if (this.uuid) formData.append('uuid', this.uuid);

      if (!this.clientView) formData.append('project', this.publication.project)
      this.$API.post(this.clientView ? 'projects/client/publications/comments/add' : 'projects/publications/comments/add', formData).then(async (response) => {
        this.comments.push(response.data)
        Publication.update({where: this.publication.id, data: {comments_count: this.comments.length}})
        this.form.text = ""
        this.$emit('commentAdd')
      })
    }
  },
  mounted() {
    this.get_comments()
  }
}
</script>

<template>
  <div class="sp-publication-comments">
    <base-preloader :radius="20" :show="!loaded">
      <div v-if="comments.length" class="sp-publication-comments-chat">
        <div v-for="comment in comments"
             :key="comment.id"
             :class="{'sp-publication-comments-chat-message_self': comment.user.id === $store.state.authStore.user.id }"
             class="sp-publication-comments-chat-message"
        >
          <base-avatar :alt="comment.user.name"
                       :radius="20"
                       :text="comment.user.name"
          />
          <div class="sp-publication-comments-chat-message__content">
            <div class="sp-publication-comments-chat-message__top">
              <p class="sp-publication-comments-chat-message__name">
                {{ comment.user.name }}
              </p>
              <p class="sp-publication-comments-chat-message__date">
                {{ comment.date }}
              </p>
            </div>
            <p class="sp-publication-comments-chat-message__text">
              {{ comment.text }}
            </p>
          </div>
        </div>
      </div>
    </base-preloader>
    <form ref="form" class="sp-publication-comments-send" @submit.prevent.stop>
      <base-avatar :alt="$store.state.authStore.user.name"
                   :text="$store.state.authStore.user.name"/>
      <BaseTextarea
          v-model="form.text"
          class="sp-publication-comments-send__input"
          label="Написать комментарий"
          name="text"
          @keydown="onKeypress"
          @keyup="onKeyup"
          ref="textarea"
      />
      <!--      <base-input name="text"-->
      <!--                  v-model="form.text"-->
      <!--                  type="text"-->
      <!--                  label="Написать комментарий"-->
      <!--                  class="sp-publication-comments-send__input"-->
      <!--                  @kd="keydown"-->
      <!--      />-->
      <div class="sp-publication-comments-send__btn sp-btn"
           @click="send"
      >
        <base-mdi-icon :padding="10"
                       :path="icons.mdiSend"
                       color="#9BBF2E"
                       name="Отправить"
        />
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@import "src/assets/scss/env";

.sp-publication-comments {
  &-chat {
    margin: 10px 0;
    padding: 20px;
    border-radius: 10px;
    background: $componentBg;
    display: flex;
    flex-direction: column;

    &-message {
      margin: 5px 0;
      display: flex;
      align-items: center;
      width: 60%;

      &__content {
        margin-left: 10px;
      }

      &__top {
        display: flex;
        align-items: center;
      }

      &__name, &__date {
        @include font(15, 18, bold);
      }

      &__date {
        margin-left: 10px;
        color: $inactiveText;
      }

      &__text {
        @include font(14, 16);
        white-space: pre-line;
      }

      $pcm: &;

      &_self {
        align-self: flex-end;
        flex-direction: row-reverse;

        & #{$pcm} {
          &__content {
            margin-right: 10px;
          }

          &__top {
            flex-direction: row-reverse;
          }

          &__text {
            text-align: right;
          }
        }
      }
    }
  }

  &-send {
    margin-top: 30px;
    display: flex;
    align-items: center;

    &__input {
      flex: 1;
      margin: 0 10px !important;
      transform: scale(0.9);
      @include _480 {
        margin: 0 !important;
      }

      & > :deep(.sp-input__label) {
        @include _480 {
          left: 12px;
          font-size: 12px !important;
          &--focused {
            font-size: 10px !important;
          }
        }

      }
    }

    &__btn {
      width: fit-content;
    }
  }
}
</style>